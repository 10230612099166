import React, { } from 'react';
import Header3 from '../layout/header3';
import Footer1 from '../layout/footer1';



function PrivacyPolicy() {

    return (
        <>
            <Header3 />
            <div className="terms_condition">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="terms_condition-content">
                                <div className="terms_condition-text">
                                    <h3>Privacy Agreement</h3>
                                    <p>This Privacy Agreement ("<strong>Agreement</strong>") is made and entered into by and between The Crypto Exchange, a non-custodial crypto exchange ("<strong>Company</strong>"), and the user ("<strong>User</strong>") of The Crypto Exchange's services and website. </p>
                                </div>
                                <div className="terms_condition-text">
                                    <div>
                                        <h3>1. User Information:</h3> 
                                        <p>
                                            The Crypto Exchange may collect and process the following information: 
                                        </p>
                                    </div>
                                        <p className="mb-0">
                                            ● Personal Information: Information provided by the User during account registration, including but not limited to name, email address, and contact information.
                                        </p>
                                    
                                        <p className="mb-0">
                                            ● Financial Information: Information related to transactions, withdrawals, and deposits conducted through The Crypto Exchange's platform. 
                                        </p>
                                    
                                        <p className="mb-0">
                                            ● Transaction Data: Records of cryptocurrency transactions, wallet addresses, and associated metadata.
                                        </p> 
                                    
                                        <p>
                                            ● Device Information: Information related to the User's device, including IP address, browser type, and operating system. 
                                        </p>
                                </div>

                                <div className="terms_condition-text">
                                    <div>
                                        <h3>2. Purpose of Data Collection: </h3>
                                        <p>
                                            The Crypto Exchange collects and processes User information for the following purposes: 
                                        </p>
                                    </div>
                                        <p className="mb-0">
                                            ● Account Management: To create and maintain User accounts, process transactions, and provide customer support.
                                        </p>
                                    
                                        <p className="mb-0">
                                            ● Security: To enhance platform security, prevent fraud, and comply with legal obligations.
                                        </p>
                                    
                                        <p className="mb-0">
                                            ● User Experience: To personalize User experiences and improve The Crypto Exchange's services.
                                        </p>   
                                    
                                        <p>
                                            ● Communication: To send important updates, notifications, and marketing materials with User consent. 
                                        </p>      
                                </div>

                                <div className="terms_condition-text">
                                        <h3>3. Data Protection:</h3>
                                        <p>
                                            The Crypto Exchange employs industry-standard security measures to protect User data. However, no method of data transmission over the internet or electronic storage is entirely secure. Users acknowledge the inherent risks and agree that The Crypto Exchange is not liable for data breaches beyond its control.
                                        </p>
                                </div>

                                <div className="terms_condition-text">
                                    <div>
                                        <h3>4. Data Sharing:</h3>
                                        <p>The Crypto Exchange may share User data with: </p>
                                    </div>                                  
                                        <p className="mb-0">
                                            ● Third-Party Service Providers: To facilitate services such as payment processing and identity verification.
                                        </p>
                                    
                                        <p>
                                            ● Legal and Regulatory Authorities: To comply with legal requirements, respond to legal requests, and protect The Crypto Exchange's rights.
                                        </p>
                                </div>

                                <div className="terms_condition-text">
                                    <div>
                                    <h3>5. User Rights:</h3>
                                    <p>Users have the following rights regarding their data:</p>
                                    </div>                                 
                                        <p className="mb-0">
                                            ● Access: The right to request access to their personal information.
                                        </p>
                                    
                                        <br />
                                    
                                        <p className="mb-0">
                                            ● Rectification: The right to request correction of inaccurate data.
                                        </p>
                                    
                                        <br />
                                    
                                        <p className="mb-0">
                                            ● Deletion: The right to request erasure of personal data, subject to legal obligations.
                                        </p>
                                    
                                        <p>
                                            ● Data Portability: The right to receive data in a structured, machine-readable format.
                                        </p>
                                </div>

                                <div className="terms_condition-text">
                                    <h3>6. Cookies:</h3>
                                    <p>
                                        The Crypto Exchange uses cookies to enhance the User experience. Users can adjust cookie settings in their browser but may limit certain website functionalities. 
                                    </p>
                                </div>


                                <div className="terms_condition-text">
                                    <h3>7. Amendments: </h3>
                                    <p>
                                        The Crypto Exchange reserves the right to update this Privacy Agreement at any time. Users will be notified of significant changes.
                                    </p>
                                </div>

                                <div className="terms_condition-text">
                                    <h3>8. Consent:</h3>
                                    <p>
                                        By using The Crypto Exchange's services, Users consent to the collection, processing, and sharing of their data as described in this Agreement. 
                                    </p>
                                </div>

                                <div className="terms_condition-text">
                                    <div>
                                        <h3>9. Contact Information:</h3>
                                        <p>
                                            For questions or concerns regarding this Privacy Agreement or User data, please contact:
                                        </p>
                                    </div>
                                    <p>
                                        The Crypto Exchange at contact@thecryptoexchange.us 
                                    </p>
                                    <p>
                                        By agreeing to this Privacy Agreement, the User acknowledges that they have read, understood, and accepted its terms and conditions. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer1 />
        </>
    )
}

export default PrivacyPolicy;