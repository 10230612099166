import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import {
  commonError,
  getTrades,
  getTransfers,
  resetGetTrades,
  resetGetTradesFailed,
  resetgetTransferFailed,
  resetGetExternalBankAccountListAction,
} from "../../actions/customerActions";
import { formatDate, formatDateHypen } from "../../utils/Encryption";
import Gifloader from "../layout/gifloader";

const DashboardTransaction = () => {

  const dispatch = useDispatch();

  // const getTransactionData = useSelector((state) => state.customerReducer.getTransferData);
  // const getTransactionFailed = useSelector((state) => state.customerReducer.getTransferFailed);
  const getTradesData = useSelector((state) => state.customerReducer.getTradesData);
  const getTradesFailed = useSelector((state) => state.customerReducer.getTradesFailed);

  // const [DepositArr, setDepositArr] = useState([]);
  // const [WithdrawArr, setWithdrawArr] = useState([]);
  // const [PendingArr, setPendingArr] = useState([]);
  // const [CanceledArr, setCanceledArr] = useState([]);
  const [trades, setTrades] = useState([]);
  const [buyArr, setBuyArr] = useState([]);
  const [sellArr, setSellArr] = useState([]);
  const [loader, setLoader] = useState(true);


  // useEffect(() => {
  //   dispatch(getTransfers(sessionStorage.at))
  // }, [])

  // useEffect(() => {
  //   if (getTransactionData && getTransactionData.objects) {
  //     // List of bank accounts data 
  //     let DepositArray = getTransactionData.objects?.filter(item => item.side === "deposit" && item.failure_code === null && item.state === "completed")
  //     let WithdrawArray = getTransactionData.objects?.filter(item => item.side === "withdrawal" && item.failure_code === null && item.state === "completed")
  //     let PendingArray = getTransactionData.objects?.filter(item => item.side === "deposit" && item.failure_code === null && item.state === "pending")
  //     let CanceledArray = getTransactionData.objects?.filter(item => item.side === "deposit" && item.failure_code === null && item.state === "failed")
  //     setDepositArr(DepositArray)
  //     setWithdrawArr(WithdrawArray)
  //     setPendingArr(PendingArray)
  //     setCanceledArr(CanceledArray)
  //     dispatch(resetGetExternalBankAccountListAction())
  //     setLoader(false)
  //   }
  // }, [getTransactionData]);

  // useEffect(() => {
  //   if (Object.keys(getTransactionFailed).length !== 0) {
  //     let errObj = getTransactionFailed
  //     if (errObj && errObj.data && errObj.data.error_message) {
  //       dispatch(commonError(errObj.data.error_message))
  //       dispatch(resetgetTransferFailed())
  //     }
  //   }
  // }, [getTransactionFailed]);

  useEffect(() => {
    dispatch(getTrades(sessionStorage.at))
  }, []);

  useEffect(() => {
    if (getTradesData && Object.keys(getTradesData).length !== 0) {
      let modifiedArray = getTradesData.objects.map((item) => {
        let divider;
        let sign;
        if (item.symbol === "BTC-USD") {
          sign = "BTC";
          divider = 100000000; // 8 decimal places
        } else if (item.symbol === "ETH-USD") {
          sign = "ETH";
          divider = 1000000000000000000; // 18 decimal places
        }else if(item.symbol === "USDC-USD") {
          sign = "USDC";
          divider = 1000000; // 6 decimal places
        }
        //  else if (item.symbol === "BCH-CAD") {
        //   sign = "BCH";
        //   divider = 100000000; // 8 decimal places
        // } else if (item.symbol === "LTC-CAD") {
        //   sign = "LTC";
        //   divider = 100000000; // 8 decimal places
        // }
        let recAmt =
          item.side === "buy"
            ? (item.symbol === "USDC-USD" ? (item.receive_amount / divider)?.toFixed(4) : (item.receive_amount / divider)?.toFixed(8))
            : (item.symbol === "USDC-USD" ? (item.deliver_amount / divider)?.toFixed(4) : (item.deliver_amount / divider)?.toFixed(8));
        let delAmt =
          item.side === "buy"
            ? (item.deliver_amount / 100)?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )
            : (item.receive_amount / 100)?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            );
        return {
          ...item,
          sign,
          purchaseQuantity: recAmt,
          purchasePrice: delAmt,
        };
      });
      setTrades(modifiedArray);
      dispatch(resetGetTrades());
      setLoader(false)
    }
  }, [getTradesData]);

  useEffect(() => {
    if (trades) {
      let buyArray = trades.filter(item => item.side === "buy" && item.failure_code === null).slice(0, 5);
      let sellArray = trades.filter(item => item.side === "sell" && item.failure_code === null).slice(0, 5);
      setBuyArr(buyArray)
      setSellArr(sellArray)
    }
  }, [trades]);

  useEffect(() => {
    if (getTradesFailed && Object.keys(getTradesFailed).length !== 0) {
      let errObj = getTradesFailed
      if (errObj && errObj.data && errObj.data.error_message) {
        dispatch(commonError(errObj.data.error_message))
        dispatch(resetGetTradesFailed())
      }
    }
  }, [getTradesFailed]);


  return (
    <>
      <div className="card-body">
        <div className="transaction-widget">
          {loader ?
            <div>
              <Gifloader />
            </div>
            : <>
              <Tabs defaultActiveKey="buy" id="uncontrolled-tab-example">
                <Tab eventKey="buy" title="Buy">
                  <ul className="list-unstyled">
                    {buyArr && buyArr.length !== 0 ? buyArr.map(item => (
                      <li key={item.guid} className="d-flex">
                        <span className="buy-thumb">
                          <i className="la la-arrow-up"></i>
                        </span>
                        <div className="rc_detail">
                          <div className="flex-grow-1">
                            <p>
                              <small>{formatDateHypen(item.created_at)}</small>
                            </p>
                            <p className="wallet-address text-primary fw-bold">
                              {item.guid}
                            </p>
                          </div>
                          <div className="text-end">
                            <h4>
                              $<span className="text-primary fw-bold">{item.purchasePrice}</span> USD = {" "}
                              <span className="text-primary fw-bold">{item.purchaseQuantity}</span> {item.sign}
                            </h4>
                          </div>
                        </div>
                      </li>
                    )) : (
                      <>
                        <div className="text-center">
                          <p className="fw-bold">No transactions found.</p>
                        </div>
                      </>
                    )}
                  </ul>
                </Tab>
                <Tab eventKey="sell" title="Sell">
                  <ul className="list-unstyled">
                    {sellArr && sellArr.length !== 0 ? sellArr.map((item) => (
                      <li className="d-flex" key={item.guid}>
                        <span className="buy-thumb">
                          <i className="la la-arrow-down"></i>
                        </span>
                        <div className="rc_detail">
                          <div className="flex-grow-1">
                            <p>
                              <small>{formatDateHypen(item.created_at)}</small>
                            </p>
                            <p className="wallet-address text-primary fw-bold">
                              {item.guid}
                            </p>
                          </div>
                          <div className="text-end">
                            <h4>
                              <span className="text-primary fw-bold">{item.purchaseQuantity}</span> {item.sign} = $
                              <span className="text-primary fw-bold">{item.purchasePrice}</span> USD
                            </h4>
                          </div>
                        </div>
                      </li>
                    )) : (
                      <>
                        <div className="text-center">
                          <p className="fw-bold">No transactions found.</p>
                        </div>
                      </>
                    )}
                  </ul>
                </Tab>

                {/* <Tab eventKey="inprogress" title="In progress">
                  <ul className="list-unstyled">
                    <li className="d-flex">
                      <span className="sold-thumb">
                        <i className="la la-arrow-down"></i>
                      </span>
                      <div className="flex-grow-1">
                        <p>
                          <small>16 August, {new Date().getFullYear()} </small>
                          <small>15:33</small>
                        </p>
                        <p className="wallet-address text-primary fw-bold">
                          15f5s8s47bhj61r8w4e77e5e56
                        </p>
                      </div>
                      <div className="text-end">
                        <h4>
                          <span className="text-primary fw-bold">0.000242</span> BTC
                        </h4>
                      </div>
                    </li>
                    <li className="d-flex">
                      <span className="buy-thumb">
                        <i className="la la-arrow-up"></i>
                      </span>
                      <div className="flex-grow-1">
                        <p>
                          <small>16 August, {new Date().getFullYear()} </small>
                          <small>15:33</small>
                        </p>
                        <p className="wallet-address text-primary fw-bold">
                          15f5s8s47bhj61r8w4e77e5e56
                        </p>
                      </div>
                      <div className="text-end">
                        <h4>
                          <span className="text-primary fw-bold">0.000242</span> LTC
                        </h4>
                      </div>
                    </li>
                    <li className="d-flex">
                      <span className="sold-thumb">
                        <i className="la la-arrow-down"></i>
                      </span>
                      <div className="flex-grow-1">
                        <p>
                          <small>16 August, {new Date().getFullYear()} </small>
                          <small>15:33</small>
                        </p>
                        <p className="wallet-address text-primary fw-bold">
                          15f5s8s47bhj61r8w4e77e5e56
                        </p>
                      </div>
                      <div className="text-end">
                        <h4>
                          <span className="text-primary fw-bold">0.000242</span> XRP
                        </h4>
                      </div>
                    </li>
                    <li className="d-flex">
                      <span className="buy-thumb">
                        <i className="la la-arrow-up"></i>
                      </span>
                      <div className="flex-grow-1">
                        <p>
                          <small>16 August, {new Date().getFullYear()} </small>
                          <small>15:33</small>
                        </p>
                        <p className="wallet-address text-primary fw-bold">
                          15f5s8s47bhj61r8w4e77e5e56
                        </p>
                      </div>
                      <div className="text-end">
                        <h4>
                          <span className="text-primary fw-bold">0.000242,</span> XRP
                        </h4>
                      </div>
                    </li>
                    <li className="d-flex">
                      <span className="buy-thumb">
                        <i className="la la-arrow-up"></i>
                      </span>
                      <div className="flex-grow-1">
                        <p>
                          <small>16 August, {new Date().getFullYear()} </small>
                          <small>15:33</small>
                        </p>
                        <p className="wallet-address text-primary fw-bold">
                          15f5s8s47bhj61r8w4e77e5e56
                        </p>
                      </div>
                      <div className="text-end">
                        <h4>
                          <span className="text-primary fw-bold">0.000242</span> XRP
                        </h4>
                      </div>
                    </li>
                  </ul>
                </Tab>
                <Tab eventKey="cancelled" title="Cancelled">
                  <ul className="list-unstyled">
                    <li className="d-flex">
                      <span className="sold-thumb">
                        <i className="la la-arrow-down"></i>
                      </span>
                      <div className="flex-grow-1">
                        <p>
                          <small>16 August, {new Date().getFullYear()} </small>
                          <small>15:33</small>
                        </p>
                        <p className="wallet-address text-primary fw-bold">
                          15f5s8s47bhj61r8w4e77e5e56
                        </p>
                      </div>
                      <div className="text-end">
                        <h4>
                          <span className="text-primary fw-bold">0.000242</span> BTC
                        </h4>
                      </div>
                    </li>
                    <li className="d-flex">
                      <span className="buy-thumb">
                        <i className="la la-arrow-up"></i>
                      </span>
                      <div className="flex-grow-1">
                        <p>
                          <small>16 August, {new Date().getFullYear()} </small>
                          <small>15:33</small>
                        </p>
                        <p className="wallet-address text-primary fw-bold">
                          15f5s8s47bhj61r8w4e77e5e56
                        </p>
                      </div>
                      <div className="text-end">
                        <h4>
                          <span className="text-primary fw-bold">0.000242</span> LTC
                        </h4>
                      </div>
                    </li>
                    <li className="d-flex">
                      <span className="sold-thumb">
                        <i className="la la-arrow-down"></i>
                      </span>
                      <div className="flex-grow-1">
                        <p>
                          <small>16 August, {new Date().getFullYear()} </small>
                          <small>15:33</small>
                        </p>
                        <p className="wallet-address text-primary fw-bold">
                          15f5s8s47bhj61r8w4e77e5e56
                        </p>
                      </div>
                      <div className="text-end">
                        <h4>
                          <span className="text-primary fw-bold">0.000242</span> XRP
                        </h4>
                      </div>
                    </li>
                    <li className="d-flex">
                      <span className="buy-thumb">
                        <i className="la la-arrow-up"></i>
                      </span>
                      <div className="flex-grow-1">
                        <p>
                          <small>16 August, {new Date().getFullYear()} </small>
                          <small>15:33</small>
                        </p>
                        <p className="wallet-address text-primary fw-bold">
                          15f5s8s47bhj61r8w4e77e5e56
                        </p>
                      </div>
                      <div className="text-end">
                        <h4>
                          <span className="text-primary fw-bold">0.000242,</span> XRP
                        </h4>
                      </div>
                    </li>
                    <li className="d-flex">
                      <span className="buy-thumb">
                        <i className="la la-arrow-up"></i>
                      </span>
                      <div className="flex-grow-1">
                        <p>
                          <small>16 August, {new Date().getFullYear()} </small>
                          <small>15:33</small>
                        </p>
                        <p className="wallet-address text-primary fw-bold">
                          15f5s8s47bhj61r8w4e77e5e56
                        </p>
                      </div>
                      <div className="text-end">
                        <h4>
                          <span className="text-primary fw-bold">0.000242</span> XRP
                        </h4>
                      </div>
                    </li>
                  </ul>
                </Tab> */}
              </Tabs>
            </>}

        </div>
      </div >
    </>
  );
};

export default DashboardTransaction;
