import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Card, Tab, Tabs } from "react-bootstrap";
import Header3 from "../layout/header3";
import Footer1 from "../layout/footer1";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

function ReferralProgram() {
    return (
        <>
            {/* Meta Tag */}
            <Helmet>
                <title>Referral Program | Buy & Sell Cryptocurrency | United States</title>
                <meta name="description" content="Join our referral program at The Crypto Exchange and earn rewards! Use our crypto wallet app and spread the word to make the most out of your crypto journey." />
            </Helmet>

            <Header3 />
            {/* <div className="about-one sp-inner">
                <div className="container">
                <div className="our-ceo py-5">
                <div className="container"> */}
            {/* <div className="row">
                        <div className="col-md-12">
                            <div className="ceo-content">
                                <div className="d-flex">

                                <div className="flex-grow-1">
                                        <h3>Victor Boras,</h3>
                                        <span>CEO - The Crypto Exchange</span>
                                        <p className="mt-2">
                                            Victor Boras has over 10 years of
                                            experience working in and around
                                            Financial Technology. He has
                                            delivered innovative SaaS systems
                                            for some of today's biggest
                                            institutions around payments,
                                            identity, and banking
                                            infrastructure. Victor Boras has
                                            been in the Blockchain space since
                                            2012 and is involved in a number of
                                            blockchain and fintech businesses
                                            both as an investor, board director,
                                            and founder.
                                        </p>
                                    </div>
                                    <img
                                         src={require("./../../images/profile/about.jpg")}
                                        alt=""
                                        className="img-fluid ms-4 rounded-circle"
                                    />                                    
                                </div>
                            </div>
                        </div>
                    </div> */}
            {/* <h1 className="m-5 text-center">Coming Soon</h1>
                </div>
            </div> */}
            {/* 

            <div className="about-two section-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="service-content my-5">
                                <h3>Services Provided</h3>
                                <p>
                                    Digital currencies are changing how we use
                                    and think about money. Tradix, the most
                                    trusted company in the space, is looking for
                                    you to join our rapidly growing team.
                                </p>
                                <Link to={"#"} className="btn btn-primary">
                                    Read more
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="service-img">
                                <img
                                    src={require("./../../images/testimonial/2.jpg")}
                                    alt=""
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="service-img">
                                <img
                                    src={require("./../../images/testimonial/1.jpg")}
                                    alt=""
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="service-content m-t-50">
                                <h3>    History of Victor and company </h3>
                                <p>
                                    Founded in January of{" "}
                                    {new Date().getFullYear()}, Tradix is a
                                    digital currency wallet and platform where
                                    merchants and consumers can transact with
                                    new digital currencies like bitcoin,
                                    ethereum, and litecoin. We're based in San
                                    Francisco, California.
                                </p>
                                <p>
                                    Bitcoin is the world's most widely used
                                    alternative currency with a total market cap
                                    of over $100 billion. The bitcoin network is
                                    made up of thousands of computers run by
                                    individuals all over the world.
                                </p>
                            </div>
                        </div>
                    </div> */}
            {/* </div>
            </div> */}

            {/* <div className="lear_page _reffer sp-inners">
                <div className="banners">
                    <h2>Referral Program </h2>
                </div>
                <div className="main">
                    <div className="container">
                        <div className="reff">
                            <div className="d-flex">
                                <div className="col-md-4">
                                    <div className="box">
                                        <div className="icon">
                                            <img
                                                src={require("./../../images/reff/icon_1.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="txt">
                                            <h4>Refer a friend</h4>
                                            <p>Lorem ipsum dolor amet consect adiscing elit eiusmod tempor enime dolore magna aliqua.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="box">
                                        <div className="icon">
                                            <img
                                                src={require("./../../images/reff/icon_2.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="txt">
                                            <h4>They receive 10% off</h4>
                                            <p>Lorem ipsum dolor amet consect adiscing elit eiusmod tempor enime dolore magna aliqua.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="box">
                                        <div className="icon">
                                            <img
                                                src={require("./../../images/reff/icon_3.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="txt">
                                            <h4>You get rewarded</h4>
                                            <p>Lorem ipsum dolor amet consect adiscing elit eiusmod tempor enime dolore magna aliqua.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <button className="btn btn-primary">REFER A FRIEND</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="faq_page">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="faq_content">
                            <h2>Coming Soon...</h2>
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer1 />
        </>
    );
}

export default ReferralProgram;
