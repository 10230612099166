import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { resetPasswordLink,resetPasswordSentFailed } from '../../actions/authAction';
import { useDispatch, useSelector} from 'react-redux';
import { commonError, commonSuccess } from '../../actions/customerActions';
import { useNavigate } from 'react-router-dom';
import ButtonLoader from '../layout/buttonLoader';

function Reset() {

const [email, setEmail] = useState('');
const [isLoading, setIsLoading] = useState(false);
const dispatch = useDispatch();
const navigate = useNavigate();
const linkSentFailed = useSelector((state) => state.authReducer.postResetPasswordLinkFailed);
const linkSentSuccess = useSelector((state) => state.authReducer.postResetPasswordLinkData);

useEffect(() => {
    if (Object.keys(linkSentSuccess).length != 0) {
      setIsLoading(false);
      dispatch(commonSuccess("Please Check Your Mail Inbox"));
    }
  }, [linkSentSuccess]);

useEffect(() => {
    if (Object.keys(linkSentFailed).length !== 0) {
        setIsLoading(false);
        dispatch(commonError(linkSentFailed?.response?.data?.message));
        dispatch(resetPasswordSentFailed());
        
    }
  }, [linkSentFailed]);

const handleChange = (value) => {
    setEmail(value);
};

const handleSubmit = async (value)=> {
    if (isLoading) {
        return;
    }
    if(value?.length === 0) {
        dispatch(commonError("Enter Your Email ID"));
    }else {
        setIsLoading(true);
        const response = await dispatch(resetPasswordLink(value));
        if (response?.status === 200) {
            navigate("/signin");
            setEmail('');
        }
    }
}

return (
    <>
       <div className="authincation section-padding1 fslogin">
      <div className="art-wrapper">
          <img src={require('./../../images/auth-bg.png')} alt="" />
        </div>
        <div className="fscon-wrapper">
            <div className="container-fluid h-100">               
                        <div className="mini-logo text-center mb-4">
                            <Link to={'/'}><img src={require('./../../images/logo-with-text.png')} alt="" /></Link>
                        </div>
                        <div className="auth-form card fslogincard mb-0">
                            <div className="card-header justify-content-center">
                                <h4 className="card-title">Reset password</h4>
                            </div>
                            <div className="card-body">
                                <form action="">
                                    <div className="mb-3">
                                        <label>Email</label>
                                        <input type="email" className="form-control" placeholder="hello@example.com" value={email} onChange={(e) => handleChange(e.target.value)} />
                                    </div>
                                    <div className="text-center  mt-5">
                                        <button type="button" className={`btn btn-primary w-30 btn-loader ${isLoading ? "active" : ""}`} onClick={()=> {handleSubmit(email)}}>
                                        <span>Send Email</span>
                                        {isLoading && <ButtonLoader />}
                                        </button>
                                    </div>
                                   
                                </form>
                                {/* <div className="new-account mt-3">
                                    <p className="mb-1">Don't Received? </p>
                                    <Link className="text-primary" to={'./reset'}>Resend </Link>
                                </div> */}

<div className="new-account mt-5 text-center">
                    <p>
                      Already have an account?{" "}
                      <Link className="text-primary" to={"/signin"}>
                        Sign in
                      </Link>
                    </p>
                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
    </>
    )
}

export default Reset;