import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import {
  commonError,
  commonSuccess,
  postQuotesAction,
  postTradesAction,
  resetpostTradeFailed,
  resetpostQuoteFailed,
  resetPostTradesAction,
  resetPostQuotesAction,
  getCustomerAccountDetails,
  resetGetCustomerAccountDetails,
  getExternalBankAccountWalletListAction
} from "../../actions/customerActions";
import { decryptPassword, formatCurrency, formatDate } from "../../utils/Encryption";
import { EmailNotification } from "../../actions/authAction";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ethereumLogo from "../../images/ethereum.png";
import bitcoincashLogo from "../../images/bch.png";
import bitcoinLogo from "../../images/bitcoin.png";
import ButtonLoader from "../layout/buttonLoader";
import litecoinLogo from "../../images/LTC.png";
import cadLogo from "../../images/canada.png";
import Gifloader from "../layout/gifloader";
import usdcLogo from "../../images/usdc.png";
import usdLogo from "../../images/usd.png"


const Exchange = ({ priceInfo, updateBuySelectedObj, updateSellSelectedObj, fiatAccount }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const inputBuyRef = useRef(null);
  const inputSellRef = useRef(null);
  let tempToken = sessionStorage.getItem("guid");
  let deToken = decryptPassword(tempToken);
  let timeOutFunctionRef = useRef(null);


  const getExchangeQuote = useSelector((state) => state.customerReducer.postQuoteData);
  const getExchangeQuotFailed = useSelector((state) => state.customerReducer.postQuoteFailed);
  const getTradeData = useSelector((state) => state.customerReducer.postTradesData);
  const getTradeFailed = useSelector((state) => state.customerReducer.postTradesFailed);
  const getAccountDatais = useSelector((state) => state.customerReducer.getAccountData);
  const getWallet = useSelector((state) => state.customerReducer.getExternalBankWalletAccountData);
  const getWalletFailed = useSelector((state) => state.customerReducer.getExternalBankWalletAccountFailed);


  const [isBuyQuoteBtnLoading, setBuyQuoteBtnLoading] = useState(false);
  const [isBuyTradeBtnLoading, setBuyTradeBtnLoading] = useState(false);
  const [isSellQuoteBtnLoading, setSellQuoteBtnLoading] = useState(false);
  const [isSellTradeBtnLoading, setSellTradeBtnLoading] = useState(false);
  const [exchangeLoader, setExchangeLoader] = useState(true);
  const [externalWalletList, setExternalWalletList] = useState([]);
  const [buySelectedObj, setBuySelectedObj] = useState(null);
  const [sellSelectedObj, setSellSelectedObj] = useState(null);
  const [buySelectedSymbol, setBuySelectedSymbol] = useState("");
  const [sellSelectedSymbol, setSellSelectedSymbol] = useState("");
  const [isBuyGetQuote, setBGQ] = useState(false);
  const [isBGQview, setBGQV] = useState(false);
  const [isBuyOrderSub, setBOS] = useState(false);
  const [isSellGetQuote, setSGQ] = useState(false);
  const [isSGQview, setSGQV] = useState(false);
  const [isSellOrderSub, setSOS] = useState(false);
  const [buyDetails, setBuyDetails] = useState([]);
  const [sellDetails, setSellDetails] = useState([]);
  const [buyInput, setBuyInput] = useState(null);
  const [cadBuyInput, setCadBuyInput] = useState(false);
  const [cadSellInput, setCadSellInput] = useState(false);
  const [sellInput, setSellInput] = useState(null);
  const [buyQuoteData, setBuyQuoteData] = useState(null);
  const [buyTradeData, setBuyTradeData] = useState(null);
  const [sellQuoteData, setSellQuoteData] = useState(null);
  const [sellTradeData, setSellTradeData] = useState(null);
  const [liveSellBTC, setLiveSellBTC] = useState();
  const [liveSellETH, setLiveSellETH] = useState();

  const [liveSellUSDC, setLiveSellUSDC] = useState();

  const [liveSellBCH, setLiveSellBCH] = useState();
  const [liveSellLTC, setLiveSellLTC] = useState();
  const [fiatBalance, setFiatBalance] = useState();
  const [cryptoAssets, setCryptoAssets] = useState();
  const [isBuyCad, setIsBuycad] = useState(false);
  const [isSellCad, setIsSellCad] = useState(false);
  const [buySeconds, setBuySeconds] = useState(0);
  const [sellSeconds, setSellSeconds] = useState(0);
  const [activeKeyis, setActiveKeyis] = useState("buy");
  const [isWalletNotAdded, setIsWalletNotAdded] = useState(false);
  const [showAddWallet, setShowAddWallet] = useState(false);


  useEffect(() => {
    dispatch(getExternalBankAccountWalletListAction(sessionStorage.at))
    dispatch(getCustomerAccountDetails(sessionStorage.at, deToken));

    return () => {
      // Clear Customet account details API setTimeOut time
      clearTimeout(timeOutFunctionRef.current);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(getWallet).length !== 0) {
      setExternalWalletList(getWallet.objects);
    }
  }, [getWallet]);

  useEffect(() => {
    // Decrease the seconds every second
    const timer = setInterval(() => {
      setBuySeconds(prevSeconds => prevSeconds - 1);
    }, 1000);
    // Clear the interval and reset the timer when it reaches 0
    if (buySeconds === 0) {
      clearInterval(timer);
      // You can also perform any action you need when the timer reaches 0
    }
    // Buy
    if (buySeconds === 0 && isBuyGetQuote && isBGQview) {
      toggleBGQ();
    }
    return () => clearInterval(timer);
  }, [buySeconds]);

  useEffect(() => {
    // Decrease the seconds every second
    const timer = setInterval(() => {
      setSellSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);
    // Clear the interval and reset the timer when it reaches 0
    if (sellSeconds === 0) {
      clearInterval(timer);
      // You can also perform any action you need when the timer reaches 0
    }
    // Sell
    if (sellSeconds === 0 && isSellGetQuote && isSGQview) {
      toggleSGQ();
    }
    return () => clearInterval(timer);
  }, [sellSeconds]);

  useEffect(() => {
    if (Object.keys(getTradeFailed).length !== 0) {
      let errObj = getTradeFailed
      if(errObj?.data?.failure_code === "limit_exceeded") {
        dispatch(commonError("Limit has been exceeded"))
      } else {
        dispatch(commonError(errObj.data.error_message))
      }
      setBuyTradeBtnLoading(false)
      setSellTradeBtnLoading(false)
      dispatch(resetpostTradeFailed())
    }
  }, [getTradeFailed])

  useEffect(() => {
    if (getExchangeQuotFailed && Object.keys(getExchangeQuotFailed).length !== 0) {
      let errObj = getExchangeQuotFailed
      if (errObj?.status === 422 && errObj?.data?.message_code === "invalid_quote_amount") {
        dispatch(commonError("Quote amount is below the configured threshold"));
      }else {
        dispatch(commonError(errObj?.data?.error_message));
      }
      setBuyQuoteBtnLoading(false)
      setSellQuoteBtnLoading(false)
      dispatch(resetpostQuoteFailed())
    }
  }, [getExchangeQuotFailed])

  useEffect(() => {
    if (priceInfo) {
      if (priceInfo.length > 0) {
        setExchangeLoader(false);
      }
      const buyData = priceInfo?.map((item) => {
        return {
          symbol: item?.symbol,
          buy_price: item.buy_price,
          logo: item.logo,
          name: item.name,
        };
      });
      const sellData = priceInfo?.map((item) => {
        return {
          symbol: item?.symbol,
          sell_price: item.sell_price,
          logo: item.logo,
          name: item.name,
        };
      });
      setBuyDetails(buyData);
      if (buyInput || cadBuyInput) {
        const currObj = buyData?.find(
          (currency) => currency?.symbol === buySelectedObj?.symbol
        );
        let amount = ((cadBuyInput * 100) / currObj.buy_price).toFixed(8);
        let quantity = ((buyInput * currObj.buy_price) / 100).toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );
        setBuySelectedObj({
          ...currObj,
          currentQuantityforPrice: quantity,
          currentPriceforQuantity: amount,
        });
        activeKeyis == "buy" && updateBuySelectedObj({
          ...currObj,
          currentQuantityforPrice: quantity,
          currentPriceforQuantity: amount,
        });


      } else if (buyData.length != 0) {
        if (buySelectedObj) {
          let obj = buyData?.find(e => e?.symbol == buySelectedObj?.symbol)
          setBuySelectedObj(obj);
          activeKeyis == "buy" && updateBuySelectedObj(obj);
        }
        !buySelectedObj && setBuySelectedObj(buyData[0]);
        !buySelectedObj && activeKeyis == "buy" && updateBuySelectedObj(buyData[0]);
      }
      setSellDetails(sellData);
      if (sellInput || cadSellInput) {
        const currObj2 = sellData?.find(
          (currency) => currency?.symbol === sellSelectedSymbol
        );
        let amount = ((cadSellInput * 100) / currObj2?.sell_price).toFixed(8);
        let quantity = ((sellInput * currObj2?.sell_price) / 100).toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );
        setSellSelectedObj({
          ...currObj2,
          currentQuantityforPrice: quantity,
          currentPriceforQuantity: amount,
        });
        activeKeyis == "sell" && updateSellSelectedObj({
          ...currObj2,
          currentQuantityforPrice: quantity,
          currentPriceforQuantity: amount,
        });
      } else if (sellData.length != 0) {
        if (sellSelectedObj) {
          let obj = sellData?.find(e => e?.symbol == sellSelectedObj?.symbol)
          setSellSelectedObj(obj);
          activeKeyis == "sell" && updateSellSelectedObj(obj);
        }
        !sellSelectedObj && setSellSelectedObj(sellData[0]);
        !sellSelectedObj && activeKeyis == "sell" && updateSellSelectedObj(sellData[0]);
      }
      if (priceInfo.length !== 0) {
        const bitCadObject = priceInfo.find((item) => item?.symbol === "BTC");
        const ethCadObject = priceInfo.find((item) => item?.symbol === "ETH");
        const usdcCadObject = priceInfo.find((item) => item?.symbol === "USDC");

        setLiveSellBTC(bitCadObject?.sell_price);
        setLiveSellETH(ethCadObject?.sell_price);
        setLiveSellUSDC(usdcCadObject?.sell_price);

        // const bchCadObject = priceInfo.find((item) => item?.symbol === "BCH");
        // const ltcCadObject = priceInfo.find((item) => item?.symbol === "LTC");
        // setLiveSellBCH(bchCadObject?.sell_price);
        // setLiveSellLTC(ltcCadObject?.sell_price);
      }
    }
  }, [priceInfo]);

  useEffect(() => {
    if (
      Object.keys(getExchangeQuote).length !== 0 && getExchangeQuote.side === "buy"
    ) {
      setBuySeconds(5)
      toggleBGQ();
      setBuyQuoteBtnLoading(false);
      let divider;
      let sign;
      let logo;
      if (getExchangeQuote?.symbol === "BTC-USD") {
        logo = bitcoinLogo;
        sign = "BTC";
        divider = 100000000; // 8 decimal places
      } else if (getExchangeQuote?.symbol === "ETH-USD") {
        logo = ethereumLogo;
        sign = "ETH";
        divider = 1000000000000000000; // 18 decimal places
      } else if (getExchangeQuote?.symbol === "USDC-USD") {
        logo = usdcLogo;
        sign = "USDC";
        divider = 1000000; // 6 decimal places
      } 
      
      // else if (getExchangeQuote?.symbol === "BCH-CAD") {
      //   logo = bitcoincashLogo;
      //   sign = "BCH";
      //   divider = 100000000; // 8 decimal places
      // } else if (getExchangeQuote?.symbol === "LTC-CAD") {
      //   logo = litecoinLogo;
      //   sign = "LTC";
      //   divider = 100000000; // 8 decimal places
      // }
      let quoteArr;
      if (isBuyCad) {
        quoteArr = {
          ...getExchangeQuote,
          sign,
          logo,
          purchaseQuantity: getExchangeQuote.receive_amount / divider,
          purchasePrice: ((getExchangeQuote.deliver_amount / 100) + (getExchangeQuote.fee / 100))?.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          ),
        };
      } else if (!isBuyCad) {
        quoteArr = {
          ...getExchangeQuote,
          sign,
          logo,
          purchaseQuantity: getExchangeQuote.receive_amount / divider,
          purchasePrice: ((getExchangeQuote.deliver_amount / 100) + (getExchangeQuote.fee / 100))?.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          ),
        };
      }
      setBuyQuoteData(quoteArr);
      dispatch(resetPostQuotesAction());
    }

    if (
      Object.keys(getExchangeQuote).length !== 0 &&
      getExchangeQuote.side === "sell"
    ) {
      setSellSeconds(5)
      toggleSGQ();
      setSellQuoteBtnLoading(false);
      let divider;
      let sign;
      let logo;
      if (getExchangeQuote?.symbol === "BTC-USD") {
        logo = bitcoinLogo;
        sign = "BTC";
        divider = 100000000; // 8 decimal places
      } else if (getExchangeQuote?.symbol === "ETH-USD") {
        logo = ethereumLogo;
        sign = "ETH";
        divider = 1000000000000000000; // 18 decimal places
      } else if (getExchangeQuote?.symbol === "USDC-USD") {
        logo = usdcLogo;
        sign = "USDC";
        divider = 1000000; // 6 decimal places
      }
      
      // else if (getExchangeQuote?.symbol === "BCH-CAD") {
      //   logo = bitcoincashLogo;
      //   sign = "BCH";
      //   divider = 100000000; // 8 decimal places
      // } else if (getExchangeQuote?.symbol === "LTC-CAD") {
      //   logo = litecoinLogo;
      //   sign = "LTC";
      //   divider = 100000000; // 8 decimal places
      // }

      let quoteArr;
      if (isSellCad) {
        quoteArr = {
          ...getExchangeQuote,
          sign,
          logo,
          purchaseQuantity: getExchangeQuote.deliver_amount / divider,
          purchasePrice: ((getExchangeQuote.receive_amount / 100) + (getExchangeQuote.fee / 100))?.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          ),
        };
      } else if (!isSellCad) {
        quoteArr = {
          ...getExchangeQuote,
          sign,
          logo,
          purchaseQuantity: getExchangeQuote.deliver_amount / divider,
          purchasePrice: ((getExchangeQuote.receive_amount / 100) + (getExchangeQuote.fee / 100))?.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          ),
        };
      }

      setSellQuoteData(quoteArr);
      dispatch(resetPostQuotesAction());
    }
  }, [getExchangeQuote]);

  useEffect(() => {
    if (
      getTradeData &&
      getTradeData.receive_amount &&
      getTradeData.side === "buy"
    ) {
      timeOutFunctionRef.current = setTimeout(() => {
        dispatch(getCustomerAccountDetails(sessionStorage.at, deToken))
      }, 6000);

      if (getTradeData && getTradeData.failure_code === null) {
        let PTD = getTradeData
        let ExAmount;
        if (PTD?.symbol === "BTC-USD") {
          ExAmount = PTD.receive_amount / 100000000;
        } else if (PTD?.symbol === "ETH-USD") {
          ExAmount = PTD.receive_amount / 1000000000000000000;
        } else if (PTD?.symbol === "USDC-USD") {
          ExAmount = PTD.receive_amount / 1000000;
        } 
        
        // else if (PTD?.symbol === "BCH-CAD") {
        //   ExAmount = PTD.receive_amount / 100000000;
        // } else if (PTD?.symbol === "LTC-CAD") {
        //   ExAmount = PTD.receive_amount / 100000000;
        // }
        // const transformedData = {
        //   guid: PTD.guid,
        //   customer_guid: PTD?.customer_guid,
        //   side: PTD.side,
        //   symbol: PTD?.symbol,
        //   amount: ExAmount,
        //   created_at: PTD.created_at,
        //   type: 'crypto-buy', // Set type to 'fiat-withdrawal' for withdrawal
        //   user_name: localStorage.getItem('displayName'),
        //   user_email: sessionStorage.getItem('user_email'),
        //   fee: (PTD?.fee / 100)?.toFixed(2),
        // };

        // dispatch(EmailNotification(transformedData))
        dispatch(commonSuccess(`Your ${buySelectedSymbol} transaction was initiated successful! Your request is being processed.`))
      }

      toggleBGQV();
      setBuyTradeBtnLoading(false);

      let divider;
      let sign;
      if (getTradeData?.symbol === "BTC-USD") {
        sign = "BTC";
        divider = 100000000; // 8 decimal places
      } else if (getTradeData?.symbol === "ETH-USD") {
        divider = 1000000000000000000; // 18 decimal places
        sign = "ETH";
      } else if (getTradeData?.symbol === "USDC-USD") {
        divider = 1000000; // 6 decimal places
        sign = "USDC";
      } 
      
      // else if (getTradeData?.symbol === "BCH-CAD") {
      //   divider = 100000000; // 8 decimal places
      //   sign = "BCH";
      // } else if (getTradeData?.symbol === "LTC-CAD") {
      //   divider = 100000000; // 8 decimal places
      //   sign = "LTC";
      // }

      let quoteArr
      if (isBuyCad) {
        quoteArr = {
          ...getTradeData,
          sign,
          purchaseQuantity: getTradeData.receive_amount / divider,
          purchasePrice: ((getTradeData.deliver_amount / 100) + (getTradeData.fee / 100))?.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          ).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          ),
        };
      } else if (!isBuyCad) {
        quoteArr = {
          ...getTradeData,
          sign,
          purchaseQuantity: getTradeData.receive_amount / divider,
          purchasePrice: ((getTradeData.deliver_amount / 100) + (getTradeData.fee / 100))?.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          ).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          ),
        };
      }

      setBuyTradeData(quoteArr);
      dispatch(resetPostTradesAction());
    }
    if (
      getTradeData &&
      getTradeData.receive_amount &&
      getTradeData.side === "sell"
    ) {
      timeOutFunctionRef.current = setTimeout(() => {
        dispatch(getCustomerAccountDetails(sessionStorage.at, deToken))
      }, 6000);

      if (getTradeData && getTradeData.failure_code === null) {
        let PTD = getTradeData
        let ExAmount;
        if (PTD?.symbol === "BTC-USD") {
          ExAmount = PTD.deliver_amount / 100000000;
        } else if (PTD?.symbol === "ETH-USD") {
          ExAmount = PTD.deliver_amount / 1000000000000000000;
        } else if (PTD?.symbol === "USDC-USD") {
          ExAmount = PTD.deliver_amount / 1000000;
        }
        
        // else if (PTD?.symbol === "BCH-CAD") {
        //   ExAmount = PTD.deliver_amount / 100000000;
        // } else if (PTD?.symbol === "LTC-CAD") {
        //   ExAmount = PTD.deliver_amount / 100000000;
        // }
        // const transformedData = {
        //   guid: PTD.guid,
        //   customer_guid: PTD?.customer_guid,
        //   side: PTD.side,
        //   symbol: PTD?.symbol,
        //   amount: ExAmount,
        //   created_at: PTD.created_at,
        //   type: 'crypto-sell', // Set type to 'fiat-withdrawal' for withdrawal
        //   user_name: localStorage.getItem('displayName'),
        //   user_email: sessionStorage.getItem('user_email'),
        //   fee: (PTD?.fee / 100)?.toFixed(2),
        // };

        // dispatch(EmailNotification(transformedData))
        dispatch(commonSuccess(`Your ${sellSelectedSymbol} transaction was initiated successful! Your request is being processed.`))
      }

      toggleSGQV();
      setSellTradeBtnLoading(false);

      let divider;
      let sign;
      if (getTradeData?.symbol === "BTC-USD") {
        sign = "BTC";
        divider = 100000000; // 8 decimal places
      } else if (getTradeData?.symbol === "ETH-USD") {
        divider = 1000000000000000000; // 18 decimal places
        sign = "ETH";
      } else if (getTradeData?.symbol === "USDC-USD") {
        divider = 1000000; // 6 decimal places
        sign = "USDC";
      } 

      // else if (getTradeData?.symbol === "BCH-CAD") {
      //   divider = 100000000; // 8 decimal places
      //   sign = "BCH";
      // } else if (getTradeData?.symbol === "LTC-CAD") {
      //   divider = 100000000; // 8 decimal places
      //   sign = "LTC";
      // }
      let quoteArr;
      if (isSellCad) {
        quoteArr = {
          ...getTradeData,
          sign,
          purchaseQuantity: getTradeData.deliver_amount / divider,
          purchasePrice: ((getTradeData.receive_amount / 100) + (getTradeData.fee / 100))?.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          ),
        };
      } else if (!isSellCad) {
        quoteArr = {
          ...getTradeData,
          sign,
          purchaseQuantity: getTradeData.deliver_amount / divider,
          purchasePrice: ((getTradeData.receive_amount / 100) + (getTradeData.fee / 100))?.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          ),
        };
      }
      setSellTradeData(quoteArr);
      dispatch(resetPostTradesAction());
    }
  }, [getTradeData]);

  useEffect(() => {
    if (Object.keys(getAccountDatais).length !== 0) {
      const fiatAcc = getAccountDatais.objects?.find(
        (account) => account.asset === "USD"
      );
      setFiatBalance(fiatAcc.platform_available);
      let modifiedData = getAccountDatais.objects.map((account) => {
        let divider;
        let logo;
        let name;
        let symbol;
        let livePrice;
        if (account.asset === "BTC") {
          logo = bitcoinLogo;
          name = "Bitcoin";
          symbol = "BTC";
          divider = 100000000;
          livePrice = liveSellBTC;
        } else if (account.asset === "ETH") {
          logo = ethereumLogo;
          name = "Ethereum";
          symbol = "ETH";
          divider = 1000000000000000000;
          livePrice = liveSellETH;
        } else if (account.asset === "USDC") {
          logo = ethereumLogo;
          name = "USDC";
          symbol = "USDC";
          divider = 1000000;
          livePrice = liveSellUSDC;
        } 
        
        // else if (account.asset === "BCH") {
        //   logo = bitcoincashLogo;
        //   name = "Bitcoin Cash";
        //   symbol = "BCH";
        //   divider = 100000000;
        //   livePrice = liveSellBCH;
        // } else if (account.asset === "LTC") {
        //   logo = litecoinLogo;
        //   name = "Litecoin";
        //   symbol = "LTC";
        //   divider = 100000000;
        //   livePrice = liveSellLTC;
        // } 
        
        else if (account.asset === "USD") {
          let balance;
          logo = usdLogo;
          name = "USDC";
          symbol = "USD";
          balance = account.platform_balance / 100;
          return {
            ...account,
            logo: logo,
            name: name,
            symbol: symbol,
            platform_balance: balance,
            balance_usd: balance,
          };
        }
        // show crypto platform balance
        const platform_balance_million_units =
          account.platform_balance / divider;
        // show crypto available balance
        const available_balance_million_units =
        account.platform_available/ divider;
        // Crypto available balance equal to CAD
        const balance_usd =
          (available_balance_million_units * livePrice) / 100;
        return {
          ...account,
          logo: logo,
          name: name,
          symbol: symbol,
          platform_balance: platform_balance_million_units,
          platform_available: available_balance_million_units,
          balance_usd: balance_usd.toFixed(3),
        };
      });
      setCryptoAssets(modifiedData);
      modifiedData = []
      dispatch(resetGetCustomerAccountDetails());
    }
  }, [getAccountDatais]);


  const handleAddWallet = () => {
    let currSym = buyTradeData && buyTradeData?.sign;
    const selectedWallet = externalWalletList?.find(v => v.asset === currSym);
    if (selectedWallet) {
      toggleBOS();
      setTimeout(() => {
        navigate("/withdrawals#crypto");
      }, 100);
    } else {
      setShowAddWallet(true);
    }
  };

  const handleAddWalletClose = () => setShowAddWallet(false);

  const handleUsdAmountChange = (inputValue, type) => {
    if (!/^\d*\.?\d*$/.test(inputValue)) {
      // Invalid input, handle error or ignore the input
      return;
    }

    if (type === "buy") {
      setIsBuycad(false);
      let quantity = ((inputValue * buySelectedObj.buy_price) / 100).toFixed(2);
      setBuySelectedObj((prevBuySelectedObj) => ({
        ...prevBuySelectedObj,
        currentQuantityforPrice: quantity,
      }));
      setCadBuyInput(quantity);
      setBuyInput(inputValue);
      setBuySelectedSymbol(buySelectedObj?.symbol);
    } else if (type === "sell") {
      setIsSellCad(false);
      let quantity = ((inputValue * sellSelectedObj.sell_price) / 100).toFixed(2);
      setSellSelectedObj((prevSellSelectedObj) => ({
        ...prevSellSelectedObj,
        currentQuantityforPrice: quantity,
      }));
      setSellInput(inputValue);
      setCadSellInput(quantity);
      setSellSelectedSymbol(sellSelectedObj?.symbol);
    }
  };

  const handleCadAmountBlur = (e, type) => {
    const inputValue = e.target.value;
    if (type === "buy" && e.target.value) {
      const formattedValue = parseFloat(inputValue).toFixed(2);
      setCadBuyInput(formattedValue);
    } else if (type === "sell" && e.target.value) {
      const formattedValue = parseFloat(inputValue).toFixed(2);
      setCadSellInput(formattedValue);
    } else if(type === "buy" && !e.target.value) {
      setCadBuyInput("0.00");
      handleCadAmountChange("0.00", "buy");
    }else if(type === "sell" && !e.target.value) {
      setCadSellInput("0.00");
      handleCadAmountChange("0.00", "sell");
    }
  };


  const handleCadAmountChange = (inputValue, type) => {
    if (!/^\d*\.?\d*$/.test(inputValue)) {
      return;
    }
    if (type === "buy") {    
      setBuySelectedSymbol(buySelectedObj?.symbol)
      setIsBuycad(true);
      setCadBuyInput(inputValue);
      if (inputValue) {
        let amount = ((inputValue * 100) / buySelectedObj.buy_price).toFixed(8);
        setBuySelectedObj({
          ...buySelectedObj,
          currentPriceforQuantity: amount,
        });
        updateBuySelectedObj({
          ...buySelectedObj,
          currentPriceforQuantity: amount,
        });
        setBuyInput(amount);
      }
    } else if (type === "sell") {
      setIsSellCad(true);
      setSellSelectedSymbol(sellSelectedObj?.symbol)
      setCadSellInput(inputValue)
      if (inputValue) {
        let amount = ((inputValue * 100) / sellSelectedObj.sell_price).toFixed(8);
        setSellSelectedObj({
          ...sellSelectedObj,
          currentPriceforQuantity: amount,
        });
        updateSellSelectedObj({
          ...sellSelectedObj,
          currentPriceforQuantity: amount,
        });
        setSellInput(amount);
      }
    }
  }

  const handleCurrencyChange = (event, type) => {
    const selectedSymbol = event.target.value;
    if (type === "buy") {
      const currObj = buyDetails.find(
        (currency) => currency?.symbol === selectedSymbol
      );
      if (buyInput || cadBuyInput) {
        let amount = ((cadBuyInput * 100) / currObj.buy_price).toFixed(8);
        let quantity = ((buyInput * currObj.buy_price) / 100).toFixed(2);

        setBuySelectedObj({
          ...currObj,
          currentQuantityforPrice: quantity,
          currentPriceforQuantity: amount,
        });
        updateBuySelectedObj({
          ...currObj,
          currentQuantityforPrice: quantity,
          currentPriceforQuantity: amount,
        });
      } else {
        setBuySelectedObj(currObj);
        updateBuySelectedObj(currObj)
      }
      setBuySelectedSymbol(selectedSymbol);
    } else if (type === "sell") {
      const currObj = sellDetails.find(
        (currency) => currency?.symbol === selectedSymbol
      );

      if (sellInput || cadSellInput) {
        let amount = ((cadSellInput * 100) / currObj.sell_price).toFixed(8);
        let quantity = ((sellInput * currObj.sell_price) / 100).toFixed(2);
        setSellSelectedObj({
          ...currObj,
          currentQuantityforPrice: quantity,
          currentPriceforQuantity: amount,
        });
        updateSellSelectedObj({
          ...currObj,
          currentQuantityforPrice: quantity,
          currentPriceforQuantity: amount,
        });
      } else {
        setSellSelectedObj(currObj);
        updateSellSelectedObj(currObj)
      }
      setSellSelectedSymbol(selectedSymbol);
    }
  };

  const toggleBGQ = () => {
    setBGQ(!isBuyGetQuote);
    setBGQV(!isBGQview);
  };

  const toggleBGQV = () => {
    setBGQV(!isBGQview);
    setBOS(!isBuyOrderSub);
  };
  const toggleBOS = () => {
    setBGQ(false);
    setBOS(false);
  }
  const toggleSGQ = () => {
    setSGQ(!isSellGetQuote);
    setSGQV(!isSGQview);
  };

  const toggleSGQV = () => {
    setSGQV(!isSGQview);
    setSOS(!isSellOrderSub);
  };

  const toggleSOS = () => {
    setSOS(!isSellOrderSub);
    setSGQ(false);
    setSGQV(false);
    setBOS(false);
    setBGQ(false);
    setBGQV(false);
    handleUsdAmountChange("", "sell")
  };

  const buyPostQuote = () => {
    if(isBuyQuoteBtnLoading) {
      return;
    }
    if (!buyInput || !cadBuyInput || cadBuyInput == 0 || buyInput == 0) {
      dispatch(commonError('Please enter valid amount'));
      return;
    }
    // Connect wallet before purchase popup
    // if (!externalWalletList || externalWalletList.length === 0 || !externalWalletList.find(e => e.asset === buySelectedSymbol)) {
    //   setIsWalletNotAdded(true);
    //   return;
    // }

    let ExAmount = isBuyCad
      ? parseFloat(cadBuyInput) * 100
      : buySelectedObj.currentQuantityforPrice * 100;
    if (ExAmount <= fiatBalance) {
      const reqData = {
        customer_guid: decryptPassword(sessionStorage.guid),
        symbol: `${buySelectedSymbol}-USD`,
        side: "buy",
        deliver_amount: ExAmount,
      };
      setBuyQuoteBtnLoading(true);
      dispatch(postQuotesAction(sessionStorage.at, reqData));
    } else {
      dispatch(
        commonError(
          "Insufficient funds. Please ensure you have enough balance to complete the transaction."
        )
      );
    }
  };

  const sellPostQuote = () => {
    if(isSellQuoteBtnLoading) {
      return;
    }
    if (!sellInput || !cadSellInput || sellInput == 0 || cadSellInput == 0) {
      dispatch(commonError('Please enter valid amount'));
      return;
    }
    let ExAmount = isSellCad
      ? parseFloat(cadSellInput) * 100
      : sellSelectedObj.currentQuantityforPrice * 100;
    let tempobj = cryptoAssets?.find(
      (item) => item?.symbol === sellSelectedSymbol
    );
    let CryptAmt = !isSellCad ?
      findCryptoDecimal(sellInput, sellSelectedSymbol) :
      findCryptoDecimal(sellSelectedObj.currentPriceforQuantity, sellSelectedSymbol)
    if (CryptAmt <= findCryptoDecimal(tempobj?.platform_available, sellSelectedSymbol)) {
      let reqData = {
        customer_guid: decryptPassword(sessionStorage.guid),
        symbol: `${sellSelectedSymbol}-USD`,
        side: "sell",
        deliver_amount: CryptAmt,
      };
      setSellQuoteBtnLoading(true);
      dispatch(postQuotesAction(sessionStorage.at, reqData));
    } else {
      dispatch(
        commonError(
          `Insufficient ${tempobj?.name} Balance. Please ensure you have enough quantity to complete the transaction.`
        )
      );
    }
  };

  const buyPostTrade = () => {
    if(isBuyTradeBtnLoading) {
      return;
    }
    if (buyQuoteData) {
      if (buySeconds == 0) {
        dispatch(commonError('Transaction time has expired'));
        return;
      }
      const reqData = {
        quote_guid: buyQuoteData.guid,
      };
      setBuyTradeBtnLoading(true);
      dispatch(postTradesAction(sessionStorage.at, reqData));
    }
  };

  const sellPostTrade = () => {
    if(isSellTradeBtnLoading) {
      return;
    }
    if (sellQuoteData) {
      if (sellSeconds == 0) {
        dispatch(commonError('Transaction time has expired'));
        return;
      }
      const reqData = {
        quote_guid: sellQuoteData.guid,
      };
      setSellTradeBtnLoading(true);
      dispatch(postTradesAction(sessionStorage.at, reqData));
    }
  };

  const switchCadAndCrypto = () => {
    if (isBuyCad) {
      setIsBuycad(false);
    } else if (!isBuyCad) {
      setIsBuycad(true);
    }
  };

  const switchSellCadAndCrypto = () => {
    if (isSellCad) {
      setSellInput("")
      setIsSellCad(false);
    } else if (!isSellCad) {
      setIsSellCad(true);
    }
  };

  const showCurrency = (val = "BTC") => {
    if (val === "BTC") {
      return (1 / (liveSellBTC / 100)).toFixed(10);
    } else if (val === "ETH") {
      return (1 / (liveSellETH / 100)).toFixed(10);
    } else if (val === "BCH") {
      return (1 / (liveSellBCH / 100)).toFixed(10);
    } else if (val === "LTC") {
      return (1 / (liveSellLTC / 100)).toFixed(10);
    }
  };

  const convertSymbolToName = (asset) => {
    if (asset === "BTC") {
      return "Bitcoin";
    } else if (asset === "ETH") {
      return "Ethereum";
    } else if (asset === "USDC") {
      return "USDC";
    } else if (asset === "BCH") {
      return "Bitcoin Cash";
    } else if (asset === "LTC") {
      return "Litecoin";
    }
  };

  const findCryptoDecimal = (val, symbol) => {
    if (symbol === "BTC") {
      return val * 100000000;
    } else if (symbol === "ETH") {
      return val * 1000000000000000000;
    } else if (symbol === "USDC") {
      return val * 1000000;
    } else if (symbol === "BCH") {
      return val * 100000000;
    } else if (symbol === "LTC") {
      return val * 100000000;
    }
  };

  // const ExpiryTimeFinder = (data) => {
  //   const expiresAt = new Date(data.expires_at);
  //   const issuedAt = new Date(data.issued_at);
  //   const timeGapInMilliseconds = expiresAt - issuedAt;
  //   const seconds = Math.floor((timeGapInMilliseconds % (1000 * 60)) / 1000);
  //   if (seconds === 30) {
  //     return seconds - 25;
  //   } else {
  //     return seconds - 3;
  //   }
  // };

  const handleTabChange = (newKey) => {
    if (newKey == "sell") {
      updateSellSelectedObj(sellSelectedObj)
    } else if (newKey == "buy") {
      updateBuySelectedObj(buySelectedObj)
    }
    setActiveKeyis(newKey);
  };

  const setMax = () => {
    // if (!isSellCad) {
    setIsSellCad(true);
    let obj = sellSelectedObj && cryptoAssets?.find(e => e?.symbol === sellSelectedObj?.symbol)
    handleUsdAmountChange(obj.platform_available, "sell")
    // }
  };

  const setCADMax = async () => {
    handleCadAmountChange(fiatAccount?.platform_available, "buy");
  };

  const formatNumberToCurrency = (number) => {
    return number?.toLocaleString(
      undefined,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    )
  }


  return (
    <>
      <div className={(isBuyGetQuote || isSellGetQuote) ? "embossoverlay d-block" : "embossoverlay d-none"}></div>
      <div className="col-md-4 dash4col">
        <div className={(isBuyGetQuote || isSellGetQuote) ? "card fadeInDown emboss" : "card fadeInDown"}>
          <div className="card-header">
            <h4 className="card-title">Exchange </h4>
          </div>
          <div className="card-body p-0">
            <div className="buy-sell-widget dashtabs exchangetabs">
              <Tabs
                defaultActiveKey="buy"
                id="uncontrolled-tab-example"
                className="m-0"
                onSelect={handleTabChange}
              >
                <Tab eventKey="buy" title="Buy">
                  {exchangeLoader ?
                    <div className="my-5 py-5">
                      <Gifloader />
                    </div>
                    :
                    <form
                      method="post"
                      name="myform"
                      className="currency_validate fadeInDown"
                    >
                      <div className={isBuyGetQuote || isBuyOrderSub ? "d-none" : "row"}>
                        {/* <div className="col-sm-6">
                          <div className="mb-3">
                            <label >Crypto Amount</label>
                            <div className="input-group mb-3 igpcust">
                              <div className="input-group-prepend ">
                                <label className="input-group-text small">
                                  {isBuyCad ? "CAD" : buySelectedObj?.symbol}
                                </label>
                              </div>
                             
                               <div className="input-group-append ">
                            <label
                              className="input-group-text small cursor-pointer"
                              onClick={switchCadAndCrypto}
                            >
                              <img
                                src={require("../../images/two-arrows.png")}
                                alt=""
                                className="img-fluid icon16"
                              />
                            </label>
                          </div> 
                            </div>
                          </div>
                        </div>*/}

                        <div className="col-sm-12">
                          <div className="mb-3">
                            {/* <label>You Pay</label> */}
                            <div className="row">
                              <div className="col-4">
                                <label>You Pay</label>
                              </div>
                              <div className="col-8 text-end">
                                <span  onClick={setCADMax} className="text-primary fw-bold cursor-pointer">Max ${buySelectedObj && formatNumberToCurrency(fiatAccount?.platform_available)} {buySelectedObj && (fiatAccount?.asset)}</span>
                              </div>
                            </div>
                            <div className="input-group mb-3 igpcust">
                              <div className="input-group-prepend selpoabs">
                                <label className="input-group-text">
                                  <img
                                    src={require("../../images/usd.png")}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </label>
                                <select disabled
                                  name="currency"
                                  className="form-control smallselect bg-img-none">
                                  <option>{"USD"}</option>
                                </select>
                              </div>
                              <div className="dollor d-flex justify-content-start align-items-center px-0 ps-md-2 fw-bold fs-3 dollorclr">$</div>
                              <input
                                type="text"
                                name="buy_cad_amount"
                                className="form-control ps-0"
                                onChange={(e) => handleCadAmountChange(e.target.value, "buy")}
                                onBlur={(e) => handleCadAmountBlur(e, "buy")}
                                value={isBuyCad ? cadBuyInput || "" : buySelectedObj && buySelectedObj.currentQuantityforPrice || ""}
                              // onKeyPress={(e) => {
                              //   const key = e.key;
                              //   if (key !== "." && isNaN(Number(key))) {
                              //     e.preventDefault();
                              //   }
                              // }}
                              />
                              {/* {<div className="input-group-append" onClick={setCADMax}>
                                <label className="input-group-text small cursor-pointer bg-light fw-normal">Max</label>
                              </div>} */}
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div className="mb-3">
                            <label>You Get</label>
                            <div className="input-group mb-3 igpcust">
                              <div className="input-group-prepend selpoabs">
                                <label className="input-group-text">
                                  <img
                                    src={buySelectedObj && buySelectedObj.logo}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </label>
                                <select
                                  name="currency"
                                  className="form-control smallselect"
                                  onChange={(e) => {
                                    handleCurrencyChange(e, "buy")
                                    inputBuyRef.current.focus();
                                  }}
                                  value={buySelectedObj && buySelectedObj?.symbol}
                                >
                                  {buyDetails?.map((currency) => (
                                    <option
                                      key={currency?.symbol}
                                      value={currency?.symbol}
                                    >
                                      {currency?.symbol}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <input
                                type="text"
                                name="usd_amount"
                                className="form-control"
                                onChange={(e) => handleUsdAmountChange(e.target.value, "buy")}
                                value={!isBuyCad ? buyInput || "" : buySelectedObj && buySelectedObj.currentPriceforQuantity || ""}
                                ref={inputBuyRef}
                                onKeyPress={(e) => {
                                  const key = e.key;
                                  if (key !== "." && isNaN(Number(key))) {
                                    e.preventDefault();
                                  }
                                }}
                              />

                            </div>
                          </div>
                          {/* <OverlayTrigger overlay={
                            <Tooltip>
                              For transaction above $25000, you can reach OTC (Over The Counter) for support.
                            </Tooltip>
                          }>

                            <div className="m-0 shadow-none text-center txtsmall">
                              <div className="mb-0 cdwadd">
                                <span className="mdi mdi-alert-outline"></span>
                                <p className="fw-bold">
                                  For transaction above <em className="text-dark">$25000</em>, you can reach OTC (Over The Counter) for support.
                                </p>
                              </div>
                            </div>
                          </OverlayTrigger> */}
                        </div>

                        {/* <label className="text-center w-100">
                        {isBuyCad ? (
                          <>
                            {buySelectedObj &&
                            buySelectedObj.currentPriceforQuantity &&
                            buyInput ? (
                              <>
                                <span className="text-primary fw-bold">
                                  $ {buySelectedObj.currentPriceforQuantity}
                                </span>{" "}
                                {buySelectedObj?.symbol}
                              </>
                            ) : (
                              buySelectedObj &&
                              `1 ${buySelectedObj?.symbol} = ${formatCurrency(
                                buySelectedObj.buy_price / 100
                              )}`
                            )}
                          </>
                        ) : (
                          <>
                            {buySelectedObj &&
                            buySelectedObj.currentQuantityforPrice &&
                            buyInput ? (
                              <>
                                <span className="text-primary fw-bold">
                                  $ {buySelectedObj.currentQuantityforPrice}
                                </span>{" "}
                                CAD
                              </>
                            ) : (
                              buySelectedObj &&
                              `1 CAD = ${showCurrency(buySelectedObj?.symbol)}`
                            )}
                          </>
                        )}
                      </label> */}

                        <div className="text-center mt-3">
                          <button
                            type="button"
                            className={`btn btn-primary mt-3 btn-loader ${isBuyQuoteBtnLoading ? "active" : ""
                              }`}
                            onClick={buyPostQuote}
                          >
                            <span>Get Quote</span>
                            <ButtonLoader />
                          </button>
                        </div>
                      </div>

                      {/* <div
                      className={isBGQview ? "d-block fadeInDown" : "d-none"}
                    >                    
                      <div className="ppmethod">
                        <ul className="list-unstyled smplist">
                          <li className="flex-column">
                            <h4 className="fw-bold mb-0">Order Summary</h4>                            
                          </li>
                          <li>
                            <div className="spm-content">Account</div>
                            <div className="spm-icon">
                              <span className="text-primary me-1 fw-bold">NOIA9212991235213</span>
                            </div>
                          </li>
                          <li>
                            <div className="spm-content">Wallet Name</div>
                            <div className="spm-icon">
                              <span className="text-primary me-1 fw-bold">
                                Prefilled Wallet Name
                              </span>
                              
                            </div>
                          </li>
                          <li>
                            <div className="spm-content">Crypto Value</div>

                            <div className="spm-icon">
                              <span className="text-primary me-1 fw-bold">
                                $0.00
                              </span>{" "}
                              CAD
                            </div>
                          </li>
                          <li>
                            <div className="spm-content">Enter OTP</div>

                            <div className="spm-icon">                            
                                <input className="otp form-control" type="text" oninput='digitValidate(this)' onkeyup='tabChange(1)' maxlength="1" />
                                <input className="otp form-control" type="text" oninput='digitValidate(this)' onkeyup='tabChange(2)' maxlength="1" />
                                <input className="otp form-control" type="text" oninput='digitValidate(this)' onkeyup='tabChange(3)' maxlength="1" />
                                <input className="otp form-control" type="text" oninput='digitValidate(this)'onkeyup='tabChange(4)' maxlength="1" />
                            
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="text-center tbtnbox">
                        <button
                          type="button"
                          className="btn btn-light me-3 minw-auto"
                          onClick={toggleBGQ}
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          className={`btn btn-primary btn-loader ${
                            isBuyTradeBtnLoading ? "active" : ""
                          }`}
                          onClick={buyPostTrade}                          
                        >
                          <span>Verify and Continue</span>
                          <ButtonLoader />
                        </button>
                      </div>
                    </div> */}

                      <div
                        className={isBGQview && !isBuyOrderSub ? "d-block fadeInDown" : "d-none"}
                      >
                        {/* <div className="ppmethod mb-3">
                        <ul className="list-unstyled smplist gqlist">
                          <li className="border-none bg-light p-2">
                            <div className="spm-icon" onClick={toggleBGQ}>
                              <i className="las la-angle-left"></i>
                            </div>
                            <div className="spm-content">
                              <div className="spm-icon">
                                <img
                                  src={buySelectedObj && buySelectedObj.logo}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <p>
                                {buySelectedObj &&
                                buySelectedObj.currentPriceforQuantity ? (
                                  <>
                                    {buySelectedObj.currentPriceforQuantity} ={" "}
                                    <span className="text-primary fw-bold">
                                      ${buyInput}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {buySelectedObj &&
                                      `1 ${buySelectedObj?.symbol} = `}
                                    <span className="text-primary fw-bold">
                                      {buySelectedObj &&
                                        formatCurrency(
                                          buySelectedObj.buy_price / 100
                                        )}
                                    </span>{" "}
                                  </>
                                )}
                                CAD
                              </p>
                            </div>
                            <div className="spm-icon">&nbsp;</div>
                          </li>
                        </ul>
                      </div> */}

                        <div className="ppmethod">
                          <ul className="list-unstyled smplist">
                            <li className="flex-column">
                              {/* <h4 className="fw-bold">Quote Breakdown</h4> */}
                              <span className="small">
                                Market rates will automatically refresh every{" "}
                                <em className="quote-countdown">{buySeconds && buySeconds}</em>{" "}
                                seconds
                              </span>
                            </li>
                            <li>
                              <div className="spm-content">Purchase amount</div>
                              <div className="spm-icon">
                                <span className="text-primary me-1 fw-bold">
                                  ${buyQuoteData && buyQuoteData.purchasePrice}
                                </span>{" "}
                                USD
                              </div>
                            </li>
                            {/* <li>
                            <div className="spm-content">Transaction fee</div>
                            <div className="spm-icon">
                              <span className="text-primary me-1 fw-bold">
                                ${buyQuoteData && (buyQuoteData.fee / 100)?.toFixed(2)}
                              </span>{" "}
                              USD
                            </div>
                          </li> */}
                            <li>
                              <div className="spm-content">Purchase quantity</div>
                              <div className="spm-icon">
                                <span className="text-primary me-1 fw-bold">
                                  {buyQuoteData && buyQuoteData.purchaseQuantity}
                                </span>{" "}
                                {buyQuoteData && buyQuoteData.sign}
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="text-center tbtnbox" style={{ cursor: isBuyTradeBtnLoading ? "not-allowed" : "pointer" }}>
                          <button
                            type="button"
                            className="btn btn-light me-3"
                            onClick={toggleBGQ}
                            disabled={isBuyTradeBtnLoading}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className={`btn btn-primary btn-loader ${isBuyTradeBtnLoading ? "active" : ""
                              }`}
                            onClick={buyPostTrade}
                          >
                            <span>Buy Now</span>
                            <ButtonLoader />
                          </button>
                        </div>
                      </div>

                      <div
                        className={
                          isBuyOrderSub ? "d-block fadeInDown" : "d-none"
                        }
                      >
                        <div className="ppmethod mb-3">
                          <ul className="list-unstyled smplist">
                            <li className="flex-column">
                              <h4 className="fw-bold">Buy Order Complete</h4>
                              <span className="small">
                                Your order has been placed, thank you.
                              </span>
                            </li>
                            <li>
                              <div className="spm-content ">
                                <span className="fw-bold">Transaction ID</span>
                                <p>
                                  <span className="text-ecli">
                                    {buyTradeData && buyTradeData.guid}
                                  </span>
                                </p>
                              </div>
                              <div className="spm-content ">
                                <span className="fw-bold">Date</span>
                                <p>
                                  <span>
                                    {buyTradeData &&
                                      formatDate(buyTradeData.created_at)}
                                  </span>
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="spm-content">Purchase amount</div>
                              <div className="spm-icon">
                                <span className="text-primary me-1 fw-bold">
                                  ${buyTradeData && buyTradeData.purchasePrice}
                                </span>{" "}
                                USD
                              </div>
                            </li>
                            {/* <li>
                            <div className="spm-content">Transaction fee</div>
                            <div className="spm-icon">
                              <span className="text-primary me-1 fw-bold">
                                ${buyTradeData && (buyTradeData.fee / 100)?.toFixed(2)}
                              </span>{" "}
                              USD
                            </div>
                          </li> */}
                            <li>
                              <div className="spm-content">Purchase quantity</div>
                              <div className="spm-icon">
                                <span className="text-primary me-1 fw-bold">
                                  {buyTradeData && buyTradeData.purchaseQuantity}
                                </span>{" "}
                                {buyTradeData && buyTradeData.sign}
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="text-center">
                          <div className="mb-2 alert alert-warning small iconalert">
                            <span className="mdi mdi-alert-outline"></span>
                            <span className="text-start">The Crypto Exchange is Non-Custodial and all Crypto Balances must be withdrawn within 48 hrs of purchase to an External Wallet or generate one from The Crypto Wallet App.</span>
                          </div>
                          <Link
                            type="button"
                            className="btn btn-primary"
                            onClick={handleAddWallet}
                          >
                            Please Withdraw Your Crypto
                          </Link>
                        </div>
                      </div>
                    </form>
                  }
                </Tab>
                <Tab eventKey="sell" title="Sell" >
                  {
                    sellSelectedObj !== null && Object.keys(sellSelectedObj).length > 0 ? (
                      <form
                        method="post"
                        name="myform"
                        className="currency_validate fadeInDown"
                      >
                        <div className={isSellGetQuote || isSellOrderSub ? "d-none" : "row"}>
                          <div className="col-sm-12">
                            <div className="mb-3 ">
                              <div className="row">
                                <div className="col-4">
                                  <label>You Pay</label>
                                </div>
                                <div className="col-8 text-end">

                                  <span onClick={setMax} className="text-primary fw-bold cursor-pointer">
                                    Max {sellSelectedObj && ((sellSelectedObj?.symbol === "USDC" && 
                                    ((cryptoAssets?.find(e => e?.symbol === sellSelectedObj?.symbol))?.platform_available) > 0) ? 
                                    ((cryptoAssets?.find(e => e?.symbol === sellSelectedObj?.symbol))?.platform_available).toFixed(4) : 
                                    (cryptoAssets?.find(e => e?.symbol === sellSelectedObj?.symbol))?.platform_available)} {sellSelectedObj && 
                                    (cryptoAssets?.find(e => e?.symbol === sellSelectedObj?.symbol))?.asset}
                                  </span>

                                </div>
                              </div>
                              <div className="input-group mb-3 igpcust">
                                <div className="input-group-prepend selpoabs">
                                  <label className="input-group-text">
                                    <img
                                      src={sellSelectedObj && sellSelectedObj.logo}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </label>
                                  <select
                                    name="currency"
                                    className="form-control smallselect"
                                    onChange={(e) => {
                                      handleCurrencyChange(e, "sell")
                                      inputSellRef.current.focus();
                                    }}
                                    value={sellSelectedObj && sellSelectedObj?.symbol}
                                  >
                                    {sellDetails?.map((currency) => (
                                      <option
                                        key={currency?.symbol}
                                        value={currency?.symbol}
                                      >
                                        {currency?.symbol}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                {/* </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label>Crypto Amount</label>
                              <div className="input-group mb-3 igpcust">
                                <div className="input-group-prepend ">
                                  <label className="input-group-text small">
                                    {isSellCad ? "CAD" : sellSelectedObj && sellSelectedObj?.symbol}
                                  </label>
                                </div> */}
                                <input
                                  type="text"
                                  name="usd_amount"
                                  className="form-control"
                                  onChange={(e) => handleUsdAmountChange(e.target.value, "sell")}
                                  value={!isSellCad ? sellInput || "" : sellSelectedObj && sellSelectedObj.currentPriceforQuantity || ""}
                                  ref={inputSellRef}
                                  onKeyPress={(e) => {
                                    const key = e.key;
                                    if (key !== "." && isNaN(Number(key))) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {/* {<div className="input-group-append" onClick={setMax}>
                                  <label className="input-group-text small cursor-pointer bg-light fw-normal">Max</label>
                                </div>} */}
                              </div>
                            </div>
                            {/* {!isSellCad && <div className="input-group-append" onClick={setMax}>
                                  <label className="input-group-text small cursor-pointer bg-light fw-normal">Max</label>
                                </div>}
                                <div className="input-group-append ">
                            <label
                              className="input-group-text small cursor-pointer"
                              onClick={switchSellCadAndCrypto}
                            >
                              <img
                                src={require("../../images/two-arrows.png")}
                                alt=""
                                className="img-fluid icon16"
                              />
                            </label>
                          </div>
                              </div>
                            </div> */}
                          </div>
                          <div className="col-sm-12">
                            <div className="mb-3">
                              <label>You Get</label>
                              <div className="input-group mb-3 igpcust">
                                <div className="input-group-prepend selpoabs">
                                  <label className="input-group-text">
                                    <img
                                      src={require("../../images/usd.png")}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </label>
                                  {/* <label className="input-group-text small">
                                  {"CAD"}
                                </label> */}
                                  <select disabled
                                    name="currency"
                                    className="form-control smallselect bg-img-none">
                                    <option>{"USD"}</option>
                                  </select>
                                </div>

                                <div className="dollor d-flex justify-content-start align-items-center px-0 ps-md-2 fw-bold fs-3 dollorclr">$</div>
                                <div className="dwinput">
                                  <input
                                    type="text"
                                    name="sell_cad_amount"
                                    className="form-control ps-0"
                                    onChange={(e) => handleCadAmountChange(e.target.value, "sell")}
                                    onBlur={(e) => handleCadAmountBlur(e, "sell")}
                                    value={isSellCad ? cadSellInput || "" : sellSelectedObj && sellSelectedObj.currentQuantityforPrice || ""}
                                  // onKeyPress={(e) => {
                                  //   const key = e.key;
                                  //   if (key !== "." && isNaN(Number(key))) {
                                  //     e.preventDefault();
                                  //   }
                                  // }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <label className="text-center w-100">
                        {isSellCad ? (
                          <>
                            {sellSelectedObj &&
                            sellSelectedObj.currentPriceforQuantity &&
                            sellInput ? (
                              <>
                                <span className="text-primary fw-bold">
                                  $ {sellSelectedObj.currentPriceforQuantity}
                                </span>{" "}
                                {sellSelectedObj?.symbol}
                              </>
                            ) : (
                              sellSelectedObj &&
                              `1 ${sellSelectedObj?.symbol} = ${formatCurrency(
                                sellSelectedObj.sell_price / 100
                              )}`
                            )}
                          </>
                        ) : (
                          <>
                            {sellSelectedObj &&
                            sellSelectedObj.currentQuantityforPrice &&
                            sellInput ? (
                              <>
                                <span className="text-primary fw-bold">
                                  $ {sellSelectedObj.currentQuantityforPrice}
                                </span>{" "}
                                CAD
                              </>
                            ) : (
                              sellSelectedObj &&
                              `1 CAD = ${showCurrency(sellSelectedObj?.symbol)}`
                            )}
                          </>
                        )}
                      </label> */}
                          <div className="text-center mt-3">
                            <button
                              type="button"
                              className={`btn btn-primary mt-3 btn-loader ${isSellQuoteBtnLoading ? "active" : ""
                                }`}
                              onClick={sellPostQuote}
                            >
                              <span>Get Quote</span>
                              <ButtonLoader />
                            </button>
                          </div>
                        </div>

                        <div
                          className={isSGQview && !isSellOrderSub ? "d-block fadeInDown" : "d-none"}
                        >
                          {/* <div className="ppmethod mb-3">
                        <ul className="list-unstyled smplist gqlist">
                          <li className="border-none bg-light p-2">
                            <div className="spm-icon" onClick={toggleSGQ}>
                              <i className="las la-angle-left"></i>
                            </div>
                            <div className="spm-content">
                              <div className="spm-icon">
                                <img
                                  src={sellSelectedObj && sellSelectedObj.logo}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <p>
                                {sellSelectedObj &&
                                sellSelectedObj.currentPriceforQuantity ? (
                                  <>
                                    {sellSelectedObj.currentPriceforQuantity} ={" "}
                                    <span className="text-primary fw-bold">
                                      ${sellInput}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {sellSelectedObj &&
                                      `1 ${sellSelectedObj?.symbol} = `}
                                    <span className="text-primary fw-bold">
                                      {sellSelectedObj &&
                                        formatCurrency(
                                          sellSelectedObj.sell_price / 100
                                        )}
                                    </span>{" "}
                                  </>
                                )}
                                CAD
                              </p>
                            </div>
                            <div className="spm-icon">&nbsp;</div>
                          </li>
                        </ul>
                      </div> */}

                          <div className="ppmethod">
                            <ul className="list-unstyled smplist">
                              <li className="flex-column">
                                {/* <h4 className="fw-bold">Quote Breakdown</h4> */}
                                <span className="small">
                                  Market rates will automatically refresh every{" "}
                                  <em className="quote-countdown">{sellSeconds && sellSeconds}</em>{" "}
                                  seconds
                                </span>
                              </li>
                              <li>
                                <div className="spm-content">Sell amount</div>
                                <div className="spm-icon">
                                  <span className="text-primary me-1 fw-bold">
                                    ${sellQuoteData && sellQuoteData.purchasePrice}
                                  </span>{" "}
                                  USD
                                </div>
                              </li>
                              {/* <li>
                            <div className="spm-content">Transaction fee</div>
                            <div className="spm-icon">
                              <span className="text-primary me-1 fw-bold">
                                ${sellQuoteData && (sellQuoteData.fee / 100)?.toFixed(2)}
                              </span>{" "}
                              USD
                            </div>
                          </li> */}
                              <li>
                                <div className="spm-content">Sell quantity</div>
                                <div className="spm-icon">
                                  <span className="text-primary me-1 fw-bold">
                                    {sellQuoteData &&
                                      sellQuoteData.purchaseQuantity}
                                  </span>{" "}
                                  {sellQuoteData && sellQuoteData.sign}
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div className="text-center tbtnbox" style={{ cursor: isSellTradeBtnLoading ? "not-allowed" : "pointer" }}>
                            <button
                              type="button"
                              className="btn btn-light me-3"
                              onClick={toggleSGQ}
                              disabled={isSellTradeBtnLoading}
                            >
                              Cancel
                            </button>

                            <button
                              type="button"
                              className={`btn btn-primary btn-loader ${isSellTradeBtnLoading ? "active" : ""
                                }`}
                              onClick={sellPostTrade}
                            >
                              <span>Sell Now</span>
                              <ButtonLoader />
                            </button>
                          </div>
                        </div>

                        <div
                          className={
                            isSellOrderSub ? "d-block fadeInDown" : "d-none"
                          }
                        >
                          <div className="ppmethod">
                            <ul className="list-unstyled smplist">
                              <li className="flex-column">
                                <h4 className="fw-bold">Sell Order Complete</h4>
                                <span className="small">
                                  Your order has been placed, thank you.
                                </span>
                              </li>
                              <li>
                                <div className="spm-content ">
                                  <span className="fw-bold">Transaction ID</span>
                                  <p>
                                    <span className="text-ecli">
                                      {sellTradeData && sellTradeData.guid}
                                    </span>
                                  </p>
                                </div>
                                <div className="spm-content ">
                                  <span className="fw-bold">Date</span>
                                  <p>
                                    <span>
                                      {sellTradeData &&
                                        formatDate(sellTradeData.created_at)}
                                    </span>
                                  </p>
                                </div>
                              </li>

                              <li>
                                <div className="spm-content">Sold Quantity</div>
                                <div className="spm-icon">
                                  <span className="text-primary me-1 fw-bold">
                                    {sellTradeData && sellTradeData.purchaseQuantity}
                                  </span>{" "}
                                  {sellTradeData && sellTradeData.sign}
                                </div>
                              </li>
                              {/* <li>
                            <div className="spm-content">Transaction fee</div>
                            <div className="spm-icon">
                              <span className="text-primary me-1 fw-bold">
                                ${sellTradeData && (sellTradeData.fee / 100)?.toFixed(2)}
                              </span>{" "}
                              USD
                            </div>
                          </li> */}
                              <li>
                                <div className="spm-content">Sold Amount</div>
                                <div className="spm-icon">
                                  <span className="text-primary me-1 fw-bold">
                                    ${sellTradeData && sellTradeData.purchasePrice}
                                  </span>{" "}
                                  USD
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="text-center">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={toggleSOS}
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </form>
                    ) : (
                      <div className="my-5 py-5">
                        <Gifloader />
                      </div>
                    )
                  }
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal centered show={isWalletNotAdded} className="tce-popup">
        <div className="tce-ppcon ">
          <div className="getverified fadeInDown">
            <Modal.Body className="text-center">
              <div className="tce-ppcls" onClick={() => setIsWalletNotAdded(false)}><span>x</span></div>
              <p className="mb-0 mt-3 mt-4 ls-3">Please ensure that you are connected to your <br /> external <strong>{`${convertSymbolToName(buySelectedSymbol)}`}</strong> wallet.</p>
              <Link to="/external-wallet">
                <button className="btn btn-primary mt-3">Add Wallet</button>
              </Link>
            </Modal.Body>
          </div>
        </div>
      </Modal>

      <Modal centered show={showAddWallet} className="tce-popup fadeInDown" size="lg">
        <div className="tce-ppcon">
          <div className="tce-ppcls" onClick={handleAddWalletClose}><span>x</span></div>
          {showAddWallet && (
            <div className="getverified fadeInDown">
              {/* <Modal.Title id="contained-modal-title-vcenter" className="px-5">
                Please withdraw your cryptocurrency to an external wallet for added security
              </Modal.Title> */}
              <Modal.Body>
                <h3 className="text-center mb-5 mt-3 fw-bold">Complete your withdrawal within 48 hours of the time of purchase</h3>

                <div className="row pwyctewfasrow">
                  {/* <div className="col-md-4">
                    <div className="pwyctewfas">
                      <img src={require('./../../images/cash-withdrawal-1.png')} alt="" />
                      <p className="text-center">
                        If you have an external wallet connected, click the button below to go to the withdrawal page.
                      </p>
                      <Link to="/withdrawals#crypto" className="btn btn-primary">Withdraw</Link>
                    </div>
                  </div> */}

                  

                  <div className="col-md-6">
                    <div className="pwyctewfas">
                      <img src={require('./../../images/booking-app.png')} alt="" />
                      <p className="text-center">
                        If you require an address, download our app, "The Crypto Wallet" to complete your withdrawal.
                      </p>
                      <Link to="#" className="btn btn-primary">Download Wallet</Link>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="pwyctewfas">
                      <div className="pwyctewfasmult">
                        <a href="https://trezor.io/" target="_blank"><img src={require('./../../images/trezor.png')} alt="" /></a>
                        {/* <img src={require('./../../images/wallet-1.png')} alt="" /> */}
                        <a href="https://www.ledger.com/" target="_blank"><img src={require('./../../images/ledger-logo-long.png')} alt="" /></a>
                      </div>
                      <p className="text-center">
                        If you don't have an external wallet connected, click the button below and add your crypto withdrawal address.
                      </p>
                      <Link to="/external-wallet" className="btn btn-primary">Add External Wallet</Link>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Exchange;