import React from 'react';

function Svgloading() {

    return (
        <>
            <div className="spm-icon spm-cc">
                <svg className="crossmark animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" >
                    <circle className="crossmark__circle addClass" cx="26" cy="26" r="25" fill="none" />
                    <circle className="loader-path" cx="26" cy="26" r="17" fill="none" stroke="#ffffff" stroke-width="3" />
                </svg>
            </div>
        </>
    )
}

export default Svgloading;