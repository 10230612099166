import React, { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { logoutUser } from '../../actions/authAction';
import PageTitle from "../element/page-title";
import { useDispatch } from 'react-redux';


function Sidebar() {

      const [show, setShow] = useState(false);
      const dispatch = useDispatch();
      const navigate = useNavigate();

      const handleLogout = () => {
            // Dispatch the logoutUser action
            dispatch(logoutUser());
            // Clear the token from local storage
            sessionStorage.removeItem("at");
            sessionStorage.removeItem("ut");
            sessionStorage.removeItem("user_email");
            sessionStorage.removeItem("guid");
            localStorage.removeItem("f_at");
            localStorage.removeItem("displayName");
            // localStorage.removeItem("f_refreshToken");
            navigate("/signin");
      }

      return (
            <>
                  <div className={show ? "sidebar show" : "sidebar"}>
                        <button className="tag_btn" onClick={() => setShow(!show)}>
                              <i className="fa fa-bars"></i>
                              <i className="fa fa-times"></i>
                        </button>
                        <Link className="brand-logo" to={'/'}><img src={require('./../../images/logo-with-text.png')} alt="" /></Link>
                        {/* <Link className="brand-logo _mobile" to={'/'}><img src={require('./../../images/logo-icon.png')} alt="" width='30' /></Link> */}

                        <PageTitle />
                        <div className="menu">
                              <ul>
                                    <li>
                                          <NavLink to="/dashboard" className={({ isActive }) => (isActive ? "active" : '')}>
                                                <span><i className="mdi mdi-view-dashboard"></i></span>
                                                <span className="nav-text">Dashboard</span>
                                          </NavLink>
                                    </li>
                                    <li>
                                          <NavLink to="/exchange" className={({ isActive }) => (isActive ? "active" : '')}>
                                                <span><i className="mdi mdi-swap-horizontal"></i></span>
                                                <span className="nav-text">Exchange</span>
                                          </NavLink>
                                    </li>
                                    <li><NavLink to="/assets" className={({ isActive }) => (isActive ? "active" : '')}>
                                          <span><i className="mdi mdi-cash-multiple"></i></span>
                                          <span className="nav-text">Assets</span>
                                    </NavLink>
                                    </li>
                                    <li><NavLink to="/deposits" className={({ isActive }) => (isActive ? "active" : '')}>
                                          <span><i className="mdi mdi-arrow-up-bold-box"></i></span>
                                          <span className="nav-text">Deposits</span>
                                    </NavLink>
                                    </li>
                                    <li><NavLink to="/withdrawals" className={({ isActive }) => (isActive ? "active" : '')}>
                                          <span><i className="mdi mdi-arrow-down-bold-box"></i></span>
                                          <span className="nav-text">Withdrawals</span>
                                    </NavLink>
                                    </li>
                                    <li><NavLink to="/external-wallet" className={({ isActive }) => (isActive ? "active" : '')}>
                                          <span><i className="la la-wallet"></i></span>
                                          <span className="nav-text">External Wallet</span>
                                    </NavLink>
                                    </li>
                                    <li><NavLink to="/orders" className={({ isActive }) => (isActive ? "active" : '')}>
                                          <span><i className="mdi mdi-view-stream"></i></span>
                                          <span className="nav-text">Orders</span>
                                    </NavLink>
                                    </li>

                                    <li>
                                          <ul className='fixed_sidebar'>
                                                <li className='mobinav'>
                                                      <h5 className='fw-bold'>My Account</h5>
                                                </li>

                                    <li className='mobinav'><NavLink to="/settings" className={({ isActive }) => (isActive ? "active" : '')}>
                                                      <span><i className="la la-cog"></i></span>
                                                      <span className="nav-text">Settings</span>
                                                </NavLink>
                                                </li>

                                    <li className='mobinav'><NavLink to="/payment-method" className={({ isActive }) => (isActive ? "active" : '')}>
                                                      <span><i className="la la-credit-card"></i></span>
                                                      <span className="nav-text">Payment Method</span>
                                                </NavLink>
                                                </li>
                                                {/* <li className='mobinav'><NavLink to="/external-wallet" className={({ isActive }) => (isActive ? "active" : '')}>
                                                            <span><i className="la la-wallet"></i></span>
                                                            <span className="nav-text">External Wallet</span>
                                                      </NavLink>
                                                      </li> */}
                                                <li className='mobinav'><NavLink to="/signin" onClick={handleLogout}>
                                                      <span><i className="la la-sign-out"></i></span>
                                                      <span className="nav-text">Logout</span>
                                                </NavLink>
                                                </li>
                                          </ul>
                                    </li>
                              </ul>
                        </div>
                        <div className="sidebar-footer">
                              <div className="social">
                                    <Link to=""><i className="fa-brands fa-youtube"></i></Link>
                                    <Link to=""><i className="fa-brands fa-instagram"></i></Link>
                                    <Link to=""><i className="fa-brands fa-x-twitter"></i></Link>
                                    <Link to=""><i className="fa-brands fa-facebook-f"></i></Link>
                              </div>
                              <div className="copy_right">
                                    © {new Date().getFullYear()}<br /> The Crypto Exchange
                              </div>
                        </div>
                  </div>
            </>
      )
}

export default Sidebar;