import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';


function Header3() {
  const accessToken = sessionStorage.getItem("at");

  return (
    <>
      <div className="header landing_page">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="navigation">
                <Navbar expand="lg">
                  <Link className="navbar-brand" to={'/'}><img src={require('./../../images/logo-with-text.png')} alt="" />
                    {/* <span>The Crypto Exchange </span> */}
                  </Link>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse>
                    <Nav className="mx-auto">
                      <Nav.Item><a className="nav-link" href="/dashboard">Exchange</a></Nav.Item>
                      <Nav.Item><a className="nav-link" href="/wallet">Wallet </a></Nav.Item>
                      <Nav.Item><a type="button" className="nav-link" data-bs-toggle="dropdown" aria-expanded="false">Services</a>
                        <ul className="dropdown-menu">
                          <li><a className="dropdown-item" href="/services">Services</a></li>
                          <li><a className="dropdown-item" href="/otc">OTC</a></li>
                        </ul>
                      </Nav.Item>

                      <NavDropdown title="Learn" id="learn-dropdown">
                        <div className="d-block d-md-flex flex-row">
                          <div className="flex-fill" style={{padding: '0 20px'}}>
                            <NavDropdown.ItemText style={{ color: '#1355d7', fontWeight: '600', textTransform: 'uppercase', fontSize: '14px', padding: 0 }}>What It Is?</NavDropdown.ItemText>
                            <NavDropdown.Divider />
                            <NavDropdown.Item className='custom-dropdown-item' as={Link} to="/what-is-bitcoin-btc">What is Bitcoin?</NavDropdown.Item>
                            <NavDropdown.Item className='custom-dropdown-item' as={Link} to="/what-is-ether">What is Ether?</NavDropdown.Item>
                            <NavDropdown.Item className='custom-dropdown-item' as={Link} to="/what-is-litecoin">What is Litecoin?</NavDropdown.Item>
                            <NavDropdown.Item className='custom-dropdown-item' as={Link} to="/what-is-bitcoin-cash">What is Bitcoin Cash?</NavDropdown.Item>
                            <NavDropdown.Item className='custom-dropdown-item' as={Link} to="/what-is-XRP">What is XRP?</NavDropdown.Item>
                          </div>

                          <div className="flex-fill" style={{padding: '0 20px'}}>
                            <NavDropdown.ItemText style={{ color: '#1355d7', fontWeight: '600', textTransform: 'uppercase', fontSize: '14px', padding: 0 }}>How To Buy?</NavDropdown.ItemText>
                            <NavDropdown.Divider />
                            <NavDropdown.Item className='custom-dropdown-item' as={Link} to="/how-to-buy-bitcoin-canada">How to Buy Bitcoin?</NavDropdown.Item>
                            <NavDropdown.Item className='custom-dropdown-item' as={Link} to="/how-to-buy-ether">How to Buy Ether?</NavDropdown.Item>
                            <NavDropdown.Item className='custom-dropdown-item' as={Link} to="/how-to-buy-litecoin">How to Buy Litecoin?</NavDropdown.Item>
                            <NavDropdown.Item className='custom-dropdown-item' as={Link} to="/how-to-buy-bitcoin-cash">How to Buy Bitcoin Cash?</NavDropdown.Item>
                            <NavDropdown.Item className='custom-dropdown-item' as={Link} to="/how-to-buy-XRP">How to Buy XRP?</NavDropdown.Item>
                          </div>
                        </div>
                      </NavDropdown>

                      <Nav.Item><a className="nav-link" href="/referral-program">Referral Program </a></Nav.Item>
                      <Nav.Item><a className="nav-link" href="/about">About </a></Nav.Item>
                      <Nav.Item><a className="nav-link" href="https://blog.thecryptoexchange.us/" target='_blank' rel="noreferrer">Blog </a></Nav.Item>
                    </Nav>

                    <div className="btn-group selcur">
                      <button type="button" className="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src={require('./../../images/usd.png')} alt="" className="img-fluid" /> USA
                      </button>
                      <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="#"><img src={require('./../../images/usd.png')} alt="" className="img-fluid" /> USA</a></li>
                        <li><a className="dropdown-item" target="_blank" href="https://thecryptoexchange.ca/"><img src={require('./../../images/canada.png')} alt="" className="img-fluid" /> CA</a></li>
                      </ul>
                    </div>

                    <div className="header_auth">
                      {!accessToken ?
                        <>
                          <Link to={'/signin'} className="btn btn-outline-primary">Sign in</Link>
                          <Link to={'/signup'} className="btn btn-primary">Sign up</Link>
                        </> :

                        <Link to={'/dashboard'} className="btn btn-outline-primary">Dashboard</Link>
                      }
                    </div>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header3;