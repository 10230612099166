import Accordion from 'react-bootstrap/Accordion'

import Header3 from '../layout/header3'
import Footer1 from './../layout/footer1'

function Faq() {
  const faqs = [
    // FAQ
    {
      eventKey: '0',
      question: 'What cryptocurrencies can I trade on TheCryptoExchange?',
      answer: 'You can Buy/Sell Bitcoin, Ethereum, Litecoin and Bitcoin Cash and we offer over 3000 additional Crypto Currencies on our Cross-Chain Defi Swap Agregator site',
      linkText: 'www.thecryptowallet.io',
      link: 'https://thecryptowallet.io',
    },
    {
      eventKey: '1',
      question: 'Does TheCryptoExchange offer any educational resources for beginners in cryptocurrency trading?',
      answer: 'We do not offer courses but we offer 1 on 1 Consulting Services',
    },
    {
      eventKey: '2',
      question: 'Can I use TheCryptoExchange on my mobile device?',
      answer: 'Yes and it is the preferred way for you to setup your account using your phone using the latest in WEB3 services to take pictures/ KYC and link your banking with ease.',
    },
    {
      eventKey: '3',
      question: 'How does TheCryptoExchange handle disputes or issues with trades?',
      answer: (
        <span>
          We offer excellent email support with quick turnaround{' '}
          <a href='mailto:support@thecryptoexchange.us' style={{ color: '#1355d7', fontWeight: 'bold' }}>support@thecryptoexchange.us</a>
          . Please help and provide as much information as possible to help us resolve your issue as
          fast as possible.
        </span>
      ),
    },
  ]

  const otcFaqs = [
    // OTC
    {
      eventKey: '4',
      question: 'What is OTC trading?',
      answer: 'OTC trading, or Over-The-Counter trading, involves direct buying and selling of financial assets outside formal exchanges, bypassing centralized platforms.',
    },
    {
      eventKey: '5',
      question: 'How does OTC trading differ from regular exchange trading?',
      answer: 'Dedicated Broker to get onboarded and verified seamlessly, improved rates, live quotes with quick settlement. What cryptocurrencies are available for OTC trading ? Bitcoin, Ethereum and USDC',
    },
    {
      eventKey: '6',
      question: 'How do I access the OTC trading platform on TheCryptoExchange.us?',
      answer: 'Book a 15 minute Consultation to see if you qualify and we will guide you thru the process step by step',
    },
    {
      eventKey: '7',
      question: 'Is there a minimum trade size for OTC trades on TheCryptoExchange.us?',
      answer: 'OTC Services start for accredited Investors buying or selling $25,000 Usd worth or Crypto or more.',
    },
    {
      eventKey: '8',
      question: 'What documentation is required for OTC trading on TheCryptoExchange.us?',
      answer: 'All document will be uploaded using a secure link for either Business or Individual accounts',
    },
    {
      eventKey: '9',
      question: 'Does TheCryptoExchange.us offer any incentives or rewards for OTC traders?',
      answer: 'Yes, We offer better Buy and Sell rates depending on the size of the transaction.',
    },
    {
      eventKey: '10',
      question: 'Can I access the OTC trading platform on TheCryptoExchange.us through a mobile device?',
      answer: 'No, OTC trading is completed separately from the online and mobile platform.',
    },
  ]

  const walletFaqs = [
    // Wallet
    {
      eventKey: '11',
      question: 'What is a CryptoExchange Wallet?',
      answer: 'Should read What is TheCryptoWallet.io? Is a Mobile Defi Cross-Chain Swap Wallet. The wallet also offer many more use cases linking to Dapp and other Defi Protocols, learn more at',
      linkText: 'www.thecryptowallet.io',
      link: 'https://thecryptowallet.io',
    },
    {
      eventKey: '12',
      question: 'What cryptocurrencies are supported by TheCryptoWallet.io Wallet?',
      answer: 'The Crypto Wallet offers over 3000 Crypto Currencies see detailed list here.',
      linkText: 'https://thecryptowallet.io/supported-crypto',
      link: 'https://thecryptowallet.io/supported-crypto',
    },
    {
      eventKey: '13',
      question: 'Can I access my wallet from multiple devices?',
      answer: (
        <>
          <span>Yes, The Crypto Wallet can be accessed on any Apple or Google based products.</span>
          <div className="gastore d-flex align-items-center justify-content-center">
            {/* <a href="http://www.thecryptowallet.io/" target="_blank" title="Google Play store" rel='noreferrer'>
              <img
                src={require("../../images/google_button.png")}
                alt=""
                width="150"
                className="img-fluid"
              />
            </a> */}
            <a href="https://apps.apple.com/us/app/the-crypto-wallet/id6478397189" target="_blank" title="Apple store" rel='noreferrer'>
              <img
                src={require("../../images/apple_button.png")}
                alt=""
                width="150"
                className="img-fluid"
              />
            </a>
          </div>
        </>
      ),
    },
    {
      eventKey: '14',
      question: 'Can I buy and sell cryptocurrencies directly from my wallet on TheCryptoWallet.io?',
      answer: 'Yes, you can buy directly using the mobile Wallet App. We may need a section on the wallet site that explains the process in detail',
    },
    {
      eventKey: '15',
      question: 'Can I send cryptocurrencies from my wallet to external wallets?',
      answer: 'Yes, freely move your crypto currency to any other exchange or wallet you chose',
    },
    {
      eventKey: '16',
      question: 'What happens if I lose access to my wallet on TheCryptoWallet.io?',
      answer: 'It is important to record and save your seed phrase during the wallet setup you can easily recover your wallet to another device using the proper seed phrase. Please ensure you store it offline and in a safe place.',
    },
    {
      eventKey: '17',
      question: 'How do I transfer funds between TheCryptoExchange.us and the mobile wallet TheCryptoWallet.io?',
      answer: (
        <span>
          Simply add The Crpyto Wallet address into the <b>“Add External Wallet”</b> Section on The Crypto Exchange to send your crypto directly to the Mobile wallet and vise versa to return the Crypto to the The Crypto Exchange.
        </span>
      ),
    },

    // Suggested
    // {
    //   eventKey: '18',
    //   question: 'Does TheCryptoExchange offer any incentives or rewards for frequent traders?',
    //   answer: 'Coming Soon',
    // },
    {
      eventKey: '19',
      question: 'Are there any limits on the amount of cryptocurrency I can buy or sell on TheCryptoExchange.us?',
      answer: 'Here is a link to our current trading and funding limits. All trades over $25,000 must be completed OTC.',
      linkText: 'https://kb.cybrid.xyz/what-limits-exist-on-the-platform#btclimits',
      link: 'https://kb.cybrid.xyz/what-limits-exist-on-the-platform#btclimits',
    },
    {
      eventKey: '20',
      question: 'Is there a minimum deposit requirement to start trading on TheCryptoExchange.us?',
      answer: 'No, there is no minimum deposit amount.',
    },
    {
      eventKey: '21',
      question: 'What are the fees associated with trading on TheCryptoExchange.us?',
      answer: 'We have Zero fees to Deposit or Withdraw Cad or Usd Funds. The trading fee is built into the spread when buying and selling Crypto. Only other fee we have is a $15 estimated transaction fee to withdraw your Crypto after purchase to help cover the network fees associated.',
    },
    {
      eventKey: '22',
      question: 'How long does it take for deposits and withdrawals to be processed on TheCryptoExchange.us?',
      answer: 'Cad and USD Deposit and Withdrawals take 2 business days. Crypto Deposit and withdrawals once initiated are usually processed within an hour.',
    },
    // {
    //   eventKey: '23',
    //   question: 'What security measures does TheCryptoExchange.us have in place to protect my funds and personal information?',
    //   answer: 'We use the latest in WEB3 Security thru or trusted partners to keep your data private.',
    // },
    {
      eventKey: '24',
      question: 'Are there any restrictions on who can use TheCryptoExchange?',
      answer: 'Clients must be 19 years or older and a Canadian or US Citizen.',
    },
  ]

  return (
    <>
      <Header3 />
      <div className='lear_page _services sp-inners'>
        <div className='banners'>
          <h2>Frequently Asked Questions</h2>
        </div>
        <div className='main'>
          <div className='serv2'>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-9'>
                  <div className='blk d-flex align-items-center justify-content-center text-center'>
                    <div className='txt'>
                      <h3>FAQs</h3>
                    </div>
                  </div>

                  <Accordion defaultActiveKey='0'>
                    {faqs.map((faq) => (
                      <Accordion.Item eventKey={faq.eventKey} key={faq.eventKey}>
                        <Accordion.Header>
                          <span style={{ textTransform: 'none' }}>
                            {faq.question.charAt(0).toUpperCase() + faq.question.slice(1)}
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          {faq.answer}{' '}
                          <a
                            href={faq.link && faq.link}
                            target='_blank'
                            rel='noreferrer'
                            style={{ color: '#1355d7', fontWeight: 'bold' }}
                          >
                            {faq.linkText && faq.linkText}
                          </a>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>

                  <div className='blk d-flex align-items-center justify-content-center text-center mt-5'>
                    <div className='txt'>
                      <h3>OTC</h3>
                    </div>
                  </div>

                  <Accordion>
                    {otcFaqs.map((faq) => (
                      <Accordion.Item eventKey={faq.eventKey} key={faq.eventKey}>
                        <Accordion.Header>
                          <span style={{ textTransform: 'none' }}>
                            {faq.question.charAt(0).toUpperCase() + faq.question.slice(1)}
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          {faq.answer}{' '}
                          <a
                            href={faq.link && faq.link}
                            target='_blank'
                            rel='noreferrer'
                            style={{ color: '#1355d7', fontWeight: 'bold' }}
                          >
                            {faq.linkText && faq.linkText}
                          </a>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>

                  <div className='blk d-flex align-items-center justify-content-center text-center mt-5'>
                    <div className='txt'>
                      <h3>Wallet</h3>
                    </div>
                  </div>

                  <Accordion>
                    {walletFaqs.map((faq) => (
                      <Accordion.Item eventKey={faq.eventKey} key={faq.eventKey}>
                        <Accordion.Header>
                          <span style={{ textTransform: 'none' }}>
                            {faq.question.charAt(0).toUpperCase() + faq.question.slice(1)}
                          </span>
                        </Accordion.Header>
                        <Accordion.Body>
                          {faq.answer}{' '}
                          <a
                            href={faq.link && faq.link}
                            target='_blank'
                            rel='noreferrer'
                            style={{ color: '#1355d7', fontWeight: 'bold' }}
                          >
                            {faq.linkText && faq.linkText}
                          </a>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer1 />
    </>
  )
}

export default Faq
