import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  signInUser,
  generateCustomerToken,
  resetCustomerToken,
  resetCustomerTokenFailed,
  resetSignInUser,
  resetSignInUserFailed,
  generateEmailLink,
  resetEmailSentFailed,
  createOtp,
  verifyOtp,
  resetVerifyOtp,
  resetCreateOtp,
  logoutUser,
  resetCreateOtpFailed,
  } from "../../actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { decryptPassword, encryptPassword } from "../../utils/Encryption";
import ButtonLoader from "../layout/buttonLoader";
import { commonError, commonSuccess } from "../../actions/customerActions";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function Signin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const signInFailError = useSelector((state) => state.authReducer.signInFailed);
  const signInSuccess = useSelector((state) => state.authReducer.signInSuccess);
  const customerTokenSuccess = useSelector((state) => state.authReducer.customerTokenSuccess);
  const customerTokenFailed = useSelector((state)=> state.authReducer.customerTokenFailed)
  const emailSentSuccess = useSelector((state) => state.authReducer.postEmailLinkData);
  const emailSentFailed = useSelector((state) => state.authReducer.postEmailLinkFailed); 
  const createOtpData = useSelector((state) => state.authReducer.createOtpData);
  const createOtpFailed = useSelector((state) => state.authReducer.createOtpFailed)
  const verifyOtpData = useSelector((state) => state.authReducer.verifyOtpData);


  const inputRefs = useRef([]);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  // const [rememberMe, setRememberMe] = useState(localStorage.getItem("rmu"));
  const [isSignIn, setIsSignIn] = useState(false);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [otpString, setOtpString] = useState("");
  const [remainingOtpAttempts, setRemainingOtpAttempts] = useState(5);
  const [isCircleStarted, setIsCircleStarted] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);


  // useEffect(() => {
  //   // Check if "Remember me" is selected and load saved email and password from localStorage
  //   if (localStorage.getItem("rmu")) {
  //     const savedEmail = localStorage.getItem("rmu");
  //     const savedPassword = localStorage.getItem("rmp");

  //     if (savedEmail && savedPassword) {
  //       setFormData({
  //         email: decryptPassword(savedEmail),
  //         password: decryptPassword(savedPassword),
  //       });
  //     }
  //   }
  // }, [rememberMe]);

  useEffect(()=> {
    if(createOtpFailed && Object.keys(createOtpFailed)?.length !== 0){
      setIsLoading(false);
      dispatch(commonError(createOtpFailed?.message));
      dispatch(resetCreateOtpFailed());
    }
  },[createOtpFailed]);

  useEffect(() => {
    if (signInFailError && signInFailError.code) {
      // console.log(signInFailError.code);
      setIsLoading(false);
      switch (signInFailError.code) {
        case "auth/user-not-found":
          dispatch(commonError("User not found"));
          break;
        case "auth/wrong-password":
          dispatch(commonError("Incorrect Password. Please try again."));
          // console.log("this error works");
          break;
        case "auth/too-many-requests":
          dispatch(commonError("Too many Attempts. Please try again later."));
          break;
        case "auth/invalid-login-credentials":
          dispatch(commonError("Invalid login email and password."));
          break;
        default:
          dispatch(commonError("Something went wrong. Please try again."));
          break;
      }
      dispatch(resetSignInUserFailed())
    }
  }, [signInFailError]);

  useEffect(() => {
    // emailVerified
    if (signInSuccess && signInSuccess.statusCode === 200) {
      //signInSuccess.data.user.emailVerified
      if (signInSuccess.data.user.emailVerified) {
        localStorage.setItem(
          "f_at",
          signInSuccess.data._tokenResponse.idToken
        );
        // localStorage.setItem(
        //   "f_refreshToken",
        //   signInSuccess.data._tokenResponse.refreshToken
        // );
        sessionStorage.setItem(
          "user_email",
          signInSuccess.data._tokenResponse.email
        );
        localStorage.setItem(
          "displayName",
          signInSuccess.data._tokenResponse.displayName
        );

        let formData = { email: signInSuccess.data._tokenResponse.email };
        dispatch(generateCustomerToken(formData));
        // dispatch(createOtp(formData.email));
      } else {
        dispatch(generateEmailLink(signInSuccess.data.user.email))
        dispatch(commonError("Please verify your email by checking your inbox."))
      }
      dispatch(resetSignInUser());
    }
  }, [signInSuccess]);

  const toggleSTV = () => {
    setIsSignIn(!isSignIn);
    setShowVerifyOtp(!showVerifyOtp);
  }

  const toggleVTS = () => {
    setIsSignIn(!isSignIn);
    setShowVerifyOtp(!showVerifyOtp);
  }

  useEffect(() => {
    if (createOtpData?.data) {
      const message = createOtpData?.data?.message;
      dispatch(commonSuccess(message.charAt(0).toUpperCase() + message.slice(1)));
      toggleSTV();
      setIsLoading(false);
      setIsCircleStarted(true);
      dispatch(resetCreateOtp());
    }
  }, [createOtpData])

  useEffect(() => {
    if (Object.keys(emailSentSuccess).length != 0) {
      setIsLoading(false);
    }
  }, [emailSentSuccess]);

  useEffect(() => {
    if (Object.keys(emailSentFailed).length !== 0) {
      // console.log(emailSentFailed);
      setIsLoading(false);
      dispatch(resetEmailSentFailed())
    }
  }, [emailSentFailed]);
  
  useEffect(() => {
    if (customerTokenSuccess && customerTokenSuccess.status === 200) {
      sessionStorage.setItem(
        "at",
        customerTokenSuccess.data.data.at
      );
      sessionStorage.setItem(
        "guid",
        encryptPassword(customerTokenSuccess.data.data.uid)
      );
      sessionStorage.setItem(
        "ut",
        customerTokenSuccess.data.data.ut
      );

      dispatch(resetCustomerToken());
      dispatch(logoutUser());
      setIsLoading(false);
      navigate("/dashboard");
    }
  }, [customerTokenSuccess]);

  useEffect(()=> {
    if(customerTokenFailed && Object.keys(customerTokenFailed)?.length !== 0){
      setIsLoading(false);
      dispatch(commonError(customerTokenFailed?.message));
      setIsCircleStarted(false);
      toggleSTV();
      setOtpValues(["", "", "", "", "", ""])
      setRemainingOtpAttempts(5);
      dispatch(resetCustomerTokenFailed());
    }
  },[customerTokenFailed]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    const updatedOtpValues = [...otpValues];
    updatedOtpValues[index] = value;

    setOtpValues(updatedOtpValues);
    setOtpString(updatedOtpValues.join(""));

    // Move to the next input field if a digit is entered
    if (value !== "" && index < otpValues.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    const pastedValues = pastedData.split("").slice(0, otpValues.length);
    const updatedOtpValues = Array.from(otpValues);

    for (let i = 0; i < updatedOtpValues.length; i++) {
      if (pastedValues.length > 0) {
        updatedOtpValues[i] = pastedValues.shift();
      } else {
        updatedOtpValues[i] = "";
      }
    }

    setOtpValues(updatedOtpValues);
    setOtpString(updatedOtpValues.join(""));
  };

  const handleTimerComplete = () => {
    setIsCircleStarted(false);
    toggleVTS();
  }

  function handleVerifyOtp() {
    if (isLoading) {
      return;
    }
    if (otpValues.some(value => value === "")) {
      dispatch(commonError("Please fill all the OTP fields"));
      return;
    }
    setIsLoading(true);
    dispatch(verifyOtp(formData.email, otpString));
  }

  useEffect(() => {
    if (verifyOtpData?.data?.data) {
      const concatenatedString = Object.values(verifyOtpData.data.data).join("");
      if (concatenatedString !== "verified") {
        setIsLoading(false);
        dispatch(commonError("Please enter a valid OTP"));
        setRemainingOtpAttempts((prevAttempts) => prevAttempts - 1);
      } else if (concatenatedString === "verified") {
        dispatch(generateCustomerToken(formData))
        setRemainingOtpAttempts(5);
      }
      dispatch(resetVerifyOtp());
    }
  }, [verifyOtpData]);

  useEffect(() => {
    if (remainingOtpAttempts <= 0) {
      setOtpValues(["", "", "", "", "", ""])
      setRemainingOtpAttempts(5);
      dispatch(commonError("You have exceeded the maximum number of OTP verification attempts."));
      toggleVTS();
    }
  }, [remainingOtpAttempts])

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const maxLength = 60;
    const sanitizedValue = value.slice(0, maxLength);
    setFormData((prevFormData) => ({ ...prevFormData, [name]: sanitizedValue }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isLoading) {
      return;
    }
    const validationErrors = {};

    if (!formData.email.trim() || !formData.password.trim()) {
      dispatch(commonError("Please fill in all fields."));
      return;
    } else if (formData.password.trim().length < 8) {
      dispatch(commonError("Password must be at least 8 characters long"));
      return;
    }

    // dispatch(commonError(validationErrors));

    // If there are no validation errors, dispatch the signin action
    if (Object.keys(validationErrors).length === 0 || !validationErrors) {
      // console.log("passed it");
      // if (rememberMe) {
      //   localStorage.setItem("rmu", encryptPassword(formData.email));
      //   localStorage.setItem("rmp", encryptPassword(formData.password));
      // } else {
      //   localStorage.removeItem("rmu");
      //   localStorage.removeItem("rmp");
      // }
      setIsLoading(true);
      setOtpValues(["", "", "", "", "", ""])
      dispatch(signInUser(formData.email, formData.password));
    }
  };

  return (
    <>
      <div className="authincation section-padding fslogin">
        <div className="art-wrapper">
          <img src={require('./../../images/auth-bg.png')} alt="" />
        </div>
        <div className="fscon-wrapper">
          <div className="container-fluid h-100">


            <div className="mini-logo text-center mb-4">
              <Link to={"/"}>
                <img
                  src={require("./../../images/logo-with-text.png")}
                  alt=""
                />
              </Link>
            </div>
            {/* {showVerifyOtp ? (
              <div className={`optarea`}>
                <div className="auth-form card fslogincard">
                  <li>
                    <div className="card-header justify-content-center border-0 flex-column mt-3">
                      <h2 className="mb-2 ggtimefont">Verification</h2>
                      <p className="mb-0 text-center px-5">Please enter the 6-digit verification code that was sent to your Email. </p>
                    </div>

                    <div className="d-flex align-items-center justify-content-center mb-3">
                      <CountdownCircleTimer
                        size={80}
                        isPlaying={isCircleStarted}
                        duration={80}
                        strokeWidth={10}
                        colors={['#1355d7', '#F7B801', '#FFA500', '#A30000']}
                        colorsTime={[60, 40, 20, 0]}
                        onComplete={handleTimerComplete}
                      >
                        {({ remainingTime }) => remainingTime}
                      </CountdownCircleTimer>
                    </div>

                    <div className="card-body d-flex align-items-center justify-content-center">
                      <div className="spm-icon logotpverify">
                        {otpValues.map((value, index) => (
                          <input className="otp form-control" type="text" value={value} onChange={(e) => handleChange(e, index)}
                            maxLength="1" onPaste={handlePaste} ref={(ref) => (inputRefs.current[index] = ref)} key={index}
                          />
                        ))}
                      </div>

                    </div>
                  </li>
                  <div className="d-flex align-items-center justify-content-center text-center mb-4">
                    <button type="submit" className={`btn btn-primary btn-loader m-4 ${isLoading ? "active" : ""}`} onClick={() => handleVerifyOtp()}>
                      <span>Verify Otp</span>
                      <ButtonLoader />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`auth-form card fslogincard mb-0 ${isSignIn ? "d-none" : ""}`}>
                <div className="card-header justify-content-center">
                  <h4 className="card-title">Sign in</h4>
                </div>
                <div className="card-body">
                  <form
                    method="post"
                    name="myform"
                    className="signin_validate"
                    onSubmit={handleSubmit}
                  >
                    <div className="mb-3">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="hello@example.com"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label>Password</label>
                      <div className="input-group">
                        <input
                          type={viewPassword ? "text" : "password"}
                          className="form-control"
                          value={formData.password}
                          onChange={handleInputChange}
                          name="password"
                          autoComplete="off"
                        />
                        <div className="input-group-append" onClick={() => setViewPassword(!viewPassword)}>
                          <span className="input-group-text bg-transparent cursor-pointer">
                            {viewPassword ? <FontAwesomeIcon icon="eye" /> : <FontAwesomeIcon icon="eye-slash" />}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-4 mb-2">
                      // <div>
                      //   <label className="toggle">
                      //     <input
                      //       className="toggle-checkbox"
                      //       type="checkbox"
                      //       checked={rememberMe}
                      //       onChange={(e) => setRememberMe(e.target.checked)}
                      //     />
                      //     <span className="toggle-switch"></span>
                      //     <span className="toggle-label">Remember me</span>
                      //   </label>
                      // </div>
                      <div className="mb-3 mb-0">
                        <Link className="text-primary" to={"/reset"}>
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                    <div className="text-center">
                      <button type="submit" className={`btn btn-primary w-100 btn-loader ${isLoading ? "active" : ""}`}>
                        <span>Sign in</span>
                        <ButtonLoader />
                      </button>
                    </div>
                  </form>
                  <div className="new-account mt-3">
                    <p>
                      Don't have an account?{" "}
                      <Link className="text-primary" to={"/signup"}>
                        Sign up
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            )} */}


              <div className={`auth-form card fslogincard mb-0 ${isSignIn ? "d-none" : ""}`}>
                <div className="card-header justify-content-center">
                  <h4 className="card-title">Sign in</h4>
                </div>
                <div className="card-body">
                  <form
                    method="post"
                    name="myform"
                    className="signin_validate"
                    onSubmit={handleSubmit}
                  >
                    <div className="mb-3">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="hello@example.com"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label>Password</label>
                      <div className="input-group">
                        <input
                          type={viewPassword ? "text" : "password"}
                          className="form-control"
                          value={formData.password}
                          onChange={handleInputChange}
                          name="password"
                          autoComplete="off"
                        />
                        <div className="input-group-append" onClick={() => setViewPassword(!viewPassword)}>
                          <span className="input-group-text bg-transparent cursor-pointer">
                            {viewPassword ? <FontAwesomeIcon icon="eye" /> : <FontAwesomeIcon icon="eye-slash" />}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-4 mb-2">
                      {/* <div>
                        <label className="toggle">
                          <input
                            className="toggle-checkbox"
                            type="checkbox"
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                          />
                          <span className="toggle-switch"></span>
                          <span className="toggle-label">Remember me</span>
                        </label>
                      </div> */}
                      <div className="mb-3 mb-0">
                        <Link className="text-primary" to={"/reset"}>
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                    <div className="text-center">
                      <button type="submit" className={`btn btn-primary w-100 btn-loader ${isLoading ? "active" : ""}`}>
                        <span>Sign in</span>
                        <ButtonLoader />
                      </button>
                    </div>
                  </form>
                  <div className="new-account mt-3">
                    <p>
                      Don't have an account?{" "}
                      <Link className="text-primary" to={"/signup"}>
                        Sign up
                      </Link>
                    </p>
                  </div>
                </div>
              </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default Signin;
