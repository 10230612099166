import React, { useState, useEffect } from "react";


const DownloadWallet = () => {


    return (
        <div className="col-md-4 dash4col ">
                            <div className="card fadeInDown">
                                <div className="card-body text-center dtcwbg">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="dTCWlogo">
                                            <a href="http://www.thecryptowallet.io/" target="_blank" rel="noopener noreferrer">
                                            <img src={require('./../../images/TCW-logo.png')} alt="" className="TCW-logo" />
                                            </a>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <img
                                                src={require("../../images/illustration2.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <h4 className="card-title mt-3 mb-1">Need a Crypto Wallet?</h4>
                                            <h3 className="text-primary">Download The Crypto Wallet App</h3>


                                            <div className="gastore dashstore">
                                                {/* <a href="http://www.thecryptowallet.io/" target="_blank" title="Google Play store">
                                                    <img
                                                        src={require("../../images/google_button.png")}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </a> */}
                                                <a href="https://apps.apple.com/us/app/the-crypto-wallet/id6478397189" target="_blank" title="Apple store">
                                                    <img
                                                        src={require("../../images/apple_button.png")}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
    );
}

export default DownloadWallet;