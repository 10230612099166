import Header3 from './../layout/header3';
import Footer1 from './../layout/footer1';
import { Helmet } from "react-helmet";



function Wallet() {

    return (
        <>
            {/* Meta Tag */}
            <Helmet>
                <title>Safest Crypto Wallet App Online: Crosschain & DeFi Compatible</title>
                <meta name="description" content="Safely manage diverse cryptocurrencies with our crosschain-compatible DeFi wallet app. Download now for secure, hassle-free asset management. Download Now!" />
            </Helmet>

            <Header3 />

            <div className="lear_page _wallet sp-inners">
                <div className="banners">
                    <div className="container">
                        <div className="ban_cnt">
                            <div className="txt">
                                <h4>#crypto wallet app</h4>
                                <h2>Most secure and advanced crypto wallet</h2>
                                <p>Experience next-generation cryptocurrency management. Store, purchase, trade, and exchange your crypto assets. Dive into a universe of 30+ blockchains and an expansive range of tokens.</p>
                                <div className="download_sec pt-0">

                                    <div className="gastore">
                                        {/* <a href="#" target="_blank" title="Google Play store">
                                            <img
                                                src={require("../../images/google_button.png")}
                                                alt=""
                                                width="400"
                                                className="img-fluid"
                                            />
                                        </a> */}
                                        <a href="https://apps.apple.com/us/app/the-crypto-wallet/id6478397189" target="_blank" title="Apple store">
                                            <img
                                                src={require("../../images/apple_button.png")}
                                                alt=""
                                                width="400"
                                                className="img-fluid"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="img">
                                <img src={require("../../images/wallet_banner.png")} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="serv_aprent">
                                <h2>Cryptocurrency Wallet</h2>
                                <p style={{textAlign: 'left'}}>Expand your accessibility to 30+ globally renowned blockchains through our highly advanced and secure crypto wallet. We have integrated some handpicked productive features that the best crypto wallet must have. Let’s explore the potential you can utilize:-</p>
                                
                                <div style={{textAlign: 'left'}}>
                                    <p className="mb-0">● Users can have access to swap across 3000+ trading pairs. The process is very simple; just select the trading pair, deposit your cryptocurrency, and swap with a desired crypto asset in three simple steps.</p>
                                    <p className="mb-0">● The multi-swap feature of our crypto wallet enables users to <a href="https://thecryptoexchange.us/" style={{color: '#1355d7'}}>Cryptocurrency Exchange</a> at the most favorable rates and lightning-fast speed.</p>
                                    <p>● This is a non-custodial crypto wallet that empowers you with full control over your assets. We ensure optimum privacy, security, and autonomy for all transactions. </p>
                                </div>

                                <p style={{textAlign: 'left'}}>Manage multiple portfolios effortlessly and make optimized <a href="https://cryptoexperts.ca/" style={{color: '#1355d7'}}>crypto purchase decisions</a> with the help of its intuitive dashboard. You can even integrate with other Web3 platforms conveniently for enhanced exchange options.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="main">
                    <section>
                        <div className="container">
                            <div className="wallet_box">
                                <div className="box">
                                    <div className="txt">
                                        <h4>#crypto exchange widget</h4>
                                        <h2>SWAP across a universe of 3 000+ Pairs</h2>
                                        <p>Delve into a vast sea of trading pairs. With integration to popular decentralized exchanges, we offer unparalleled rates and opportunities.</p>
                                        <ul>
                                            <li>1. Choose trading pair</li>
                                            <li>2. Deposit crypto</li>
                                            <li>3. Swap and get your crypto</li>
                                        </ul>
                                    </div>
                                    <div className="img">
                                        <img
                                            src={require("../../images/illustration3.png")}
                                            alt=""
                                            width="400"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="ex_change">
                        <div className="container">
                            <div className="ex_txt">
                                <h2>Exchange crypto at light speed with optimal rates</h2>
                                <p>No more juggling between exchanges. Our multi-swap feature ensures you always trade at the most favorable rates, streamlining your crypto transactions.</p>
                                <a class="btn btn-primary" href="https://thecryptowallet.io/exchange" target="_blank">Exchange</a>
                            </div>
                        </div>
                        <img src={require("../../images/wallet_bg.png")} className="img-fluid" alt="" />
                        <img src={require("../../images/tokens_bg.png")} className="img-fluid" alt="" />
                    </section>
                    <section className="all_sec">
                        <div className="container">
                            <h2>
                                <span>All-in-one</span>
                                crypto wallet
                            </h2>
                            <div className="blk">
                                <div className="box">
                                    <div className="icon">
                                        <img src={require("../../images/all_1.png")} className="img-fluid" alt="" />
                                    </div>
                                    <div className="txt">
                                        <h4>True non-custodial freedom</h4>
                                        <p className="wal_black">Embrace the genuine essence of coins. Our non-custodial wallet ensures you remain the sole guardian of your assets.</p>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="icon">
                                        <img src={require("../../images/all_2.png")} className="img-fluid" alt="" />
                                    </div>
                                    <div className="txt">
                                        <h4>Feature-rich coins wallet</h4>
                                        <p className="wal_black">Immerse yourself in a multifunctional world. Our mobile wallet is packed with features, ensuring you have a powerful and flexible coins experience.</p>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="icon">
                                        <img src={require("../../images/all_3.png")} className="img-fluid" alt="" />
                                    </div>
                                    <div className="txt">
                                        <h4>Unbeatable exchange rates</h4>
                                        <p className="wal_black">Access the most competitive rates in the coins market. Trade confidently, knowing you're getting the best value.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="get_sec">
                        <div className="container">
                            <div className="blk">
                                <div className="txt">
                                    <h2>
                                        get the best
                                        <span>crypto exchange</span>
                                        Rates and Instant
                                        Transactions
                                    </h2>
                                    <p>Gone are the days of checking multiple exchanges in search of the best possible rate. Multi-swaps allow you to enjoy a seamless and hassle-free trading experience!</p>
                                    <div className="download_sec pt-0">

                                        <div className="gastore">
                                            {/* <a href="#" target="_blank" title="Google Play store">
                                                <img
                                                    src={require("../../images/google_button.png")}
                                                    alt=""
                                                    width="400"
                                                    className="img-fluid"
                                                />
                                            </a> */}
                                            <a href="https://apps.apple.com/us/app/the-crypto-wallet/id6478397189" target="_blank" title="Apple store">
                                                <img
                                                    src={require("../../images/apple_button.png")}
                                                    alt=""
                                                    width="400"
                                                    className="img-fluid"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="img">
                                    <img src={require("../../images/wallet_img2.png")} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="e_sec">
                        <div className="container">
                            <h2>Everything you  <br /> need in one wallet</h2>
                            <div className="blk">
                                <div className="box">
                                    <div className="icon">
                                        <img src={require("../../images/e_img1.png")} className="img-fluid" alt="" />
                                    </div>
                                    <div className="txt">
                                        <h4>Multi-Portfolio Management</h4>
                                        <p>Organize and manage various coins portfolios effortlessly.</p>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="icon">
                                        <img src={require("../../images/e_img2.png")} className="img-fluid" alt="" />
                                    </div>
                                    <div className="txt">
                                        <h4>Optimized Crypto Purchases</h4>
                                        <p>Buy your favorite cryptocurrencies using Visa or MasterCard. Choose the most attractive rates from a range of providers.</p>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="icon">
                                        <img src={require("../../images/e_img3.png")} className="img-fluid" alt="" />
                                    </div>
                                    <div className="txt">
                                        <h4>Comprehensive Dashboards</h4>
                                        <p>View all your transactions, assets, handy and insightful widgets in a single, user-friendly interface.</p>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="icon">
                                        <img src={require("../../images/e_img4.png")} className="img-fluid" alt="" />
                                    </div>
                                    <div className="txt">
                                        <h4>Wallet Connect</h4>
                                        <p>Integrate effortlessly with other web3 platforms for a fluid coins journey.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="wel_sec">
                        <div className="container">
                            <div className="blk">
                                <div className="txt">
                                    <h2>
                                        Follow these
                                        <span>3 simple steps</span>
                                    </h2>
                                    <p className="wal_black">Getting started is a simple process that only takes a few minutes. Join our rapidly expanding community of crypto enthusiasts and discover how easy it is to use TCW Wallet today!</p>
                                    <ul>
                                        <li><span>1</span>Download the The Crypto Wallet app</li>
                                        <li><span>2</span>Create your account and ensure your wallet's security</li>
                                        <li><span>3</span>Effortlessly manage your crypto assets with TCW Wallet</li>
                                    </ul>
                                    <div className="download_sec pt-0">

                                        <div className="gastore">
                                            {/* <a href="#" target="_blank" title="Google Play store">
                                                <img
                                                    src={require("../../images/google_button.png")}
                                                    alt=""
                                                    width="400"
                                                    className="img-fluid"
                                                />
                                            </a> */}
                                            <a href="https://apps.apple.com/us/app/the-crypto-wallet/id6478397189" target="_blank" title="Apple store">
                                                <img
                                                    src={require("../../images/apple_button.png")}
                                                    alt=""
                                                    width="400"
                                                    className="img-fluid"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <p className="wal_black mt-4">To know more visit: <a href="https://thecryptowallet.io/">thecryptowallet.io</a></p>
                                </div>
                                <div className="img">
                                    <img src={require("../../images/illustration.png")} alt="" />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <Footer1 />
        </>
    )
}

export default Wallet;