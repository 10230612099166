import React from "react";
import { Link } from "react-router-dom";
import Header3 from "../layout/header3";
import Footer1 from "../layout/footer1";
import { Helmet } from "react-helmet";

function About() {
    return (
        <>
            {/* Meta Tag */}
            <Helmet>
                <title>About Us | United State's Top Cryptocurrency Wallet</title>
                <meta name="description" content="We are the leading platform to buy, sell, and store your cryptocurrency through a safe and secure crypto wallet app. Learn more about our us today!" />
            </Helmet>

            <Header3 />
            <div className="about-one sp-inners">
                <div className="banners">
                    <h2>About</h2>
                </div>
                <div className="container">
                    {/* <div className="our-ceo py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="ceo-content">
                                        <div className="d-flex">
                                            <div className="col-md-6">
                                                <div className="flex-grow-1 service-content">
                                                    <h3>Victor Boras,</h3>
                                                    <span>CEO - The Crypto Exchange</span>
                                                    <p className="mt-2">
                                                        Victor Boras has over 10 years of
                                                        experience working in and around
                                                        Financial Technology. He has
                                                        delivered innovative SaaS systems
                                                        for some of today's biggest
                                                        institutions around payments,
                                                        identity, and banking
                                                        infrastructure. Victor Boras has
                                                        been in the Blockchain space since
                                                        2012 and is involved in a number of
                                                        blockchain and fintech businesses
                                                        both as an investor, board director,
                                                        and founder.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="service-img">
                                                    <img
                                                        src={require("./../../images/profile/about.jpg")}
                                                        alt=""
                                                        className="img-fluid ms-4 rounded-circle"
                                                    />
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}


                    <div className="about-two section-padding">
                        <div className="container">
                            <div className="row align-items-center">
                            <div className="col-md-6">
                                    <div className="service-img">
                                        <img
                                            src={require("./../../images/testimonial/2.jpg")}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="service-content my-5">
                                        {/* <h3>Victor Boras</h3> */}
                                        <p>
                                        <span className="fs-2">W</span>ith a decade-long tenure in the dynamic and rapidly evolving field of cryptocurrency, The Crypto Exchange and its team of experts stand as seasoned leaders at the forefront of the digital financial revolution. Armed with extensive experience operating a fully compliant and regulated Crypto OTC Desk and providing in-person hardware wallet setups with one-on-one crypto consulting services since 2017, our focus lies on self-custody and the security of client funds.
                                        </p>
                                        <p>
                                        Having weathered the highs and lows of the volatile crypto market, The Crypto Exchange and its team of experts possess a deep understanding of blockchain technology, decentralized finance (DeFi), and the intricacies of various cryptocurrencies. 
                                        </p>
                                        {/* <Link to={"#"} className="btn btn-primary">
                                            Read more
                                        </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="service-content m-t-50">
                                {/* <h3>    History of Victor and company </h3> */}
                                <p>
                                    Under The Crypto Exchange’s leadership, the company has demonstrated a commitment to regulatory compliance, security, user education, and self-custody. With growing security concerns associated with centralized exchanges mishandling client funds and deposits, leading to substantial financial losses for users, our team continues to focus on the importance of taking control of your crypto assets with the products and infrastructure to do so safely and securely. 
                                </p>
                                <p>
                                    Notably, The Crypto Exchange places a strong emphasis on empowering individuals by promoting the self-custody of their crypto assets. Recognizing the importance of financial autonomy, we advocate for user-friendly tools and education to enable individuals to take control of their digital wealth securely. 
                                </p>
                                <p>
                                    As the crypto landscape continues to evolve, The Crypto Exchange remains dedicated to helping reshape the future of finance and contributing to the ongoing development of the global blockchain ecosystem. With a decade of experience as our foundation, we stand ready to lead into new frontiers within the ever-expanding world of cryptocurrency. 
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="service-img">
                                <img
                                    src={require("./../../images/testimonial/1.jpg")}
                                    alt=""
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="address_blk">
                        <h4>Location:</h4>
                        <p>
                            1001 S Main Street STE 600, <br />
                            Kalispell, Montana, <br />
                            USA 59901
                        </p>
                    </div>                    
                </div>
            </div>


            <Footer1 />
        </>
    );
}

export default About;
