import React, { useState, useEffect, useRef } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';
import {
    getCustomer,
    resetGetCustomer,
    getCustomerAccountDetails,
    resetGetCustomerAccountDetails,
    getExternalBankAccountListAction,
    resetGetExternalBankAccountListAction,
    getExternalBankAccountWalletListAction,
    getIdentityVerificationList,
    resetGetIdentityVerificationList,
    resetGetIdentityVerificationActionFailed,
    commonError,
    resetgetAccountFailed,
    resetgetExternalBankAccountListFailed,
    resetGetExternalBankAccountWalletListActionFailed,
    getTransfers,
    resetGetTransfers,
    getIdentityVerificationAction
} from "../../actions/customerActions";
import { decryptPassword } from "../../utils/Encryption";
import Verifykyc from "../shared/verifykyc";
import Svgverify from "../layout/svgverify";
import Plaidbank from "../shared/plaidbank";
import Svgloading from "../layout/svgloading";


function Verifymodule() {
    const verifyCustomerInterval = useRef();
    const verifyBankInterval = useRef();
    let EncToken = sessionStorage.getItem("guid");
    let deToken = decryptPassword(EncToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const verificationIntervalMins = 40; //40 minutes
    const verifyCustomerIntervalSecs = 60;//60 seconds
    const verifyBankIntervalSecs = 20;//20 seconds


    const getCustomerDatais = useSelector((state) => state.customerReducer.getCustomerData);
    const getAccountDatais = useSelector((state) => state.customerReducer.getAccountData);
    const getAccountDataisFailed = useSelector((state) => state.customerReducer.getAccountFailed);
    const getExternalBankAccountListData = useSelector((state) => state.customerReducer.getExternalBankAccountListData);
    const getExternalBankAccountListFailed= useSelector((state) => state.customerReducer.getExternalBankAccountListFailed);
    const getExternalBankAccountWalletList = useSelector((state) => state.customerReducer.getExternalBankWalletAccountData);
    const getExternalBankWalletAccountFailed = useSelector((state) => state.customerReducer.getExternalBankWalletAccountFailed);
    const postIdentityVerifyFailed = useSelector((state) => state.customerReducer.postIdentityVerifyFailed);
    const getIdentityVerifyListSuccess = useSelector((state) => state.customerReducer.getIdentityVerifyListData);
    const getIdentityVerifyListFailed = useSelector((state) => state.customerReducer.getIdentityVerifyListFailed);
    const getTransferData = useSelector((state)=> state.customerReducer.getTransferData);


    const [isCustomerVerified, setIsCustomerVerified] = useState(false);
    const [isBankVerified, setIsBankVerified] = useState(false);
    const [isDepostFundDone, setDepostFundDone] = useState(false);
    const [isBTCAdded, setIsBTCAdded] = useState(false);
    const [isETHAdded, setIsETHAdded] = useState(false);
    const [isUSDCAdded, setIsUSDCAdded] = useState(false);
    const [isBCHAdded, setIsBCHAdded] = useState(false);
    const [isLTCAdded, setIsLTCAdded] = useState(false);
    const [identityData, setIdentityData] = useState({});
    const [verificationUpdate, setVerificationUpdate] = useState('');
    const [verificationOutcome, setVerificationOutcome] = useState('');
    const [bankUpdate, setBankUpdate] = useState('');
    const [depositUpdate, setDepositUpdate] = useState('');
    const [walletUpdate, setWalletUpdate] = useState('');
    const [firstTransferData, setFirstTrasferData] = useState({});
    const [cadAccountData, setCadAccountdata] = useState();
    const [customerData,  setCustomerData] = useState({});


    // Initial API call
    useEffect(() => {
        let tempToken = sessionStorage.getItem("guid");
        let deToken = decryptPassword(tempToken);
        setVerificationUpdate("loading");
        setBankUpdate('loading');
        setDepositUpdate('loading');
        setWalletUpdate('loading');
        dispatch(getExternalBankAccountListAction(sessionStorage.at, deToken));
        dispatch(getCustomer(sessionStorage.at, deToken));
        dispatch(getCustomerAccountDetails(sessionStorage.at, deToken));
        dispatch(getExternalBankAccountWalletListAction(sessionStorage.at));
        dispatch(getIdentityVerificationList(sessionStorage.at, deToken));
        dispatch(getTransfers(sessionStorage.at, deToken));
    }, []);

    // Fetch external wallet list
    useEffect(() => {
        if (getExternalBankAccountWalletList && Object.keys(getExternalBankAccountWalletList).length !== 0) {
            // Check the state if it is completed or not. If the state is completed the find the cryptos based on the asset
            let filterList = getExternalBankAccountWalletList.objects?.filter(i => i.state == "completed");
            const findBTC = (filterList)?.find(i => i.asset == "BTC");
            if (findBTC) {
                setIsBTCAdded(true);
            } else {
                setIsBTCAdded(false);
            }
            const findETH = (filterList)?.find(i => i.asset == "ETH");
            if (findETH) {
                setIsETHAdded(true);
            } else {
                setIsETHAdded(false);
            }

            const findUSDC = (filterList)?.find(i => i.asset == "USDC");
            if (findUSDC) {
                setIsUSDCAdded(true);
            } else {
                setIsUSDCAdded(false);
            }

            // const findBCH = (filterList)?.find(i => i.asset == "BCH");
            // if (findBCH) {
            //     setIsBCHAdded(true);
            // } else {
            //     setIsBCHAdded(false);
            // }
            // const findLTC = (filterList)?.find(i => i.asset == "LTC");
            // if (findLTC) {
            //     setIsLTCAdded(true);
            // } else {
            //     setIsLTCAdded(false);
            // }

            if (findBTC || findETH || findUSDC) {
                setWalletUpdate("completed");
            } else {
                setWalletUpdate("notCompleted");
            }
        }
    }, [getExternalBankAccountWalletList]);

    // Fetch customer data
    useEffect(() => {
        if (getCustomerDatais && getCustomerDatais.length !== 0) {
            setCustomerData(getCustomerDatais);
            if (getCustomerDatais.state !== "verified") {
                setIsCustomerVerified(false)
            } else {
                setIsCustomerVerified(true)
                clearInterval(verifyCustomerInterval.current)
            }
            dispatch(resetGetCustomer())
        }
    }, [getCustomerDatais]);

    // Fetch external bank account list data
    useEffect(() => {
        if(getExternalBankAccountListData && getExternalBankAccountListData.total === 0) {
            setBankUpdate('notCompleted');
        }
        if (getExternalBankAccountListData && getExternalBankAccountListData.total > 0) {
            let bankObj = getExternalBankAccountListData.objects;
            // Check if the state is completed or not
            const hasCompletedState = bankObj.some(item => item.state === "completed");
            if (hasCompletedState) {
                // At least one bank account is in the "completed" state
                setIsBankVerified(true);
                setBankUpdate('completed');
                clearInterval(verifyBankInterval.current);
            } else {
                // No bank account in the "completed" state
                setBankUpdate('notCompleted');
                setIsBankVerified(false);
            }
            dispatch(resetGetExternalBankAccountListAction())
        }
    }, [getExternalBankAccountListData]);


    // Fetch account data
    useEffect(() => {
        if (getAccountDatais && getAccountDatais.length !== 0) {
            const userAccount = getAccountDatais.objects?.find((account) => account.asset === "USD")
            if (userAccount !== undefined) {
                setCadAccountdata(userAccount);
            }
            dispatch(resetGetCustomerAccountDetails())
        }
    }, [getAccountDatais]);

     // Fetch Tranfers data
     useEffect(() => {
        if(getTransferData?.objects && getTransferData?.objects?.length !== 0){
          const transfersData = getTransferData?.objects?.filter((data) => data.side === "deposit");
          setFirstTrasferData((transfersData[transfersData.length-1]));
          dispatch(resetGetTransfers());
        }
      }, [getTransferData]);

    useEffect(()=> {
        if(cadAccountData && Object.keys(cadAccountData).length !== 0 && firstTransferData && Object.keys(firstTransferData).length !== 0) {
            if (cadAccountData.platform_available !== 0) {
                setDepostFundDone(true);
                setDepositUpdate('completed');
            } else if(firstTransferData?.state === "completed") {
                setDepostFundDone(true);
                setDepositUpdate('completed');
            } else if(firstTransferData?.state === "storing" || firstTransferData?.state === "pending") {
                setDepostFundDone(false);
                setDepositUpdate('processing');
            } else {
                setDepositUpdate('notCompleted');
            }
        }else {
            setDepositUpdate('notCompleted');
        }

    },[cadAccountData, firstTransferData]);

    // Check repeatedly customer KYC verification completed or not
    useEffect(() => {
        let verificationInterval;
        verificationInterval = setInterval(() => {
            if (verificationUpdate !== "completed") {
                // Use a callback function when updating state based on the previous state
                setVerificationUpdate((prevVerificationUpdate) => {
                    if (prevVerificationUpdate !== "completed") {
                        return "storing";
                    }
                    return prevVerificationUpdate; // No change if it's already "completed"
                });
                dispatch(getIdentityVerificationList(sessionStorage.at, deToken));
            }
        }, verificationIntervalMins * 60 * 1000);
        return () => {
            clearInterval(verificationInterval);
        };
    }, [verificationUpdate]);

    // Check Post customer KYC verification API call failed
    useEffect(() => {
        if (Object.keys(postIdentityVerifyFailed).length !== 0) {
            dispatch(commonError(postIdentityVerifyFailed?.data?.error_message));
            setVerificationUpdate("failed");
        }
    }, [postIdentityVerifyFailed]);

    // Check customer KYC verification state success
    useEffect(() => {
        if (getIdentityVerifyListSuccess && Object.keys(getIdentityVerifyListSuccess)?.length !== 0) {
            const customerKycStatus = getIdentityVerifyListSuccess.objects?.find(item => item.type === "kyc" && (item.method === "business_registration" || item.method === "id_and_selfie")) || null;        
 
            if (customerKycStatus) {
                dispatch(getIdentityVerificationAction(sessionStorage.at, customerKycStatus.guid))
            }
 
            if (customerKycStatus?.state === 'completed' && customerKycStatus?.outcome === 'passed') {
                setVerificationUpdate(customerKycStatus?.state);
                setVerificationOutcome(customerKycStatus?.outcome);
                clearInterval(verifyCustomerInterval.current);
                setIdentityData(customerKycStatus);
                dispatch(resetGetIdentityVerificationList());
            } else {
                setVerificationUpdate(customerKycStatus?.state);
                setVerificationOutcome(customerKycStatus?.outcome);
                setIdentityData(customerKycStatus);
                dispatch(resetGetIdentityVerificationList());
            }
        }
    }, [getIdentityVerifyListSuccess]);

    // Check customer KYC verification state failed 
    useEffect(() => {
        if (getIdentityVerifyListFailed && Object.keys(getIdentityVerifyListFailed)?.length !== 0) {
            setVerificationUpdate("failed");
            dispatch(resetGetIdentityVerificationActionFailed());
        }
    }, [getIdentityVerifyListFailed]);
    
    // Get external bank account list API failed
    useEffect(() => {
        if(getExternalBankAccountListFailed && Object.keys(getExternalBankAccountListFailed)?.length !== 0) {
            setBankUpdate("failed");
            dispatch(resetgetExternalBankAccountListFailed());
        }
    },[getExternalBankAccountListFailed]);

    // Check customer account API failed
    useEffect(() => {
        if (getAccountDataisFailed && Object.keys(getAccountDataisFailed)?.length !== 0) {
            setDepositUpdate("failed");
            dispatch(resetgetAccountFailed());
        }
    }, [getAccountDataisFailed]);

    // Get external wallet account failed
    useEffect(()=> {
        if(getExternalBankWalletAccountFailed && Object.keys(getExternalBankWalletAccountFailed)?.length !== 0) {
            setWalletUpdate("failed");
            dispatch(resetGetExternalBankAccountWalletListActionFailed());
        }
    },[getExternalBankWalletAccountFailed]);

    
    // It will dispatch the getCustomer function every 5seconds to fetches data from the server to verify customer details.
    const recheckCustomer = () => {
        verifyCustomerInterval.current = setInterval(() => {
            dispatch(getIdentityVerificationList(sessionStorage.at, deToken));
            // dispatch(getCustomer(sessionStorage.at, deToken));
        },verifyCustomerIntervalSecs * 1000);
    };

    // It will dispatch the getExternalBankAccountListAction function every 3seconds to fetches data from the server to verify bank details.
    const recheckbank = () => {
        verifyBankInterval.current = setInterval(() => {
            dispatch(getExternalBankAccountListAction(sessionStorage.at, deToken))
        },verifyBankIntervalSecs * 1000);
    };

    const updateState = (verificationState, verificationOutcome, name) => {
        if (verificationState === "storing" || verificationState === "loading" || verificationState === "failed") {
            return <Svgloading />
        } else if (verificationState === "completed"  && verificationOutcome === "passed") {
            return <Svgverify />
        } else {
            return <div className="spm-icon spm-cc">
                {name === "verify" && 1}{name === "bank" && 2}
                {name === "deposit" && 3}{name === "wallet" && 4}
            </div>
        }
    }

    const depositStateTitle = (state) => {
        if(state === "completed") {
            return <h4>Deposited</h4>
        } else if(state === "processing") {
            return <h4>Processing Deposits</h4>
        } else {
            return <h4>Deposit Fiat Funds</h4>
        }
    }

    const depositStateSummary = (state) => {
        if(state === "completed") {
            return <p className="mb-0 small">Fund Deposited. Ready for Trading</p>
        } else if(state === "processing") {
            return <p>Deposit of funds in progress</p>
        } else {
            return  <p className="mb-0 small">Transfer fiat funds from bank to exchange balance</p>
        }
    }

    return (
        <>
            <div className="card fadeInDown">
                <div className="card-header border-0">
                    {isDepostFundDone ?
                        <h4 className="card-title">Your Account Setup is Complete</h4>
                        :
                        <h4 className="card-title">Your Exchange account is incomplete </h4>
                    }
                </div>
                <ul className="list-unstyled smplist avlist">
                    <li>
                        {updateState(verificationUpdate, verificationOutcome, "verify")}
                        <div className="spm-content">
                            {verificationUpdate === "completed" && verificationOutcome === "passed" ?
                                <h4>Verified</h4>
                                :
                                <h4>Get Verified</h4>
                            }
                            {verificationUpdate === "completed" && verificationOutcome === "passed" ?
                                <p className="mb-0 small">Your KYC information has been verified</p>
                                :
                                <p className="mb-0 small">Submit your KYC information to get verified</p>
                            }
                        </div>
                        {(verificationUpdate !== "completed" || verificationUpdate === "failed" || verificationOutcome !== "passed") ? (
                            <div className="spm-icon d-flex flex-column gap-1">
                                <Verifykyc verificationOutcome={verificationOutcome} recheckCustomer={recheckCustomer} setVerificationUpdate={setVerificationUpdate} verificationUpdate={verificationUpdate} identityData={identityData} customerData={customerData}/>
                            </div>
                        ) : null}
                    </li>
                    <li className={`${isBankVerified ? "active" : ""}`}>
                        {updateState(bankUpdate, verificationOutcome, "bank")}
                        <div className="spm-content">
                            {!isBankVerified ?
                                <h4>Connect </h4>
                                :
                                <h4>Connected</h4>
                            }
                            {!isBankVerified ?
                                <p className="mb-0 small">Connect your bank account(s)</p>
                                :
                                <p className="mb-0 small">Bank account connected</p>
                            }
                        </div>
                        {!isBankVerified && bankUpdate !== "failed" ?
                            <div className={`spm-icon ${verificationUpdate === "completed" ? "" : "disabledbtn"}`}>
                                {/* <button type="button" name="submit" className="btn btn-outline-primary  btn-cussm">Connect</button> */}
                                <Plaidbank recheckbanks={recheckbank} setBankUpdate={setBankUpdate} bankUpdate={bankUpdate}/>
                            </div>
                            :
                            ""
                        }
                    </li>
                    <li className={`${isDepostFundDone ? "active" : ""}`}>
                        {updateState(depositUpdate, verificationOutcome, "deposit")}
                        <div className="spm-content">
                            {depositStateTitle(depositUpdate)}
                            {depositStateSummary(depositUpdate)}
                        </div>
                        {(!isDepostFundDone && depositUpdate !== "failed") ?
                            <div className="spm-icon" disabled={depositUpdate === "loading" || depositUpdate === "completed"} >
                                <Link to="/deposits" className={`btn  btn-cussm  ${isBankVerified ? "btn-outline-primary" : "btn-outline-light disabledbtn"}`}>
                                    Deposit
                                </Link>
                                {/* <div name="submit" className="d-none">plaid</div> */}
                            </div>
                            :
                            <div className="spm-icon">
                                <Link to="/deposits" className="text-link small">+ Add more funds</Link>
                            </div>
                        }
                    </li>

                    <li className="awllboxs">
                        <div className="awlltbox">
                        {updateState(walletUpdate, verificationOutcome, "wallet")}

                        <div className="spm-content">
                            <h4>Withdraw </h4>                                                      
                        </div>
                        {isBTCAdded || isUSDCAdded ?
                            
                            <div className="spm-icon">
                                <Link to="/external-wallet" className="text-link small">+ Add external wallet</Link>
                            </div>
                             :
                            <div className="spm-icon">
                                <button type="button" name="submit" className={`btn  btn-cussm  ${isDepostFundDone ? "btn-outline-primary" : "btn-outline-light disabledbtn"}`}
                                    onClick={() => {
                                        if (isDepostFundDone) {
                                            navigate("/external-wallet");
                                        } else {
                                            navigate("/dashboard");
                                        }
                                    }}
                                >withdraw</button>
                            </div>
                        }
                        </div>
                        <div className="mb-0 small mt-2 wdclist">

                                <OverlayTrigger overlay={
                                    <Tooltip>
                                        Bitcoin
                                    </Tooltip>
                                }>
                                    <span className={isBTCAdded ? "badge badge-outline-primary active" : "badge badge-outline-dark disabled"} onClick={() => {
                                        if (!isBTCAdded) {
                                            navigate("/external-wallet");
                                        } else {
                                            navigate("/dashboard");
                                        }
                                    }}>
                                        {isBTCAdded ? (<span className="mdi mdi-check"></span>) : (<span className="mdi mdi-plus"></span>)}
                                        <img
                                            src={require("../../images/bitcoin.png")}
                                            alt=""
                                            className="img-fluid"
                                        />BTC</span>
                                </OverlayTrigger>

                                {/* <OverlayTrigger overlay={
                                    <Tooltip>
                                        Ethereum
                                    </Tooltip>
                                }>
                                    <span className={isETHAdded ? "badge badge-outline-primary active" : "badge badge-outline-dark disabled"} onClick={() => {
                                        if (!isETHAdded) {
                                            navigate("/external-wallet");
                                        } else {
                                            navigate("/dashboard");
                                        }
                                    }}>
                                        {isETHAdded ? (<span className="mdi mdi-check"></span>) : (<span className="mdi mdi-plus"></span>)}
                                        <img
                                            src={require("../../images/ethereum.png")}
                                            alt=""
                                            className="img-fluid"
                                        /> ETH</span>
                                </OverlayTrigger> */}

                                <OverlayTrigger overlay={
                                    <Tooltip>
                                        USDC
                                    </Tooltip>
                                }>
                                    <span className={isUSDCAdded ? "badge badge-outline-primary active" : "badge badge-outline-dark disabled"} onClick={() => {
                                        if (!isUSDCAdded) {
                                            navigate("/external-wallet");
                                        } else {
                                            navigate("/dashboard");
                                        }
                                    }}>
                                        {isUSDCAdded ? (<span className="mdi mdi-check"></span>) : (<span className="mdi mdi-plus"></span>)}
                                        <img
                                            src={require("../../images/usdc.png")}
                                            alt=""
                                            className="img-fluid"
                                        />USDC</span>
                                </OverlayTrigger>

                                {/* <OverlayTrigger overlay={
                                    <Tooltip>
                                        Litecoin
                                    </Tooltip>
                                }>
                                    <span className={isLTCAdded ? "badge badge-outline-primary active" : "badge badge-outline-dark disabled"} onClick={() => {
                                        if (!isLTCAdded) {
                                            navigate("/external-wallet");
                                        } else {
                                            navigate("/dashboard");
                                        }
                                    }}>
                                        {isLTCAdded ? (<span className="mdi mdi-check"></span>) : (<span className="mdi mdi-plus"></span>)}
                                        <img
                                            src={require("../../images/LTC.png")}
                                            alt=""
                                            className="img-fluid"
                                        /> LTC</span>
                                </OverlayTrigger> */}

                                {/* <OverlayTrigger overlay={
                                    <Tooltip>
                                        Bitcoin Cash
                                    </Tooltip>
                                }>
                                    <span className={isBCHAdded ? "badge badge-outline-primary active" : "badge badge-outline-dark disabled"} onClick={() => {
                                        if (!isBCHAdded) {
                                            navigate("/external-wallet");
                                        } else {
                                            navigate("/dashboard");
                                        }
                                    }}>
                                        {isBCHAdded ? (<span className="mdi mdi-check"></span>) : (<span className="mdi mdi-plus"></span>)}
                                        <img
                                            src={require("../../images/bch.png")}
                                            alt=""
                                            className="img-fluid"
                                        /> BCH</span>
                                </OverlayTrigger> */}
                                <OverlayTrigger overlay={
                                    <Tooltip>
                                        US Dollar
                                    </Tooltip>
                                }>
                                    <span className={isBankVerified ? "badge badge-outline-primary active" : "badge badge-outline-dark disabled"} onClick={() => {
                                        if (!isBankVerified) {
                                            navigate("/payment-method");
                                        } else {
                                            navigate("/dashboard");
                                        }
                                    }}>
                                        {isBankVerified ? (<span className="mdi mdi-check"></span>) : (<span className="mdi mdi-plus"></span>)}
                                        <img
                                            src={require("../../images/usd.png")}
                                            alt=""
                                            className="img-fluid"
                                        />USD</span>
                                </OverlayTrigger>
                            </div>
                    </li>
                    <li className="pb-0 penone"></li>
                </ul>
                <div className="spm-content px-3">
                    <h4 className="text-center mb-0">Trade 100's of Crypto's using <a href="http://www.thecryptowallet.io" target="_blank" className="text-primary fw-bold">The Crypto Wallet</a> App</h4>
                    <div className="tceapps">
                        <a href="https://apps.apple.com/us/app/the-crypto-wallet/id6478397189" target="_blank" title="Apple store">
                            <img
                                src={require("../../images/apple_button.png")}
                                alt=""
                                className="img-fluid"
                            />
                        </a>
                        {/* <a href="http://www.thecryptowallet.io/" target="_blank" title="Google Play store" className="ms-3">
                            <img
                                src={require("../../images/google_button.png")}
                                alt=""
                                className="img-fluid"
                            />
                        </a> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Verifymodule;