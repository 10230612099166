import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const TinyLine = ({ marketPrices }) => {
  const [series, setSeries] = useState([
    {
      name: "Price",
      data: marketPrices || [],
    }
  ]);

  useEffect(() => {
    setSeries([{ ...series[0], data: marketPrices || [] }]);
  }, [marketPrices]);

  const options = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false,
      }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      colors: ["#1355d7"],
    },
    grid: {
        show: false,
    },
    tooltip: {
        enabled: false,
        x: {
            format: "dd MMM yyyy"
        },
    },
    xaxis: {
      categories: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
      axisBorder: {
        show: false
    },
      labels: {
        show: false
      }
    },
    yaxis: {
      labels: {
        show: false
      }
    },
  };

  return (
    <ReactApexChart options={options} series={series} type="line" height={90} width={250} />
  );
};

export default TinyLine;
