import React, { useState, useEffect } from "react";
import Gifloader from "../../layout/gifloader";
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import {
  getAdminAllCustomersList,
  getAdminTrades,
  resetGetAllCustomerListFailure,
  resetGetAllCustomerListSuccess,
  resetGetTradesFailed,
  resetGetTradesSuccess,
} from "../../../actions/adminActions";
import { commonError } from "../../../actions/customerActions";
import relativeTime from "dayjs/plugin/relativeTime";
import { formatDate } from "../../../utils/Encryption";
import { useDispatch, useSelector } from "react-redux";
import { Table, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import PieChart from "../../charts/piechart";
import bitcoinLogo from "../../../images/bitcoin.png";
import usdcLogo from "../../../images/usdc.png";


// Importing the relativeTime plugin to enable 'fromNow' functionality
dayjs.extend(relativeTime);


const Sales = () => {
  const dispatch = useDispatch();
  let jwtToken = sessionStorage.getItem("a_at");


  const getAC = useSelector((state) => state.adminReducer.getAllCustomersList);
  const getACF = useSelector((state) => state.adminReducer.getAllCutomersListFailed);
  const getTrades = useSelector((state) => state.adminReducer.getTrades);
  const getTradesFailed = useSelector((state) => state.adminReducer.getTradesFailed);


  const [isloader, setIsLoader] = useState(true);
  const [customersList, setCustomersList] = useState([]);
  const [tradesList, setTradesList] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [customerGuid, setCustomerGuid] = useState('');
  const [sortTrades, setSortedTrades] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [buyTrades, setBuyTrades] = useState([]);
  const [sellTrades, setSellTrades] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");


  useEffect(() => {
    dispatch(getAdminAllCustomersList(jwtToken));
    dispatch(getAdminTrades(jwtToken));
  }, []);

  // Fetching the all customers list data
  useEffect(() => {
    if (getAC && getAC.data) {
      setCustomersList(getAC.data.data)
      dispatch(resetGetAllCustomerListSuccess())
    }
  }, [getAC]);
  
  // If all customers list data failed
  useEffect(() => {
    if (Object.keys(getACF).length !== 0) {
      let errObj = getACF

      // If there is an error
      if (errObj && errObj.data && errObj?.data?.message) {
        dispatch(commonError(errObj?.data?.message))
        dispatch(resetGetAllCustomerListFailure())
      }
    }
  }, [getACF]);

  // Modifying the trades data
  useEffect(() => {
    if (customersList.length > 0 && Object.keys(getTrades).length !== 0) {
      let filteredTrades;
      
      if (customersList) {
        const customerGuid = customersList.map(customer => customer.guid);
        filteredTrades = getTrades.data.data.filter(trade => customerGuid.includes(trade.customer_guid));
      } 
            
      let modifiedArray = filteredTrades.map((trade) => {       
        let divider;
        let sign;

        // Determine the sign and divider based on the trading pair symbol.
        if (trade.symbol === "BTC-USD") {
          sign = "BTC";
          divider = 100000000; // 8 decimal places
        } else if (trade.symbol === "USDC-USD") {
          sign = "USDC";
          divider = 1000000; // 18 decimal places
        }
        let recAmt =
          trade.side === "buy"
            ? trade.receive_amount / divider
            : trade.deliver_amount / divider;
        let delAmt =
          trade.side === "buy"
            ? trade.deliver_amount / 100
            : trade.receive_amount / 100;
        return {
          sign,
          type: trade.side,
          guid: trade.guid,
          state: trade.state,
          purchasePrice: delAmt,
          purchaseQuantity: recAmt,
          quoteGuid: trade.quote_guid,
          customerGuid: trade.customer_guid,
          createdAt: trade?.created_at,
          currencyPair: `${recAmt} ${sign} = $${delAmt}`,
          createAtDaysFormat: dayjs(trade?.created_at).fromNow(),
        };
      });
      const filteredBuyTrades = modifiedArray.filter(trade => trade.type === "buy");
      const filteredSellTrades = modifiedArray.filter(trade => trade.type === "sell");   
      
      setIsFilter(false);
      setIsLoader(false);
      setTradesList(modifiedArray);
      setBuyTrades(filteredBuyTrades);
      setSellTrades(filteredSellTrades);
      dispatch(resetGetTradesSuccess());
    }
  }, [getTrades, customersList]);


  // Calculate sum by sign
  const buyTotal = calculateSumBySign(buyTrades);
  const sellTotal = calculateSumBySign(sellTrades);
  
  // Calculate sum by sign
  function calculateSumBySign(tradeData) {
    return tradeData.reduce((acc, type) => {
      const { sign, purchasePrice, purchaseQuantity } = type;
      const existingItem = acc.find(item => item.sign === sign);
  
      if (existingItem) {
        existingItem.purchasePrice += purchasePrice;
        existingItem.purchaseQuantity += purchaseQuantity;
      } else {
        acc.push({ sign, purchasePrice, purchaseQuantity });
      }
  
      return acc;
    }, []);
  }

  // Sort account data 
  function sortArrayBySymbol(array) {
    const symbolOrder = ['USD', 'BTC', 'USDC'];
    return array.sort((a, b) => {
      const aSymbol = a.asset;
      const bSymbol = b.asset;
      const aIndex = symbolOrder.indexOf(aSymbol);
      const bIndex = symbolOrder.indexOf(bSymbol);
      return aIndex - bIndex;
    });
  }
  
  // Function to convert data into percentages for a pie chart
  const convertToPercentages = (totalPrice) => {
    if (!totalPrice || !Array.isArray(totalPrice)) {      
      // Return default values or handle the case where totalPrice is falsy.
      return {
        colorNameData: [],
        assetNameData: [],
        percentages: [],
      };
    }

    const getAssetDetails = (asset) => {
      switch (asset) {
        case 'BTC':
          return { name: 'Bitcoin', color: 'rgba(246, 146, 26)' };
        case 'USDC':
          return { name: 'USDC', color: 'rgba(23, 195, 164)' };
        default:
          return { name: '', color: '' };
      }
    };

    const assetDetails = totalPrice.map((data) => getAssetDetails(data?.sign));
    const priceArr = totalPrice.map((e) => parseFloat(e.purchasePrice || 0));
    const total = priceArr.reduce((acc, val) => acc + val, 0);

    if (total === 0) {
      return {
        colorNameData: assetDetails.map((details) => details.color),
        assetNameData: assetDetails.map((details) => details.name),
        percentages: priceArr,
      };
    }

    const percentages = priceArr.map((value) => (value ? ((value / total) * 100).toFixed(2) : 0));

    return {
      colorNameData: assetDetails.map((details) => details.color),
      assetNameData: assetDetails.map((details) => details.name),
      percentages,
    };
  };


  // If trades data failed
  useEffect(() => {
    if (getTradesFailed && Object.keys(getTradesFailed).length !== 0) {
      let errObj = getTradesFailed;
      if (errObj && errObj.data && errObj?.data?.message) {
        dispatch(commonError(errObj?.data?.message));
        dispatch(resetGetTradesFailed());
        setIsLoader(false);
      }
    }
  }, [getTradesFailed]);

  const handleFilter = () => {
    setIsFilter(true);
    let filteredTradesTemp = tradesList?.filter((trade) => {
      // Filter by date
      const fromDateStr = fromDate ? new Date(fromDate).toISOString() : null;
      const toDateStr = toDate ? new Date(toDate + "T23:59:59.000Z").toISOString() : null;
      const dateFilter =
        (!fromDateStr || trade.createdAt >= fromDateStr) &&
        (!toDateStr || trade.createdAt <= toDateStr);

      const findCustomerGuid = customerGuid?.toLocaleLowerCase().trim();
      const searchFilter = !findCustomerGuid || trade.customerGuid.toLowerCase().includes(findCustomerGuid);
        
      return dateFilter && searchFilter;
    });
    setSortedTrades(filteredTradesTemp);
    setPageNum(0);
  };

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "fromDate":
        setFromDate(value);
        break;
      case "toDate":
        setToDate(value);
        break;
    }
  };
  

  // Pagination calculations
  const itemsPerPage = 20;
  let pageCount = (sortTrades?.length > 0 || isFilter) ? Math.ceil(sortTrades.length / itemsPerPage) : Math.ceil(tradesList.length / itemsPerPage);
  const endOffset = pageNum + itemsPerPage;
  let currentItems = (sortTrades?.length > 0 || isFilter) ? sortTrades.slice(pageNum, endOffset) : tradesList.slice(pageNum, endOffset);


  // Changing the page number
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * itemsPerPage) % tradesList.length;
    setPageNum(newOffset);
  };

  const setClassName = (state) => {
    if(state === "completed") {
      return "mw-badge badge badge-success"
    }else if(state === "cancelled" || state === "failed"){ 
      return "mw-badge badge badge-danger1"
    }else {
      return "mw-badge badge badge-warning"
    }
  };

  const clearFilter = () => {
    setIsFilter(false)
    setFromDate("")
    setToDate("")
    setCustomerGuid("")
    setSortedTrades([]);
    setPageNum(0);
  }

  // Table datas
  const Items = ({ currentItems }) => {
    return (
      <>
        <Table hover responsive>
          <thead>
            <tr>
              <th className="">ID</th>
              <th className="">Customer GUID</th>
              <th className="text-end">Date</th>
              <th className="text-center">Type</th>
              <th className="text-end">Currency Pair</th>
              <th className="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems?.map((item, index) => (
                <tr key={item?.guid}>
                  <td style={{ whiteSpace: "nowrap" }}>{item?.guid}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {
                      <Link to={`/admin/customer/${item.customerGuid}`}>
                        <span className="text-primary fw-bold">
                          {item.customerGuid}
                        </span>
                      </Link>
                    }
                  </td>
                  <td style={{ whiteSpace: "nowrap" }} className="text-end text-capitalize">
                    {formatDate(item.createdAt)}
                  </td>
                  <td className="text-center">
                    <span className={`${item.type === 'buy' ? 'mw-badge badge badge-primary' : 'mw-badge badge badge-danger'}`}>
                      {item.type}
                    </span>
                  </td>
                  <td style={{ whiteSpace: "nowrap" }} className="text-end">
                    {
                      <span>
                        <span className="text-primary fw-bold">{`${item.purchaseQuantity}`}</span>{" "}
                        {`${item.sign}`} = $
                        <span className="text-primary fw-bold">{`${(item.purchasePrice)?.toFixed(2)}`}</span>{" "}
                        USD
                      </span>
                    }
                  </td>
                  <td style={{ whiteSpace: "nowrap" }} className="text-center">
                    <span className={setClassName(item.state)}>
                      {item.state}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </>
    );
  };


  return (
    <>
      <AdminHeader />
      <AdminSidebar />

      <div className="content-body dash-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Sales List</h4>
                </div>
                {
                  tradesList?.length > 0 && (
                    <div className="row align-items-center filterset mx-0">
                       <div className="col-lg-5 col-md-5 col-12 my-3">
                        <Form.Group className="w-100">
                          <Form.Control type="text" placeholder="Customer GUID" value={customerGuid} onChange={(e) => setCustomerGuid(e.target.value)} />
                        </Form.Group>
                      </div>

                      <div className="col-lg-5 col-md-5 col-12 my-3">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <label className="input-group-text">
                                <i className="las la-calendar-alt"></i>
                              </label>
                            </div>

                            <input
                              type="date"
                              name="fromDate"
                              className="form-control"
                              value={fromDate}
                              placeholder="From"
                              onChange={handleTimeChange}
                              max={new Date().toISOString().split("T")[0]}
                            />
                            <input
                              type="date"
                              name="toDate"
                              className="form-control"
                              value={toDate}
                              placeholder="To"
                              onChange={handleTimeChange}
                              max={new Date().toISOString().split("T")[0]}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex align-items-center gap-2 col-lg-2 col-md-2 col-12 my-3">
                        <button
                          className="btn btn-primary mw-auto"
                          onClick={handleFilter}>
                          Submit
                        </button>

                        <div>
                          <OverlayTrigger overlay={
                            <Tooltip>
                              Clear
                            </Tooltip>
                        }>
                            <button
                              className="btn btn-secondary mw-auto"
                              onClick={clearFilter}>
                              <span className="mdi mdi-close"></span>
                            </button>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  )
                }
                <div className="card-body py-0">
                  <div
                    className={`${isloader ? "d-block" : "d-none"
                      } justify-content-center d-flex align-item-center my-5 py-5`}
                  >
                    <Gifloader />
                  </div>


                  {!isloader && <div className="row mx-0 mt-5">
                    <div className="col-lg-6">
                      <div className="row align-items-start">
                        <h3 className="text-center p-3 fw-bold">
                          Crypto Buy Report
                        </h3>
                        <div className="col text-start col-lg-6 col-md-6 col-12 p-2">
                          <div className="my-5">
                            {buyTotal && (
                            <PieChart
                              priceData={convertToPercentages(sortArrayBySymbol(buyTotal))}
                            />
                          )}
                          </div>
                        </div>
                        <div className="col text-start col-lg-6 col-md-6 col-12">
                          {buyTotal ? (
                            buyTotal.map((item) => (
                              <div
                                className="col text-start ml-4 "
                                key={item.sign}
                              >
                                <div className="text-center">
                                  <img
                                    src={item.sign === "BTC" ? bitcoinLogo : 
                                      item.sign === "USDC" ? usdcLogo : ""}
                                    alt=""
                                    className="me-2"
                                    width="25"
                                  />
                                  <h4 className="fw-bold mb-0 my-2">
                                    {item.sign === "BTC" ? "Bitcoin" : 
                                      item.sign === "USDC" ? "USDC" : ""}
                                  </h4>
                                </div>
                                <h4 className="my-2 text-center">
                                  <span className="text-primary fw-bold">
                                    {item.sign !== undefined && item.purchaseQuantity.toFixed("8")}
                                  </span>
                                  <span className="small"> {item.sign}</span>
                                </h4>
                              </div>
                            ))
                          ) : (
                            <p>No buy data available.</p>
                          )}
                        </div>
                       
                      </div>
                  </div>
                  <div className="col-lg-6  border-start border-1">
                    <div className="row align-items-start">
                      <h3 className="text-center p-3 fw-bold">Crypto Sell Report</h3>
                      <div className="col text-start col-lg-6 col-md-6 col-12 p-2">
                        <div className="my-5">
                          {sellTotal && <PieChart priceData={convertToPercentages(sortArrayBySymbol(sellTotal))} />}
                        </div>
                      </div>  
                      <div className="col text-start col-lg-6 col-md-6 col-12 ">
                      {sellTotal ? (
                            sellTotal.map((item) => (
                              <div
                                className="col text-start ml-4 "
                                key={item.sign}
                              >
                                <div className="text-center">
                                  <img
                                    src={item.sign === "BTC" ? bitcoinLogo : 
                                      item.sign === "USDC" ? usdcLogo : ""}
                                    alt=""
                                    className="me-2"
                                    width="25"
                                  />
                                  <h4 className="fw-bold mb-0 my-2">
                                    {item.sign === "BTC" ? "Bitcoin" : 
                                      item.sign === "USDC" ? "USDC" : ""}
                                  </h4>
                                </div>
                                <h4 className="my-2 text-center">
                                  <span className="text-primary fw-bold">
                                    {item.sign !== undefined && item.purchaseQuantity.toFixed("8")}
                                  </span>
                                  <span className="small"> {item.sign}</span>
                                </h4>
                              </div>
                            ))
                          ) : (
                            <p>No sell data available.</p>
                          )}
                      </div>
                                         
                    </div>                   
                  </div>
                </div>}
                  
                  
                  <div className={`${isloader ? "d-none" : "d-block"} mt-3`}>
                    {currentItems.length > 0 ? (
                      <div>
                        <Items currentItems={currentItems} />
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="Next"
                          onPageChange={handlePageClick}
                          pageCount={pageCount}
                          previousLabel="Previous"
                          containerClassName={
                            "pagination justify-content-center my-3"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          activeClassName={"active"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          forcePage={pageNum === 0 ? 0 : currentPage}
                        />
                      </div>
                    ) : (
                      <div className="text-center py-5 text-capitalize">
                        <p>No trades found.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sales;

