
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";



class BtcChart extends Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [{
                name: "Desktops",
                data: this.props.marketPrices || [], // Initialize with the provided prop or an empty array
            }],
            options: {
                chart: {
                    height: 100,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },

                    toolbar: {
                        show: false,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 2,
                    colors: ["#1355d7"],
                },
                grid: {
                    show: false,
                },
                tooltip: {
                    enabled: false,
                    x: {
                        format: "dd MMM yyyy"
                    },
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                    axisBorder: {
                        show: false
                    },

                    labels: {
                        show: false
                    }
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
            },


        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.marketPrices !== prevProps.marketPrices) {
            this.setState({
                series: [{
                    ...this.state.series[0], // Preserve the other properties of the series
                    data: this.props.marketPrices || [], // Update the data with the new prop value
                }],
            });
        }
    }



    render() {
        return (


            <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={90} />


        );
    }
}

export default BtcChart;