import React, { useEffect, useState } from 'react'
import Header3 from '../../layout/header3'
import Footer1 from '../../layout/footer1'
import AreaChart from '../../charts/area'
import { useDispatch, useSelector } from 'react-redux'
import { getXrpFiveYearsData, resetXrpFiveYearsData } from '../../../actions/customerActions'

const XRP = () => {
  const [oneYearValue, setOneYearValue] = useState([])
  const XrpPricesForOneYear = useSelector((state) => state.customerReducer.getXRPFiveYearsData);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getXrpFiveYearsData())
  }, [])

  // useEffect(() => {
  //   if (Array.isArray(XrpPricesForOneYear) && XrpPricesForOneYear.length > 0) {
  //     const convertToUnix = (isoDate) => {
  //       const date = new Date(isoDate);
  //       return isNaN(date.getTime()) ? null : Math.floor(date.getTime());
  //     };

  //     const modifiedValue = XrpPricesForOneYear.map((data) => [
  //       convertToUnix(data.time_period_start),
  //       data.price_close,
  //     ]);
  //     setOneYearValue(modifiedValue);
  //     dispatch(resetXrpFiveYearsData());
  //   }
  // }, [XrpPricesForOneYear]);

  useEffect(() => {
    if (XrpPricesForOneYear && XrpPricesForOneYear.length !== 0) {
      setOneYearValue(XrpPricesForOneYear)
      dispatch(resetXrpFiveYearsData())
    }
  }, [XrpPricesForOneYear])

  return (
    <>
      <Header3 />
      <div className='lear_page sp-inners'>
        {/* <div className='banners'>
          <h2>Learn</h2>
        </div> */}

        <div className='main'>
          <div className='container'>
            <div>
              <div className='txt'>
                <h3>How to buy XRP?</h3>
                <div className='d-flex align-items-center justify-content-center'>
                  <img className="img-fluid py-4 w-50 learn-page-img" src={require('../../../images/learn/how_to_buy_xrp.png')} alt=""/>
                </div>
                <p>
                  XRP is a digital asset operated on the XRP Ledger, a decentralized blockchain designed for fast, low-cost, and eco-friendly transactions. Launched in 2012, <a href='https://thecryptoexchange.ca/what-is-XRP' style={{ color: '#1355d7' }}>XRP</a> gained popularity for its scalability and sustainability, making it ideal for financial institutions and consumers alike for cross-border payments and microtransactions. Currently traded on over 100 markets globally, including in Canada, XRP is a reliable option for those looking to invest or transact in cryptocurrencies. If you're considering buying XRP in Canada, here's what you need to know:
                </p>

                <h3>Why Buy XRP?</h3>
                <p>
                  Here is the list of several compelling reasons to choose XRP for investment purposes:
                </p>

                <p className='mb-0'>
                  <b>Fast Transactions:</b> XRP transactions settle in just 3-5 seconds, significantly faster than traditional financial systems and many other cryptocurrencies.
                </p>
                <p className='mb-0'>
                  <b>Low-Cost Transactions:</b> The cost per transaction is extremely low at $0.0002, making it a cost-effective choice for frequent transactions.
                </p>
                <p className='mb-0'>
                  <b>Scalability:</b> XRP can handle 1,500 transactions per second, showcasing its ability to scale efficiently.
                </p>      
                <p className='mb-0'>
                  <b>Cross-Border Payments:</b> XRP is used by financial institutions as a bridge currency to facilitate faster and more affordable cross-border payments.
                </p>
                <p>
                  <b>Regulatory Compliance:</b> XRP’s potential regulatory compliance increases its utility and value over time, attracting attention from financial institutions.
                </p> 

                <div className="py-4 px-0 px-md-5">
                  <AreaChart BtcPriceEachDay={oneYearValue} cryptoName={'Ripple'} />
                </div>
                <h3>Where and How Do I Buy XRP?</h3>  
                <p>
                  XRP, commonly known as Ripple, is a popular cryptocurrency that can be purchased through various channels in Canada. Here are some of the most accessible options with the steps that you need to follow to make a purchase: 
                </p>      

                <h4>Crypto Exchanges</h4> 
                <p>
                  One of the most straightforward ways to buy XRP is through a cryptocurrency exchange. In Canada, The Crypto Exchange is one of the best platforms that offers a user-friendly experience for purchasing XRP. This exchange provides a secure environment for trading various cryptocurrencies, including XRP, ensuring that users have access to competitive rates and a seamless transaction process.
                </p>

                <p>
                  <b>Choose an Exchange:</b> Select a cryptocurrency exchange platform that supports XRP.
                </p>
                <p>
                  <b>Create an Account:</b> Sign up and verify your identity according to the exchange's requirements. This typically involves providing personal information and proof of identity.
                </p>
                <p>
                  <b>Deposit Funds:</b> Deposit Canadian dollars (CAD) into your exchange account using a bank transfer or other supported payment methods. Ensure that the exchange supports your preferred method of funding.
                </p>
                <p>
                  <b>Buy XRP:</b> Navigate to the trading section of the exchange, select XRP as the cryptocurrency you want to buy, and execute your purchase at the current market price or set a limit order. A market order will buy XRP at the current price, while a limit order allows you to specify the price at which you want to buy.
                </p>

                <h4>Cryptocurrency ATMs</h4>
                <p>
                  Cryptocurrency ATMs (also known as Bitcoin ATMs) offer a convenient way to buy XRP using cash. These machines are strategically located in various public places across Canada.
                </p>

                <ol>
                  <p>
                    <b>Steps to Use a Cryptocurrency ATM:</b>
                  </p>
                  <p className='mb-0'>
                    <b>1. Locate a Nearby ATM:</b> Use an online map or a dedicated website to find a cryptocurrency ATM near you.
                  </p>
                  <p className='mb-0'>
                    <b>2. Insert amount of Cash Needed:</b> Follow the on-screen instructions to insert the desired amount of cash.
                  </p>
                  <p className='mb-0'>
                    <b>3. Provide Your Cryptocurrency Wallet Address:</b> Scan your wallet QR code or manually enter your wallet address.
                  </p>
                  <p className='mb-0'>
                    <b>4. Confirm the Transaction:</b> Verify the details and confirm the transaction.
                  </p>
                  <p>
                    <b>5. Receive Your Cryptocurrency:</b> The purchased cryptocurrency will be sent to your wallet address.
                  </p>
                </ol>

                <h4>Peer-to-Peer (P2P) Platforms</h4>
                <p>
                  Peer-to-peer platforms enable you to buy cryptocurrency directly from other individuals. This method often offers more privacy and flexibility in payment options. Popular P2P platforms include LocalBitcoins and Paxful.
                </p>
                <p>
                  Steps to Buy on P2P Platforms:
                </p>

                <ol>
                  <p className='mb-0'>
                    <b>1. Sign Up for a P2P Platform:</b> Create an account on the chosen platform.
                  </p>
                  <p className='mb-0'>
                    <b>2. Browse Listings:</b> Look for sellers offering XRP at competitive prices.
                  </p>
                  <p className='mb-0'>
                    <b>3. Choose a Seller:</b> Select a seller with a good reputation and positive feedback.
                  </p>
                  <p className='mb-0'>
                    <b>4. Initiate a Trade:</b> Follow the platform’s instructions to complete the payment.
                  </p>
                  <p>
                    <b>5. Receive the Cryptocurrency:</b> Once the seller confirms the payment, the XRP will be transferred to your wallet.
                  </p>
                </ol>
                <div className='d-flex align-items-center justify-content-center'>
                  <img className="img-fluid py-4 w-100 learn-page-img" src={require('../../../images/learn/xrp_steps.png')} alt=""/>
                </div>

                <h4>Over-the-counter (OTC) Desks</h4>
                <p>
                  <a href='https://thecryptoexchange.ca/otc' style={{ color: '#1355d7' }}>OTC desks</a> are ideal for purchasing large amounts of cryptocurrency. They provide personalized service and often have better liquidity than public exchanges. This option is typically used by institutional investors or high-net-worth individuals.
                </p>

                <ol>
                  <p><b>Steps to Buy Through OTC Desks:</b></p>
                  <p>
                    1. Contact an OTC Desk: Reach out and set up an account with an OTC desk.
                  </p>
                  <p>
                    2. Negotiate Terms: Discuss and agree on the purchase terms, including the price and amount.
                  </p>
                  <p>
                    3. Transfer Funds: Send the agreed amount to the OTC desk.
                  </p>
                  <p>
                    4. Receive the Cryptocurrency: The purchased XRP will be transferred directly to your wallet.
                  </p>
                </ol>
                <div className='d-flex align-items-center justify-content-center'>
                  <img className="img-fluid py-4 w-100 learn-page-img" src={require('../../../images/learn/xrp_otc.png')} alt=""/>
                </div>

                <h3>How to use XRP?</h3>
                <p>
                  <b>Cross-Border Payments:</b> Use XRP to send and receive international payments quickly and cost-effectively. Its low transaction fees and fast settlement times make it ideal for cross-border transactions.
                </p>
                <p>
                  <b>Trading and Investment:</b> Trade XRP on cryptocurrency exchanges to potentially profit from price fluctuations or to diversify your <a href='https://cryptoexperts.ca/' style={{ color: '#1355d7' }}>investment</a> portfolio. XRP’s liquidity and widespread acceptance make it a popular choice among traders.
                </p>
                <p>
                  <b>Asset Diversification:</b> Hold XRP as part of a diversified portfolio of cryptocurrencies and traditional assets. Including XRP in your portfolio can provide exposure to the growing cryptocurrency market while balancing risk with other investment types.
                </p>

                <p>
                  XRP stands out as a versatile and efficient digital asset, making it an attractive option for investors and users alike. Its rapid transaction speeds, low costs, high scalability, and environmental sustainability are compelling reasons to consider XRP for various financial activities, including cross-border payments and investment diversification. With the multiple sources to buy XRP in Canada, still choose the reliable source. Reach out the <a href='https://thecryptoexchange.ca/' style={{ color: '#1355d7' }}>The Crypto Exchange</a> for a better investment experience. Visit our website to learn more details about our services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer1 />
    </>
  )
}

export default XRP
