import React, { useState, useEffect } from "react";
import Gifloader from "../../layout/gifloader";
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import {
  getAdminAllCustomersList,
  getAdminTrades,
  resetGetAllCustomerListFailure,
  resetGetAllCustomerListSuccess,
  resetGetTradesFailed,
  resetGetTradesSuccess,
} from "../../../actions/adminActions";
import { commonError, commonSuccess } from "../../../actions/customerActions";
import relativeTime from "dayjs/plugin/relativeTime";
import { formatDate } from "../../../utils/Encryption";
import { useDispatch, useSelector } from "react-redux";
import { Table, Form, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import dayjs from "dayjs";

// Importing the relativeTime plugin to enable 'fromNow' functionality
dayjs.extend(relativeTime);


const Trades = () => {
  const dispatch = useDispatch();
  let jwtToken = sessionStorage.getItem("a_at");

  const getAC = useSelector((state) => state.adminReducer.getAllCustomersList);
  const getACF = useSelector((state) => state.adminReducer.getAllCutomersListFailed);
  const getTrades = useSelector((state) => state.adminReducer.getTrades);
  const getTradesFailed = useSelector((state) => state.adminReducer.getTradesFailed);


  const [isloader, setIsLoader] = useState(true);
  const [customersList, setCustomersList] = useState([]);
  const [tradesList, setTradesList] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [guid, setGuid] = useState('');
  const [customerGuid, setCustomerGuid] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [sortingOption, setSortingOption] = useState("all");
  const [dataModifiedForExport, setDataModifiedForExport] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);


  // Search function using guid and customer guid
  useEffect(() => {
    const filteredListCopy = [...tradesList].filter((item) => {
      // Converting all the characters to lowercase, so it will avoid case-sensitive issues.
      // And removing the unnecessary white space before and after in the query.
      const findGuid = guid?.toLowerCase().trim();
      const findCustomerGuid = customerGuid?.toLowerCase().trim();

      if (!item.guid.toLowerCase().includes(findGuid)) {
        return false;
      }
      if (!item.customerGuid.toLowerCase().includes(findCustomerGuid)) {
        return false;
      }
      if (sortingOption === "buy" && item.type === "sell") {
        return false;
      }
      if (sortingOption === "sell" && item.type === "buy") {
        return false;
      }
      return true;
    });

    setFilteredData(filteredListCopy);
    setPageNum(0);
  }, [tradesList, guid, customerGuid, sortingOption]);

  useEffect(() => {
    dispatch(getAdminAllCustomersList(jwtToken));
    dispatch(getAdminTrades(jwtToken));
  }, []);

  // Fetching the all customers list data
  useEffect(() => {
    if (getAC && getAC.data) {
      setCustomersList(getAC.data.data)
      dispatch(resetGetAllCustomerListSuccess())
    }
  }, [getAC]);
  
  // If all customers list data failed
  useEffect(() => {
    if (Object.keys(getACF).length !== 0) {
      let errObj = getACF

      // If there is an error
      if (errObj && errObj.data && errObj?.data?.message) {
        dispatch(commonError(errObj?.data?.message))
        dispatch(resetGetAllCustomerListFailure())
      }
    }
  }, [getACF]);

  // Modifying the trades data
  useEffect(() => {
    if (customersList.length > 0 && Object.keys(getTrades).length !== 0) {
      let filteredTrades;
      
      if (customersList) {
        const customerGuid = customersList.map(customer => customer.guid);
        filteredTrades = getTrades.data.data.filter(trade => customerGuid.includes(trade.customer_guid));
      } 
      
      let modifiedArray = filteredTrades.map((trade) => {       
        let divider;
        let sign;

        // Determine the sign and divider based on the trading pair symbol.
        if (trade.symbol === "BTC-USD") {
          sign = "BTC";
          divider = 100000000; // 8 decimal places
        } else if (trade.symbol === "USDC-USD") {
          sign = "USDC";
          divider = 1000000; // 6 decimal places
        } 

        let recAmt =
          trade.side === "buy"
            ? trade.receive_amount / divider
            : trade.deliver_amount / divider;
        let delAmt =
          trade.side === "buy"
            ? trade.deliver_amount / 100
            : trade.receive_amount / 100;
        return {
          sign,
          type: trade.side,
          guid: trade.guid,
          state: trade.state,
          purchasePrice: delAmt,
          purchaseQuantity: recAmt,
          quoteGuid: trade.quote_guid,
          customerGuid: trade.customer_guid,
          createdAt: formatDate(trade?.created_at),
          currencyPair: `${recAmt} ${sign} = $${delAmt}`,
          createAtDaysFormat: dayjs(trade?.created_at).fromNow(),
        };
      });
      setIsLoader(false);
      setTradesList(modifiedArray);
      dispatch(resetGetTradesSuccess());
    }
  }, [getTrades, customersList]);

  // If trades data failed
  useEffect(() => {
    if (getTradesFailed && Object.keys(getTradesFailed).length !== 0) {
      let errObj = getTradesFailed;
      if (errObj && errObj.data && errObj?.data?.message) {
        dispatch(commonError(errObj?.data?.message));
        dispatch(resetGetTradesFailed());
        setIsLoader(false);
      }
    }
  }, [getTradesFailed]);


  // Pagination calculations
  const itemsPerPage = 20;
  let pageCount = (filteredData?.length > 0 || customerGuid?.length > 0 || guid?.length > 0) ? Math.ceil(filteredData.length / itemsPerPage) : Math.ceil(tradesList.length / itemsPerPage);
  const endOffset = pageNum + itemsPerPage;
  let currentItems = (filteredData?.length > 0 || customerGuid?.length > 0 || guid?.length > 0) ? filteredData.slice(pageNum, endOffset) : tradesList.slice(pageNum, endOffset);

  // Changing the page number
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * itemsPerPage) % tradesList.length;
    setPageNum(newOffset);
  };

  // Deep copy function
  const deepCopy = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
    if (Array.isArray(obj)) {
      return obj.map(deepCopy);
    }
    const copiedObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        copiedObj[key] = deepCopy(obj[key]);
      }
    }
    return copiedObj;
  };

  // Handle convert tradeList data to csv file
  const handleExportToCSV = async () => {
    let dataToExport;
    if (filteredData?.length > 0) {
      // Deep copy of filteredData
      dataToExport = deepCopy(filteredData);
    } else {
      // Deep copy of tradesList
      dataToExport = deepCopy(tradesList);
    }
    // Modify the copy of dataToExport
    const exportData = dataToExport.map((trade) => {
      delete trade?.purchaseQuantity;
      delete trade?.purchasePrice;
      delete trade?.createAtDaysFormat;
      delete trade?.sign;
      return trade;
    });
    await setDataModifiedForExport(exportData);
    await setShowModal(false);
    dispatch(commonSuccess("File downloading"));
  };

  // set headers to csv file
  const headers = [
    { label: "Trades GUID", key: "guid" },
    { label: "Customer GUID", key: "customerGuid" },
    { label: "Quote GUID", key: "quoteGuid" },
    { label: "Trade Type", key: "type" },
    { label: "Currency Pair", key: "currencyPair" },
    { label: "State", key: "state" },
    { label: "Created At", key: "createdAt" },
  ];

  // Handle convert tradeList data to xlsx file
  const handleExportXLSX = () => {
    let dataToExport;
    if (filteredData?.length > 0) {
      dataToExport = deepCopy(filteredData);
    } else {
      dataToExport = deepCopy(tradesList);
    }
    const exportData = dataToExport.map((trade) => {
      delete trade?.purchaseQuantity;
      delete trade?.purchasePrice;
      delete trade?.createAtDaysFormat;
      delete trade?.sign;
      return {
        guid: trade.guid,
        customerGuid: trade.customerGuid,
        quoteGuid: trade.quoteGuid,
        type: trade.type,
        currencyPair: trade.currencyPair,
        state: trade.state,
        createdAt: trade.createdAt
      };
    });
    try {
      const ws = XLSX.utils.json_to_sheet(exportData);
      // Customize xlsx header name
      ws["A1"].v = "Trades GUID";
      ws["B1"].v = "Customer GUID";
      ws["C1"].v = "Quote GUID";
      ws["D1"].v = "Trade Type";
      ws["E1"].v = "Currency Pair";
      ws["F1"].v = "State";
      ws["G1"].v = "Created At";
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "data.xlsx");
      setShowModal(false);
      dispatch(commonSuccess("File downloaded"));
    } catch (error) {
      setShowModal(false);
      console.error("XLSX Conversion Error:", error);
      dispatch(commonError("Unable to export XLSX file"));
    }
  };

  // file export show modal 
  const updateShowModal = () => {
    if ((guid?.length > 0 || customerGuid?.length > 0 || sortingOption?.length > 0) && filteredData?.length > 0) {
      setShowModal(true)
    } else if (guid?.length === 0 && customerGuid?.length === 0 && sortingOption?.length > 0 && tradesList?.length > 0) {
      setShowModal(true)
    } else {
      setShowModal(false)
    }
  }

  const setClassName = (state) => {
    if(state === "completed") {
      return "mw-badge badge badge-success"
    }else if(state === "cancelled" || state === "failed"){ 
      return "mw-badge badge badge-danger1"
    }else {
      return "mw-badge badge badge-warning"
    }
  };

  // Table datas
  const Items = ({ currentItems }) => {
    return (
      <>
        <Table hover responsive>
          <thead>
            <tr>
              <th className="">ID</th>
              <th className="">Customer GUID</th>
              <th className="text-end">Date</th>
              <th className="text-center">Type</th>
              <th className="text-end">Currency Pair</th>
              <th className="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems?.map((item, index) => (
                <tr key={item?.guid}>
                  <td style={{ whiteSpace: "nowrap" }}>{item?.guid}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {
                      <Link to={`/admin/customer/${item.customerGuid}`}>
                        <span className="text-primary fw-bold">
                          {item.customerGuid}
                        </span>
                      </Link>
                    }
                  </td>
                  <td style={{ whiteSpace: "nowrap" }} className="text-end text-capitalize">
                    {item.createAtDaysFormat}
                  </td>
                  <td className="text-center">
                    <span className={`${item.type === 'buy' ? 'mw-badge badge badge-primary' : 'mw-badge badge badge-danger'}`}>
                      {item.type}
                    </span>
                  </td>
                  <td style={{ whiteSpace: "nowrap" }} className="text-end">
                    {
                      <span>
                        <span className="text-primary fw-bold">{`${item.purchaseQuantity}`}</span>{" "}
                        {`${item.sign}`} = $
                        <span className="text-primary fw-bold">{`${(item.purchasePrice)?.toFixed(2)}`}</span>{" "}
                        USD
                      </span>
                    }
                  </td>
                  <td style={{ whiteSpace: "nowrap" }} className="text-center">
                    <span className={setClassName(item.state)}>
                      {item.state}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </>
    );
  };


  return (
    <>
      <AdminHeader />
      <AdminSidebar />

      <div className="content-body dash-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Trades List</h4>
                </div>
                {
                  tradesList?.length > 0 && (
                    <div className="row align-items-center filterset mx-0">
                      <div className="col-xl-3 col-lg-6 col-md-6 col-12 my-3">
                        <Form.Group className="w-100">
                          <Form.Control type="text" placeholder="ID" onChange={(e) => setGuid(e.target.value)} />
                        </Form.Group>
                      </div>

                      <div className="col-xl-3 col-lg-6 col-md-6 col-12 my-3">
                        <Form.Group className="w-100">
                          <Form.Control type="text" placeholder="Customer GUID" onChange={(e) => setCustomerGuid(e.target.value)} />
                        </Form.Group>
                      </div>

                      <div className="col-xl-3 col-lg-6 col-md-6 col-12 my-3">
                        <select
                          style={{ padding: "9.5px", cursor: "pointer" }}
                          id="sortingDropdown"
                          className="form-select"
                          value={sortingOption}
                          onChange={(e) => setSortingOption(e.target.value)}
                        >
                          <option value="all">All</option>
                          <option value="buy">Buy</option>
                          <option value="sell">Sell</option>
                        </select>
                      </div>

                      <div className="col-xl-3 col-lg-6 col-md-6 col-12 my-3 text-center text-md-start">
                        <button className="btn btn-primary" onClick={() => updateShowModal()}>
                          Export to file
                        </button>
                      </div>
                    </div>
                  )
                }
                <div className="card-body py-0">
                  <div
                    className={`${isloader ? "d-block" : "d-none"
                      } justify-content-center d-flex align-item-center my-5 py-5`}
                  >
                    <Gifloader />
                  </div>
                  <div className={isloader ? "d-none" : "d-block"}>
                    {currentItems.length > 0 ? (
                      <div>
                        <Items currentItems={currentItems} />
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="Next"
                          onPageChange={handlePageClick}
                          pageCount={pageCount}
                          previousLabel="Previous"
                          containerClassName={
                            "pagination justify-content-center my-3"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          activeClassName={"active"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          forcePage={pageNum === 0 ? 0 : currentPage}
                        />
                      </div>
                    ) : (
                      <div className="text-center py-5 text-capitalize">
                        <p>No trades found.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        showModal && (
          <Modal centered show={showModal} className="tce-popup w250px fadeInDown">
            <div className="tce-ppcon ">
              <div className="tce-ppcls" onClick={() => setShowModal(false)}>
                <span>x</span>
              </div>

              <div className="getverified fadeInDown">
                <Modal.Title id="contained-modal-title-vcenter">
                  Download Options
                </Modal.Title>

                <Modal.Body className="p-3 p-md-4">
                  <div className="d-flex flex-row justify-content-center gap-2 gap-md-3">
                    <CSVLink data={dataModifiedForExport || []} headers={headers} className="btn btn-primary" onClick={() => handleExportToCSV()}>
                      <i className="fa fa-download fs-4 pe-2"></i>  CSV
                    </CSVLink>
                    <button className="btn btn-primary" onClick={() => handleExportXLSX()}><i className="fa fa-download fs-4 pe-2"></i>  XLSX</button>
                  </div>
                  <div className="text-center mt-3">
                    <small><strong>Note:</strong> Click the button above to initiate the export</small>
                  </div>
                </Modal.Body>
              </div>
            </div>
          </Modal>
        )
      }
    </>
  );
};

export default Trades;
