import React, { useState, useEffect, useRef } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useDispatch, useSelector } from "react-redux";
import { usePlaidLink } from 'react-plaid-link';
import { useNavigate } from "react-router-dom";
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import {
    commonError,
    commonSuccess,
    postExternalBankAccountAction,
    deleteExternalBankAccountAction,
    getExternalBankAccountListAction,
    resetPostExternalBankAccountAction,
    resetDeleteExternalBankAccountAction,
    resetgetExternalBankAccountListFailed,
    getExternalBankAccountWalletListAction,
    resetPostExternalBankAccountActionFailed,
    resetGetExternalBankAccountWalletListActionFailed,
    patchExternalBankAccountAction,
    resetPatchExternalBankAccountActionFailed,
    resetPatchExternalBankAccountAction,
    postWorkFlowsActionForPayment,
    getWorkFlowForPayment,
    resetGetWorkFlowForPayment,
    resetGetWorkFlowFailedForPayment,
    resetPostWorkflowsActionForPayment,
    resetPostWorkflowsActionFailedForPayment,
    identityVerificationAction,
    getIdentityVerificationList,
    getIdentityVerificationAction,
    resetGetIdentityVerificationAction,
    resetGetIdentityVerificationActionFailed,
    getSingleExternalBankAccountAction,
    resetGetSingleExternalBankAccountFailed,
    resetGetSingleExternalBankAccountSuccess

} from "../../actions/customerActions";
import { decryptPassword } from "../../utils/Encryption";
import Verifymodule from "../shared/verifymodule";
import ButtonLoader from "../layout/buttonLoader";
import Header2 from '../layout/header2';
import Sidebar from '../layout/sidebar';
import RegenerateCustomerToken from "../shared/regenerateCustomerToken";
import { config } from '../../config';
import Persona from 'persona';


function PaymentMethod() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    let tempToken = sessionStorage.getItem("guid");
    let deToken = decryptPassword(tempToken);

    
    const postWorkflow = useSelector((state) => state.customerReducer.postWorkflowDataForPayment);
    const postWorkflowFailed = useSelector((state) => state.customerReducer.postWorkflowForPaymentFailed);
    const getWorkflowData = useSelector((state) => state.customerReducer.getWorkflowDataForPayment);
    const getWorkflowDataFailed = useSelector((state) => state.customerReducer.getWorkflowForPaymentFailed);
    const postExternalBankAccount = useSelector((state) => state.customerReducer.postExternalBankAccountData);
    const postExternalBankAccountFailed = useSelector((state) => state.customerReducer.postExternalBankAccountFailed);
    const patchExternalBankAccount = useSelector((state) => state.customerReducer.patchExternalBankAccountData);
    const patchExternalBankAccountFailed = useSelector((state) => state.customerReducer.patchExternalBankAccountFailed);
    const getExternalBankList = useSelector((state) => state.customerReducer.getExternalBankAccountListData);
    const getExternalBankListFailed = useSelector((state) => state.customerReducer.getExternalBankAccountListFailed);
    const deleteExternalBankAccount = useSelector((state) => state.customerReducer.deleteExternalBankAccountData);
    const deleteExternalBankAccountFailed = useSelector((state) => state.customerReducer.deleteExternalBankAccountFailed);
    const getIdentityVerifyListSuccess = useSelector((state) => state.customerReducer.getIdentityVerifyListData);
    const postIdentityVerify = useSelector((state) => state.customerReducer.postIdentityVerifyData);
    const getIdentityVerify = useSelector((state) => state.customerReducer.getIdentityVerifyData);
    const getIdentityVerifyFailed = useSelector((state) => state.customerReducer.getIdentityVerifyFailed)
    const getSingleExternalBankAccount = useSelector((state) => state.customerReducer.getSingleExternalBankAccountData)
    const getSingleExternalBankAccountFailed = useSelector((state) => state.customerReducer.getSingleExternalBankAccountFailed)


    const plaidDivRef = useRef(null);
    const [btnLoad, setBtnLoad] = useState(false);
    const [sPaymethod, setSPM] = useState(false);
    const [plaidToken, setPlaidToken] = useState('');
    const [externalBankList, setExternalBankList] = useState([]);
    const [currBankGuid, setCurrBankGuid] = useState('');
    const [isWorkFlowStarted, setIsWorkFlowStarted] = useState(false);
    const [verificationList, setVerificationList] = useState([]);
    const [submitBtn, setSubmitBtn] = useState(false);
    const [newExternalBankAccGuid, setNewExternalBankAccGuid] = useState('')
    const singleExternalBankAccInterval = useRef()
    

    useEffect(() => {
        dispatch(getExternalBankAccountListAction(sessionStorage.at, deToken))
        dispatch(getExternalBankAccountWalletListAction(sessionStorage.at))
        dispatch(getIdentityVerificationList(sessionStorage.at, deToken));
    }, [])

    useEffect(() => {
        if (postWorkflow && Object.keys(postWorkflow).length !== 0) {
            dispatch(getWorkFlowForPayment(sessionStorage.at, postWorkflow.guid));
            dispatch(resetPostWorkflowsActionForPayment());
        }
    }, [postWorkflow]);

    useEffect(() => {
        if (Object.keys(postWorkflowFailed).length !== 0) {
            let errObj = postWorkflowFailed;
            if (errObj && errObj.data && errObj.data.error_message) {
                dispatch(commonError(errObj.data.error_message))
                dispatch(resetPostWorkflowsActionFailedForPayment())
                setIsWorkFlowStarted(false);
            }
        }
    }, [postWorkflowFailed]);

    useEffect(() => {
        if (getWorkflowData && Object.keys(getWorkflowData).length !== 0) {
            if(getWorkflowData.state === "storing"){
                setTimeout(() => {
                  dispatch(getWorkFlowForPayment(sessionStorage.at, getWorkflowData.guid));
                }, 3000);
              }else {
                setPlaidToken(getWorkflowData.plaid_link_token);
              }
            dispatch(resetGetWorkFlowForPayment());
        }
    }, [getWorkflowData]);

    useEffect(() => {
        if (Object.keys(getWorkflowDataFailed).length !== 0) {
            let errObj = getWorkflowDataFailed;
            if (errObj && errObj.data && errObj.data.error_message) {
                dispatch(commonError(errObj.data.error_message))
                dispatch(resetGetWorkFlowFailedForPayment())
                setIsWorkFlowStarted(false);
        }
        }
    }, [getWorkflowDataFailed]);

    useEffect(() => {
        if (plaidToken) {
            setTimeout(() => {
                plaidDivRef.current.click();
    
                // Set isWorkFlowStarted based on its current value
                setIsWorkFlowStarted((workFlow) => {
                    if (workFlow === true) {
                        return false;
                    }
                    return workFlow;
                });
            }, 500);
        }
    }, [plaidToken]);
    
    useEffect(() => {
        if (Object.keys(patchExternalBankAccount).length !== 0) {
            setPlaidToken(patchExternalBankAccount.plaid_link_token);
            dispatch(resetPatchExternalBankAccountAction());
            dispatch(getExternalBankAccountListAction(sessionStorage.at, deToken))
        }
    }, [patchExternalBankAccount]);

    useEffect(() => {
        if (postIdentityVerify && postIdentityVerify.guid) {
            if(postIdentityVerify?.type === "kyc" && postIdentityVerify?.method === "document_submission") {
                dispatch(getIdentityVerificationAction(sessionStorage.at, postIdentityVerify.guid));
            } else {
                dispatch(getIdentityVerificationList(sessionStorage.at, deToken));
            }
             // dispatch(resetIdentityVerificationAction());
        }
    }, [postIdentityVerify])

    useEffect(() => {
        if (Object.keys(getIdentityVerify).length !== 0 && getIdentityVerify.persona_inquiry_id && getIdentityVerify?.method === "document_submission") {
            openpersona(getIdentityVerify.persona_inquiry_id);
            dispatch(resetGetIdentityVerificationAction());
        } else if (Object.keys(getIdentityVerify).length !== 0 && getIdentityVerify.persona_inquiry_id === null && getIdentityVerify?.method === "document_submission") {
            setTimeout(() => {
                dispatch(getIdentityVerificationAction(sessionStorage.at, getIdentityVerify.guid));
            }, 2000);
        }
    }, [getIdentityVerify])

    useEffect(()=> {
        if(getIdentityVerifyFailed && Object.keys(getIdentityVerifyFailed).length !== 0){
            setSubmitBtn(false);
            let errObj = getIdentityVerifyFailed;
            if (errObj && errObj.data && errObj.data.error_message) {
                dispatch(commonError(errObj.data.error_message));
                dispatch(resetGetIdentityVerificationActionFailed());
            }
        };
    },[getIdentityVerifyFailed]);

    useEffect(() => {
        if (getIdentityVerifyListSuccess && Object.keys(getIdentityVerifyListSuccess)?.length !== 0) {
            return setVerificationList(getIdentityVerifyListSuccess.objects)
        }
    }, [getIdentityVerifyListSuccess]);

    useEffect(() => {
        if (getExternalBankList && getExternalBankList.objects != undefined) {
            // List of bank accounts data 
            setExternalBankList((getExternalBankList.objects)?.filter(i => i.state != "deleted"));
            setIsWorkFlowStarted(false);
        }
    }, [getExternalBankList]);

    useEffect(() => {
        if (Object.keys(postExternalBankAccountFailed).length !== 0) {
            setIsWorkFlowStarted(false);
            let errObj = postExternalBankAccountFailed;
            if (errObj && errObj.data && errObj.data.error_message) {
                dispatch(commonError(errObj.data.error_message))
                dispatch(resetPostExternalBankAccountActionFailed())
            }
        }
    }, [postExternalBankAccountFailed]);

    useEffect(() => {
        if (Object.keys(patchExternalBankAccountFailed).length !== 0) {
            setIsWorkFlowStarted(false);
            let errObj = patchExternalBankAccountFailed;
            if (errObj && errObj.data && errObj.data.error_message) {
                dispatch(commonError(errObj.data.error_message))
                dispatch(resetPatchExternalBankAccountActionFailed())
            }
        }
    }, [patchExternalBankAccountFailed]);

    useEffect(() => {
        if (getExternalBankListFailed && Object.keys(getExternalBankListFailed).length !== 0) {
            setIsWorkFlowStarted(false);
            let errObj = getExternalBankListFailed
            if (errObj && errObj.data && errObj.data.error_message) {
                dispatch(commonError(errObj.data.error_message))
                dispatch(resetgetExternalBankAccountListFailed())
                if (errObj.data.error_message == "Signature has expired" || errObj.data.message_code == "authentication_failed") {
                    navigate("/logout");
                }
            }
        }
    }, [getExternalBankListFailed]);

    useEffect(() => {
        if (Object.keys(postExternalBankAccount).length !== 0) {
            dispatch(commonSuccess('Your Bank Account has been added successfully!'));
            setNewExternalBankAccGuid(postExternalBankAccount.guid)      
            dispatch(resetPostExternalBankAccountAction());
            dispatch(getExternalBankAccountListAction(sessionStorage.at, deToken));
        }
    }, [postExternalBankAccount]);

    useEffect(() => {
        singleExternalBankAccInterval.current = setInterval(() => {
            if(newExternalBankAccGuid !== '') {
                dispatch(getSingleExternalBankAccountAction(sessionStorage.at, newExternalBankAccGuid))
            }
        }, 5000)
        return () => clearInterval(singleExternalBankAccInterval.current)
    }, [newExternalBankAccGuid])

    useEffect(() => {
        if (getSingleExternalBankAccount && Object.keys(getSingleExternalBankAccount).length !== 0 && getSingleExternalBankAccount.state === 'unverified') {
            clearInterval(singleExternalBankAccInterval.current)
            const bankIdVerification = {
                type: 'bank_account',
                method: 'account_ownership',
                customer_guid: getSingleExternalBankAccount.customer_guid,
                external_bank_account_guid: getSingleExternalBankAccount.guid,
            };
            // Creating a bank account identity verification
            dispatch(identityVerificationAction(sessionStorage.at, bankIdVerification));
            dispatch(resetGetSingleExternalBankAccountSuccess());
        }
    }, [getSingleExternalBankAccount]);

    useEffect(() => {
        if (getSingleExternalBankAccountFailed && Object.keys(getSingleExternalBankAccountFailed).length !== 0) {
            let errObj = getSingleExternalBankAccountFailed
            if (errObj && errObj.data && errObj.data.error_message) {
                dispatch(commonError(errObj.data.error_message))
                dispatch(resetGetSingleExternalBankAccountFailed())
            }
        }
    }, [getSingleExternalBankAccountFailed]);

    useEffect(() => {
        if (Object.keys(deleteExternalBankAccount).length !== 0) {
            dispatch(commonSuccess("Your Bank Account has been removed successfully!"))
            dispatch(resetDeleteExternalBankAccountAction())
            dispatch(getExternalBankAccountListAction(sessionStorage.at, deToken))
        }
    }, [deleteExternalBankAccount]);

    useEffect(() => {
        if (Object.keys(deleteExternalBankAccountFailed).length !== 0) {
            let errObj = deleteExternalBankAccountFailed
            if (errObj && errObj.data && errObj.data.error_message) {
                dispatch(commonError(errObj.data.error_message))
                dispatch(resetGetExternalBankAccountWalletListActionFailed())
                dispatch(getExternalBankAccountListAction(sessionStorage.at, deToken))
            }
        }
    }, [deleteExternalBankAccountFailed]);


    const handleVerification = (account) => {
        const bankIdVerification = {
            "type": "bank_account",
            "method": "account_ownership",
            "customer_guid": account.customer_guid,
            "external_bank_account_guid": account.guid
        };
        dispatch(identityVerificationAction(sessionStorage.at, bankIdVerification));
    };

    // Submit additional identity documents
    const sumbitAdditionalDocuments = (guid) => {
        dispatch(getIdentityVerificationAction(sessionStorage.at, guid));
    };
        
    useEffect(() => {
        if (Object.keys(getIdentityVerify).length !== 0 && getIdentityVerify?.persona_inquiry_id && getIdentityVerify?.method === "account_ownership") {
            window.open(`https://withpersona.com/verify?inquiry-id=${getIdentityVerify.persona_inquiry_id}`)
            dispatch(resetGetIdentityVerificationAction())
        }
    }, [getIdentityVerify])

    const openpersona = (inquiryid) => {
        const client = new Persona.Client({
            // This refers to a production demo template owned by Persona
            inquiryId: inquiryid,
            environment: config.PERSONA_ENVIRONMENT,
            onComplete: ({ inquiryId, status, fields }) => {
                setSubmitBtn(false);
                dispatch(commonSuccess("Document submitted successfully"));
                dispatch(getIdentityVerificationList(sessionStorage.at, deToken));
            },
            onCancel: ({ inquiryId, sessionToken }) =>{
                setSubmitBtn(false);
                dispatch(getIdentityVerificationList(sessionStorage.at, deToken));
            },
            onError: (error) => {
                setSubmitBtn(false);
                console.log(error)
            },
        });
        client.open();
    }

    const handleSPM = () => setSPM(true);
    const handleSPMClose = () => setSPM(false);
    const startConnectBank = () => {
        console.log("startConnectBank triggered");
        const postwork = {
            "type": "plaid",
            "kind": "link_token_create",
            "language": "en",
            "customer_guid": deToken,
            "link_customization_name": "default"
        };
        dispatch(postWorkFlowsActionForPayment(sessionStorage.at, postwork));
    };
    const startReconnectBank = (bankguid) => {
        console.log("startReconnectBank triggered");
        const postwork = {
            "type": "plaid",
            "kind": "link_token_update",
            "language": "en",
            "customer_guid": deToken,
            "link_customization_name": "default",
            "external_bank_account_guid":bankguid
        };
        setIsWorkFlowStarted(true);
        dispatch(postWorkFlowsActionForPayment(sessionStorage.at, postwork));
    };

    const connectBank = () => {
        // console.log("connectBank triggered");
        if(btnLoad) {
            return;
        }
        setBtnLoad(true);
        // handleSPMClose();
        startConnectBank();
    };
    const reconnectBank = (v) => {
        // console.log("reconnectBank triggered");
        setCurrBankGuid(v)
        setBtnLoad(true);
        // handleSPMClose();
        startReconnectBank(v);
    };

    const handleBankDelete = (guid) => dispatch(deleteExternalBankAccountAction(sessionStorage.at, guid));

    const onLinkSuccess = (public_token, metadata) => {
        // console.log("onLinkSuccess triggered");
        let bankaccount;
        setIsWorkFlowStarted(true);
        if (metadata?.account?.id) {
            bankaccount = {
                name: "Plaid Checking",
                account_kind: "plaid",
                customer_guid: deToken,
                asset: "USD",
                plaid_public_token: public_token,
                plaid_account_id: metadata?.account?.id,
                plaid_institution_id: metadata?.institution?.institution_id,
                plaid_account_mask: metadata?.account?.mask,
                plaid_account_name: metadata?.account?.name,
            };
            dispatch(postExternalBankAccountAction(sessionStorage.at, bankaccount));
        } else {
            dispatch(patchExternalBankAccountAction(sessionStorage.at, currBankGuid, "completed"))
            setCurrBankGuid("")
        }
        setBtnLoad(false);
    };
    
    const configs = {
        onSuccess: onLinkSuccess,
        onExit: (err, metadata) => {
            // console.log("onExit triggered");
            // console.log("Error: ", err);
            // console.log("Metadata: ", metadata);
            setBtnLoad(false);
        },
        onEvent: (eventName, metadata) => {
            // console.log("onEvent triggered");
            // console.log("Event Name: ", eventName);
            // console.log("Metadata: ", metadata);
        },
        token: plaidToken
    };

    const { open, exit, ready } = usePlaidLink(configs);
    
    const openplaid = () => {
        // console.log("openplaid triggered");
        open();
    };

    const badgeClasses = {
        reviewing: "badge-warning",
        passed: "badge-success",
        failed: "badge-danger1",
        storing: "badge-warning",
        waiting: "badge-warning",
        pending: "badge-warning",
        expired: "badge-danger1",
        processing: "badge-warning",
    };
    
    const getBadge = (outcome, state) => {
        if (outcome) {
            if (outcome === "passed" || outcome === "failed") {
                return <span className={`mw-badge badge ${badgeClasses[outcome]}`}>{outcome}</span>;
            }
        }
    
        if (state) {
            if (state === 'reviewing' || state === 'storing' || state === 'waiting' || state === 'pending' || state === 'expired') {
                return <span className={`mw-badge badge ${badgeClasses[state]}`}>{state}</span>;
            }
            return <span className="mw-badge badge badge-warning">Processing</span>;
        }
    
        return null;
    };
    
    const renderButton = (tooltipText, iconClass, onClick, isDisabled) => (
        <OverlayTrigger overlay={<Tooltip>{tooltipText}</Tooltip>}>
            <button disabled={isDisabled} className="btn btn-light btn-sm btn-icon btn-icon-add mx-1" onClick={onClick}>
                <i className={iconClass}></i>
                <ButtonLoader />
            </button>
        </OverlayTrigger>
    );
    

    return (
        <>
            <RegenerateCustomerToken/>
            <Header2 />
            <Sidebar />
            <div className="content-body dash-main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8">
                                <div className="card">
                                    <div className="card-header d-block">
                                        <div className="row align-items-center pay_head">
                                            <div className="col-md-6">
                                        <h4 className="card-title">
                                            Bank Accounts{" "}
                                        </h4>
                                        </div>
                                       
                                        <div className="col-md-6">
                                        {externalBankList && externalBankList.length > 0 ?
                                            <div className="toprhtbtns">
                                                <button type="button" name="submit" className={`btn btn-primary btn-loader ${btnLoad ? "active" : ""}`} onClick={() => connectBank()}>
                                                    <span>Add payment method</span>
                                                    <ButtonLoader />
                                                </button>
                                                </div> :''}
                                                </div>
                                                </div>
                                    </div>
                                    <div className="card-body py-0">
                                    {externalBankList && externalBankList.length > 0 ? (
                                        // <div className="transaction-table">
                                        <div className="table-responsive">
                                            <table className="table mb-0 table-responsive-sm">
                                                <thead>
                                                    <tr>
                                                        <th>Asset</th>
                                                        <th>Bank Account</th>
                                                        <th>State</th>
                                                        <th>Identity Verification</th>
                                                        <th>Action</th>
                                                        {/* <th>Created At</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {externalBankList && externalBankList.map((account, index) => {
                                                        // Find the verification outcome for the current account
                                                        const verificationOutcome = verificationList?.filter(item => item.type === 'bank_account').find(item => item.external_bank_account_guid === account.guid);
                                                        return (
                                                            <tr key={index}>
                                                            <td>
                                                                {account.asset}
                                                            </td>
                                                            <td>
                                                                {account.plaid_institution_id ?
                                                                    (<span className="text-nowrap">
                                                                        {account.plaid_institution_id} - {account.plaid_account_name} ({account.plaid_account_mask})
                                                                    </span>)
                                                                    :
                                                                    <p className="mb-0">Not available</p>
                                                                }
                                                            </td>
                                                            <td>
                                                                {account.state === "completed" ? (                                                                 
                                                                        <span className="mw-badge badge badge-success">
                                                                            {account.state}
                                                                        </span>
                                                                    ) : (account.state === "failed" || account.state === "deleting" || account.state === "deleted") ? (
                                                                        <div className="d-flex flex-column gap-1">
                                                                            <span className="mw-badge badge badge-danger1">
                                                                                {account.state}
                                                                            </span>

                                                                            <span className="mw-badge badge badge-danger1">
                                                                                {account.failure_code}
                                                                            </span>
                                                                        </div>

                                                                    ) :  (account.state === "refresh_required") ? (
                                                                        <span className="mw-badge badge badge-warning w-auto">
                                                                        {account.state.replace(/_/g, ' ')}
                                                                        </span>
                                                                    ):(
                                                                        <span className="mw-badge badge badge-warning">
                                                                            {account.state}
                                                                        </span>
                                                                    
                                                                )}
                                                            </td>

                                                            <td>
                                                                <div className={verificationOutcome && (verificationOutcome.outcome === "failed" || ["waiting", "pending", "expired"].includes(verificationOutcome.state)) ? "d-flex align-items-center" : ""}>
                                                                    {verificationOutcome ? getBadge(verificationOutcome.outcome, verificationOutcome.state) : null}

                                                                    {verificationOutcome === undefined && account.state === 'completed' && <span className="mw-badge badge badge-warning">Not Required</span>}

                                                                    {verificationOutcome === undefined && account.state === 'unverified' &&
                                                                        renderButton("Start Verification", "las la-redo-alt", () => handleVerification(account))
                                                                    }

                                                                    {verificationOutcome && ["waiting", "pending"].includes(verificationOutcome.state) &&
                                                                        renderButton("Submit additional documents", "las la-upload", () => sumbitAdditionalDocuments(verificationOutcome.guid))
                                                                    }

                                                                    {verificationOutcome && (verificationOutcome.outcome === "failed" && verificationOutcome.state === "completed" || verificationOutcome.state === "expired") &&
                                                                        renderButton("Verify Again", "las la-redo-alt", () => handleVerification(account))
                                                                    }
                                                                </div>

                                                                <div className="d-flex gap-1 mt-1">
                                                                    {verificationOutcome && ["waiting", "pending"].includes(verificationOutcome.state) && (
                                                                        <span className="badge badge-danger1" style={{width: 'fit-content'}}>Please upload document within 24 hours</span>
                                                                    )}
                                                                    {verificationOutcome && verificationOutcome.outcome === 'failed' && (
                                                                        verificationOutcome?.failure_codes.map((code, i) => <div key={i} className="badge badge-danger1 text-capitalize" style={{width: 'fit-content'}}>{code.split('_').join(' ')}</div>)
                                                                    )}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className={account.state === "refresh_required" ? "d-flex" : ""}>
                                                                    {renderButton('Delete', 'las la-trash', () => handleBankDelete(account.guid), isWorkFlowStarted)}
                                                                    {account.state === "refresh_required" && renderButton('Reconnect', 'las la-redo-alt', () => reconnectBank(account.guid), isWorkFlowStarted)}
                                                                </div>
                                                            </td>

                                                            {/* <td>
                                                                {new Date(account.created_at).toLocaleString(undefined, {
                                                                    year: "numeric",
                                                                    month: "short",
                                                                    day: "numeric",
                                                                    hour: "numeric",
                                                                    minute: "numeric",
                                                                    hour12: true,
                                                                })}
                                                            </td> */}
                                                        </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    // </div>
                                    )
                                    : (
                                        <div className="smplistnolist">
                                    <div className="spm-icon ciricon"><i className="fa fa-bank"></i></div>
                                    <div className="spm-content">
                                        <h4>Add a payment method</h4>
                                        <p className="mb-3 small">Get ready to trade</p>
                                        {/* <p className='text-primary'>Why this is important?</p> */}
                                        <div className="spm-icon">
                                        <button type="button" name="submit" className={`btn btn-primary btn-loader ${btnLoad ? "active" : ""}`} onClick={() => connectBank()}>
                                            <span>Add payment method</span>
                                            <ButtonLoader />
                                        </button>
                                        {/* <button type="button" name="submit" className="btn btn-primary" onClick={handleSPM}>Add payment method</button> */}
                                    </div>
                                    </div>
                                    </div>
                                    )
                                }        
                                <div ref={plaidDivRef} name="submit" className="d-none" onClick={openplaid} >plaid</div>
                                    </div>
                                </div>
                            </div>
                        <div className="col-md-4">
                            <Verifymodule />
                        </div>
                    </div>
                   
                </div>
            </div>

            <Modal centered show={sPaymethod} className="tce-popup fadeInDown">
                <div className="tce-ppcon ">
                    <div className="tce-ppcls" onClick={handleSPMClose}><span>x</span></div>

                        <div className="getverified fadeInDown">
                            <Modal.Title id="contained-modal-title-vcenter">
                                Select a payment method
                            </Modal.Title>

                            <ul className="list-unstyled smplist">
                                <li onClick={() => connectBank()}>
                                    <div className="spm-icon"><span className="mdi mdi-bank"></span></div>
                                    <div className="spm-content">
                                        <h4>Bank Account</h4>
                                        <p className="mb-2 small">Invest target amounts</p>
                                        <p>Use a bank account to buy crypto and deposit or withdraw cash. Buy orders process instantly.</p>
                                    </div>
                                    <div className="spm-icon"><i className="fa fa-angle-right"></i></div>
                                </li>
                                {/* <li className="p-3">
                                    <div className="spm-content w-100">                                        
                                        <p className="text-center small">Learn about our buy/sell fees <a href="" className="text-primary">here</a></p>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                </div>
            </Modal>
        </>
    )
}

export default PaymentMethod;