import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  postQuotesAction,
  postTransferAction,
  resetPostQuotesAction,
  resetPostTransferAction,
  resetGetTransfers,
  commonError,
  commonSuccess,
  resetpostQuoteFailed,
  resetpostTransferFailed,
  getCustomerAccountDetails,
  getExternalBankAccountWalletListAction
} from "../../actions/customerActions";
import { decryptPassword, formatDate } from "../../utils/Encryption";
import ButtonLoader from "../layout/buttonLoader";
import Gifloader from "../layout/gifloader";
import bitcoinLogo from "../../images/bitcoin.png";
import ethereumLogo from "../../images/ethereum.png";
import usdcEthereumLogo from "../../images/usdc.png";
import { Link } from "react-router-dom";
import { EmailNotification, createOtpCrypto, resetCreateOtpCrypto, verifyOtpCrypto, resetVerifyOtpCrypto } from "../../actions/authAction";
import { CountdownCircleTimer } from "react-countdown-circle-timer";


const Withdrawalscrypto = () => {
  const dispatch = useDispatch();
  let tempToken = sessionStorage.getItem("guid");
  let deToken = decryptPassword(tempToken);

  const getTransferQuote = useSelector((state) => state.customerReducer.postQuoteData);
  const getTransferQuoteFailed = useSelector((state) => state.customerReducer.postQuoteFailed);
  const postTransferData = useSelector((state) => state.customerReducer.postTransferData);
  const getTransferFailed = useSelector((state) => state.customerReducer.postTransferfailed);
  const getAccountDatais = useSelector((state) => state.customerReducer.getAccountData);
  const getExternalBankAccountWalletList = useSelector((state) => state.customerReducer.getExternalBankWalletAccountData);
  const createOtpValue = useSelector((state) => state.authReducer.createOtpCryptoData);
  const verifyOtpData = useSelector((state) => state.authReducer.verifyOtpCryptoData);


  const [isloader, setIsLoader] = useState(true);
  const [isGetQuote, setGQ] = useState(false);
  const [isGQview, setGQV] = useState(false);
  const [isOrderSub, setOS] = useState(false);
  const [cadAmount, setCadAmount] = useState("");
  const [quoteData, setQuoteData] = useState({});
  const [transferedData, setTransferedData] = useState({});
  const [isQuoteBtnLoading, setQuoteBtnLoading] = useState(false);
  const [isTransferBtnLoading, setTransferBtnLoading] = useState(false);
  const [externalWalletList, setExternalWalletList] = useState();
  const [walletOptionList, setWalletOptionList] = useState();
  const [accountList, setAccountList] = useState();
  const [coinDivider, setCoinDivider] = useState(100);
  const [currentTransAccout, setCurrentTransAccout] = useState();
  const [currentTransWallet, setCurrentTransWallet] = useState();
  const [seconds, setSeconds] = useState(0);
  const [seletedLogo, setSeletedLogo] = useState();
  const [activeExternalWallet, setActiveExternalWallet] = useState('');
  const [seletedWalletLogo, setSeletedWalletLogo] = useState();
  const [selectedObj, setSelectedObj] = useState({});
  const [accountDetails, setAccountDetails] = useState([]);
  const [createOTPState, setCreateOTPState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [otpString, setOtpString] = useState("");
  const [circleStarted, setCircleStarted] = useState(false);
  const [remainingOtpAttempts, setRemainingOtpAttempts] = useState(5);
  const [reqDataForQuote, setReqDataForQuote] = useState({})
  

  const inputRefs = useRef([]);
  let timeOutFunctionRef = useRef(null);

  useEffect(() => {
    dispatch(getExternalBankAccountWalletListAction(sessionStorage.at))
    return () => {
      // Clear Customet account details API setTimeOut time
      clearTimeout(timeOutFunctionRef.current);
    };
  }, []);

  useEffect(() => {
    // Decrease the seconds every second
    const timer = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);
    // Clear the interval and reset the timer when it reaches 0
    if (seconds === 0) {
      clearInterval(timer);
      // You can also perform any action you need when the timer reaches 0
    }
    if (seconds === 0 && isGetQuote && isGQview) {
      toggleGQ();
      setCreateOTPState(false);
    }
    return () => clearInterval(timer);
  }, [seconds]);

  // If the transfer quote failed
  useEffect(() => {
    if (Object.keys(getTransferQuoteFailed).length !== 0) {
      let errObj = getTransferQuoteFailed
      if (errObj.status === 422) {
        if (errObj.data.message_code == "invalid_quote_amount") {
          dispatch(commonError('Crypto value is below the configured threshold'));
        } else {
          dispatch(commonError(errObj.data.error_message))
        }
      }
      setQuoteBtnLoading(false);
      setIsLoading(false);
      setCreateOTPState(false);
      setGQV(false);
      setOtpValues(["", "", "", "", "", ""]);
      dispatch(resetpostQuoteFailed())
    }
  }, [getTransferQuoteFailed])

  // Check transfer failed
  useEffect(() => {
    if (Object.keys(getTransferFailed).length !== 0) {
      let errObj = getTransferFailed
      if (errObj.status === 422) {
        dispatch(commonError(errObj.data.error_message))
      }
      if (errObj.status === 500) {
        dispatch(commonError(errObj.data.error_message))
      }
      setTransferBtnLoading(false);
      dispatch(resetpostTransferFailed())
    }
  }, [getTransferFailed])

  useEffect(() => {
    if (getExternalBankAccountWalletList && getExternalBankAccountWalletList.objects != undefined) {
      if (Object.keys(getExternalBankAccountWalletList).length !== 0) {
        let accountLists = getExternalBankAccountWalletList.objects?.filter(e => e.state !== "deleted")?.map(account =>
        // Determine the logo based on the account asset property
        {
          let logo;
          if (account.asset === "BTC") {
            logo = bitcoinLogo
          } else if (account.asset === "ETH") {
            logo = ethereumLogo
          } else if (account.asset === "USDC") {
            logo = usdcEthereumLogo
          } 
          return {
            ...account,
            logo: logo,
          };
        }
        )
        setExternalWalletList(accountLists);
        !walletOptionList && setWalletOptionList(accountLists)
      }
      setIsLoader(false);
    }
  }, [getExternalBankAccountWalletList])

  useEffect(() => {
    if (getAccountDatais && Object.keys(getAccountDatais).length !== 0) {
      setAccountDetails(getAccountDatais);
      const modifiedData = getAccountDatais.objects
        ?.filter((account) => account.asset !== "USD")
        .map((account) => {
          let divider;
          let logo;
          let name;
          let symbol;
          // Determine the logo, name, symbol and divider based on the account asset property
          if (account.asset === "BTC") {
            logo = bitcoinLogo;
            name = "Bitcoin";
            symbol = "BTC";
            divider = 100000000;
          } else if (account.asset === "ETH") {
            logo = ethereumLogo;
            name = "Ethereum";
            symbol = "ETH";
            divider = 1000000000000000000;
          } else if (account.asset === "USDC") {
            logo = usdcEthereumLogo;
            name = "USDC";
            symbol = "USDC";
            divider = 1000000;
          } 

          // Add platform balance
          const platform_balance_million_units =
            account.platform_balance / divider;
            const platform_available_million_units =
            account.platform_available / divider;
          return {
            ...account,
            logo: logo,
            name: name,
            symbol: symbol,
            platform_balance: platform_balance_million_units,
            platform_available: platform_available_million_units
          };
        });
      setAccountList(modifiedData);
      setSelectedObj(modifiedData[0])
      setSeletedLogo(modifiedData[0].logo)
      if (externalWalletList) {
        const externalWalletObj = externalWalletList?.find(
          (currency) => currency.asset === modifiedData[0].symbol
        );
        if (externalWalletObj) {
          setSeletedWalletLogo(externalWalletObj.logo)
          setActiveExternalWallet(externalWalletObj.address)
          let modifiedList = externalWalletList?.filter(e => e.asset === modifiedData[0].symbol)
          setWalletOptionList(modifiedList)
        } else {
          setSeletedWalletLogo("")
          setActiveExternalWallet("")
          setWalletOptionList([])
        }
      }
    }
  }, [getAccountDatais]);

  
  useEffect(() => {
    if (getTransferQuote && getTransferQuote.deliver_amount && getTransferQuote.product_type === "crypto_transfer") {
      setSeconds(ExpiryTimeFinder(getTransferQuote))
      if (getTransferQuote.asset === "BTC") {
        setCoinDivider(100000000);
      } else if (getTransferQuote.asset === "ETH") {
        setCoinDivider(1000000000000000000);
      } else if (getTransferQuote.asset === "USDC") {
        setCoinDivider(1000000);
      } 
      toggleGQ();
      setCreateOTPState(false)
      setQuoteBtnLoading(false);
      setQuoteData(getTransferQuote);
      handleEmptyStates();
      dispatch(resetPostQuotesAction());
    }
  }, [getTransferQuote]);

  useEffect(() => {
    if (postTransferData && postTransferData.amount && postTransferData.transfer_type === "crypto") {
      toggleGQV();
      timeOutFunctionRef.current = setTimeout(() => {
        dispatch(getCustomerAccountDetails(sessionStorage.at, deToken));
      }, 6000);
      if (postTransferData && postTransferData.failure_code === null) {
        let PTD = postTransferData
        let ExAmount;
        let feeAmount;
        // If there is no error, compare it with post transfer data asset property and divide based on it
        if (PTD.asset === "BTC") {
          ExAmount = PTD.amount / 100000000;
          feeAmount = PTD.fee / 100000000;
        } else if (PTD.asset === "ETH") {
          ExAmount = PTD.amount / 1000000000000000000;
          feeAmount = PTD.fee / 1000000000000000000;
        } else if (PTD.asset === "USDC") {
          ExAmount = PTD.amount / 1000000;
          feeAmount = PTD.fee / 1000000;
        } 
        const externalWalletGuid = PTD?.destination_account.guid;
        const externalWallet = externalWalletList?.find(item => item.guid === externalWalletGuid);
        
        const transformedData = {
          guid: PTD.guid,
          transfer_type: PTD.transfer_type,
          customer_guid:PTD?.customer_guid,
          asset: PTD.asset,
          side: PTD.side,
          amount:  (ExAmount - feeAmount).toFixed(8),
          fee: feeAmount.toFixed(8),
          final_amt: (ExAmount + feeAmount).toFixed(8),
          external_wallet_address: externalWallet?.address,
          created_at: PTD.created_at,
          type: 'crypto-withdrawal', // Set type to 'fiat-withdrawal' for withdrawal
          user_name: localStorage.getItem('displayName'),
          user_email: sessionStorage.getItem('user_email'),
        };
        // dispatch(EmailNotification(transformedData))
        dispatch(commonSuccess("Crypto withdrawal was initiated successful! Your request is being processed."))
      }
      setTransferBtnLoading(false);
      setTransferedData(postTransferData);
      dispatch(resetPostTransferAction());
      dispatch(resetGetTransfers());
    }

  }, [postTransferData]);

  useEffect(() => {
    if (createOtpValue?.data) {
      const message = createOtpValue?.data?.message;
      dispatch(commonSuccess(message.charAt(0).toUpperCase() + message.slice(1)));
      setCreateOTPState(true);
      setCircleStarted(true);
      dispatch(resetCreateOtpCrypto());
    }
  }, [createOtpValue]);

  useEffect(() => {
    if (verifyOtpData?.data?.data) {
      const concatenatedString = Object.values(verifyOtpData.data.data).join("");
      if (concatenatedString !== "verified") {
        setIsLoading(false);
        dispatch(commonError("Please enter a valid OTP"));
        setRemainingOtpAttempts((prevAttempts) => prevAttempts - 1);
      } else if (concatenatedString === "verified") {
        dispatch(postQuotesAction(sessionStorage.at, reqDataForQuote));
        setRemainingOtpAttempts(5);
      }
      dispatch(resetVerifyOtpCrypto());
    }
  }, [verifyOtpData]);

  useEffect(() => {
    if (remainingOtpAttempts <= 0) {
      setOtpValues(["", "", "", "", "", ""]);
      setRemainingOtpAttempts(5);
      setCreateOTPState(false);
      setIsLoading(false);
      setQuoteBtnLoading(false);
      dispatch(commonError("You have exceeded the maximum number of OTP verification attempts."));
    }
  }, [remainingOtpAttempts])
  

  const toggleGQ = () => {
    setGQ(!isGetQuote);
    setGQV(!isGQview);
    setIsLoading(false);
  };
  const toggleGQV = () => {
    setGQV(!isGQview);
    setOS(!isOrderSub);
  };

  const toggleOS = () => {
    setOS(!isOrderSub);
    setGQ(!isGetQuote);
  };

  const handleCadAmountChange = (event) => {
    const inputValue = event.target.value;
    if (!/^\d*\.?\d*$/.test(inputValue)) {
      // Invalid input, handle error or ignore the input
      return;
    }
    setCadAmount(event.target.value);
  };

  const roundToDecimalPlaces = (number, decimalPlaces) => {
    const multiplier = 10 ** decimalPlaces;
    return Math.round(number * multiplier) / multiplier;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isQuoteBtnLoading) {
      return;
    }

    // Extract relevant form input values.
    const account_symbol = event.target.elements.account.value;
    const account_selectedIndex = event.target.elements.account.selectedIndex;
    const account_selectedOption = event.target.elements.account.options[account_selectedIndex];
    const account_guid = account_selectedOption.getAttribute('data-guid');

    const wallet = event.target.elements.wallet.value;
    const wallet_selectedIndex = event.target.elements.wallet.selectedIndex;
    const wallet_selectedOption = event.target.elements.wallet.options[wallet_selectedIndex];
    const wallet_guid = wallet_selectedOption && wallet_selectedOption.getAttribute('data-guid');
    const wallet_symbol = wallet_selectedOption && wallet_selectedOption.getAttribute('data-symbol');
    const amount = event.target.elements.amount.value;

    // Find the crypto account in the account details.
    const findCryptoAccount = accountDetails?.objects?.length > 0 && accountDetails?.objects.find((account) => account?.asset === account_symbol);
    const platFormBalance = findCryptoAccount?.platform_available;

    // Calculate the amount in the appropriate format based on the selected cryptocurrency.
    let ExAmount;
    if (account_symbol === "BTC") {
      ExAmount = roundToDecimalPlaces(parseFloat(amount) * 100000000, 8);
    } else if (account_symbol === "ETH") {
      ExAmount = roundToDecimalPlaces(parseFloat(amount) * 1000000000000000000, 18);
    } else if (account_symbol === "USDC") {
      ExAmount = roundToDecimalPlaces(parseFloat(amount) * 1000000, 6);
    }

    // Perform validation checks.
    if (amount === null || amount === "" || amount == 0) {
      dispatch(commonError("Please enter a valid amount"));
      return;
    }
    if (account_symbol !== wallet_symbol) {
      dispatch(commonError("Selected crypto account and wallet do not match"));
      return;
    }
    if (wallet === null || wallet === "") {
      dispatch(commonError("Please select a wallet"));
      return;
    }
    if (Number(platFormBalance) < Number(ExAmount)) {
      dispatch(commonError("Insufficient crypto account balance"));
      return;
    }

    // Set the current transaction account and wallet.
    setCurrentTransAccout(account_guid);
    setCurrentTransWallet(wallet_guid);

    // Prepare the request data for the quote.
    const reqData = {
      product_type: "crypto_transfer",
      asset: account_symbol,
      customer_guid: decryptPassword(sessionStorage.guid),
      deliver_amount: ExAmount,
      side: "withdrawal"
    };
    setQuoteBtnLoading(true);
    dispatch(createOtpCrypto('', deToken));
    setReqDataForQuote(reqData);
  };


  const postTrasfer = () => {
    if (isTransferBtnLoading) {
      return;
    }
    
    // Check if the time for the transaction has expired.
    if (seconds == 0) {
      dispatch(commonError('Transaction time has expired'));
      return;
    }
    // Check if a valid quote data is available.
    if (quoteData) {
      // Prepare the request data for the transfer.
      const reqData = {
        quote_guid: quoteData.guid,
        transfer_type: "crypto",
        source_account_guid: currentTransAccout,
        external_wallet_guid: currentTransWallet
      }
      setTransferBtnLoading(true);
      dispatch(postTransferAction(sessionStorage.at, reqData));
    }
  };

  const ExpiryTimeFinder = (data) => {
    const expiresAt = new Date(data.expires_at);
    const issuedAt = new Date(data.issued_at);
    const timeGapInMilliseconds = expiresAt - issuedAt;
    const seconds = Math.floor((timeGapInMilliseconds % (1000 * 60)) / 1000);

    if(seconds === 30) {
      return seconds - 10;
    } else {
      return seconds - 3;
    }
  }

  const handleCurrencyChange = (event) => {
    const selectedSymbol = event.target.value;
    const currObj = accountList.find(
      (currency) => currency.asset === selectedSymbol
    );
    setSelectedObj(currObj)
    setSeletedLogo(currObj.logo)
    setCadAmount("")
    const externalWalletObj = externalWalletList?.find(
      (currency) => currency.asset === selectedSymbol
    );
    if (externalWalletObj) {
      setSeletedWalletLogo(externalWalletObj.logo)
      setActiveExternalWallet(externalWalletObj.address)
      let modifiedList = externalWalletList?.filter(e => e.asset === selectedSymbol)
      setWalletOptionList(modifiedList)
    } else {
      setSeletedWalletLogo("")
      setActiveExternalWallet("")
      setWalletOptionList([])
    }
  }

  const handleWalletChange = (event) => {
    const selectedSymbol = event.target.value;
    const currObj = externalWalletList?.find(
      (currency) => currency.guid === selectedSymbol
    );
    setSeletedWalletLogo(currObj.logo)
    setActiveExternalWallet(currObj.address)
  }

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    const pastedValues = pastedData.split("").slice(0, otpValues.length);
    const updatedOtpValues = Array.from(otpValues);

    for (let i = 0; i < updatedOtpValues.length; i++) {
      if (pastedValues.length > 0) {
        updatedOtpValues[i] = pastedValues.shift();
      } else {
        updatedOtpValues[i] = "";
      }
    }
    setOtpValues(updatedOtpValues);
    setOtpString(updatedOtpValues.join(""));
  };
  
  const handleChange = (e, index) => {
    const { value } = e.target;
    const updatedOtpValues = [...otpValues];
    updatedOtpValues[index] = value;

    setOtpValues(updatedOtpValues);
    setOtpString(updatedOtpValues.join(""));

    // Move to the next input field if a digit is entered
    if (value !== "" && index < otpValues.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  // Sets max available balance
  const setMax = () => {
    let obj = selectedObj && accountList?.find(e => e.symbol === selectedObj.symbol)
    setCadAmount(obj.platform_available)
  }

  const handleEmptyStates = () => {
    setOtpValues(["", "", "", "", "", ""]);
  }
  
  const handleVerifyOtp = () => {
    if (isLoading) {
      return;
    }
    
    if (otpValues.some(value => value === "")) {
      dispatch(commonError("Please fill all the OTP fields"));
      return;
    }
    setIsLoading(true);
    dispatch(verifyOtpCrypto("", otpString , deToken));
  }


  return (
    <>
      {isloader ? (
        <>
          <div className=" justify-content-center d-flex align-item-center my-5 py-5">
            <Gifloader />
          </div>
        </>
      ) : (
        <>
          <form
            method="post"
            name="myform"
            className="currency_validate fadeInDown"
            onSubmit={handleSubmit}
          >
            <div className={createOTPState || isGQview || isOrderSub ? "d-none" : ""}>
              <div className="mb-3">
                <label className="me-sm-2">Select Crypto</label>
                <div className="input-group mb-3 igpcust">
                  <div className="input-group-prepend ">
                    <label className="input-group-text">
                      <img
                        src={seletedLogo}
                        alt=""
                        className="img-fluid"
                      />
                    </label>
                  </div>
                  <select
                    name="account"
                    className="form-control"
                    onChange={(e) => handleCurrencyChange(e)}
                    value={selectedObj.symbol}
                  >
                    {accountList?.map((item) => (
                      <option key={item.symbol} value={item.symbol} data-guid={item.guid} >
                        {`${item.symbol}`}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="text-end">
              <Link to="/external-wallet"
                  className={"text-link small"}
                >
                  <span className="mdi mdi-plus"></span> <span>Add External Wallet</span>
                </Link>
              </div>
              </div>
              
              
              
              <div className="mb-3">
                <div className="d-flex flex-row justify-content-between">
                  <label>Amount to withdraw</label>
                  <div className="text-md-end small" onClick={setMax}>
                  <span className="text-primary fw-bold cursor-pointer">Max {selectedObj && ((selectedObj?.platform_available > 0 && selectedObj?.asset === "USDC") ? (selectedObj?.platform_available).toFixed(4) : selectedObj?.platform_available)} {selectedObj && selectedObj?.asset}</span>
                  </div>
                </div>
                <div className="input-group mb-3 igpcust">
                  <input
                    type="text"
                    name="amount"
                    className="form-control"
                    onChange={handleCadAmountChange}
                    value={cadAmount}
                    onKeyPress={(e) => {
                      const key = e.key;
                      if (key !== "." && isNaN(Number(key))) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {/* <div className="input-group-append" onClick={setMax}>
                    <label className="input-group-text small cursor-pointer bg-light fw-normal">Max</label>
                  </div> */}
                  {/* <div className="btn btn-primary" > max</div> */}
                </div>

              </div>
              <div className="mb-3">
                <label className="me-sm-2">Select Wallet</label>
                <div className="input-group mb-3 igpcust">
                  <div className="input-group-prepend ">
                    <label className="input-group-text">
                      <img
                        src={seletedWalletLogo}
                        alt=""
                        className="img-fluid"
                      />
                    </label>
                  </div>
                  <select
                    name="wallet"
                    className="form-control"
                    onChange={(e) => handleWalletChange(e)}
                  >
                    {walletOptionList?.map((item, index) => (
                      <option key={index} value={item.guid} data-guid={item.guid} data-symbol={item.asset}>
                        {`${item.name}`}
                      </option>
                    ))}
                  </select>
                </div>
                {activeExternalWallet ?
                  <div className="m-0 shadow-none text-center txtsmall">
                    <div className="mb-0 cdwadd">
                      <span className="fw-bold">{activeExternalWallet}</span>
                    </div>
                  </div>
                  : ""
                }
              </div>
              <div className="d-flex align-items-center justify-content-center gap-3 text-center">
                <button
                  type="submit"
                  className={`btn btn-primary mt-3 btn-loader ${isQuoteBtnLoading ? "active" : ""}`}
                >
                  <span>Withdraw</span>
                  <ButtonLoader />
                </button>

                
              </div>
            </div>

            {createOTPState &&
              <div className={`${isGQview ? "d-none" : ""} row justify-content-center h-100 align-items-center`}>
                <div className="d-block fadeInDown optarea">
                  <div>
                    <div className="card-header justify-content-center border-0 flex-column">
                      <h2 className="mb-2 ggtimefont">Verification</h2>
                      <p className="mb-0 text-center px-5">
                        Please enter the 6-digit verification code that was sent to your Email.
                      </p>
                    </div>

                    <div className="d-flex align-items-center justify-content-center mb-1">
                      <CountdownCircleTimer
                        size={80}
                        isPlaying={circleStarted}
                        duration={80}
                        strokeWidth={10}
                        colors={['#1355d7', '#F7B801', '#FFA500', '#A30000']}
                        colorsTime={[60, 40, 20, 0]}
                        onComplete={() => {
                          setQuoteBtnLoading(false);
                          setCreateOTPState(false);
                        }}
                      >
                        {({ remainingTime }) => remainingTime}
                      </CountdownCircleTimer>
                    </div>

                    <div className="card-body d-flex align-items-center justify-content-center">
                      <div className="spm-icon logotpverify">
                        {otpValues.map((value, index) => (
                          <input
                            className="otp form-control"
                            type="text"
                            value={value}
                            onChange={(e) => handleChange(e, index)}
                            maxLength="1"
                            onPaste={handlePaste}
                            ref={(ref) => (inputRefs.current[index] = ref)}
                            key={index}
                          />
                        ))}
                      </div>
                    </div>

                    <div className="ppmethod" style={{border:"none", marginBottom:"0"}}></div>
                    <div className="d-flex align-items-center justify-content-center text-center">
                      <button
                        type="button"
                        className="btn btn-light"
                        style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
                        onClick={() => {
                          setQuoteBtnLoading(false);
                          setCreateOTPState(false);
                          setOtpValues(["", "", "", "", "", ""]);
                        }}
                        disabled={isLoading}
                      >
                        <span>Back</span>
                      </button>
                      <button
                        type="button"
                        className={`btn btn-primary btn-loader mx-3 my-1 ${isLoading ? "active" : ""
                          }`}
                        onClick={() => handleVerifyOtp()}
                      >
                        <span>Verify Otp</span>
                        <ButtonLoader />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className={isGQview && seconds > 0 ? "d-block fadeInDown" : "d-none"}>
              <div className="ppmethod">
                <ul className="list-unstyled smplist">
                  <li className="flex-column">
                    {/* <h4 className="fw-bold">Order Quote</h4> */}
                    <span className="small">
                      The order quote will expire in{" "}
                      <em className="quote-countdown">{seconds && seconds}</em>{" "}
                      seconds
                    </span>
                  </li>
                  <li className="flex-column">
                    <h4 className="fw-bold">Confirm Withdrawal </h4>
                  </li>
                  <li>
                    <div className="spm-content">Amount</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {quoteData &&
                          (quoteData.asset === "USDC" ? ((quoteData.deliver_amount + quoteData.fee) / coinDivider).toFixed(4) : ((quoteData.deliver_amount + quoteData.fee) / coinDivider).toFixed(8))
                        }
                      </span>{" "}
                      {quoteData.asset}
                    </div>
                  </li>
                  <li>
                    <div className="spm-content">Fee</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {quoteData && (quoteData.fee / coinDivider)}  
                      </span>{" "}
                      {quoteData.asset}
                    </div>
                  </li>
                  <li>
                    <div className="spm-content">Final Amount</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {quoteData &&
                          (quoteData.asset === "USDC" ? (quoteData.deliver_amount / coinDivider).toFixed(4) : (quoteData.deliver_amount / coinDivider).toFixed(8))}
                      </span>{" "}
                      {quoteData.asset}
                    </div>
                  </li>
                  <li>
                    <div className="spm-content">Withdraw Date</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {quoteData && formatDate(quoteData.issued_at)}
                      </span>
                    </div>
                  </li>
                  {/* <li>
                    <div className="spm-content">Fee</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {quoteData && (quoteData.network_fee/coinDivider).toFixed(8)} 
                      </span>{" "}
                      {quoteData.asset}
                    </div>
                  </li> */}
                  {/* <li>
                    <div className="spm-content">To</div>

                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {selectedBankObject &&
                          `${selectedBankObject.plaid_institution_id}-${selectedBankObject.plaid_account_name} (${selectedBankObject.plaid_account_mask})`}
                      </span>
                    </div>
                  </li> */}
                </ul>
              </div>
              <div className="text-center" style={{ cursor: isTransferBtnLoading ? "not-allowed" : "pointer" }}>
                <button
                  type="button"
                  className="btn btn-light me-3"
                  onClick={toggleGQ}
                  disabled={isTransferBtnLoading}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className={`btn btn-primary btn-loader ${isTransferBtnLoading ? "active" : ""
                    }`}
                  onClick={postTrasfer}
                  style={
                    isTransferBtnLoading ? { display: "inline-block" } : null
                  }
                >
                  <span>Confirm</span>
                  <ButtonLoader />
                </button>
              </div>
            </div>

            <div className={isOrderSub ? "d-block fadeInDown" : "d-none"}>
              <div className="ppmethod">
                <ul className="list-unstyled smplist">
                  <li className="flex-column">
                    <h4 className="fw-bold">Withdrawal Details </h4>
                  </li>

                  <li>
                    <div className="spm-content">State</div>
                    <div className="spm-icon text-primary fw-bold">
                      Processing
                    </div>
                  </li>
                  <li>
                    <div className="spm-content">Amount</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {transferedData &&
                          (transferedData.asset === "USDC" ? ((transferedData.estimated_amount + transferedData.fee) / coinDivider).toFixed(4) : ((transferedData.estimated_amount + transferedData.fee) / coinDivider).toFixed(8))
                        }
                      </span>
                      {transferedData.asset}
                    </div>
                  </li>
                  <li>
                    <div className="spm-content">Fee</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {transferedData && (transferedData.fee / coinDivider)}
                      </span>
                      {transferedData.asset}
                    </div>
                  </li>
                  <li>
                    <div className="spm-content">Final Amount</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {transferedData &&
                          (transferedData.asset === "USDC" ? (transferedData.estimated_amount / coinDivider).toFixed(4) : (transferedData.estimated_amount / coinDivider).toFixed(8))
                        }
                      </span>
                      {transferedData.asset}
                    </div>
                  </li>
                  <li>
                    <div className="spm-content">Withdraw Date</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {transferedData &&
                          formatDate(transferedData.created_at)}
                      </span>
                    </div>
                  </li>
                  {/* <li>
                    <div className="spm-content">Fee</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {transferedData && transferedData.network_fee / coinDivider}
                      </span>{" "}{transferedData.asset}
                    </div>
                  </li> */}
                  {/* <li>
                    <div className="spm-content">To</div>

                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {selectedBankObject &&
                          `${selectedBankObject.plaid_institution_id}-${selectedBankObject.plaid_account_name} (${selectedBankObject.plaid_account_mask})`}
                      </span>
                    </div>
                  </li> */}
                </ul>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={toggleOS}
                >
                  Done
                </button>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default Withdrawalscrypto;
