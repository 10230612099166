import React, { useState, useEffect } from "react";
import Gifloader from "../../layout/gifloader";
import { Link } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import {
   getAdminAccounts,
   getAdminAllCustomersList,
   getAdminTrades,
   resetGetAccountsFailed,
   resetGetAccountsSuccess,
   resetGetAllCustomerListFailure,
   resetGetAllCustomerListSuccess, 
   resetGetTradesSuccess
  } from "../../../actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import { commonError, commonSuccess, resetGetTradesFailed } from "../../../actions/customerActions";
import { findCadDecimal, renderAddress} from "../../../utils/Encryption";
import ReactPaginate from "react-paginate";
import { Form, InputGroup, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";


// Importing the relativeTime plugin to enable 'fromNow' functionality
dayjs.extend(relativeTime);


const CustomerAccounts = () => {
  const dispatch = useDispatch();
  let jwtToken = sessionStorage.getItem("a_at");


  const getAC = useSelector((state) => state.adminReducer.getAllCustomersList);
  const getACF = useSelector((state) => state.adminReducer.getAllCutomersListFailed);
  const getA = useSelector((state) => state.adminReducer.getAccounts);
  const getAF = useSelector((state) => state.adminReducer.getAccountsFailed);
  const getTrades = useSelector((state) => state.adminReducer.getTrades);
  const getTradesFailed = useSelector((state) => state.adminReducer.getTradesFailed);


  const [isloader, setIsLoader] = useState(true);
  const [customersList, setCustomersList] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [accountsList, setAccountsList] = useState([]);
  const [tradesList, setTradesList] = useState([]);
  const [guidSearch, setGuidSearch] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [copied, setCopied] = useState(false);
  

  let currentItems = accountsList;


  useEffect(() => {
    dispatch(getAdminAllCustomersList(jwtToken));
    dispatch(getAdminTrades(jwtToken));
    dispatch(getAdminAccounts(jwtToken))
  }, []);

  // Fetching the all customers list data
  useEffect(() => {
    if (getAC && getAC.data) {
      setCustomersList(getAC.data.data)
      dispatch(resetGetAllCustomerListSuccess())
    }
  }, [getAC]);
  
  // If all customers list data failed
  useEffect(() => {
    if (Object.keys(getACF).length !== 0) {
      let errObj = getACF

      // If there is an error
      if (errObj && errObj.data && errObj?.data?.message) {
        dispatch(commonError(errObj?.data?.message))
        dispatch(resetGetAllCustomerListFailure())
      }
    }
  }, [getACF]);

  // Fetching the all trades data
  useEffect(() => {
    if (Object.keys(getTrades).length !== 0) {
      let groupedData = {}
      getTrades.data.data?.forEach((item)=>{
        const customerGuid = item.customer_guid;
        const TradeCreatedAt = item.created_at

        // If there is no data for the particular customer id add the empty array
        if (!groupedData[customerGuid]) {
          groupedData[customerGuid] = { customer_guid: customerGuid,trades:[] };
        }
        // If there is a data for the all trade push the when it was created
        groupedData[customerGuid].trades.push(TradeCreatedAt);
      })
      const result = Object.values(groupedData);
      setTradesList(result);
      dispatch(resetGetTradesSuccess());
    }
  }, [getTrades]);

  // If the trades list failed
  useEffect(() => {
    if (Object.keys(getTradesFailed).length !== 0) {
      let errObj = getTradesFailed;
      if (errObj && errObj.data && errObj?.data?.message) {
        dispatch(commonError(errObj?.data?.message))
        dispatch(resetGetTradesFailed());
      }
    }
  }, [getTradesFailed]);

  // Fetch the customer accounts
  useEffect(() => {
    if (Object.keys(getA).length !== 0) {
      dispatch(resetGetAccountsSuccess());
      if(customersList) {
        const groupedData = {};
        getA.data?.forEach((item) => {
          const customerGuid = item.customer_guid;
          const asset = item.asset;
          const platform_balance = item.platform_balance;
            if (!groupedData[customerGuid]) {
              groupedData[customerGuid] = { customer_guid: customerGuid };
            }
            groupedData[customerGuid][asset] = platform_balance;
          });
          const result = Object.values(groupedData);

          // Find the name
          customersList?.forEach((customerItem) => {
            const customerGuid = customerItem.guid;
            const fullName = customerItem.fullName;
            const matchingObject = result.find(item => item.customer_guid === customerGuid);
            if (matchingObject) {
              matchingObject.name = fullName;
            }
          });

        // Find the last trade
        tradesList?.forEach((tradeItem) => {
          const customerGuid = tradeItem.customer_guid;
          const LastTrade = tradeItem?.trades[0];
          const matchingObject = result.find(item => item.customer_guid === customerGuid);
          if (matchingObject) {
            matchingObject.lastTraded = LastTrade;
          }
        });
        setAccountsList(result)
        setIsLoader(false);
      }
    }
  }, [getA]);
  
  // If the customer accounts data failed
  useEffect(() => {
    if (Object.keys(getAF).length !== 0) {
      let errObj = getAF;
      if (errObj && errObj.data && errObj?.data?.message) {
        dispatch(commonError(errObj?.data?.message))
        dispatch(resetGetAccountsFailed());
        setIsLoader(false);
      }
    }
  }, [getAF]);

  // Search function using guid and name
  useEffect(() => {
    const filteredListCopy = [...accountsList].filter((item) => {
      // Converting all the characters to lowercase, so it will avoid case-sensitive issues.
      // And removing the unnecessary white space before and after in the query.
      const guidSearchValue = guidSearch?.toLowerCase().trim();
      const nameSearchValue = nameSearch?.toLowerCase().trim();

      if(!item.customer_guid?.toLowerCase().includes(guidSearchValue)) {
        return false;
      }
      if(!item.name?.toLowerCase().includes(nameSearchValue)) {
        return false;
      }
      return true;
    });
    setFilteredList(filteredListCopy);
    setPageNum(0);
  }, [guidSearch, nameSearch, accountsList]);
  
  const copyaddress = (guid) => {
    const textArea = document.createElement('textarea'); // Create a new <textarea> element in the DOM.
    textArea.value = guid; // Set the value of the <textarea> to the provided 'guid'.
    document.body.appendChild(textArea); // Append the <textarea> element to the document's body.
    textArea.select(); // Select the content within the <textarea>.
    document.execCommand('copy'); // Execute the 'copy' command to copy the selected text to the clipboard.
    document.body.removeChild(textArea); // Remove the temporary <textarea> element from the document's body.
    dispatch(commonSuccess('Text copied to clipboard'));
    setCopied(true);

    // Reset the "Copied" text back to "Copy" after a delay (e.g., 2 seconds).
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }
  
  // Table datas
  const Items = ({ currentItems }) => {
    const filteredItem = currentItems.filter((item) => {
      // Converting all the characters to lowercase, so it will avoid case-sensitive issues.
      // And removing the unnecessary white space before and after in the query.
      const guidSearchValue = guidSearch?.toLowerCase().trim();
      const nameSearchValue = nameSearch?.toLowerCase().trim();
  
      if(!item.customer_guid?.toLowerCase().includes(guidSearchValue)) {
        return false;
      }
      if(!item.name?.toLowerCase().includes(nameSearchValue)) {
        return false;
      }
      return true;
    })
    
    return (
      <>
      {
        filteredItem?.length > 0 ? (
          <Table hover responsive>
            <thead>
              <tr>
                <th className="">GUID</th>
                <th className="">Full Name</th>
                <th className="text-end">Ethereum</th>
                <th className="text-end">Bitcoin</th>
                {/* <th className="text-end">Bitcoin Cash</th> */}
                {/* <th className="text-end">Litecoin</th> */}
                <th className="text-end">USDC</th>
                <th className="text-end">United States Dollar Balance</th>
                <th className="text-end">Last Trade</th>
              </tr>
            </thead>
            <tbody>
              {filteredItem &&
                filteredItem?.map((item, index) => (
                  <tr key={item?.customer_guid}>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      <Link
                        to={`/admin/customer/${item?.customer_guid}`}
                      >
                      </Link>
                      <span className="text-primary fw-bold">
                          {renderAddress(item?.customer_guid)}
                          <OverlayTrigger overlay={<Tooltip> {copied ? "Copied" : "Copy"}</Tooltip>}>
                            <span className="text-primary px-2" type="button" id="button-addon2" onClick={() => copyaddress(item.customer_guid)}>
                              <i className="fa fa-copy"></i>
                            </span>
                          </OverlayTrigger>
                        </span>
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }} className="text-capitalize">{item?.name}</td>
                    <td style={{ whiteSpace: 'nowrap' }} className="text-end">
                      <span className="text-primary fw-bold">
                        {item.ETH ?findCadDecimal(item.ETH, "ETH") : "-"}
                      </span> {item.ETH ? "ETH" : ""}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }} className="text-end">
                      <span className="text-primary fw-bold">
                        {item.BTC ?findCadDecimal(item.BTC, "BTC") : "-"}
                      </span> {item.BTC ? "BTC" : ""}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }} className="text-end">
                      <span className="text-primary fw-bold">
                        {item.USDC ?findCadDecimal(item.USDC, "USDC") : "-"}
                      </span> {item.USDC ? "USDC" : ""}
                    </td>
                    {/* <td style={{ whiteSpace: 'nowrap' }} className="text-end">
                      <span className="text-primary fw-bold">
                        {item.BCH ?findCadDecimal(item.BCH, "BCH") : "-"}
                      </span> {item.BCH ? "BCH" : ""}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }} className="text-end">
                      <span className="text-primary fw-bold">
                        {item.LTC ?findCadDecimal(item.LTC, "LTC") : "-"}
                      </span> {item.LTC ? "LTC" : ""}
                    </td> */}
                    <td style={{ whiteSpace: 'nowrap' }} className="text-end">
                      <span className="text-primary fw-bold">
                        {item.USD ?findCadDecimal(item.USD, "USD") : "-"}
                      </span> {item.USD ? "USD" : ""}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }} className="text-end text-capitalize">{item.lastTraded ? dayjs(item.lastTraded).fromNow() : "-"}</td>
                    {/* <td>{item.type}</td> */}
                    {/* <td style={{ whiteSpace: 'nowrap' }}> <span className={item.state === "verified" ? "mw-badge badge badge-success" : "mw-badge badge badge-danger"} >{item.state}</span></td> */}
                    {/* <td style={{ whiteSpace: 'nowrap' }}>{formatDate(item.Swagger_created_at)}</td> */}
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          <div className={`${
            isloader ? "d-none" : "d-block"
          } text-center mt-5`}>
            <p>No customers found.</p>
          </div>
        )
      }
      </>
    );
  }

  // Pagination Calculations
  const itemsPerPage = 20;
  const pageCount = Math.max(1, Math.ceil(filteredList.length / itemsPerPage)); // Ensure pageCount is at least 1
  const endOffset = pageNum + itemsPerPage;
  currentItems = filteredList.slice(pageNum, endOffset);

  // Changing the page number
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * itemsPerPage) % filteredList.length;
    setPageNum(newOffset);
  };


  return (
    <>
      <AdminHeader />
      <AdminSidebar />

      <div className="content-body dash-main ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Customers Accounts List</h4>
                </div>

                <div className={`row align-items-center filterset mx-0 ${isloader ? "d-none" : "d-flex"}`}>
                  <div className="col-lg-3 col-md-6 col-12 my-3"> 
                    <Form className="w-100">
                      <InputGroup>
                        <Form.Control 
                          type="text"
                          placeholder="Search Guid..."
                          value={guidSearch}
                          onChange={(e) => setGuidSearch(e.target.value)}
                          />
                      </InputGroup>
                    </Form>
                  </div>

                  <div className="col-lg-3 col-md-6 col-12 my-3">
                    <Form className="w-100">
                      <InputGroup>
                        <Form.Control 
                          type="text"
                          placeholder="Search Name..."
                          value={nameSearch}
                          onChange={(e) => setNameSearch(e.target.value)}
                          />
                      </InputGroup>
                    </Form>
                  </div>
                </div>

                <div className="card-body py-0">
                  <div
                    className={`${
                      isloader ? "d-block" : "d-none"
                    } justify-content-center d-flex align-item-center my-5 py-5`}
                  >
                    <Gifloader />
                  </div>
                
                  {accountsList && accountsList.length >= 0 ? (
                    <>
                      <Items currentItems={currentItems} />
                      <div className={isloader ? "d-none" : ""}>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={"Next"}
                          pageRangeDisplayed={20}
                          onPageChange={handlePageClick}
                          pageCount={pageCount}
                          previousLabel={"Previous"}
                          containerClassName={'pagination justify-content-center my-3'}
                          pageClassName={'page-item'}
                          pageLinkClassName={'page-link'}
                          activeClassName={'active'}
                          previousClassName={'page-item'}
                          previousLinkClassName={'page-link'}
                          nextClassName={'page-item'}
                          nextLinkClassName={'page-link'}
                          forcePage={pageNum === 0 ? 0 : currentPage}
                        />
                      </div>
                    </>
                  ) : (
                    <div className={`${isloader ? "d-none" : "d-block"} text-center mt-5`}>
                      <p>No customers found.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerAccounts;
