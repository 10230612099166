import React from "react";
import Header3 from "../layout/header3";
import Footer1 from "../layout/footer1";


function RealTimeQuotes() {
    return (
        <>
            <Header3 />
            <div className="explore sp-inners">
                <div className="banners">
                    <h2>Real Time Quotes</h2>
                </div>
                <div className="main">
                    <div className="container">
                        <div className="owfsec" id="market">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <p>Our commitment to providing you with the best possible cryptocurrency trading experience is unwavering. That's why we've partnered with multiple highly reputable liquidity providers to ensure that you consistently get the most competitive rates in the market.​</p>
                                </div>

                                <div className="col-md-4">
                                    <div className="card sshn">
                                        {/* <div className="sshnum">1</div> */}
                                        <div className="card-body ">
                                            <img src={require("./../../images/expo/expo2-1.png")} alt="" className="img-fluid" />
                                            <div className="section_subheading mb-0">

                                                <h4>Benefits</h4>
                                                <p>
                                                    Enhanced Rate Accuracy: By aggregating rates from multiple liquidity providers, we minimize the impact of isolated price fluctuations, ensuring you receive accurate and up-to-date pricing.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card sshn">
                                        {/* <div className="sshnum">2</div> */}
                                        <div className="card-body ">
                                            <img src={require("./../../images/expo/expo2-2.png")} alt="" className="img-fluid" />
                                            <div className="section_subheading mb-0">
                                                <h4>Reduced Slippage</h4>
                                                <p>
                                                    Multiple liquidity providers mean deeper order books and reduced slippage, allowing you to execute trades closer to the desired price.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card sshn">
                                        {/* <div className="sshnum">3</div> */}
                                        <div className="card-body ">
                                            <img src={require("./../../images/expo/expo2-3.png")} alt="" className="img-fluid" />
                                            <div className="section_subheading mb-0">
                                                <h4>Market Liquidity</h4>
                                                <p>
                                                    Access to multiple providers enhances overall market liquidity, making it easier to execute larger trades without causing significant price swings.
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className="col-md-4">
                                    <div className="card sshn">
                                        {/*      */}
                                        <div className="card-body ">
                                            <img src={require("./../../images/expo/expo2-4.png")} alt="" className="img-fluid" />
                                            <div className="section_subheading mb-0">
                                                <h4>Competitive Advantage​</h4>
                                                <p>
                                                    You gain a competitive edge in the market by capitalizing on the best available rates, which can maximize your trading profits.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="card sshn">
                                        {/*      */}
                                        <div className="card-body ">
                                            <img src={require("./../../images/expo/expo2-5.png")} alt="" className="img-fluid" />
                                            <div className="section_subheading mb-0">
                                                <h4>Risk Mitigation​</h4>
                                                <p>
                                                    Diversifying liquidity sources also enhances risk management, as it reduces dependency on a single provider and helps safeguard your assets.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="card sshn">
                                        <div className="card-body ">
                                            <p className="text-justify">In summary, our commitment to securing the best rates from multiple trusted liquidity providers is designed to empower you as an investor. You can expect accurate, competitive pricing, reduced slippage, and enhanced liquidity, all while diversifying your trading options and minimizing risk. Your success in the cryptocurrency market is our top priority.​</p>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer1 />
        </>
    );
}

export default RealTimeQuotes;
