import React, { useEffect, useState } from "react";
import { DropdownButton } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "../../../actions/authAction";

function AdminHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [email, setEmail] = useState("");


  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);


  // Logout function
  const handleLogout = () => {
    // Dispatch the logoutUser action
    dispatch(logoutUser());
    sessionStorage.removeItem("a_at");
    localStorage.removeItem("email");
    navigate("/admin/signin");
  };
  

  return (
    <>
      <div className="header dashboard ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <nav className="navbar navbar-expand-lg navbar-light px-0 justify-content-between">
                <div className="header-search d-flex align-items-center">
                  <Link className="brand-logo _desktop me-3" href="index.html">
                    <img
                      src={require("../../../images/logo-with-text.png")}
                      alt=""
                      width="150"
                    />
                  </Link>
                  <Link className="brand-logo _mobile me-3" href="index.html">
                    <img
                      src={require("../../../images/logo-icon.png")}
                      alt=""
                      width="30"
                    />
                  </Link>
                </div>

                <div className="dashboard_log my-2">
                  <div className="d-flex align-items-center">
                    <DropdownButton title="My Account" className="profile_log">
                      <div className="page_title-content dropdown-item flex-column align-items-start">
                        <span className="small">Welcome Back,</span>
                        <p className="m-0 text-primary fw-bold">Admin</p>
                      </div>

                      {/* <Link to={"/settings"} className="dropdown-item">
                        <i className="la la-cog"></i> Settings
                      </Link>
                      <Link to={"/payment-method"} className="dropdown-item">
                        <i className="la la-credit-card"></i> Payment Method
                      </Link>
                      <Link to={"/external-wallet"} className="dropdown-item">
                        <i className="las la-wallet"></i> External Wallet
                      </Link> */}

                      <div
                        onClick={handleLogout}
                        className="dropdown-item logout light-bg m-0 rounded-0"
                      >
                        <i className="la la-sign-out"></i> Logout
                      </div>
                    </DropdownButton>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminHeader;
