import React, { useEffect, useState } from 'react'
import Header3 from '../../layout/header3'
import Footer1 from '../../layout/footer1'
import AreaChart from '../../charts/area'
import { useDispatch, useSelector } from 'react-redux'
import { getBtcFiveYearsData, resetBtcFiveYearsData } from '../../../actions/customerActions'


const Bitcoin = () => {
  const [oneYearValue, setOneYearValue] = useState([])
  const BtcPricesForOneYear = useSelector((state) => state.customerReducer.getBTCFiveYearsData)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBtcFiveYearsData())
  }, [])

  useEffect(() => {
    if (BtcPricesForOneYear && BtcPricesForOneYear.length !== 0) {
      setOneYearValue(BtcPricesForOneYear)
      dispatch(resetBtcFiveYearsData())
    }
  }, [BtcPricesForOneYear])

  return (
    <>
      <Header3 />
      <div className='lear_page sp-inners'>
        {/* <div className='banners'>
          <h2>Learn</h2>
        </div> */}

        <div className='main'>
          <div className='container'>
            <div>
              <div className='txt'>
                <h3>How to Buy Bitcoin?</h3>
                <div className='d-flex align-items-center justify-content-center'>
                  <img
                    className='img-fluid py-4 w-100 learn-page-img'
                    src={require('../../../images/learn/how_to_buy_btc.png')}
                    alt=''
                  />
                </div>

                <h3>Buying Bitcoin?</h3>
                <p>
                  Purchasing a widely recognized cryptocurrency like Bitcoin can seem a bit tough,
                  especially for those accustomed to traditional financial instruments. The bright
                  side is that there exist numerous avenues for acquiring Bitcoin. With just an
                  account on a cryptocurrency exchange or service and a secure method to store your
                  assets, you can{' '}
                  <a
                    href='https://cryptoexperts.ca/how-to-buy-bitcoin-in-windsor/'
                    style={{ color: '#1355d7' }}
                  >
                    start buying Bitcoin
                  </a>
                  . Let’s discuss that in detail.
                </p>

                <h3>Where to Buy Bitcoin?</h3>
                <p>
                  Below are the three well-known methods available to buy Bitcoin. Have a look at
                  each and choose which you find the most convenient:
                </p>

                <ol>
                  <li>
                    <p className='mb-0'>
                      <b>1. Choose a Reputable Platform</b>
                    </p>
                    <p>
                      To buy Bitcoin from crypto exchanges, start by researching trustworthy and
                      reliable platforms like The Crypto Exchange! With a proven track record from
                      2017, The Crypto Exchange offers In-Person Over-the-Counter (OTC) service,
                      where guidance on buying and selling Bitcoin is provided.
                    </p>
                    <p>
                      In this process, the direct negotiation between the parties is involved in the
                      presence of an OTC broker. These services cater to high-net-worth individuals
                      and traders who wish to conduct large trades without impacting the market
                      price or who prefer a more personalized and private trading experience.
                    </p>
                  </li>

                  <li>
                    <p className='mb-0'>
                      <b>2. Peer-to-Peer Transaction</b>
                    </p>
                    <p>
                      This involves directly buying Bitcoin from individuals or sellers without
                      needing a centralized intermediary. In peer-to-peer transactions, you can save
                      yourself from paying transaction fees, but the chances of risk and scams can
                      be higher.
                    </p>
                  </li>

                  <li>
                    <p className='mb-0'>
                      <b>3. Exchange-traded Funds (ETFs)</b>
                    </p>
                    <p>
                      ETFs are another indirect way to own Bitcoin without directly owing the
                      underlying assets. Through ETFs, you can track the price of Bitcoin and trade
                      on traditional stock exchanges. You can easily buy or sell shares in the FTEs
                      through a brokerage account.
                    </p>
                  </li>
                </ol>

                <img
                  className='img-fluid py-4 w-100'
                  src={require('../../../images/learn/how_to_buy_tce.png')}
                  alt=''
                />
                <h3>How to Buy Bitcoin in Canada and the US?</h3>
                <p>
                  The best and most convenient way to buy Bitcoin in Canada or the US is through
                  “The Crypto Exchange” platform. The easy-to-use interface and expert guidance make
                  sure that your transactions are done in the right way. Let’s discuss how to start:
                </p>

                <ol>
                  <li>
                    <p className='mb-0'>
                      ● <b>Create an Account</b>
                    </p>
                    <p>
                      If you are new to the platform, getting started is easy! Simply sign up for a
                      free account. Fill in all your personal information like name, email, phone
                      number, etc, and create your new account.
                    </p>
                  </li>

                  <li>
                    <p className='mb-0'>
                      ● <b>Connect Your Payment Method</b>
                    </p>
                    <p>
                      After creating a new account or signing up to the existing one, link your bank
                      account or credit/debit card to your Crypto Exchange account to validate your
                      Identity. With this KYC linking, you can easily move money in and out of the
                      platform.
                    </p>
                  </li>

                  <li>
                    <p className='mb-0'>
                      ● <b>Buy and Sell Cryptocurrencies</b>
                    </p>
                    <p>
                      Once your account is set up and your payment method is connected, you can
                      start buying/selling Bitcoin and over 200 other cryptocurrencies via{' '}
                      <a href='https://thecryptowallet.io/' style={{ color: '#1355d7' }}>
                        The Crypto Wallet
                      </a>
                      . Just choose the amount you want to buy or sell, and you are good to go.
                    </p>
                  </li>
                </ol>

                <div className="py-4 px-0 px-md-5">
                  <AreaChart BtcPriceEachDay={oneYearValue} cryptoName={'Bitcoin'} />
                </div>

                <h3>Why Buy Bitcoin?</h3>
                <p>
                  Want to know more about Bitcoin? Let's find out why investing in Bitcoin is a good
                  idea. Here are the top reasons:
                </p>

                <ol>
                  <li>
                    <p className='mb-0'>
                      ● <b>Limited Supply</b>
                    </p>
                    <p>
                      The supply of Bitcoin is limited, i.e., there are only 21 million Bitcoins in
                      circulation, which gives it value as a store of value, similar to gold. This
                      rarity is one reason why buying Bitcoin is a good investment in the long run.
                    </p>
                  </li>

                  <li>
                    <p className='mb-0'>
                      ● <b>Transparency and Immutability</b>
                    </p>
                    <p>
                      All transactions related to cryptocurrencies are recorded on a public ledger
                      called the BLOCKCHAIN. So, anyone who values security and trustlessness in
                      financial systems can see the history of a Bitcoin and verify its
                      authenticity.
                    </p>
                  </li>

                  <li>
                    <p className='mb-0'>
                      ● <b>Faster and Cheaper Transactions </b>
                    </p>
                    <p>
                      For small transactions, Bitcoin can offer faster settlement times and
                      potentially lower fees than traditional international payments, which can be
                      slow and expensive due to intermediary banks.
                    </p>
                  </li>

                  <li>
                    <p className='mb-0'>
                      ● <b>Market Liquidity</b>
                    </p>
                    <p>
                      Bitcoin operates in a liquid marketplace, facilitating easy entry and exit for
                      investors. The availability of exchanges and substantial daily trading volumes
                      ensure smooth transactions and the ability to efficiently convert Bitcoin to
                      fiat currencies.
                    </p>
                  </li>
                </ol>
                <div className='d-flex align-items-center justify-content-center'>
                  <img
                    className='img-fluid py-4 w-100 learn-page-img'
                    src={require('../../../images/learn/btc_why_buy.png')}
                    alt=''
                  />
                </div>

                <h3>What Do I Need To Know To Protect My Bitcoins?</h3>
                <p>
                  Securing your Bitcoin requires vigilance because it acts like cash – if you lose
                  your private keys, you lose your Bitcoin. Here are some key points on how to
                  protect:
                </p>

                <ol>
                  <li>
                    <p className='mb-0'>
                      ● <b>Store Them Safely</b>
                    </p>
                    <p>
                      <b>Hardware wallets:</b> Hardware wallets are the most secure options for
                      Bitcoins and other cryptocurrencies storage. In this, your private keys are
                      stored offline on a physical device, significantly reducing the risk of
                      hacking.
                    </p>
                    <p>
                      <b>Software wallets:</b> These offer convenience but can be vulnerable to
                      online attacks. If you choose a software wallet, make sure it's from a
                      reputable source and ensure strong passwords.
                    </p>
                  </li>

                  <li>
                    <p className='mb-0'>
                      ● <b>Don't Share Passwords Or Encryption With Anyone</b>
                    </p>
                    <p>
                      Always use strong, unique passwords for your wallets or accounts you use to
                      access your Bitcoin. Consider seeking professional guidance on how to create
                      and store complex passwords securely.
                    </p>
                  </li>

                  <li>
                    <p className='mb-0'>
                      ● <b>Beware of Phishing Scams</b>
                    </p>
                    <p>
                      Don't open any suspicious links or download attachments from unknown senders
                      or sources. With these, phishing scams can try to trick you into revealing
                      your private keys.
                    </p>
                  </li>
                </ol>

                <p>
                  As the demand for cryptocurrency continues to evolve, it is better to stay
                  informed about market dynamics and adopt the best practices in asset protection if
                  you stay successful in this market. By doing so, investors can capitalize on the
                  opportunities presented by Bitcoin while reducing associated risks, thereby making
                  them eligible for long-term growth and financial security.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer1 />
    </>
  )
}

export default Bitcoin
