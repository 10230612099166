import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { decryptPassword } from '../../utils/Encryption';
import { getCustomer } from '../../actions/customerActions';


const CustomerVerificationPopup = () => {
    const dispatch = useDispatch();
    let tempToken = sessionStorage.getItem("guid");
    let deToken = decryptPassword(tempToken);
    const [verifyModal, setVerifyModal] = useState(false);
    const getCustomerDetails = useSelector((state) => state.customerReducer.getCustomerData);
    const handleVerifyModalOpen = () => setVerifyModal(true);


    useEffect(() => {
        dispatch(getCustomer(sessionStorage.at, deToken));
    }, []);

    useEffect(() => {
        if (getCustomerDetails.length !== 0) {
            if (getCustomerDetails.state !== "verified") {
                handleVerifyModalOpen();
                return;
            }
        }
    }, [getCustomerDetails]);


    return (
        <>
            <Modal centered show={verifyModal} className="tce-popup">
                <div className="tce-ppcon ">
                    <div className="getverified fadeInDown">
                        <Modal.Body className="text-center">
                            <p className="mb-0">Please verify your</p>
                            <h3 className="mb-4"> KYC information</h3>
                            <Link to="/dashboard">
                                <button className="btn btn-primary">Go To Dashboard</button>
                            </Link>
                        </Modal.Body>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CustomerVerificationPopup;