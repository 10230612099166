import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import Verifymodule from "../shared/verifymodule";
import { useDispatch, useSelector } from "react-redux";
import {
  getMarketPrice,
  resetGetCustomerAccountDetails,
  resetMarketPrice,
  commonError,
  resetgetAccountFailed,
  resetgetMarketPriceFailed,
  getExternalBankAccountListAction,
  resetgetExternalBankAccountListFailed,
  getExternalBankAccountWalletListAction,
  getCustomerAccountDetails,
  resetGetExternalBankAccountListAction,
} from "../../actions/customerActions";
import RecentMoneyTransfer from "../shared/recentMoneyTransfer";
import { decryptPassword } from "../../utils/Encryption";
import bitcoinLogo from "../../images/bitcoin.png";
import ethereumLogo from "../../images/ethereum.png";
import usdcEthereumLogo from "../../images/usdc.png";
import usdLogo from "../../images/usd.png";
import Gifloader from "../layout/gifloader";
import CustomerVerificationPopup from "../shared/CustomerVerificationPopup";
import RegenerateCustomerToken from "../shared/regenerateCustomerToken";


function Accounts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const marKetPriceInterval = 30 ; //30 seconds
  let livePriceInterval;
  let tempToken = sessionStorage.getItem("guid");
  let deToken = decryptPassword(tempToken);


  const getExternalBankAccountListData = useSelector((state) => state.customerReducer.getExternalBankAccountListData);
  const getExternalBankAccountListFailed = useSelector((state) => state.customerReducer.getExternalBankAccountListFailed);
  const getMarketPriceRes = useSelector((state) => state.customerReducer.getMarketPrice);
  const getMarketPriceResFailed = useSelector((state) => state.customerReducer.getMarketPriceFailed);
  const getAccountDatais = useSelector((state) => state.customerReducer.getAccountData);
  const getAccountDataisFailed = useSelector((state) => state.customerReducer.getAccountFailed);


  const [childMarketPrice, setchildMarketPrice] = useState([]);
  // const [bankAccounts, setBankAccounts] = useState([]);
  const [liveSellBTC, setLiveSellBTC] = useState();
  const [liveSellETH, setLiveSellETH] = useState();
  const [liveSellUSDC, setLiveSellUSDC] = useState();
  const [accountData, setAccountData] = useState([]);
  const [fiatAccount, setFiatAccount] = useState({});
  const [isloader, setIsLoader] = useState(true);
  const [usdPending, setUsdPending] = useState();

  // Initial API call when the component mounts
  useEffect(() => {
    dispatch(getMarketPrice(sessionStorage.at));
    livePriceInterval = setInterval(() => {
      dispatch(getMarketPrice(sessionStorage.at));
    }, marKetPriceInterval * 1000);
    dispatch(getCustomerAccountDetails(sessionStorage.at, deToken))
    dispatch(getExternalBankAccountWalletListAction(sessionStorage.at));
    dispatch(getExternalBankAccountListAction(sessionStorage.at, deToken))
    return () => {
      clearInterval(livePriceInterval);
    };
  }, [])

  // Get external bank account data
  useEffect(() => {
    if (getExternalBankAccountListData && getExternalBankAccountListData.objects) {
      // List of bank accounts data 
      // setBankAccounts(getExternalBankAccountListData.objects)
      dispatch(resetGetExternalBankAccountListAction())
    }
  }, [getExternalBankAccountListData]);

  // Get external bank account failed && trigger to logout
  useEffect(() => {
    if (Object.keys(getExternalBankAccountListFailed).length !== 0) {
      let errObj = getExternalBankAccountListFailed
      if (errObj && errObj.data && errObj.data.error_message) {
        dispatch(commonError(errObj.data.error_message))
        dispatch(resetgetExternalBankAccountListFailed())
        if (errObj.data.error_message == "Signature has expired" || errObj.data.message_code == "authentication_failed") {
          navigate("/logout");
        }
      }
    }
  }, [getExternalBankAccountListFailed])

  // Add logo, name, symbol & graphData and set live price  
  useEffect(() => {
    if (getMarketPriceRes.length !== 0) {
      const updatedMarketPrice = getMarketPriceRes.map((item) => {
        let logo;
        let name;
        let symbol;
        let graphData;
        if (item.symbol === "BTC-USD") {
          logo = bitcoinLogo;
          name = "Bitcoin";
          symbol = "BTC";
        } else if (item.symbol === "ETH-USD") {
          logo = ethereumLogo;
          name = "Ethereum";
          symbol = "ETH";
        } else if (item.symbol === "USDC-USD") {
          logo = usdcEthereumLogo;
          name = "USDC";
          symbol = "USDC";
        } 
        return {
          ...item,
          logo: logo,
          name: name,
          symbol: symbol,
          graphData: graphData,
        };
      });
      setchildMarketPrice(updatedMarketPrice);
      const bitUsdObject = getMarketPriceRes.find(
        (item) => item.symbol === "BTC-USD"
      );
      const ethUsdObject = getMarketPriceRes.find(
        (item) => item.symbol === "ETH-USD"
      );
      const usdcUsdObject = getMarketPriceRes.find(
        (item) => item.symbol === "USDC-USD"
      );

      setLiveSellBTC(bitUsdObject?.sell_price);
      setLiveSellETH(ethUsdObject?.sell_price);
      setLiveSellUSDC(usdcUsdObject?.sell_price);
      dispatch(resetMarketPrice());
    }
  }, [getMarketPriceRes]);

  // Get market price failed
  useEffect(() => {
    if (getMarketPriceResFailed && Object.keys(getMarketPriceResFailed).length !== 0) {
      let errObj = getMarketPriceResFailed
      if (errObj && errObj.data && errObj.data.error_message) {
        dispatch(commonError(errObj.data.error_message))
        dispatch(resetgetMarketPriceFailed())
      }
    }
  }, [getMarketPriceResFailed])

  // Modify account data for different assets (BTC, ETH, USDC)
  useEffect(() => {
    if (
      Object.keys(getAccountDatais).length !== 0 &&
      childMarketPrice.length !== 0
    ) {
      const modifiedData = getAccountDatais.objects.map((account) => {
        // Add divider, logo, name, symbol, and livePrice for each asset
        let divider;
        let logo;
        let name;
        let symbol;
        let livePrice;
        let usdAvailable;
        if (account.asset === "BTC") {
          logo = bitcoinLogo;
          name = "Bitcoin";
          symbol = "BTC";
          divider = 100000000;
          livePrice = liveSellBTC;
        } else if (account.asset === "ETH") {
          logo = ethereumLogo;
          name = "Ethereum";
          symbol = "ETH";
          divider = 1000000000000000000;
          livePrice = liveSellETH;
        } else if (account.asset === "USDC") {
          logo = usdcEthereumLogo;
          name = "USDC";
          symbol = "USDC";
          divider = 1000000;
          livePrice = liveSellUSDC;
        } else if (account.asset === "USD") {
          let balance;
          logo = usdLogo;
          name = "United States Dollar";
          symbol = "USD";
          balance = (account.platform_balance / 100)?.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
          usdAvailable = (account.platform_available / 100)?.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
          let usdPendingAmount = Number(account.platform_balance / 100) - Number(account.platform_available / 100);
          setUsdPending(usdPendingAmount);
          setFiatAccount({
            ...account,
            logo: logo,
            name: name,
            symbol: symbol,
            platform_balance: balance,
            balance_canadian: balance,
            platform_available: usdAvailable,
          })
        }
        const platform_balance_million_units =
          (account.platform_balance / divider);
        const platform_available_million_units =
        (account.platform_available / divider);

        const balance_canadian =
          ((platform_available_million_units * livePrice) / 100);
        
        return {
          ...account,
          logo: logo,
          name: name,
          symbol: symbol,
          platform_balance: platform_balance_million_units.toFixed(8),
          platform_available: platform_available_million_units.toFixed(8),
          balance_canadian: balance_canadian,
        };
      });

      // Calculate total Canadian balance from modified data
      const totalCanadianBalance = modifiedData
        .filter((asset) => asset.type !== "fiat")
        .reduce(
          (total, asset) => total + parseFloat(asset.balance_canadian),
          0
        );

      const assetsWithRatio = modifiedData.map((asset) => ({
        ...asset,
        ratio_percentage:
          asset.type === "fiat"
            ? 100
            : ((parseFloat(asset.balance_canadian) / totalCanadianBalance) * 100).toFixed(2),
      })).filter((asset) => asset.type !== "fiat");
      setAccountData(sortArrayBySymbol(assetsWithRatio));
      dispatch(resetGetCustomerAccountDetails());
      setIsLoader(false)
    }
  }, [getAccountDatais]);

  // Handle account data response failure and trigger logout if necessary
  useEffect(() => {
    if (Object.keys(getAccountDataisFailed).length !== 0) {
      let errObj = getAccountDataisFailed
      if (errObj && errObj.data && errObj.data.error_message) {
        dispatch(commonError(errObj.data.error_message))
        dispatch(resetgetAccountFailed())
        if (errObj.data.error_message == "Signature has expired" || errObj.data.message_code == "authentication_failed") {
          navigate("/logout");
        }
      }
    }
  }, [getAccountDataisFailed])


  // Sort account data 
  function sortArrayBySymbol(array) {
    const symbolOrder = ['USD', 'BTC', 'ETH', 'USDC'];
    return array.sort((a, b) => {
      const aSymbol = a.asset;
      const bSymbol = b.asset;
      const aIndex = symbolOrder.indexOf(aSymbol);
      const bIndex = symbolOrder.indexOf(bSymbol);
      return aIndex - bIndex;
    });
  };

  return (
    <>
    <RegenerateCustomerToken/>
      <Header2 />
      <Sidebar />
      <div className="content-body dash-main _assets">
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-md-4">
                        </div>
                        <div className="col-md-4">
                            <div className="card mh-370">
                                <div className="card-header">
                                    <h4 className="card-title">Trade</h4>

                                </div>
                                <div className="card-body p-0">
                                    <div className="buy-sell-widget dashtabs">
                                        <Tabs
                                            defaultActiveKey="deposit"
                                            id="uncontrolled-tab-example"
                                            className="mb-0"
                                        >
                                            <Tab eventKey="deposit" title="Deposit" >
                                                <Deposits bankAccount={bankAccounts} />

                                            </Tab>
                                            <Tab eventKey="withdraw" title="Withdraw">
                                                <Withdrawls bankAccount={bankAccounts} />

                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            <div className="col-md-8">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    Assets
                  </h4>
                </div>
                <div className="card-body py-0 assetcb">
                  {/* <div className="largecoinbox">
                    <div className="coin_icontxt p-0">
                      <img
                        src={require('./../../images/canada.png')}
                        alt=""
                        className="me-3"
                        width="40"
                      />
                      <div className="lcbtxt">
                        <p className="small mb-0">Available Balance</p>
                        <h3 className="fw-bold"><span className="small">$</span><span className="text-primary">{fiatAccount.platform_balance}</span> <span className="small">USD</span></h3>

                      </div>
                    </div>

                    <div className="txt-links atlinks">
                      <a href="" className="text-link">Deposit</a>
                      <a href="" className="text-link">Withdraw</a>
                    </div>



                  </div> */}

                  {isloader ? (
                    <>
                      <div className=" justify-content-center d-flex align-items-center my-5">
                        <div className="mt-5 pt-5">
                          <Gifloader />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="transaction-table assetstable">
                      <div className="table-responsive">
                        <table className="table mb-0 table-responsive-sm table-white">
                          <thead>
                            <tr>
                              <th>Currencies</th>
                              <th className="text-end">Available Balance</th>
                              <th className="text-end">Pending Balance</th>
                              <th className="text-end">USD Price</th>

                              <th className="text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="coin_icontxt p-0">
                                  <img
                                    src={require('./../../images/usd.png')}
                                    alt=""
                                    className="me-2"
                                    width="32"
                                  />
                                  <span>
                                  <strong>United States Dollar</strong> - <span className="small">USD</span>
                                  </span>
                                </div>
                              </td>
                              <td className="text-end">
                                <div className="lvtxtbox">Available Balance</div>
                                $<span className="text-primary p-0 fw-bold">{fiatAccount.platform_available}</span> <span className="small p-0">USD</span>
                                <div>
                                  {/* {Number(usdPending) > 0 && `$${usdPending.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )} USD (Pending)`} */}
                                </div>
                              </td>
                              <td className="text-end">
                                <div className="lvtxtbox">Pending</div>
                                $<span className="p-0">{

                                   usdPending?.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                }</span> <span className="small p-0">USD</span>
                                <div>
                                  {/* {Number(usdPending) > 0 && `$${usdPending.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )} USD (Pending)`} */}
                                </div>
                              </td>
                              <td className="text-end">
                                <div className="lvtxtbox">USD Price</div>
                                $<span className="text-primary p-0 fw-bold">{fiatAccount.platform_available}</span> <span className="small p-0">USD</span>
                                <div>
                                  {/* {Number(usdPending) > 0 && `$${usdPending.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )} USD (Pending)`} */}
                                </div>
                                </td>
                              <td><div className="txt-links">
                                <Link to="/deposits" className="text-link">Deposit</Link>
                                <Link to="/withdrawals" className="text-link">Withdraw</Link>
                              </div></td>
                            </tr>

                            {accountData?.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="coin_icontxt p-0">
                                    <img
                                      src={item.logo}
                                      alt=""
                                      className="me-2"
                                      width="32"
                                    />
                                    <span>
                                      <strong>{item.name}</strong> - <span className="small">{item.symbol}</span>
                                    </span>
                                  </div>
                                </td>
                                <td className="text-end">
                                  <div className="lvtxtbox">Available Balance</div>
                                  <span className="text-primary p-0 fw-bold">{item.platform_available > 0 ? (item.symbol === "USDC" ? (item.platform_available).toFixed(4) : item.platform_available) : '0.00'}</span> <span className="small p-0">{item.symbol}</span></td>
                                  <td className="text-end">
                                  <div className="lvtxtbox">Available Balance</div>
                                  <span className="p-0">{item.platform_balance - item.platform_available  > 0 ? (item.symbol === "USDC" ? (item.platform_balance - item.platform_available).toFixed(4) : (item.platform_balance - item.platform_available).toFixed(8)) : '0.00'}</span> <span className="small  p-0">{item.symbol}</span></td>
                                <td className="text-end">
                                  <div className="lvtxtbox">USD Price</div>
                                  <span className="small p-0">$</span>
                                  <span className="text-primary p-0 fw-bold">{item.balance_canadian > 0 ? item.balance_canadian?.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  ) : '0.00'}</span>
                                  <span className="small p-0"> USD</span>
                                </td>
                                <td>
                                  <div className="txt-links">
                                    <Link to="/deposits#crypto" className="text-link">Deposit</Link>
                                    <Link to="/withdrawals#crypto" className="text-link">Withdraw</Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <Verifymodule />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    All Activities
                  </h4>
                </div>
                <div className="card-body pt-0">
                  <RecentMoneyTransfer typeis={null} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomerVerificationPopup />
    </>
  );
}

export default Accounts;
