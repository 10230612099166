import { adminTypes } from "../store/constants/admintypes";
import apiSignUp from "../services/apiSignUp";
import apiAdminWithJwt from "../services/apiAdminWithJwt";


export const signInAdmin = (adminData) => {
    return (dispatch) => {
        apiSignUp
          .post("admin/login", adminData)
          .then((res) => {
            dispatch({ type: adminTypes.POST_LOGIN_SUCCESS, payload: res?.data });
          })
          .catch((error) => {
            dispatch({ type: adminTypes.POST_LOGIN_FAILURE, payload: error });
          });
      };
};

export const getAdminAllCustomersList = (token) => {
    return (dispatch) => {
        const apiWithToken = apiAdminWithJwt(token)
        apiWithToken
          .get("admin/customers")
          .then((res) => {
            dispatch({ type: adminTypes.GET_ALL_CUSTOMERS_LIST_SUCCESS, payload: res?.data });
          })
          .catch((error) => {
            dispatch({ type: adminTypes.GET_ALL_CUSTOMER_LIST_FAILURE, payload: error?.response?.data});
          });
      };
};

export const getAdminSingleCustomer = (token,customerId) => {
    return (dispatch) => {
        const apiWithToken = apiAdminWithJwt(token)
        apiWithToken
          .get(`admin/customers/${customerId}`,{
            params: {
              include_pii:true
            },
            })
          .then((res) => {
            dispatch({ type: adminTypes.GET_CUSTOMER_DATA_SUCCESS, payload: res?.data });
          })
          .catch((error) => {
            dispatch({ type: adminTypes.GET_CUSTOMER_DATA_FAILURE, payload: error?.response?.data });
          });
      };
};

export const getAdminSingleCustomerFromDB = (token,customerId) => {
  return (dispatch) => {
      const apiWithToken = apiAdminWithJwt(token);
      apiWithToken
        .get(`admin/single-customer/${customerId}`)
        .then((res) => {
          dispatch({ type: adminTypes.GET_SINGLE_CUSTOMER_DATA_SUCCESS, payload: res?.data });
        })
        .catch((error) => {
          dispatch({ type: adminTypes.GET_SINGLE_CUSTOMER_DATA_FAILURE, payload: error?.response?.data });
        });
    };
};

export const getAdminExternalBankAccounts = (token,cuid) => {
    return (dispatch) => {
        const apiWithToken = apiAdminWithJwt(token)
        apiWithToken
          .get(`admin/external-bank-accounts`, {
            params: {
                per_page: 30,
                customer_guid: cuid,
            },
            })
          .then((res) => {
            dispatch({ type: adminTypes.GET_EXTERNAL_BANK_ACCOUNTS_SUCCESS, payload: res?.data });
          })
          .catch((error) => {
            dispatch({ type: adminTypes.GET_EXTERNAL_BANK_ACCOUNT_FAILURE, payload: error?.response?.data });
          });
      };
};

export const getAdminExternalWallets = (token,cuid) => {
    return (dispatch) => {
        const apiWithToken = apiAdminWithJwt(token)
        apiWithToken
          .get(`admin/external-wallets`, {
            params: {
                per_page: 30,
                customer_guid: cuid,
            },
            })
          .then((res) => {
            dispatch({ type: adminTypes.GET_EXTERNAL_WALLETS_SUCCESS, payload: res?.data });
          })
          .catch((error) => {
            dispatch({ type: adminTypes.GET_EXTERNAL_WALLETS_FAILURE, payload: error?.response?.data });
          });
    }
}

export const getAdminAccounts = (token,cuid) => {
    return (dispatch) => {
        const apiWithToken = apiAdminWithJwt(token)
        apiWithToken
          .get(`admin/accounts`, {
            params: {
                customer_guid: cuid,
            },
            })
          .then((res) => {
            dispatch({ type: adminTypes.GET_ACCOUNTS_SUCCESS, payload: res?.data });
          })
          .catch((error) => {
            dispatch({ type: adminTypes.GET_ACCOUNTS_FAILURE, payload: error?.response?.data });
          });
    }
}

export const getAdminQuotes = (token, currentPage, perPage,cuid ="") => {
    return (dispatch) => {
        const apiWithToken = apiAdminWithJwt(token)
        apiWithToken
          .get(`admin/quotes`, {
            params: {
              customer_guid: cuid,
              page:currentPage,
              per_page:perPage,
            },
            })
          .then((res) => {
            dispatch({ type: adminTypes.GET_QUOTES_SUCCESS, payload: res?.data });
          })
          .catch((error) => {
            dispatch({ type: adminTypes.GET_QUOTES_FAILURE, payload: error?.response?.data });
          });
    }
}

export const getAdminTrades = (token, cuid = null) => {
    return (dispatch) => {
        const apiWithToken = apiAdminWithJwt(token)
        apiWithToken
          .get(`admin/trades`, {
            params: {
              customer_guid: cuid,
            },
            })
          .then((res) => {
            dispatch({ type: adminTypes.GET_TRADES_SUCCESS, payload: res?.data });
          })
          .catch((error) => {
            dispatch({ type: adminTypes.GET_TRADES_FAILURE, payload: error?.response?.data });
          });
    }
}

export const getAdminTransfers = (token,cuid) => {
    return (dispatch) => {
        const apiWithToken = apiAdminWithJwt(token)
        apiWithToken
          .get(`admin/transfers`, {
            params: {
                per_page: 30,
                customer_guid: cuid,
            },
            })
          .then((res) => {
            dispatch({ type: adminTypes.GET_TRANSFERS_SUCCESS, payload: res?.data });
          })
          .catch((error) => {
            dispatch({ type: adminTypes.GET_TRANSFERS_FAILURE, payload: error?.response?.data });
          });
    }
}

export const getAdminIdentityVerfications = (token, cuid) => {
  return (dispatch) => {
      const apiWithToken = apiAdminWithJwt(token)
      apiWithToken
        .get(`admin/identity_verifications`, {
          params: {
              // page:currentPage,
              customer_guid: cuid,
          },
          })
        .then((res) => {
          dispatch({ type: adminTypes.GET_IDENTITY_VERIFICATIONS_SUCCESS, payload: res?.data });
        })
        .catch((error) => {
          dispatch({ type: adminTypes.GET_IDENTITY_VERIFICATIONS_FAILURE, payload: error?.response?.data });
        });
  }
}

export const resetLogInSuccess = () => {
    return (dispatch) => {
        dispatch ({ type: adminTypes.POST_LOGIN_SUCCESS, payload:{} })
    }
}

export const resetLogInFailure = () => {
    return (dispatch) => {
        dispatch ({ type: adminTypes.POST_LOGIN_FAILURE, payload:{} })
    }
}

export const resetGetAllCustomerListSuccess = () => {
    return (dispatch) => {
        dispatch ({ type: adminTypes.GET_ALL_CUSTOMERS_LIST_SUCCESS, payload:{} })
    }
}

export const resetGetAllCustomerListFailure = () => {
    return (dispatch) => {
        dispatch ({ type: adminTypes.GET_ALL_CUSTOMER_LIST_FAILURE, payload:{} })
    }
}

export const resetGetSingleCustomerDataSuccess = () => {
    return (dispatch) => {
        dispatch ({ type: adminTypes.GET_CUSTOMER_DATA_SUCCESS, payload:{} })
    }
}

export const resetGetSingleCustomerDataFailure = () => {
    return (dispatch) => {
        dispatch ({ type: adminTypes.GET_CUSTOMER_DATA_FAILURE, payload:{} })
    }
}

export const resetGetSingleCustomerDBDataSuccess = () => {
  return (dispatch) => {
      dispatch ({ type: adminTypes.GET_SINGLE_CUSTOMER_DATA_SUCCESS, payload:{} })
  }
}

export const resetGetSingleCustomerDBDataFailure = () => {
  return (dispatch) => {
      dispatch ({ type: adminTypes.GET_SINGLE_CUSTOMER_DATA_FAILURE, payload:{} })
  }
}

export const resetGetExternalBankAccountSuccess = () => {
    return (dispatch) => {
        dispatch ({ type: adminTypes.GET_EXTERNAL_BANK_ACCOUNTS_SUCCESS, payload:{} })
    }
}

export const resetGetExternalBankAccountFailure = () => {
    return (dispatch) => {
        dispatch ({ type: adminTypes.GET_EXTERNAL_BANK_ACCOUNT_FAILURE, payload:{} })
    }
}


export const resetGetExternalWalletsSuccess = () => {
    return (dispatch) => {
        dispatch ({ type: adminTypes.GET_EXTERNAL_WALLETS_SUCCESS, payload:{} })
    }
}

export const resetGetExternalWalletsFailure = () => {
    return (dispatch) => {
        dispatch ({ type: adminTypes.GET_EXTERNAL_WALLETS_FAILURE, payload:{} })
    }
}

export const resetGetAccountsSuccess = () => {
    return (dispatch) => {
        dispatch({ type: adminTypes.GET_ACCOUNTS_SUCCESS, payload: {} })
    }
}

export const resetGetAccountsFailed = () => {
    return (dispatch) => {
        dispatch({ type: adminTypes.GET_ACCOUNTS_FAILURE, payload: {} })
    }
}
 
export const resetGetTradesSuccess = () => {
    return (dispatch) => {
        dispatch({ type: adminTypes.GET_TRADES_SUCCESS, payload: {} })
    }
}

export const resetGetTradesFailed = () => {
    return (dispatch) => {
        dispatch({ type: adminTypes.GET_TRADES_FAILURE, payload: {} })
    }
}

export const resetGetTransfersSuccess = () => {
    return (dispatch) => {
        dispatch({ type: adminTypes.GET_TRANSFERS_SUCCESS, payload: {} })
    }
}

export const resetGetTransfersFailed = () => {
    return (dispatch) => {
        dispatch({ type: adminTypes.GET_TRANSFERS_FAILURE, payload: {} })
    }
}

export const resetGetIdentityVerificationsSuccess = () => {
  return (dispatch) => {
      dispatch({ type: adminTypes.GET_IDENTITY_VERIFICATIONS_SUCCESS, payload: {} })
  }
}

export const resetGetIdentityVerificationsFailed = () => {
  return (dispatch) => {
      dispatch({ type: adminTypes.GET_IDENTITY_VERIFICATIONS_FAILURE, payload: {} })
  }
}
