import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getMarketPrice,
  getCustomerAccountDetails,
  resetGetCustomerAccountDetails,
  resetMarketPrice,
  commonError,
  getBtcHistoryData,
  getEthHistoryData,
  getUsdcHistoryData, 
  resetBtcHistoryData,
  resetEthHistoryData,
  resetUsdcHistoryData,
  getBtcFiveYearsData,
  getEthFiveYearsData,
  getUsdcFiveYearsData,
  resetBtcFiveYearsData,
  resetEthFiveYearsData,
  resetUsdcFiveYearsData,
  getCryptoMarketData,
  resetCryptoMarketData,
  resetgetMarketPriceFailed,
  generateCustomerAccount,
  resetGenerateAccountFailed,
} from "../../actions/customerActions";
import DashboardTransaction from "../shared/DashboardTransaction";
import { decryptPassword } from "../../utils/Encryption";
import ethereumLogo from "../../images/ethereum.png";
import GifloaderMini from "../layout/gifloadermini";
import bitcoinLogo from "../../images/bitcoin.png";
import Verifymodule from "../shared/verifymodule";
import RadialChart from "../charts/radial";
import PieChart from "../charts/piechart";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import AreaChart from "../charts/area";
import BtcChart from "../charts/btc";
import XrpChart from "../charts/xrp";
import RegenerateCustomerToken from "../shared/regenerateCustomerToken";
import usdcLogo from "../../images/usdc.png";
import usdLogo from "../../images/usd.png"
import { Helmet } from "react-helmet";

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const marKetPriceInterval = 30; // 30 seconds

  let livePriceInterval;

  const [accountData, setAccountData] = useState();
  const [usdPending, setUsdPending] = useState();
  const [usdAvailable, setUsdAvailable] = useState();
  const [liveBuyBTC, setLiveBuyBTC] = useState();
  const [liveBuyETH, setLiveBuyETH] = useState();
  const [liveBuyUSDC, setLiveBuyUSDC] = useState();
  const [liveBuyLTC, setLiveBuyLTC] = useState();
  const [liveBuyBCH, setLiveBuyBCH] = useState();
  const [liveSellBTC, setLiveSellBTC] = useState();
  const [liveSellETH, setLiveSellETH] = useState();
  const [liveSellUSDC, setLiveSellUSDC] = useState();
  const [liveSellLTC, setLiveSellLTC] = useState();
  const [liveSellBCH, setLiveSellBCH] = useState();
  const [TotalBal, setTotalBal] = useState();
  const [liveBTCloader, setLiveBTCloader] = useState(true);
  const [liveETHloader, setLiveETHloader] = useState(true);
  const [liveUSDCloader, setLiveUSDCloader] = useState(true);
  const [liveLTCloader, setLiveLTCloader] = useState(true);
  const [liveBCHloader, setLiveBCHloader] = useState(true);
  const [liveChartLoader, setLiveChartLoader] = useState(true);
  const [BtcData, setBtcData] = useState([]);
  const [EthData, setEthData] = useState([]);
  const [UsdcData, setUsdcData] = useState([]);
  const [ltcData, setLtcData] = useState([]);
  const [bchData, setBchData] = useState([]);
  const [BtcDataForFiveYears, setBtcDataForFiveYears] = useState([]);
  const [EthDataForFiveYears, setEthDataForFiveYears] = useState([]);
  const [UsdcDataForFiveYears, setUsdcDataForFiveYears] = useState([]);
  const [LtcDataForFiveYears, setLtcDataForFiveYears] = useState([]);
  const [BchDataForFiveYears, setBchDataForFiveYears] = useState([]);
  const [fiveYearsValueChange, setFiveYearsValueChange] = useState([]);
  const [BtcDataForMarket, setBtcDataForMarket] = useState([]);
  const [EthDataForMarket, setEthDataForMarket] = useState([]);
  const [UsdcDataForMarket, setUsdcDataForMarket] = useState([]);
  const [LtcDataForMarket, setLtcDataForMarket] = useState([]);
  const [BchDataForMarket, setBchDataForMarket] = useState([]);
  const [currentMarketData, setCurrentMarketData] = useState({});
  const [cryptoNameChange, setCryptoNameChange] = useState("Bitcoin");
  const [getAllAccountData, setAllAccountData] = useState([]);


  const getMarketPriceRes = useSelector((state) => state.customerReducer.getMarketPrice);
  const getMarketPriceResFailed = useSelector((state) => state.customerReducer.getMarketPriceFailed);
  const getAccountDatais = useSelector((state) => state.customerReducer.getAccountData);
  const BtcPrices = useSelector((state) => state.customerReducer.getBTCdata);
  const EthPrices = useSelector((state) => state.customerReducer.getETHdata);
  const UsdcPrices = useSelector((state) => state.customerReducer.getUSDCdata);
  const LtcPrices = useSelector((state) => state.customerReducer.getLTCdata);
  const BchPrices = useSelector((state) => state.customerReducer.getBCHdata);
  const BtcPricesForFiveYears = useSelector((state) => state.customerReducer.getBTCFiveYearsData);
  const EthPricesForFiveYears = useSelector((state) => state.customerReducer.getETHFiveYearsData);
  const UsdcPricesForFiveYears = useSelector((state) => state.customerReducer.getUSDCFiveYearsData);
  const LtcPricesForFiveYears = useSelector((state) => state.customerReducer.getLTCFiveYearsData);
  const BchPricesForFiveYears = useSelector((state) => state.customerReducer.getBCHFiveYearsData);
  const cryptoMarketData = useSelector((state) => state.customerReducer.getCryptoMarketData);
  const customerAccountDataUpdated = useSelector((state) => state.customerReducer.customerAccountData);
  const customerAccountDataUpdatedFailed = useSelector((state) => state.customerReducer.customerAccountDataFailed);


  // Initial API call when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      let tempToken = sessionStorage.getItem("guid");
      let deToken = decryptPassword(tempToken);
      try {
        await Promise.all([
          dispatch(getCustomerAccountDetails(sessionStorage.at, deToken)),
          dispatch(getMarketPrice(sessionStorage.at))
        ]);

        livePriceInterval = setInterval(() => {
          dispatch(getMarketPrice(sessionStorage.at));
        }, marKetPriceInterval * 1000);

        dispatch(getBtcHistoryData());
        // dispatch(getEthHistoryData());
        dispatch(getUsdcHistoryData());
        dispatch(getBtcFiveYearsData());
        // dispatch(getEthFiveYearsData());
        dispatch(getUsdcFiveYearsData());
        dispatch(getCryptoMarketData());
        // dispatch(getLtcHistoryData());
        // dispatch(getBchHistoryData());
        // dispatch(getLtcFiveYearsData());
        // dispatch(getBchFiveYearsData());
      } catch (error) {
        throw error;
      }
    };
    fetchData();
    return () => {
      clearInterval(livePriceInterval);
    };
  }, []);

  // Get Buy & Sell live market price
  useEffect(() => {
    // Find market price data for BTC-USD, ETH-USD, LTC-USD, and BCH-USD
    if (getMarketPriceRes.length !== 0) {
      const bitUsdObject = getMarketPriceRes?.find(
        (item) => item.symbol === "BTC-USD"
      );
      const ethUsdObject = getMarketPriceRes?.find(
        (item) => item.symbol === "ETH-USD"
      );
      const usdcUsdObject = getMarketPriceRes?.find(
        (item) => item.symbol === "USDC-USD"
      );

      // Set live buy and sell prices for BTC, ETH, LTC, and BCH
      setLiveBuyBTC(bitUsdObject?.buy_price);
      setLiveBuyETH(ethUsdObject?.buy_price);
      setLiveBuyUSDC(usdcUsdObject?.buy_price);

      setLiveSellBTC(bitUsdObject?.sell_price);
      setLiveSellETH(ethUsdObject?.sell_price);
      setLiveSellUSDC(usdcUsdObject?.sell_price);

      dispatch(resetMarketPrice());

      // const ltcUsdObject = getMarketPriceRes?.find(
      //   (item) => item.symbol === "LTC-USD"
      // );
      // const bchUsdObject = getMarketPriceRes?.find(
      //   (item) => item.symbol === "BCH-USD"
      // );

      // setLiveBuyLTC(ltcUsdObject?.buy_price);
      // setLiveBuyBCH(bchUsdObject?.buy_price);
      // setLiveSellLTC(ltcUsdObject?.sell_price);
      // setLiveSellBCH(bchUsdObject?.sell_price);
    }
  }, [getMarketPriceRes]);

  // Modify account data for different assets (BTC, ETH, USDC, LTC, BCH)
  useEffect(() => {
    if (getAccountDatais && getAccountDatais.length !== 0) {
      setAllAccountData(getAccountDatais);
      const userAccount = getAccountDatais.objects?.find(
        (account) => account.asset === "USD"
      );
      if (userAccount !== undefined) {
        let fiat_balance = userAccount.platform_balance / 100;
        let fiat_available = userAccount.platform_available / 100;
        const usdPendingAmount = (fiat_balance - fiat_available);
        setUsdAvailable(fiat_available?.toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        ));
        setUsdPending(usdPendingAmount > 0 ? usdPendingAmount : 0);
      }
      const modifiedData = getAccountDatais.objects
        ?.map((account) => {
          let divider;
          let logo;
          let name;
          let symbol;
          let livePrice;
          // Add divider, logo, name, symbol, and livePrice for each asset
          if (account.asset === "BTC") {
            logo = bitcoinLogo;
            name = "Bitcoin";
            symbol = "BTC";
            divider = 100000000;
            livePrice = liveSellBTC;
          } else if (account.asset === "ETH") {
            logo = ethereumLogo;
            name = "Ethereum";
            symbol = "ETH";
            divider = 1000000000000000000;
            livePrice = liveSellETH;
          } else if(account.asset === "USDC") {
            logo = usdcLogo;
            name = "USDC";
            symbol = "USDC";
            divider = 1000000;
            livePrice = liveSellUSDC;
          } else if (account.asset === "USD") {
            let balance;
            logo = usdLogo;
            name = "US Dollar";
            symbol = "USD";
            balance = account.platform_available / 100;

            return {
              ...account,
              logo: logo,
              name: name,
              symbol: symbol,
              platform_balance: balance,
              balance_usd: balance,
            };
          }
          
          // else if (account.asset === "LTC") {
          //   logo = LTC;
          //   name = "Litecoin";
          //   symbol = "LTC";
          //   divider = 100000000;
          //   livePrice = liveSellLTC;
          // } else if (account.asset === "BCH") {
          //   logo = bchLogo;
          //   name = "Bitcoin Cash";
          //   symbol = "BCH";
          //   divider = 100000000;
          //   livePrice = liveSellBCH;
          // } else if (account.asset === "USD") {
          //   let balance;
          //   logo = usdLogo;
          //   name = "Canadian Dollar";
          //   symbol = "USD";
          //   balance = account.platform_available / 100;

          //   return {
          //     ...account,
          //     logo: logo,
          //     name: name,
          //     symbol: symbol,
          //     platform_balance: balance,
          //     balance_canadian: balance,
          //   };
          // }
          const platform_balance_million_units =
            account.platform_balance / divider;

          const platform_available_million_units =
          account.platform_available / divider;

          const balance_usd =
            (platform_available_million_units * livePrice) / 100;

          return {
            ...account,
            logo: logo,
            name: name,
            symbol: symbol,
            platform_balance: platform_balance_million_units.toFixed(8),
            platform_available: platform_available_million_units.toFixed(8),
            balance_usd: balance_usd, // Format to six decimal places
          };
        });

      // Calculate the total USD balance
      const totalUsdBalance = modifiedData
        .reduce(
          (total, asset) => total + (parseFloat(asset.balance_usd) || 0),
          0
        );
      setTotalBal(totalUsdBalance.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }))
      // Filter out USD (US Dollar) from assets and sort by symbol
      let assetArr = modifiedData.filter((account) => account.asset !== "USD");
      let temp = sortArrayBySymbol(modifiedData);
      setAccountData(temp);
      dispatch(resetGetCustomerAccountDetails());
    }
  }, [getAccountDatais]);

  // Handle market price response failure and trigger logout if necessary
  useEffect(() => {
    if (getMarketPriceResFailed && Object.keys(getMarketPriceResFailed).length !== 0) {
      let errObj = getMarketPriceResFailed
      if (errObj && errObj.data && errObj.data.error_message) {
        dispatch(commonError(errObj.data.error_message))
        dispatch(resetgetMarketPriceFailed())
        if (errObj.data.error_message == "Signature has expired" || errObj.data.message_code == "authentication_failed") {
          navigate("/logout");
        }
      }
    }
  }, [getMarketPriceResFailed]);

  // Check if all the accounts are created successfully, if not create a new one
  useEffect(() => {
    if (getMarketPriceRes && getMarketPriceRes?.length !== 0 && getAllAccountData && getAllAccountData.objects?.length > 0) {
      const priceApiSymbol = getMarketPriceRes.map(item => item.symbol.split("-")[0]);  
      const accountApiSymbol = getAllAccountData.objects?.map(account => account.asset);
      const isSymbolMissing = priceApiSymbol.filter(symbol => !accountApiSymbol?.includes(symbol));
      let customerGuid;
      const accountWithCAD = getAllAccountData.objects?.find(account => account.asset?.includes("CAD"));
      if (accountWithCAD) {
        customerGuid = accountWithCAD.customer_guid;
      }   
      if (isSymbolMissing?.length > 0) {
        for (const symbol of isSymbolMissing) {
          try {
            let accountName;
            if (symbol === "BTC") {
              accountName = "Bitcoin Account"
            } else if (symbol === "USDC") {
              accountName = "USDC Account"
            } else {
              accountName = symbol+" Account"
            }   
            const reqData = {
              type: "trading",
              customer_guid: customerGuid,
              asset: symbol,
              name: accountName,
            }
            dispatch(generateCustomerAccount(sessionStorage.at, reqData));
          } catch (error) {
              console.log(error)
          }
        }
      }   
    }
  }, [getMarketPriceRes, getAllAccountData]);

  // If account created successfully
  useEffect(() => {
    if (customerAccountDataUpdated && Object.keys(customerAccountDataUpdated)?.length !== 0) {
      let tempToken = sessionStorage.getItem("guid");
      let deToken = decryptPassword(tempToken);
      dispatch(getCustomerAccountDetails(sessionStorage.at, deToken));
    }
  }, [customerAccountDataUpdated])

  // If there is a problem in creating the account
  useEffect(() => {
    if (customerAccountDataUpdatedFailed && Object.keys(customerAccountDataUpdatedFailed)?.length !== 0) {
      let errObj = customerAccountDataUpdatedFailed

      if (errObj && errObj.data && errObj.data.error_message) {
        dispatch(commonError(errObj.data.error_message))
        dispatch(resetGenerateAccountFailed())
      }
    }
  }, [customerAccountDataUpdatedFailed]);

  // Fetch BTC one day price data
  useEffect(() => {
    if (BtcPrices && BtcPrices.length !== 0) {
      const Btc15day = BtcPrices?.filter((v, i) => i === 0 || i % 12 === 0).map(
        (price) => {
          return price;
        }
      );
      setBtcData(Btc15day);

      setTimeout(() => {
        setLiveBTCloader(false);
      }, 2500);
      dispatch(resetBtcHistoryData());
    }
  }, [BtcPrices]);

  // Fetch ETH one day price data
  useEffect(() => {
    if (EthPrices && EthPrices.length !== 0) {
      const Eth15day = EthPrices?.filter((v, i) => i === 0 || i % 12 === 0).map(
        (price) => {
          return price;
        }
      );
      setEthData(Eth15day);

      setTimeout(() => {
        setLiveETHloader(false);
      }, 3000);
      dispatch(resetEthHistoryData());
    }
  }, [EthPrices]);

  // Fetch USDC one day price data
  useEffect(() => {
    if (UsdcPrices && UsdcPrices.length !== 0) {
      const Usdc15day = UsdcPrices?.filter((v, i) => i === 0 || i % 12 === 0).map(
        (price) => {
          return price;
        }
      );
      setUsdcData(Usdc15day);

      setTimeout(() => {
        setLiveUSDCloader(false);
      }, 3000);
      dispatch(resetUsdcHistoryData());
    }
  }, [UsdcPrices]);

  // Fetch LTC one day price data
  // useEffect(() => {
  //   if (LtcPrices && LtcPrices?.length !== 0) {
  //     const ltc15day = LtcPrices?.filter((v, i) => i === 0 || i % 12 === 0).map(
  //       (price) => {
  //         return price;
  //       }
  //     );
  //     setLtcData(ltc15day);

  //     setTimeout(() => {
  //       setLiveLTCloader(false);
  //     }, 3500);
  //     dispatch(resetLtcHistoryData());
  //   }
  // }, [LtcPrices]);

  // Fetch BCH one day price data
  // useEffect(() => {
  //   if (BchPrices && BchPrices?.length !== 0) {
  //     const bch15day = BchPrices?.filter((v, i) => i === 0 || i % 12 === 0).map(
  //       (price) => {
  //         return price;
  //       }
  //     );
  //     setBchData(bch15day);

  //     setTimeout(() => {
  //       setLiveBCHloader(false);
  //     }, 3500);
  //     dispatch(resetBchHistoryData());
  //   }
  // }, [BchPrices]);

  // Fetch BTC five years price data
  useEffect(() => {
    if (BtcPricesForFiveYears && BtcPricesForFiveYears.length !== 0) {
      const Btc5Years = BtcPricesForFiveYears?.map(
        (price) => {
          return price;
        }
      );
      setBtcDataForFiveYears(Btc5Years);
      setFiveYearsValueChange(Btc5Years);

      setTimeout(() => {
        setLiveChartLoader(false);
      }, 2000);
      dispatch(resetBtcFiveYearsData());
    }
  }, [BtcPricesForFiveYears]);

  // Fetch ETH five years price data
  useEffect(() => {
    if (EthPricesForFiveYears && EthPricesForFiveYears.length !== 0) {
      const Eth5Years = EthPricesForFiveYears?.map(
        (price) => {
          return price;
        }
      );
      setEthDataForFiveYears(Eth5Years);

      setTimeout(() => {
        setLiveChartLoader(false);
      }, 2000);
      dispatch(resetEthFiveYearsData());
    }
  }, [EthPricesForFiveYears]);

   // Fetch USDC five years price data
   useEffect(() => {
    if (UsdcPricesForFiveYears && UsdcPricesForFiveYears.length !== 0) {
      const Usdc5Years = UsdcPricesForFiveYears?.map(
        (price) => {
          return price;
        }
      );
      setUsdcDataForFiveYears(Usdc5Years);

      setTimeout(() => {
        setLiveChartLoader(false);
      }, 2000);
      dispatch(resetUsdcFiveYearsData());
    }
  }, [UsdcPricesForFiveYears]);

  // Fetch LTC five years price data
  // useEffect(() => {
  //   if (LtcPricesForFiveYears && LtcPricesForFiveYears?.length !== 0) {
  //     const Ltc5Years = LtcPricesForFiveYears?.map(
  //       (price) => {
  //         return price;
  //       }
  //     );
  //     setLtcDataForFiveYears(Ltc5Years);

  //     setTimeout(() => {
  //       setLiveChartLoader(false);
  //     }, 2000);
  //     dispatch(resetLtcFiveYearsData());
  //   }
  // }, [LtcPricesForFiveYears]);

  // Fetch BCH five years price data
  // useEffect(() => {
  //   if (BchPricesForFiveYears && BchPricesForFiveYears?.length !== 0) {
  //     const Bch5Years = BchPricesForFiveYears?.map(
  //       (price) => {
  //         return price;
  //       }
  //     );
  //     setBchDataForFiveYears(Bch5Years);

  //     setTimeout(() => {
  //       setLiveChartLoader(false);
  //     }, 2000);
  //     dispatch(resetBchFiveYearsData());
  //   }
  // }, [BchPricesForFiveYears]);

  // Fetch crypto market data
  useEffect(() => {
    if (Array.isArray(cryptoMarketData) && cryptoMarketData.length !== 0) {
      const marketData = cryptoMarketData.map((marketData) => {
        return marketData;
      });

      // Find data for each cryptocurrency (BTC, ETH, USDC, LTC, BCH) based on their symbols
      const btcDataForMarket = marketData.find((item) => item.symbol === "btc");
      const ethDataForMarket = marketData.find((item) => item.symbol === "eth");
      const usdcDataForMarket = marketData.find((item)=> item.symbol === "usdc");
      // const ltcDataForMarket = marketData.find((item) => item.symbol === "ltc");
      // const bchDataForMarket = marketData.find((item) => item.symbol === "bch");

      // Set the state variables with the data for each cryptocurrency or an empty object if not found
      setBtcDataForMarket(btcDataForMarket || {});
      setEthDataForMarket(ethDataForMarket || {});
      setUsdcDataForMarket(usdcDataForMarket || {});
      // setLtcDataForMarket(ltcDataForMarket || {});
      setCurrentMarketData(btcDataForMarket || {});
      // setBchDataForMarket(bchDataForMarket || {});

      dispatch(resetCryptoMarketData());
    }
  }, [cryptoMarketData]);

  // Function to handle changes in crypto data based on the selected symbol
  const handleCryptoDataChange = (symbol) => {
    switch (symbol) {
      case "BTC":
        setFiveYearsValueChange(BtcDataForFiveYears);
        setCurrentMarketData(BtcDataForMarket);
        setCryptoNameChange("Bitcoin");
        break;

      case "ETH":
        setFiveYearsValueChange(EthDataForFiveYears);
        setCurrentMarketData(EthDataForMarket);
        setCryptoNameChange("Ethereum");
        break;

      case "USDC":
        setFiveYearsValueChange(UsdcDataForFiveYears);
        setCurrentMarketData(UsdcDataForMarket);
        setCryptoNameChange("USDC");
        break;

      // case "LTC":
      //   setFiveYearsValueChange(LtcDataForFiveYears);
      //   setCurrentMarketData(LtcDataForMarket);
      //   setCryptoNameChange("Litecoin");
      //   break;

      // case "BCH":
      //   setFiveYearsValueChange(BchDataForFiveYears);
      //   setCurrentMarketData(BchDataForMarket);
      //   setCryptoNameChange("BitcoinCash");
      //   break;

      default:
        break;
    }
  }

  // Function to calculate the profit percentage based on an array of values
  const calculateProfitPercentage = (arr) => {
    let temp = arr.map((v) => v[1]);
    const [oldValue, newValue] = [temp[0], temp[temp.length - 1]];
    if (oldValue === 0) {
      return "Infinity";
    }
    const profitPercentage = ((newValue - oldValue) / oldValue) * 100;
    const className =
      profitPercentage >= 0
        ? "badge badge-success ms-2"
        : "badge badge-danger1 ms-2";
    return (
      <span className={className}>{profitPercentage.toFixed(2) + "%"}</span>
    );
  };

  // Function to convert data into percentages for a pie chart
  const convertToPercentages = () => {
    if (!accountData || !Array.isArray(accountData)) {
      // Return default values or handle the case where accountData is falsy.
      return {
        colorNameData: [],
        assetNameData: [],
        percentages: [],
      };
    }

    const getAssetDetails = (asset) => {
      switch (asset) {
        case 'BTC':
          return { name: 'Bitcoin', color: 'rgba(246, 146, 26)' };
        case 'ETH':
          return { name: 'Ethereum', color: 'rgba(98, 127, 234)' };
        case 'USDC':
          return { name: 'USDC', color: 'rgb(41, 117, 201)' };
        case 'USD':
          return { name: 'USD', color: 'rgb(179, 25, 66)' };
        default:
          return { name: '', color: '' };
      }
    };

    const assetDetails = accountData.map((data) => getAssetDetails(data?.asset));
  
    const priceArr = accountData.map((e) => parseFloat(e.balance_usd || 0));
    const total = priceArr.reduce((acc, val) => acc + val, 0);
    if (total === 0) {
      return {
        colorNameData: assetDetails.map((details) => details.color),
        assetNameData: assetDetails.map((details) => details.name),
        percentages: priceArr,
      };
    }
  
    const percentages = priceArr.map((value) => (value ? ((value / total) * 100).toFixed(2)  : 0));
  
    return {
      colorNameData: assetDetails.map((details) => details.color),
      assetNameData: assetDetails.map((details) => details.name),
      percentages,
    };
  };
  
  // Sort account data 
  function sortArrayBySymbol(array) {
    const symbolOrder = ['USD', 'BTC', 'ETH', 'USDC', 'BCH', 'LTC'];
    return array.sort((a, b) => {
      const aSymbol = a.asset;
      const bSymbol = b.asset;
      const aIndex = symbolOrder.indexOf(aSymbol);
      const bIndex = symbolOrder.indexOf(bSymbol);
      return aIndex - bIndex;
    });
  }


  return (
    <>
      {/* Meta Tag */}
      <Helmet>
        <title>The Crypto Exchange</title>
        <meta name="description" content="Buy and sell crypto currencies securely. Access a Wide Range of Digital Assets and Enjoy Fast, Reliable Transactions" />
      </Helmet>

      <RegenerateCustomerToken/>
      <Header2 />
      <Sidebar />

      <div className="content-body dash-main">
        <div className="container-fluid">
          {/* <div className="card fadeInDown tce-loader">
                  <img src={require("../../images/tce-loader.gif")} alt="" />
              </div> */}
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                {/* <Exchange priceInfo={childMarketPrice} /> */}
                <div className="col-md-7 dash12col84">
                  <div className="row">
                    <div className="col-md-7 dash12col">
                      <Verifymodule />
                    </div>
                    <div className="col-md-5 dash12col">
                      <div className="card1 fadeInDown wclr">
                        <div className="card-body1">
                          <div className="widget-card">
                            {liveBTCloader ? (
                              <div className="py-4">
                                <GifloaderMini />
                              </div>
                            ) : (
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="widget-stat">
                                  <div className="coin-title">
                                    <span>
                                      <img
                                        src={require("../../images/bitcoin.png")}
                                        alt="BTC_logo"
                                      />
                                    </span>
                                    <h5 className="d-inline-block ms-2 mb-3">
                                      Bitcoin
                                      {/* <span>
                                              (24h)
                                          </span> */}
                                    </h5>
                                  </div>
                                  <h4>
                                    USD{" "}
                                    <span className="text-primary akzidenzfont">$
                                      {typeof liveBuyBTC === "number" ?
                                        (liveBuyBTC / 100).toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })
                                        : ""
                                      }{" "}
                                    </span>
                                    {BtcData && calculateProfitPercentage(BtcData)}
                                  </h4>
                                </div>
                                <div className="hrsup">24h</div>
                                <BtcChart marketPrices={BtcData} />
                              </div>
                            )}
                          </div>

                          {/* <div className="widget-card fadeInDown">
                            {liveETHloader ? (
                              <div className="py-4">
                                <GifloaderMini />
                              </div>
                            ) : (
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="widget-stat">
                                  <div className="coin-title">
                                    <span>
                                      <img
                                        src={require("../../images/ethereum.png")}
                                        alt="ETH_logo"
                                      />
                                    </span>
                                    <h5 className="d-inline-block ms-2 mb-3">
                                      Ethereum
                                       <span>
                                              (24h)
                                          </span>
                                    </h5>
                                  </div>
                                  <h4>
                                    USD{" "}
                                    <span className="text-primary akzidenzfont">
                                      {typeof liveBuyETH === "number" ?
                                        (liveBuyETH / 100).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                        : ""
                                      }{" "}
                                    </span>
                                    {EthData && calculateProfitPercentage(EthData)}
                                  </h4>
                                </div>
                                <div className="hrsup">24h</div>
                                <BtcChart marketPrices={EthData} />
                              </div>
                            )}
                          </div> */}

                          <div className="widget-card fadeInDown">
                            {liveUSDCloader ? (
                              <div className="py-4">
                                <GifloaderMini />
                              </div>
                            ) : (
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="widget-stat">
                                  <div className="coin-title">
                                    <span>
                                      <img
                                        src={require("../../images/usdc.png")}
                                        alt="USDC_logo"
                                      />
                                    </span>
                                    <h5 className="d-inline-block ms-2 mb-3">
                                      USDC
                                      {/* <span>
                                              (24h)
                                          </span> */}
                                    </h5>
                                  </div>
                                  <h4>
                                    USD{" "}
                                    <span className="text-primary akzidenzfont">$
                                      {typeof liveBuyUSDC === "number" ?
                                        (liveBuyUSDC / 100).toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })
                                        : ""
                                      }{" "}
                                    </span>
                                    {UsdcData && calculateProfitPercentage(UsdcData)}
                                  </h4>
                                </div>
                                <div className="hrsup">24h</div>
                                <BtcChart marketPrices={UsdcData} />
                              </div>
                            )}
                          </div>

                          {/* <div className="widget-card fadeInDown">
                            {liveBCHloader ? (
                              <div className="py-4">
                                <GifloaderMini />
                              </div>
                            ) : (
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="widget-stat">
                                  <div className="coin-title">
                                    <span>
                                      <img src={bchLogo} alt="BCH_logo" />
                                    </span>
                                    <h5 className="d-inline-block ms-2 mb-3">
                                      Bitcoin Cash
                                      <span>
                                              (24h)
                                          </span>
                                    </h5>
                                  </div>
                                  <h4>
                                    USD{" "}
                                    <span className="text-primary akzidenzfont">
                                      {typeof liveBuyBCH === "number" ?
                                        (liveBuyBCH / 100).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                        : ""
                                      }{" "}
                                    </span>
                                    {bchData && calculateProfitPercentage(bchData)}
                                  </h4>
                                </div>
                                <div className="hrsup">24h</div>
                                <XrpChart marketPrices={bchData} />
                              </div>
                            )}
                          </div> */}


                          {/* <div className="widget-card fadeInDown">
                            {liveLTCloader ? (
                              <div className="py-4">
                                <GifloaderMini />
                              </div>
                            ) : (
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="widget-stat">
                                  <div className="coin-title">
                                    <span>
                                      <img src={LTC} alt="LTC_logo" />
                                    </span>
                                    <h5 className="d-inline-block ms-2 mb-3">
                                      Litecoin
                                      <span>
                                              (24h)
                                          </span>
                                    </h5>
                                  </div>
                                  <h4>
                                    USD{" "}
                                    <span className="text-primary akzidenzfont">
                                      {typeof liveBuyLTC === "number" ?
                                        (liveBuyLTC / 100).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                        : ""
                                      }{" "}
                                    </span>
                                    {ltcData && calculateProfitPercentage(ltcData)}
                                  </h4>
                                </div>
                                <div className="hrsup">24h</div>
                                <XrpChart marketPrices={ltcData} />
                              </div>
                            )}
                          </div> */}

                        </div>
                      </div>
                    </div>
                  </div>




                  <div className="col-12">
                    <div className="card profile_chart transparent">
                      {/*<div className="card-header">
                            <div className="chart_current_data">
                                  <h3>
                                    254856 <span>USD</span>
                                  </h3>
                                  <p className="text-primary">
                                      125648{" "}
                                      <span>USD (20%)</span>
                                  </p>                    
                              </div>                                          
                        </div>*/}
                      <div className="card-body p-0">
                        <h3 className="card-title mb-4">Historical Pricing ({cryptoNameChange})</h3>
                        {liveChartLoader ? (
                          <div className="py-4">
                            <GifloaderMini />
                          </div>
                        ) : (
                          <AreaChart BtcPriceEachDay={fiveYearsValueChange} cryptoName={cryptoNameChange} />
                        )}

                        {Object.keys(currentMarketData).length !== 0 &&
                          <div className="chart-content text-center mt-5">
                            <div className="row">
                              <div className="col-xl-3 col-sm-6 col-8">
                                <div className="chart-stat">
                                  <p className="mb-1">Volume</p>
                                  <strong>${(currentMarketData?.total_volume)?.toFixed(3)}</strong>
                                </div>
                              </div>
                              <div className="col-xl-3 col-sm-6 col-8">
                                <div className="chart-stat">
                                  <p className="mb-1">Market Cap</p>
                                  <strong>{((currentMarketData?.market_cap) / 1000000000)?.toFixed(0)}B USD</strong>
                                </div>
                              </div>
                              <div className="col-xl-3 col-sm-6 col-8">
                                <div className="chart-stat">
                                  <p className="mb-1">Circulating</p>
                                  <strong>{((currentMarketData?.circulating_supply) / 1000000)?.toFixed(1)}M <span className="text-uppercase">{currentMarketData?.symbol}</span></strong>
                                </div>
                              </div>
                              <div className="col-xl-3 col-sm-6 col-8">
                                <div className="chart-stat">
                                  <p className="mb-1">All Time High</p>
                                  <strong>{(currentMarketData?.ath)?.toFixed(2)} USD</strong>
                                </div>
                              </div>
                              {/* <div className="col-xl-4 col-sm-6 col-8">
                              <div className="chart-stat">
                                <p className="mb-1">Typical hold </p>
                                <strong>88 days</strong>
                              </div>
                            </div>
                            <div className="col-xl-4 col-sm-6 col-8">
                              <div className="chart-stat">
                                <p className="mb-1">Trading activity </p>
                                <strong>70% buy </strong>
                              </div>
                            </div> */}
                            </div>
                          </div>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-5 dash12col84">
                  <div className="card balance-widget transparent">
                    <div className="card-header px-0 pt-0">
                      <h3 className="card-title">My Portfolio</h3>
                    </div>

                    <div className="card-body p-0">
                      {/* <RadialChart priceData={convertToPercentages()} /> */}
                      <div className="balance-widget">


                        {/* <ul className="list-unstyled">
                          <li className="d-flex align-items-center">
                            <img src={usdLogo} alt="" className="me-2" width="24" />
                            <div className="flex-grow-1">
                              <h5 className="m-0">USD</h5>
                            </div>
                            <div className="text-end">
                              <div>
                                <span className="text-primary fw-bold">{usdAvailable}</span> USD
                              </div>
                              <span>
                                {usdPending > 0 && `${usdPending.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )} USD (Pending)`}
                              </span>
                            </div>
                          </li>
                          {accountData &&
                            accountData.map((account, index) => (
                              <li className="d-flex cursor-pointer" key={index}
                                onMouseEnter={() => handleCryptoDataChange(account.symbol)}
                              >
                                <img
                                  src={account.logo}
                                  alt=""
                                  className="me-2"
                                  width="24"
                                />
                                <div className="flex-grow-1">
                                  <h5 className="m-0">{account.name}</h5>
                                </div>
                                <div className="text-end">
                                  <h5>
                                    <span className="text-primary fw-bold">{account.platform_available.toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 8,
                                        maximumFractionDigits: 8,
                                      }
                                    )}{" "}</span>
                                    {account.asset}
                                  </h5>
                                  <div>
                                  <span>
                                    {account.platform_balance > 0 ? `${account.platform_balance} Pending` : ""}
                                  </span>
                                  </div>
                                  <div>
                                  <span>
                                    {account.balance_usd ? `${account.balance_usd.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })} USD` : ""}
                                  </span>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul> */}

                        <div className="dash_balance">
                          <div className="table-responsive">
                            <table className="table mb-0">
                              <thead>
                                <tr>
                                  <th className="text-start small">Currencies</th>
                                  <th className="text-end small">Available Balance</th>
                                  <th className="text-end small">Pending Balance</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="d-flex">
                                      <img src={usdLogo} alt="" className="me-2" width="24" />
                                      <div className="flex-grow-1">
                                        <h5 className="m-0">USD</h5>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      $<span className="text-primary fw-bold">{usdAvailable}</span> USD
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      $
                                      <span className="">
                                        {usdPending > 0 ? `${usdPending.toLocaleString(
                                              undefined,
                                              {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              }
                                            )}` : "0.00"} {" "}
                                      </span>
                                      USD
                                    </div>
                                  </td>
                                </tr>
                                {accountData &&
                                  accountData.map(
                                    (account, index) =>
                                      account?.asset !== 'USD' && (
                                        <tr className="cursor-pointer" key={index}
                                          onMouseEnter={() => handleCryptoDataChange(account.symbol)}
                                        >
                                          <td>
                                            <div className="d-flex">
                                              <img
                                                src={account.logo}
                                                alt=""
                                                className="me-2"
                                                width="24"
                                              />
                                              <div className="flex-grow-1">
                                                <h5 className="m-0">{account.name}</h5>
                                              </div>
                                            </div>
                                          </td>

                                          <td>
                                            <div>
                                              <h5>
                                                <span className="text-primary fw-bold">{account.asset === "USDC" ? parseFloat(account.platform_available).toFixed(4) : account.platform_available}{" "}</span>
                                                {account.asset}
                                                <span className="d-block">
                                                  {account.balance_usd ? `($${account.balance_usd.toLocaleString(undefined, {
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        })} USD)` : ""}
                                                </span>
                                              </h5>
                                            </div>
                                          </td>
                                          <td>
                                            <div>
                                              <span className=""> {account.platform_balance - account.platform_available  > 0 ? 
                                            (account.asset === "USDC" ? (account.platform_balance - account.platform_available ).toFixed(4) : 
                                            (account.platform_balance - account.platform_available ).toFixed(8)) : 
                                            account.asset === "USDC" ? "0.0000" : "0.00000000"}
                                              </span> {" "}
                                              {account.asset}
                                            </div>

                                            {/* <div>
                                          <span>
                                            {account.balance_usd ? `(${account.balance_usd.toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })} USD)` : ""}
                                          </span>
                                        </div> */}
                                          </td>

                                        </tr>
                                      ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="light-bg my-0 p-2">
                          <div className="row align-items-center">
                            <div className="col-md-6">
                              <div className="largecoinbox border-0 align-items-start">
                                <div className="lcbtxt m-0">
                                  <p className="small mb-0">Total Available Balance</p>
                                  <h3 className="fw-bold dashtbtxt">
                                    <span className="small">$</span><span className="text-primary">{TotalBal}</span>
                                    <span className="small"> USD</span>
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 text-end">
                              <div className="largecoinbox border-0 align-items-end">
                                <button type="button" className="btn btn-secondary" onClick={()=> navigate("/deposits")}><span>Add USD Funds</span></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="my-5">
                        <PieChart priceData={convertToPercentages()} />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              {/* <div className="row">
                                <div className="col-xl-3 col-lg-6 col-md-6">
                                    <div className="widget-card fadeInDown">
                                        <div className="widget-title">
                                            <h5>Balance</h5>
                                            <p className="text-primary">
                                                133%{" "}
                                                <span>
                                                    <i className="las la-arrow-up"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="widget-info">
                                            <h3>$18,235.0</h3>
                                            <p>USD</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6">
                                    <div className="widget-card fadeInDown">
                                        <div className="widget-title">
                                            <h5>Balance</h5>
                                            <p className="text-danger">
                                                133%{" "}
                                                <span>
                                                    <i className="las la-arrow-down"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="widget-info">
                                            <h3>$18,235.0</h3>
                                            <p>USD</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6">
                                    <div className="widget-card fadeInDown">
                                        <div className="widget-title">
                                            <h5>Balance</h5>
                                            <p className="text-primary">
                                                133%{" "}
                                                <span>
                                                    <i className="las la-arrow-up"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="widget-info">
                                            <h3>$18,235.0</h3>
                                            <p>USD</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6">
                                    <div className="widget-card fadeInDown">
                                        <div className="widget-title">
                                            <h5>Balance</h5>
                                            <p className="text-danger">
                                                133%{" "}
                                                <span>
                                                    <i className="las la-arrow-down"></i>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="widget-info">
                                            <h3>$18,235.0</h3>
                                            <p>USD</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              <div className="col-md-12">
                <div className="card fadeInDown">
                  <div className="card-header">
                    <h4 className="card-title">Recent Transactions</h4>
                  </div>
                  <DashboardTransaction />
                </div>
              </div>

            </div>
          </div>


        </div>
      </div>
    </>
  );
}

export default Dashboard;
