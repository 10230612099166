import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  postQuotesAction,
  postTransferAction,
  resetPostQuotesAction,
  resetPostTransferAction,
  commonError,
  commonSuccess,
  resetpostTransferFailed,
  resetpostQuoteFailed,
} from "../../actions/customerActions";
import { decryptPassword, formatDate } from "../../utils/Encryption";
import ButtonLoader from "../layout/buttonLoader";
import Gifloader from "../layout/gifloader";
import { EmailNotification } from "../../actions/authAction";
import CustomerVerificationPopup from "./CustomerVerificationPopup";
import { createOtp, resetCreateOtpFailed, resetCreateOtp, verifyOtp, resetVerifyOtp, resetVerifyOtpFailed } from "../../actions/authAction";
import { CountdownCircleTimer } from "react-countdown-circle-timer";


const Withdrawls = ({ bankAccount }) => {
  const dispatch = useDispatch();

  const getTransferQuote = useSelector((state) => state.customerReducer.postQuoteData);
  const postTransferData = useSelector((state) => state.customerReducer.postTransferData);
  const getTransferfailed = useSelector((state) => state.customerReducer.postTransferfailed);
  const getTransferQuoteFailed = useSelector((state) => state.customerReducer.postQuoteFailed);
  const getAccountDatais = useSelector((state) => state.customerReducer.getAccountData);
  const createOtpData = useSelector((state) => state.authReducer.createOtpData);
  const createOtpFailed = useSelector((state) => state.authReducer.createOtpFailed);
  const verifyOtpData = useSelector((state) => state.authReducer.verifyOtpData);

  const [isloader, setIsLoader] = useState(true);
  const [isGetQuote, setGQ] = useState(false);
  const [isGQview, setGQV] = useState(false);
  const [isOrderSub, setOS] = useState(false);
  const [bankLists, setBankLists] = useState([]);
  const [cadAmount, setCadAmount] = useState("");
  const [selectedBankObject, setSelectedBankObject] = useState({});
  const [selectedBankguid, setSelectedBankguid] = useState("");
  const [quoteData, setQuoteData] = useState({});
  const [transferedData, setTransferedData] = useState({});
  const [isQuoteBtnLoading, setQuoteBtnLoading] = useState(false);
  const [isTransferBtnLoading, setTransferBtnLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [accountDetails, setAccountDetails] = useState([]);
  const inputRefs = useRef([]);
  const [sendOtp, setSendOtp] = useState(false);
  const [otpString, setOtpString] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCircleStarted, setIsCircleStarted] = useState(false);
  const [remainingOtpAttempts, setRemainingOtpAttempts] = useState(5);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);



  useEffect(() => {
    if (Object.keys(getAccountDatais).length !== 0) {
      setAccountDetails(getAccountDatais)
    }
  }, [getAccountDatais]);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    if (seconds === 0) {
      clearInterval(timer);
    }
    if (seconds === 0 && isGetQuote && isGQview) {
      toggleGQ();
    }
    return () => clearInterval(timer);
  }, [seconds]);

  // If transfer quote failed
  useEffect(() => {
    if (Object.keys(getTransferQuoteFailed).length !== 0) {
      setSendOtp(false);
      let errObj = getTransferQuoteFailed
        if (errObj.status === 422) {
        if (errObj.data.message_code == "invalid_quote_amount") {
          dispatch(commonError('Crypto value is below the configured threshold'));
        } else {
          dispatch(commonError(errObj.data.error_message))
        }
        dispatch(resetpostQuoteFailed())
      }
    }
    setOtpValues(["", "", "", "", "", ""]);
    setQuoteBtnLoading(false)
  }, [getTransferQuoteFailed])

  // If transfer quote failed and it's status code is 422
  useEffect(() => {
    if (Object.keys(getTransferfailed).length !== 0) {
      let errObj = getTransferfailed
      if (errObj.status === 422) {
        dispatch(commonError(errObj.data.error_message))
        dispatch(resetpostTransferFailed())
      }
    }
    setTransferBtnLoading(false)
  }, [getTransferfailed])

  useEffect(() => {
    if (bankAccount) {
      let temp = bankAccount.filter((value) => value.state === "completed");
      setBankLists(temp);

      if (temp[0]) {
        setSelectedBankguid(temp[0].guid);
        setSelectedBankObject(temp[0]);
      }
    }
    setIsLoader(false);
  }, [bankAccount]);

  useEffect(() => {
    if (getTransferQuote && getTransferQuote.deliver_amount && getTransferQuote.product_type === "funding") {
      setQuoteBtnLoading(false);
      setOtpValues(["", "", "", "", "", ""]);
      toggleGQ();
      setSeconds(ExpiryTimeFinder(getTransferQuote))
      setQuoteBtnLoading(false);
      setQuoteData(getTransferQuote);
      dispatch(resetPostQuotesAction());
    }
  }, [getTransferQuote]);

  useEffect(() => {
    if (postTransferData && postTransferData.amount && postTransferData.transfer_type === "funding") {
      toggleGQV();
      if (postTransferData && postTransferData.failure_code === null) {
        const transformedData = {
          guid: postTransferData.guid,
          customer_guid: postTransferData?.customer_guid,
          transfer_type: postTransferData.transfer_type,
          asset: postTransferData.asset,
          side: postTransferData.side,
          amount: `$ ${((postTransferData.amount / 100) - (postTransferData.fee / 100)).toFixed(2)}`,
          fee: `$ ${(postTransferData.fee / 100).toFixed(2)}`,
          created_at: postTransferData.created_at,
          // type: 'fiat-withdrawal', // Set type to 'fiat-withdrawal' for withdrawal
          user_name: localStorage.getItem('displayName'),
          user_email: sessionStorage.getItem('user_email'),
        };
        // dispatch(EmailNotification(transformedData))
        dispatch(commonSuccess("Withdrawal initiation successful! Your request is being processed."))
      }
      setTransferBtnLoading(false);
      setTransferedData(postTransferData);
      dispatch(resetPostTransferAction());
    }
  }, [postTransferData]);

  useEffect(() => {
    if (verifyOtpData?.data?.data) {
      const concatenatedString = Object.values(verifyOtpData.data.data).join("");
      if (concatenatedString !== "verified") {
        setQuoteBtnLoading(false);
        dispatch(commonError("Please enter a valid OTP"));
        setRemainingOtpAttempts((prevAttempts) => prevAttempts - 1);
      } else if (concatenatedString === "verified") {
        let ExAmount = parseFloat(cadAmount) * 100;
        // If validation passes, construct the request data for the quote.
        const reqData = {
          asset: "USD",
          customer_guid: decryptPassword(sessionStorage.guid),
          deliver_amount: ExAmount,
          product_type: "funding",
          side: "withdrawal",
        };
        dispatch(postQuotesAction(sessionStorage.at, reqData));
        setRemainingOtpAttempts(5);
      }
      dispatch(resetVerifyOtp());
    }
  }, [verifyOtpData]);

  useEffect(() => {
    if (createOtpData?.data) {
      const message = createOtpData?.data?.message;
      dispatch(commonSuccess(message.charAt(0).toUpperCase() + message.slice(1)));
      setIsLoading(false);
      setIsCircleStarted(true);
      setSendOtp(true);
      dispatch(resetCreateOtp());
    }
  }, [createOtpData])

  useEffect(() => {
    if (createOtpFailed && Object.keys(createOtpFailed)?.length !== 0) {
      setIsLoading(false);
      dispatch(commonError(createOtpFailed?.message));
      dispatch(resetCreateOtpFailed());
    }
  }, [createOtpFailed]);

  useEffect(() => {
    if (remainingOtpAttempts <= 0) {
      setOtpValues(["", "", "", "", "", ""]);
      setRemainingOtpAttempts(5);
      setSendOtp(false);
      setIsLoading(false);
      dispatch(commonError("You have exceeded the maximum number of OTP verification attempts."));
    }
  }, [remainingOtpAttempts])


  const toggleGQ = () => {
    setGQ(!isGetQuote);
    setGQV(!isGQview);
    setSendOtp(false);
  };

  const toggleGQV = () => {
    setGQV(!isGQview);
    setOS(!isOrderSub);
  };

  const toggleOS = () => {
    setOS(!isOrderSub);
    setGQ(!isGetQuote);
  };

  const handleAccountChange = (event) => {
    const selectedBankGuid = event.target.value;
    setSelectedBankguid(selectedBankGuid);
    let selectedBankObj = bankLists?.find((e) => e.guid === selectedBankGuid);
    setSelectedBankObject(selectedBankObj);
  };

  const handleCadAmountChange = (event) => {
    const inputValue = event.target.value;
    if (!/^\d*\.?\d*$/.test(inputValue)) {
      return;
    }
    setCadAmount(event.target.value);
  };

  const handleCadAmountBlur = (e) => {
    const inputValue = e.target.value;
    if (e.target.value) {
      const formattedValue = parseFloat(inputValue).toFixed(2);
      setCadAmount(formattedValue);
    } else if(!e.target.value) {
      setCadAmount("0.00");
    }
  };

  const handleTimerComplete = () => {
    setIsCircleStarted(false);
    setSendOtp(false);
  }

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    const pastedValues = pastedData.split("").slice(0, otpValues.length);
    const updatedOtpValues = Array.from(otpValues);

    for (let i = 0; i < updatedOtpValues.length; i++) {
      if (pastedValues.length > 0) {
        updatedOtpValues[i] = pastedValues.shift();
      } else {
        updatedOtpValues[i] = "";
      }
    }

    setOtpValues(updatedOtpValues);
    setOtpString(updatedOtpValues.join(""));
  };

  const handleChange = (e, index) => {
    const { value } = e.target;
    const updatedOtpValues = [...otpValues];
    updatedOtpValues[index] = value;

    setOtpValues(updatedOtpValues);
    setOtpString(updatedOtpValues.join(""));

    // Move to the next input field if a digit is entered
    if (value !== "" && index < otpValues.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleVerifyOtp = () => {
    if (isQuoteBtnLoading) {
      return;
    }
    
    if (otpValues.some(value => value === "")) {
      dispatch(commonError("Please fill all the OTP fields"));
      return;
    }
    setQuoteBtnLoading(true);
    dispatch(verifyOtp("", otpString, decryptPassword(sessionStorage.guid)));
  }

  const postTransferQuote = () => {
    if (isLoading) {
      return;
    }
    
    const findFiatAccount = accountDetails.objects?.length > 0 && accountDetails.objects.find((account) => account.type === 'fiat');
    let ExAmount = parseFloat(cadAmount) * 100;
    let platformBalance = parseFloat(findFiatAccount?.platform_available);
    if (cadAmount === null || cadAmount === "" || cadAmount === 0) {
      dispatch(commonError("Please enter a valid amount"));
      return;
    } else if (Number(platformBalance) < Number(ExAmount)) {
      dispatch(commonError("Insufficient account balance"));
      return;
    } else {
      setIsLoading(true);
      dispatch(createOtp("", decryptPassword(sessionStorage.guid)));
    }
  };

  const postTrasfer = () => {
    if (isTransferBtnLoading) {
      return;
    }
    
    if (seconds == 0) {
      dispatch(commonError('Transaction time has expired'));
      return;
    }
    if (quoteData) {
      const reqData = {
        external_bank_account_guid: selectedBankguid,
        quote_guid: quoteData.guid,
        transfer_type: "funding",
      };
      setTransferBtnLoading(true);
      dispatch(postTransferAction(sessionStorage.at, reqData));
    }
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const ExpiryTimeFinder = (data) => {
    const expiresAt = new Date(data.expires_at);
    const issuedAt = new Date(data.issued_at);
    const timeGapInMilliseconds = expiresAt - issuedAt;
    const seconds = Math.floor((timeGapInMilliseconds % (1000 * 60)) / 1000);
    if(seconds === 30) {
      return seconds - 10;
    }else {
      return seconds - 3;
    }
  }

  return (
    <>
      {isloader ? (
        <>
          <div className=" justify-content-center d-flex align-item-center my-5 py-5">
            <Gifloader />
          </div>
        </>
      ) : (
        <>
          <form
            method="post"
            name="myform"
            className="currency_validate fadeInDown"
          >
            {
              !sendOtp && !isGetQuote && (
                <div>
                  <div className="mb-3">
                    <label className="me-sm-2">Select Bank Account</label>
                    <div className="input-group mb-3 igpcust">
                      <div className="input-group-prepend ">
                        <label className="input-group-text">
                          <img
                            src={require("../../images/piggy-bank.png")}
                            alt=""
                            className="img-fluid"
                          />
                        </label>
                      </div>
                      <select
                        name="currency"
                        className="form-control"
                        onChange={(e) => handleAccountChange(e)}
                      >
                        {bankLists?.map((item) => (
                          <option key={item.guid} value={item.guid}>
                            {`${item.plaid_institution_id}-${item.plaid_account_name} (${item.plaid_account_mask})`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="me-sm-2">Amount</label>
                    <div className="input-group mb-3 igpcust dollarbox">
                      <div className="input-group-prepend selpoabs">
                        <label className="input-group-text small d-flex align-items-center gap-2 px-2">
                          <img
                            src={require("../../images/usd.png")}
                            alt=""
                            className="img-fluid"
                          />
                          <span>USD</span>
                        </label>
                      </div>
                      <div className="dollor d-flex justify-content-start align-items-center px-0 ps-md-2 fw-bold dollorclr">$</div>
                      <input
                        type="text"
                        name="usd_amount"
                        className="form-control"
                        onChange={handleCadAmountChange}
                        onBlurCapture={(e) => handleCadAmountBlur(e)}
                        value={cadAmount}
                        onKeyPress={(e) => {
                          const key = e.key;
                          if (key !== "." && isNaN(Number(key))) {
                            e.preventDefault();
                          }
                        }}
                      />

                      {/* <div className="input-group-append ">
                    <label className="input-group-text small cursor-pointer">
                      <img
                        src={require("../../images/two-arrows.png")}
                        alt=""
                        className="img-fluid icon16"
                      />
                    </label>
                  </div> */}
                    </div>
                  </div>

                  {/* <div className="mb-3 d-flex justify-content-between">
                <label className="me-sm-2">
                  <strong>1</strong> BTC = $<strong>30,759.87</strong> USD
                </label>
                <label className="me-sm-2">MAX</label> 
              </div>*/}

                  <div className="text-center mt-5">
                    <button
                      type="button"
                      className={`btn btn-primary btn-loader
                    ${isLoading ? "active" : ""}
                  `}
                      onClick={postTransferQuote}
                    >
                      <span>Withdraw</span>
                      <ButtonLoader />
                    </button>
                  </div>
                </div>
              )
            }

            <div className={isGQview ? "d-block fadeInDown" : "d-none"}>
              {/* <div className="ppmethod mb-3">
                    <ul className="list-unstyled smplist gqlist">
                      <li className="border-none bg-light p-2">
                        <div className="spm-icon" onClick={toggleGQ}>
                          <i className="las la-angle-left"></i>
                        </div>
                        <div className="spm-content">
                          <div className="spm-icon">
                            <img
                              src={require("../../images/bitcoin.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <p>
                            1 BTC ={" "}
                            <span className="text-primary fw-bold">
                              $38,382.00
                            </span>{" "}
                            CAD
                          </p>
                        </div>
                        <div className="spm-icon">&nbsp;</div>
                      </li>
                    </ul>
                  </div> */}

              <div className="ppmethod">
                <ul className="list-unstyled smplist">
                  <li className="flex-column">
                    {/* <h4 className="fw-bold">Order Quote</h4> */}
                    <span className="small">
                      The order quote will expire in{" "}
                      <em className="quote-countdown">{seconds && seconds}</em>{" "}
                      seconds
                    </span>
                  </li>

                  <li className="flex-column">
                    <h4 className="fw-bold">Confirm Withdrawal </h4>
                  </li>

                  <li>
                    <div className="spm-content">Amount</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {quoteData &&
                          quoteData.deliver_amount &&
                          formatCurrency(quoteData.deliver_amount / 100)}
                      </span>{" "}
                      USD
                    </div>
                  </li>

                  <li>
                    <div className="spm-content">Withdraw Date</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {quoteData && formatDate(quoteData.issued_at)}
                      </span>
                    </div>
                  </li>

                  {/* <li>
                    <div className="spm-content">Fee</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        ${quoteData && quoteData.fee / 100}
                      </span>{" "}
                      CAD
                    </div>
                  </li> */}

                  <li>
                    <div className="spm-content">To</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {selectedBankObject &&
                          `${selectedBankObject.plaid_institution_id}-${selectedBankObject.plaid_account_name} (${selectedBankObject.plaid_account_mask})`}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="text-center tbtnbox" style={{ cursor: isTransferBtnLoading ? "not-allowed" : "pointer" }}>
                <button
                  type="button"
                  className="btn btn-light me-3"
                  onClick={toggleGQ}
                  disabled={isTransferBtnLoading}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className={`btn btn-primary btn-loader ${isTransferBtnLoading ? "active" : ""
                    }`}
                  onClick={postTrasfer}

                >
                  <span>Confirm</span>
                  <ButtonLoader />
                </button>
              </div>
            </div>

            <div className={isOrderSub ? "d-block fadeInDown" : "d-none"}>
              <div className="ppmethod">
                <ul className="list-unstyled smplist">
                  <li className="flex-column">
                    <h4 className="fw-bold">Withdrawal Details </h4>
                  </li>

                  <li>
                    <div className="spm-content">State</div>
                    <div className="spm-icon text-primary fw-bold">
                      Processing
                    </div>
                  </li>

                  <li>
                    <div className="spm-content">Amount</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {transferedData &&
                          transferedData.amount &&
                          formatCurrency(transferedData.amount / 100)}
                      </span>
                    </div>
                  </li>

                  <li>
                    <div className="spm-content">Withdraw Date</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {transferedData &&
                          formatDate(transferedData.created_at)}
                      </span>
                    </div>
                  </li>

                  {/* <li>
                    <div className="spm-content">Fee</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        ${transferedData && transferedData.fee / 100}
                      </span>{" "}
                      CAD
                    </div>
                  </li> */}

                  <li>
                    <div className="spm-content">To</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {selectedBankObject &&
                          `${selectedBankObject.plaid_institution_id}-${selectedBankObject.plaid_account_name} (${selectedBankObject.plaid_account_mask})`}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={toggleOS}
                >
                  Done
                </button>
              </div>
            </div>

            {
              sendOtp && !isloader && (
                <div className={`optarea`}>
                  <div className="auth-form card fslogincard">
                    <li>
                      <div className="card-header justify-content-center border-0 flex-column">
                        <h2 className="mb-2 ggtimefont">Verification</h2>
                        {/* <p className="mb-0">Enter the OTP that has been sent to your registered email</p> */}
                        <p className="mb-0 text-center px-5">Please enter the 6-digit verification code that was sent to your Email. </p>
                        {/* <p className="mb-0 text-center">The code is vaild for 30 minutes.</p> */}
                      </div>

                      <div className="d-flex align-items-center justify-content-center mb-1">
                        <CountdownCircleTimer
                          size={80}
                          isPlaying={isCircleStarted}
                          duration={80}
                          strokeWidth={10}
                          colors={['#1355d7', '#F7B801', '#FFA500', '#A30000']}
                          colorsTime={[60, 40, 20, 0]}
                          onComplete={handleTimerComplete}
                        >
                          {({ remainingTime }) => remainingTime}
                        </CountdownCircleTimer>
                      </div>

                      <div className="card-body d-flex align-items-center justify-content-center">
                        <div className="spm-icon logotpverify">
                          {otpValues.map((value, index) => (
                            <input className="otp form-control" type="text" value={value} onChange={(e) => handleChange(e, index)}
                              maxLength="1" onPaste={handlePaste} ref={(ref) => (inputRefs.current[index] = ref)} key={index}
                            />
                          ))}
                        </div>

                      </div>
                    </li>

                    <div className="ppmethod" style={{border:"none", marginBottom:"0"}}></div>
                    <div className="d-flex align-items-center justify-content-center text-center">
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => {
                        setSendOtp(false);
                        setOtpValues(["", "", "", "", "", ""]);
                      }}
                      disabled = {isQuoteBtnLoading}
                    >
                      Back
                    </button>
                      <button type="button" className={`btn btn-primary btn-loader mx-3 my-1 ${isQuoteBtnLoading ? "active" : ""}`} onClick={() => handleVerifyOtp()}>
                        <span>Verify Otp</span>
                        <ButtonLoader />
                      </button>
                    </div>

                    {/* <p className="text-center">You have <span style={{color:"red"}}>{remainingOtpAttempts}</span> attempts left!</p> */}
                  </div>
                </div>
              )
            }
          </form>
        </>
      )}
      <CustomerVerificationPopup />
    </>
  );
};

export default Withdrawls;
