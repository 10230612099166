import React from "react";
import Header3 from "../layout/header3";
import Footer1 from "../layout/footer1";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function otc() {
    return (
        <>
            {/* Meta Tag */}
            <Helmet>
                <title>Best OTC Cryptocurrency Exchange Service | United States</title>
                <meta name="description" content="Looking for over-the-counter exchange service? We bring you a crypto wallet app for efficient OTC trades. Call us for more information!" />
            </Helmet>

            <Header3 />
            <div className="lear_page _services sp-inners">
                <div className="banners">
                    <h2>Over The Counter (OTC) Services</h2>
                </div>
                <div className="main">
                    <div className="serv2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="img">
                                        <img
                                            src={require("./../../images/services/serv_img2.3.png")}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="txts">
                                        <h2>Over The Counter (OTC) Services</h2>
                                        <p>The Crypto Exchange offers In-Person Over-the-Counter (OTC) Crypto Exchange service providing a personalized and direct means to buy and sell cryptocurrencies outside of traditional online platforms. Where buyers and sellers can conduct face-to-face transactions. Investors looking to trade significant amounts of cryptocurrency can benefit from the personalized attention, immediate execution, and potentially favorable pricing offered by an OTC exchange. The process involves direct negotiations between the parties involved, with an OTC broker or representative facilitating the trade. This in-person OTC exchange service caters to individuals and Corporations seeking a more private, tailored, and immediate approach to cryptocurrency trading while emphasizing trust and transparency in the process.</p>
                                        <br />
                                        <br />
                                        <Link to="https://calendly.com/thecryptoexchange" target="_blank" className="btn btn-primary">
                                            Book a consultantion
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer1 />
        </>
    );
}

export default otc;