import React, { useEffect } from 'react'
import { decryptPassword } from '../../utils/Encryption';
import { regenerateCustomerToken, resetregenerateCustomerToken, resetregenerateCustomerTokenFailed } from '../../actions/authAction';
import { useDispatch, useSelector } from 'react-redux';
import { commonError } from '../../actions/customerActions';

const RegenerateCustomerToken = () => {
  const dispatch = useDispatch();

  // User JWT token
  const usertoken = sessionStorage.getItem("ut");

  const postRegenerateCustomerTokenData = useSelector((state) => state.authReducer.regenerateCustomerTokenData);
  const postRegenerateCustomerTokenFailed = useSelector((state) => state.authReducer.regenerateCustomerTokenFailed);

  // Regenerate customer token success
  useEffect(() => {
    if (Object.keys(postRegenerateCustomerTokenData).length !== 0) {
      // Store the Access token in Session Storage
      sessionStorage.setItem("at", postRegenerateCustomerTokenData?.data?.data?.at)
      dispatch(resetregenerateCustomerToken())
    }
  }, [postRegenerateCustomerTokenData]);

  // Regenerate customer token failed
  useEffect(() => {
    if (postRegenerateCustomerTokenFailed && Object.keys(postRegenerateCustomerTokenFailed)?.length !== 0) {
      dispatch(commonError(postRegenerateCustomerTokenFailed?.message));
      dispatch(resetregenerateCustomerTokenFailed());
    }
  }, [postRegenerateCustomerTokenFailed]);

  useEffect(() => {
    // Declare the timeOut variable for API call.
    let apiCallTimeOut;
    // Declare the timeOut variable for setTime function call.
    let functionCallTimeOut;
    // Define a function to set the functionCallingTime based on token and time difference.
    const setTime = () => {
      // Decode the token and calculate the time difference.
      const token = sessionStorage.getItem("at");  // Access token
      const tokenData = JSON.parse(atob(token.split(".")[1]));
      const tokenCreatedTime = tokenData.iat * 1000;
      const currentTime = Date.now();
      let timeDifference = currentTime - tokenCreatedTime;
      let minutesDifference = timeDifference / (1000 * 60);
      let functionCallingTime;

      if (token && minutesDifference > 25) {
        functionCallingTime = 1;
      } else if (token && minutesDifference > 20) {
        functionCallingTime = 5;
      } else if (token && minutesDifference > 15) {
        functionCallingTime = 10;
      } else if (token && minutesDifference > 10) {
        functionCallingTime = 15;
      } else if (token && minutesDifference > 5) {
        functionCallingTime = 20;
      } else {
        functionCallingTime = 23;
      }

      // Set a timeout to dispatch an action if conditions are met.
      apiCallTimeOut = setTimeout(() => {
        dispatch(regenerateCustomerToken({ cybrid_guid: decryptPassword(sessionStorage.guid) }, usertoken));
        // Call setTime Function to again set functionCallingTime.
        functionCallTimeOut = setTimeout(() => {
          setTime();
        }, 10 * 1000);
      }, functionCallingTime * 60 * 1000);
    }

    // Call setTime to set functionCallingTime.
    setTime();

    // Return a cleanup function to clear the timeout.
    return () => {
      clearTimeout(apiCallTimeOut);
      clearTimeout(functionCallTimeOut);
    };
  }, []);


  return (
    <></>
  )
}

export default RegenerateCustomerToken;
