import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetMarketPrice
} from "../../actions/customerActions";
import bitcoinLogo from "../../images/bitcoin.png";
import ethereumLogo from "../../images/ethereum.png";
import usdcEthereumLogo from "../../images/usdc.png";
import usdLogo from "../../images/usd.png";
import Gifloader from "../layout/gifloader";
import {useNavigate} from "react-router-dom";


const YourWalletBalance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let tempToken = sessionStorage.getItem("guid");


  const getMarketPriceRes = useSelector((state) => state.customerReducer.getMarketPrice);
  const getAccountDatais = useSelector((state) => state.customerReducer.getAccountData);


  const [childMarketPrice, setchildMarketPrice] = useState([]);
  const [liveSellBTC, setLiveSellBTC] = useState();
  const [liveSellETH, setLiveSellETH] = useState();
  const [liveSellUSDC, setLiveSellUSDC] = useState();
  const [accountData, setAccountData] = useState([]);
  const [selectedObj, setSelectedObj] = useState({});
  const [fiatAccount, setFiatAccount] = useState({});
  const [accountLoader, setAccountLoader] = useState(true);
  const [TotalBal, setTotalBal] = useState(true);
  const [usdPending, setUsdPending] = useState();

  useEffect(() => {
    // dispatch(getCustomerAccountDetails(sessionStorage.at, deToken));
  }, []);

  useEffect(() => {
    if (getMarketPriceRes.length !== 0) {
      // Determine the logo, name, symbol and graphData based on the item symbol property
      const updatedMarketPrice = getMarketPriceRes.map((item) => {
        let logo;
        let name;
        let symbol;
        let graphData;
        if (item.symbol === "BTC-USD") {
          logo = bitcoinLogo;
          name = "Bitcoin";
          symbol = "BTC";
        } else if (item.symbol === "ETH-USD") {
          logo = ethereumLogo;
          name = "Ethereum";
          symbol = "ETH";
        } else if (item.symbol === "USDC-USD") {
          logo = usdcEthereumLogo;
          name = "USDC";
          symbol = "USDC";
        }
        return {
          ...item,
          logo: logo,
          name: name,
          symbol: symbol,
          graphData: graphData,
        };
      });
      setchildMarketPrice(updatedMarketPrice);

      // Loop through the market price data and find the sell price based on the symbol
      const bitUsdObject = getMarketPriceRes.find((item) => item.symbol === "BTC-USD");
      const ethUsdObject = getMarketPriceRes.find((item) => item.symbol === "ETH-USD");
      const usdcUsdObject = getMarketPriceRes.find((item) => item.symbol === "USDC-USD");

      setLiveSellBTC(bitUsdObject?.sell_price);
      setLiveSellETH(ethUsdObject?.sell_price);
      setLiveSellUSDC(usdcUsdObject?.sell_price);

      setAccountLoader(false);
      dispatch(resetMarketPrice());
    }
  }, [getMarketPriceRes]);

  useEffect(() => {
    if (
      Object.keys(getAccountDatais).length !== 0 &&
      childMarketPrice.length !== 0
    ) {
      const fiatAccoubt = getAccountDatais.objects?.find(
        (account) => account.asset === "USD"
      );
      setFiatAccount(fiatAccoubt);

      // Determine the divider, logo, name, symbol and livePrice based on the account asset property
      const modifiedData = getAccountDatais.objects.map((account) => {
        let divider;
        let logo;
        let name;
        let symbol;
        let livePrice;
        if (account.asset === "BTC") {
          logo = bitcoinLogo;
          name = "Bitcoin";
          symbol = "BTC";
          divider = 100000000;
          livePrice = liveSellBTC;
        } else if (account.asset === "ETH") {
          logo = ethereumLogo;
          name = "Ethereum";
          symbol = "ETH";
          divider = 1000000000000000000;
          livePrice = liveSellETH;
        } else if (account.asset === "USDC") {
          logo = usdcEthereumLogo;
          name = "USDC";
          symbol = "USDC";
          divider = 1000000;
          livePrice = liveSellUSDC;
        } else if (account.asset === "USD") {
          let balance;
          logo = usdLogo;
          name = "United States Dollar";
          symbol = "USD";
          balance = (account.platform_available / 100).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );

          let usdPendingAmount = Number(account.platform_balance / 100) - Number(account.platform_available / 100);
          setUsdPending(usdPendingAmount);

          return {
            ...account,
            logo: logo,
            name: name,
            symbol: symbol,
            platform_balance: balance,
            balance_canadian: balance,
          };
        }

        // Add platform balance and balance canadian in modified data
        const platform_balance_million_units =
          account.platform_balance / divider;
          const platform_available_million_units =
          account.platform_available / divider;
        const balance_canadian =
          (platform_available_million_units * livePrice) / 100;
        return {
          ...account,
          logo: logo,
          name: name,
          symbol: symbol,
          platform_balance: platform_balance_million_units.toFixed(8),
          platform_available: platform_available_million_units.toFixed(8),
          balance_canadian: balance_canadian,
        };
      });

      // Add all the usd value in the platform 
      const totalCanadianBalance = modifiedData.reduce((total, asset) => {
        return total + parseFloat(Number(asset.balance_canadian) || 0);
      }, 0);
      setTotalBal(totalCanadianBalance.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      ));

      const assetsWithRatio = modifiedData.map((asset) => ({
        ...asset,
        ratio_percentage:
          asset.type === "fiat"
            ? 100
            : ((parseFloat(asset.balance_canadian) / totalCanadianBalance) * 100).toFixed(2),
      }));
      setSelectedObj(assetsWithRatio[0]);
      setAccountData(sortArrayBySymbol(assetsWithRatio));
      // dispatch(resetGetCustomerAccountDetails());
    }
  }, [getAccountDatais]);

  const sortArrayBySymbol = (array) => {
    const symbolOrder = ['USD', 'BTC', 'ETH', 'USDC'];

    return array.sort((a, b) => {
      const aSymbol = a.asset;
      const bSymbol = b.asset;
      const aIndex = symbolOrder.indexOf(aSymbol);
      const bIndex = symbolOrder.indexOf(bSymbol);

      return aIndex - bIndex;
    });
  }


  return (
    <>
      {accountLoader ? (
        <div className="card ccminh justify-content-center d-flex align-item-center fadeInDown">
          <Gifloader />
        </div>
      ) : (
        <>
          <div className="card ccminh fadeInDown">
            <div className="card-header">
              <h4 className="card-title">Account Balance</h4>

              {/* <div className="card-form">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <label className="input-group-text">
                      <img
                        src={selectedObj && selectedObj.logo}
                        alt=""
                        className="img-fluid"
                      />
                    </label>
                  </div>
                  <select
                    name="currency"
                    className="form-control"
                    onChange={(e) => handleCurrencyChange(e)}
                    value={selectedObj && selectedObj.symbol}
                  >
                    {accountData?.map((currency) => (
                      <option key={currency.symbol} value={currency.symbol}>
                        {currency.symbol}
                      </option>
                    ))}
                  </select>
                </div>
              </div> */}
            </div>

            {/* <div className="card-body">
              <div className="transaction-widget">
                <ul className="list-unstyled">
                  <li className="d-flex">
                    <div className="flex-grow-1">
                      <p>
                        <small>Type</small>
                      </p>
                    </div>
                    <div className="text-end">
                      <h4>
                        <span className="text-capitalize small">
                          {selectedObj && selectedObj.type}
                        </span>
                      </h4>
                    </div>
                  </li>

                  {selectedObj && selectedObj.type !== "fiat" && (
                    <li className="d-flex">
                      <div className="flex-grow-1">
                        <p>
                          <small>Holdings</small>
                        </p>
                      </div>
                      <div className="text-end">
                        <h4>
                          <span className="text-primary">
                            {selectedObj && selectedObj.ratio_percentage}
                          </span>{" "}
                         <span className="small">%</span>
                        </h4>
                      </div>
                    </li>
                  )}

                  <li className="d-flex">
                    <div className="flex-grow-1">
                      <p>
                        <small>Available Qty</small>
                      </p>
                    </div>
                    <div className="text-end">
                      <h4>
                        <span className="text-primary">
                          {selectedObj && selectedObj.platform_balance}
                        </span>{" "}
                        <span className="small">{selectedObj.asset}</span>
                      </h4>
                    </div>
                  </li>

                  {selectedObj && selectedObj.type !== "fiat" && (
                    <li className="d-flex">
                      <div className="flex-grow-1">
                        <p>
                          <small>Price</small>
                        </p>
                      </div>
                      <div className="text-end">
                        <h4>
                          <span className="text-primary">
                            ${selectedObj && selectedObj.balance_canadian}
                          </span>{" "}
                          <span className="small">USD</span>
                        </h4>
                      </div>
                    </li>
                  )}

                  <li className="d-flex">
                    <div className="flex-grow-1">
                      <p>
                        <small>Created At</small>
                      </p>
                    </div>
                    <div className="text-end">
                      <h4>
                        <span className="small">
                          {selectedObj &&
                            new Date(selectedObj.created_at).toLocaleString(
                              undefined,
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            )}
                        </span>
                      </h4>
                    </div>
                  </li>
                </ul>
              </div>
            </div> */}


            <div className="card-body">
              <div className="transaction-widget">
                {/* <ul className="list-unstyled">
                  {accountData?.map(item => {
                    return (
                      <li className="d-flex" key={item.guid}>
                        <div className="flex-grow-1">
                          <div className="coin_icontxt">
                            <img
                              src={item.logo}
                              alt=""
                              className="me-2"
                              width="32"
                            />
                            <span>
                              {/* <strong>{item.symbol}</strong><br/> */}
                             {/* {item.name}
                            </span>
                          </div>

                        </div>
                        {
                          item.symbol === "USD" && (
                            <div className="text-end">
                              <h4 className="mb-0">
                                {item.symbol === "USD" && "$"}
                                <span className="text-primary fw-bold">{item.platform_balance}</span>
                                <span className="small"> {item.symbol}</span>
                              </h4>
                              {item.symbol === "USD" && usdPending > 0 &&
                                <h6 className="mb-0">
                                  <span className="small">{usdPending > 0 ? `$${usdPending.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )} USD (Pending)` : ""}</span>
                                </h6>}
                            </div>
                          )
                        }
                        {
                          item.symbol !== "USD" && (
                            <div className="text-end">
                              <h4 className="mb-0">
                                <span className="text-primary fw-bold">{item.platform_available}</span>
                                <span className="small"> {item.symbol}</span>
                              </h4>
                              {item.symbol !== "USD" && <h6 className="mb-0">
                                <span className="small">{item.platform_balance > 0 ? `${item.platform_balance} Pending` : ""}</span>
                              </h6>}
                              {item.symbol !== "USD" && <h6 className="mb-0">
                                <span className="small">{item.balance_canadian > 0 ? `$${item.balance_canadian.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )} USD` : ""}</span>
                              </h6>}
                            </div>
                          )
                        }
                      </li>
                    )
                  })}

                </ul> */}

                <div className="act_bal">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                        <th className="text-start">Currencies</th>
                          <th>Available balance</th>
                          <th>Pending balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {accountData?.map(item => {
                          return (
                            <tr key={item.guid}>
                              <td>
                                <div className="flex-grow-1">
                                  <div className="coin_icontxt">
                                    <img
                                      src={item.logo}
                                      alt=""
                                      className="me-2"
                                      width="28"
                                    />
                                    <span>
                                      <strong>{item.name}</strong> - <span className="small">{item.symbol}</span>
                                    </span>
                                  </div>

                                </div>
                              </td>
                              <td>
                              {
                          item.symbol === "USD" && (
                            <div>
                              <h5 className="mb-0 text-nowrap">
                                {item.symbol === "USD" && "$"}
                                <span className="text-primary fw-bold">{item.platform_balance}</span>
                                <span> {item.symbol}</span>
                              </h5>
                              
                            </div>
                          )
                        }
                        {
                          item.symbol !== "USD" && (
                            <div>
                              <h5 className="mb-0 text-nowrap">
                                <span className="text-primary fw-bold">{item.symbol === "USDC" ? parseFloat(item.platform_available).toFixed(4) : item.platform_available}</span>
                                <span> {item.symbol}</span>
                              </h5>

                              {item.symbol !== "USD" && <h5 className="mb-0">
                                <span>{item.balance_canadian > 0 ? `($${item.balance_canadian.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )} USD)` : ""}</span>
                              </h5>}      
                            </div>
                          )
                        }
                              </td>
                              <td>
                              {item.symbol === "USD" &&
                                <h5 className="mb-0">
                                  <span>{usdPending > 0 ? `$${usdPending.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )} USD` : "$0.00 USD"}</span>
                                </h5>}

                               
                              {item.symbol !== "USD" && <h5 className="mb-0 text-nowrap">
                                <span>{item.symbol === "USDC" ? parseFloat(item.platform_balance).toFixed(4) : item.platform_balance}</span>
                                <span> {item.symbol}</span>
                              </h5>}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <hr className="m-0" />
                <div className="row align-items-center">
                        <div className="col-md-6">
                        <div className="largecoinbox border-0 align-items-center align-items-sm-start pb-0">
                          <div className="lcbtxt m-0">
                            <p className="small mb-0">Total Available Balance</p>
                            <h3 className="fw-bold dashtbtxt acbalainner text-center text-sm-start">
                              <span className="small">$</span>
                              <span className="text-primary">{TotalBal && TotalBal}</span>
                              <span className="small"> USD</span>
                            </h3>
                          </div>
                        </div>
                        </div>
                        <div className="col-md-6 text-end">
                        <div className="largecoinbox border-0 align-items-center align-items-sm-end px-0 pb-0">
                            <button type="button" className="btn btn-secondary" onClick={()=> navigate("/deposits")}><span>Add USD Funds</span></button>
                        </div>
                        </div>
                        </div>  

                
                {/* <hr />
                <div className="largecoinbox border-0 p-0 mt-4">
                  <div className="lcbtxt m-0 text-center">
                    <p className="small mb-2">Total Balance</p>
                    <h3 className="fw-bold dashtbtxt acbalainner">
                      <span className="small">$</span>
                      <span className="text-primary">{TotalBal && TotalBal}</span> <span className="small">USD</span>
                    </h3>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default YourWalletBalance;
