import React, { useEffect, useState } from 'react'
import Header3 from '../../layout/header3'
import Footer1 from '../../layout/footer1'
import AreaChart from '../../charts/area'
import { useDispatch, useSelector } from 'react-redux'
import { getEthFiveYearsData, resetEthFiveYearsData } from '../../../actions/customerActions'

const Ether = () => {
  const [oneYearValue, setOneYearValue] = useState([])
  const EthPricesForOneYear = useSelector((state) => state.customerReducer.getETHFiveYearsData);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEthFiveYearsData())
  }, [])

  useEffect(() => {
    if (EthPricesForOneYear && EthPricesForOneYear.length !== 0) {
      setOneYearValue(EthPricesForOneYear)
      dispatch(resetEthFiveYearsData())
    }
  }, [EthPricesForOneYear])
  
  return (
    <>
      <Header3 />
      <div className='lear_page sp-inners'>
        {/* <div className='banners'>
          <h2>Learn</h2>
        </div> */}

        <div className='main'>
          <div className='container'>
            <div>
              <div className='txt'>
                <h3>How to Buy Ether?</h3>
                <div className='d-flex align-items-center justify-content-center'>
                  <img className="img-fluid py-4 w-100 learn-page-img" src={require('../../../images/learn/how_to_buy_ether.png')} alt=""/>
                </div>
                
                <h3>Want to Buy Ether?</h3>
                <p>
                  Are you considering investing in Ether (ETH)? Notably, Ether's market
                  capitalization has surged, rivaling Bitcoin's dominance. With a total supply
                  capped at 120 million, Ether's scarcity makes it a valuable investment. Its
                  decentralized nature provides security and transparency, making investing easier
                  than you think. So let us explain the process of buying ether on our platform:
                </p>

                <img className="img-fluid py-4 w-100" src={require('../../../images/learn/how_to_buy_tce.png')} alt=""/>
                <h3>How to Buy Ether with The Crypto Exchange?</h3>
                <p>
                  Here's a step-by-step guide to help you with the process of buying ether through
                  our platform:
                </p>

                <h4>Create an account</h4>
                <p>
                  To begin, sign up for an account with The Crypto Exchange if you do not already
                  have one. Make sure you follow all the steps to register and verify your identity.
                </p>

                <h4>Connect Your Payment Method</h4>
                <p>
                  Credit cards, Google Wallet, and Apple Pay are a few of the many funding options
                  that you can use to make a payment. Thanks to our streamlined KYC linking process
                  you can manage your money flow easily in the platform. To add funds to your
                  account, you can choose between using Canadian dollars or US dollars.
                </p>

                <h4>Buy Ether</h4>
                <p>
                  After you've topped up your account, go to the "buy" tab in The Crypto Wallet to
                  purchase Ethereum (ETH). Choose the amount you want to buy in Ether (ETH) and then
                  click the "Buy" button. Now, proceed with the purchase of Ether (ETH) by following
                  the on-screen instructions and selecting the funding method.
                </p>

                <p>
                  After the purchase is finalized, you can view the Ether you bought in your wallet
                  within The <a href='https://thecryptowallet.io/' style={{ color: '#1355d7' }}>Crypto Wallet</a>.
                </p>

                <h3>Why buy ETH?</h3>
                <p>
                  Ether is a valuable asset for investors who want to capitalize on the growth of
                  blockchain technology. Do you still have thoughts on why you should buy ether? Let
                  us explain:
                </p>

                <h4>Digital Currency with Growing Adoption:</h4>
                <p>
                  It has been a prominent digital currency with its widespread adoption as a means
                  of exchange and store of value within merchants and businesses. This is worth it
                  for those seeking exposure to the digital asset market as its utility continues to
                  expand in the upcoming time.
                </p>

                <h4>Market Liquidity:</h4>
                <p>
                  With high trading volumes across various exchanges, Ether tops the list of the
                  most liquid cryptocurrencies. This means it ensures easy buying and selling with
                  quick transactions.
                </p>

                <h4>Speculative Investment Potential:</h4>
                <p>
                  The significant price fluctuations over time can help investors capitalize and
                  generate returns through trading or long-term holding strategies.
                </p>

                <h4>Deflationary Economics:</h4>
                <p>
                  Wondering what is Deflationary Economics? As transactions occur on the Ethereum
                  network, a portion of the transaction fees (gas fees) is burned. It reduces the
                  total supply of ETH over time, which contributes to price appreciation as demand
                  outstrips supply.
                </p>

                <h4>Global Acceptance and Adoption: </h4>
                <p>
                  It is a legitimate digital currency with widespread global adoption across various
                  industries, including finance, gaming, and decentralized finance (DeFi)
                  applications.{' '}
                </p>

                <div className="py-4 px-0 px-md-5">
                  <AreaChart BtcPriceEachDay={oneYearValue} cryptoName={'Ethereum'} />
                </div>

                <h3>Where Your ETH Goes After Purchase?</h3>
                <p>
                  When you buy ETH, it is transferred into your wallet from the sellers. For
                  transparency and security, all these transactions are recorded on the Ethereum
                  blockchain. Here’s a look at the entire process:
                </p>

                <h4>Verification Process:</h4>
                <p>
                  For verification, nodes - which are computers linked to the Ethereum network,
                  check all transactions on the Ethereum blockchain. It uses a consensus mechanism,
                  Proof-of-Stake (PoS); validators check and ensure the transactions are legitimate
                  and integrity.
                </p>

                <h4>Block Creation:</h4>
                <p>
                  After being checked, verified transactions are put together in a new "block,"
                  which is added to the blockchain. This process is known as block creation, which
                  maintains a reliable ledger of transactions, its continuity and immutability.
                </p>

                <h4>Circulation:</h4>
                <p>
                  The ETH you bought will appear in your wallet after the transaction is approved
                  and the block is added to the blockchain. You are now in charge of all of your ETH
                  and can sell, hold, or spend it. Depending on your needs, you can also move ETH to
                  other wallets, both on and off the market.
                </p>

                <h4>Staking:</h4>
                <p>
                  In Ethereum's Proof of Stake (PoS) model, people who own ETH can stake their
                  tokens to help protect the network. By "staking" ETH, holders lock up a certain
                  number of tokens to become validators. These people help process transactions,
                  create blocks, and get paid for their work maintaining the network.
                </p>

                <h3>What can I do after I buy Ethereum (ETH)?</h3>
                <h4>Hold as an Investment:</h4>
                <p>
                  Keeping your ETH as an <a href='https://cryptoexperts.ca/' style={{ color: '#1355d7' }}>investment</a> for the
                  long run is one of the simplest strategies. Many investors believe that Ethereum's
                  value will rise over time due to its widespread adoption and ongoing development.
                </p>

                <h4>Participate in Initial Coin Offerings (ICOs) and Token Sales:</h4>
                <p>
                  The Ethereum platform is used by a lot of new blockchain projects to sell their
                  shares in ICOs and other ways. You can invest in these new projects early with
                  your ETH and get profits from their growth and success in the future.
                </p>

                <h4>Support Decentralized Autonomous Organizations (DAOs):</h4>
                <p>
                  In decentralized autonomous organizations (DAOs), which are organizations governed
                  by smart contracts and community voting, you can use your ETH to participate. When
                  you possess and contribute to ETH, you have the ability to influence the decisions
                  and directions of blockchain projects and initiatives.
                </p>
                <p>
                  When you invest in Ether, you have the opportunity to benefit from its continued
                  growth and development. In multiple ways, you can use this digital currency to
                  diversify your portfolio. So, why wait? Invest in Ether now and be part of the
                  future of digital innovation and financial growth!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer1 />
    </>
  )
}

export default Ether
