import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Card, Tab, Tabs } from "react-bootstrap";
import Header3 from "../layout/header3";
import Footer1 from "../layout/footer1";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";


function Learn() {
    return (
        <>
            {/* Meta Tag */}
            <Helmet>
                <title>Everything You Need To Know About Crypto Trading & Exchange</title>
                <meta name="description" content="New to crypto? Learn the ropes with our easy-to-understand resources. Get started with your crypto journey with our crypto wallet app. Join us today!" />
            </Helmet>

            <Header3 />
            <div className="lear_page sp-inners">
                <div className="banners">
                    <h2>Learn</h2>
                </div>
                <div className="main">
                    <div className="container">
                        {/* <div className="blk d-flex">
                            <div className="txt">
                                <h3>Support</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            </div>
                            <div className="img">
                                <img
                                    src={require("./../../images/learn/support_img.png")}
                                    alt=""
                                    className="img-fluid"
                                />
                            </div>
                        </div> */}
                        <div className="blk d-flex">
                            <div className="txt">
                                <h3>What is Bitcoin?</h3>
                                <p>Bitcoin is a decentralized digital currency that operates on a peer-to-peer network, allowing users to send and receive payments without the need for an intermediary like a bank. Created in 2009 by an unknown person or group using the pseudonym Satoshi Nakamoto, Bitcoin is based on blockchain technology (SHA265 Algorithm)—a distributed ledger that records all transactions across a network of computers. Bitcoin is often referred to as "digital gold" due to its limited supply of 21 million coins, which contributes to its scarcity and potential store of value. Transactions on the Bitcoin network are verified by network nodes secured by hundreds of thousands of computers across the globe through cryptography and recorded on the blockchain. Beyond its financial implications, Bitcoin has sparked discussions about the future of money, financial sovereignty, and the potential for blockchain technology to revolutionize various industries.</p>
                            </div>
                            <div className="img">
                                <img
                                    src={require("./../../images/learn/buy_bitcon.png")}
                                    alt=""
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                        <div className="blk d-flex">
                            <div className="txt">
                                <h3>How to Buy Bitcoin in Canada and US?</h3>
                                <p>To buy Bitcoin in Canada and the US, you can follow these general steps. The Crypto Exchange offers Bitcoin for purchase and sale in both countries. Create an account on our platform and provide the necessary personal information to complete the verification process. Once your account is set up and verified, link your bank account via Plaid and deposit funds into your account using a bank transfer, or other accepted payment methods. With funds in your account, navigate to the Exchange section and select Bitcoin as the cryptocurrency you want to buy. Enter the amount you wish to purchase and review the transaction details. Finally, confirm your purchase, and the Bitcoin will be credited to your account. After securing your Bitcoin purchase, withdrawing the purchased Bitcoin to a self-custody wallet, where you have control over your private keys is required. If you are in need of a self custody wallet you can download one for a trusted source. <a href="www.thecryptowallet.io" target="_blank" style={{color: "#1355d7"}}>www.thecryptowallet.io</a></p>
                                <div className="download_sec pt-0">
                                    <div className="gastore">
                                        {/* <a href="http://www.thecryptowallet.io/" target="_blank" title="Google Play store">
                                            <img
                                                src={require("../../images/google_button.png")}
                                                alt=""
                                                width="400"
                                                className="img-fluid"
                                            />
                                        </a> */}
                                        <a href="https://apps.apple.com/us/app/the-crypto-wallet/id6478397189" target="_blank" title="Apple store">
                                            <img
                                                src={require("../../images/apple_button.png")}
                                                alt=""
                                                width="400"
                                                className="img-fluid"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="img">
                                <img
                                    src={require("./../../images/learn/invest_bitcon.png")}
                                    alt=""
                                    className="img-fluid"
                                />
                            </div>
                        </div>

                        
                        
                        {/* <div className="blogs">
                            <h3>Blog</h3>
                            <ul>
                                <li>
                                    <div className="box">
                                        <div className="img">
                                            <img
                                                src={require("./../../images/learn/blog_img.jpg")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="text">
                                            <span>06/09/2023</span>
                                            <h4>Blog 1</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt.</p>
                                            <button className="btn btn-primary">Read More</button>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="box">
                                        <div className="img">
                                            <img
                                                src={require("./../../images/learn/blog_img.jpg")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="text">
                                            <span>06/09/2023</span>
                                            <h4>Blog 2</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt.</p>
                                            <button className="btn btn-primary">Read More</button>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="box">
                                        <div className="img">
                                            <img
                                                src={require("./../../images/learn/blog_img.jpg")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="text">
                                            <span>06/09/2023</span>
                                            <h4>Blog 3</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt.</p>
                                            <button className="btn btn-primary">Read More</button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer1 />
        </>
    );
}

export default Learn;