import React from "react";

function PageNotFound() {
    return (

        <div className="confetti-box">
            
            <div className="confetti"><img src={require('./../../images/bch.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/bitcoin.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/LTC.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/ethereum.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/usdc.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/usd.png')} alt="" /></div>
            {/* <div className="confetti"><img src={require('./../../images/canada.png')} alt="" /></div> */}
            
            <div className="confetti"><img src={require('./../../images/usdc.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/usd.png')} alt="" /></div>
            {/* <div className="confetti"><img src={require('./../../images/canada.png')} alt="" /></div> */}
            <div className="confetti"><img src={require('./../../images/bch.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/bitcoin.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/LTC.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/ethereum.png')} alt="" /></div>

            <div className="confetti"><img src={require('./../../images/usd.png')} alt="" /></div>
            {/* <div className="confetti"><img src={require('./../../images/canada.png')} alt="" /></div> */}
            <div className="confetti"><img src={require('./../../images/bch.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/usdc.png')} alt="" /></div>            
            <div className="confetti"><img src={require('./../../images/bitcoin.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/LTC.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/ethereum.png')} alt="" /></div>

            <div className="confetti"><img src={require('./../../images/usdc.png')} alt="" /></div>            
            <div className="confetti"><img src={require('./../../images/bitcoin.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/LTC.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/usd.png')} alt="" /></div>
            {/* <div className="confetti"><img src={require('./../../images/canada.png')} alt="" /></div> */}
            <div className="confetti"><img src={require('./../../images/bch.png')} alt="" /></div>            
            <div className="confetti"><img src={require('./../../images/ethereum.png')} alt="" /></div>

            <div className="confetti"><img src={require('./../../images/bch.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/bitcoin.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/LTC.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/ethereum.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/usdc.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/usd.png')} alt="" /></div>
            {/* <div className="confetti"><img src={require('./../../images/canada.png')} alt="" /></div> */}
            
            <div className="confetti"><img src={require('./../../images/usdc.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/usd.png')} alt="" /></div>
            {/* <div className="confetti"><img src={require('./../../images/canada.png')} alt="" /></div> */}
            <div className="confetti"><img src={require('./../../images/bch.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/bitcoin.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/LTC.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/ethereum.png')} alt="" /></div>

            <div className="confetti"><img src={require('./../../images/usd.png')} alt="" /></div>
            {/* <div className="confetti"><img src={require('./../../images/canada.png')} alt="" /></div> */}
            <div className="confetti"><img src={require('./../../images/bch.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/usdc.png')} alt="" /></div>            
            <div className="confetti"><img src={require('./../../images/bitcoin.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/LTC.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/ethereum.png')} alt="" /></div>

            <div className="confetti"><img src={require('./../../images/usdc.png')} alt="" /></div>            
            <div className="confetti"><img src={require('./../../images/bitcoin.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/LTC.png')} alt="" /></div>
            <div className="confetti"><img src={require('./../../images/usd.png')} alt="" /></div>
            {/* <div className="confetti"><img src={require('./../../images/canada.png')} alt="" /></div> */}
            <div className="confetti"><img src={require('./../../images/bch.png')} alt="" /></div>            
            <div className="confetti"><img src={require('./../../images/ethereum.png')} alt="" /></div>


           
            

            <div className="pnftxt">
                <h1>404</h1>
                <h4>Oops! Page not found</h4>
                <p>Sorry we're unable to find the page you're looking for.</p>
                <a href="/dashboard" className="btn btn-primary">Go Back</a>
            </div>
        </div>



    );
}

export default PageNotFound;
