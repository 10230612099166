import React from "react";
import Header3 from "../layout/header3";
import Footer1 from "../layout/footer1";


function SafeandSecureCryptoWallet() {
    return (
        <>
            <Header3 />
            <div className="explore sp-inners">
                <div className="banners">
                    <h2>Safe and Secure Crypto Wallet​</h2>
                </div>
                <div className="main">
                    <div className="container">
                        <div className="blk">
                            <div className="img">
                                <img src={require("./../../images/workflow6.png")} alt="" className="img-fluid" />
                            </div>
                            <div className="txt">
                                <p>Explore boundless opportunities with our integrated Crypto Exchange wallet, where you can seamlessly trade hundreds of additional cryptocurrencies and even perform cross-chain swaps. Our platform goes beyond the mainstream options, offering you access to a diverse and ever-expanding universe of digital assets. This means you can explore and invest in projects and tokens that align with your unique investment goals and strategies, seizing opportunities in emerging markets and technologies. Whether you're seeking established cryptocurrencies or want to dive into promising altcoins, our platform caters to your appetite for diversity and innovation in the crypto space, all within the convenience of a unified Crypto Exchange wallet that enables cross-chain swaps.​</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer1 />
        </>
    );
}

export default SafeandSecureCryptoWallet;
