import React, { } from 'react';
import Header3 from '../layout/header3';
import Footer1 from '../layout/footer1';



function PrivacyAgreement() {

    return (
        <>
            <Header3 />
            <div className="terms_condition">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="terms_condition-content">
                                <div className="terms_condition-text">
                                    <h3>Crypto Exchange User/Client Agreement </h3>
                                    <p>This User/Client Agreement (the "<strong>Agreement</strong>") is made and entered into between The Crypto Exchange, a company registered in Canada and the United States ("<strong>Exchange</strong>"), and the individual or entity ("<strong>User</strong>" or "<strong>Client</strong>") who agrees to the terms and conditions outlined below. By accessing, registering, or using the services provided by The Crypto Exchange, the User acknowledges and agrees to be bound by this Agreement. </p>
                                    <p className="mb-0">● We do not rent or sell your personal information to anyone.</p>
                                    <br />
                                    <p className="mb-0">● Any personal information you provide will be secured by us.</p>
                                    <p>● You will be able to erase all the data we have stored on you at any given
                                        time. To request data termination, please contact our customer support.</p>
                                </div>


                                <div className="terms_condition-text">
                                    <h3>1. Definitions </h3>
                                    <p>1.1. Exchange: The Crypto Exchange, a non-custodial cryptocurrency exchange platform. 
                                        <br />1.2. User/Client: The individual or entity accessing and using the services provided by The Crypto Exchange. 
                                        <br />1.3. Services: The cryptocurrency trading and exchange services provided by The Crypto Exchange through its platform. <br />1.4. Wallet: A digital wallet on The Crypto Exchange's platform used to store and manage cryptocurrencies. <br />1.5. Cryptocurrencies: Digital or virtual currencies that use cryptography for security. </p>
                                </div>

                                <div className="terms_condition-text">
                                    <h3>2. Account Registration </h3>
                                    <p>2.1. To access the Services, the User must create an account on The Crypto Exchange's platform and provide accurate and complete information. <br />2.2. The User is solely responsible for maintaining the confidentiality of their account credentials, including passwords and private keys. <br />2.3. The User agrees not to share their account credentials with any third party and to notify The Crypto Exchange immediately of any unauthorized access. 
                                </p>
                                </div>

                                <div className="terms_condition-text">
                                    <h3>3. Non-Custodial Nature of Exchange</h3>
                                    <p>3.1. The Crypto Exchange operates as a non-custodial platform, meaning that Users retain control over their private keys and cryptocurrencies. <br />3.2. The Crypto Exchange does not hold custody of User funds or private keys. Users are solely responsible for the security of their private keys and the safekeeping of their cryptocurrencies. 
                                </p>
                                </div>

                                <div className="terms_condition-text">
                                    <h3>4. Trading Risks </h3>
                                    <p>4.1. Cryptocurrency trading involves substantial risk and is subject to market volatility. The User acknowledges and accepts that they may lose a significant portion or the entirety of their investment. <br />4.2. The User agrees to conduct their own research and due diligence before making any trades or investments on The Crypto Exchange. <br />4.3. The Crypto Exchange does not provide financial advice or recommendations. Users should consult with qualified financial professionals before making trading decisions. 
                                </p>
                                </div>

                                <div className="terms_condition-text">
                                    <h3>5. Prohibited Activities</h3>
                                    <p>5.1. The User agrees not to use the Services for any illegal or fraudulent activities. 
                                        <br />5.2. The User shall not use The Crypto Exchange to launder money, facilitate terrorist financing, or engage in any other unlawful activities.  
                                </p>
                                </div>

                                <div className="terms_condition-text">
                                    <h3>6. Fees and Charges </h3>
                                    <p>6.1. The Crypto Exchange may charge fees for certain Services, including trading and withdrawal fees. Users are responsible for reviewing and accepting these fees. <br />6.2. Fees may be subject to change and will be prominently displayed on The Crypto Exchange's platform. 
                                </p>
                                </div>


                                <div className="terms_condition-text">
                                    <h3>7. Privacy and Data Security </h3>
                                    <p>7.1. The Crypto Exchange collects and processes User data in accordance with its Privacy Policy. 
                                        <br />7.2. The User acknowledges and agrees to The Crypto Exchange's data handling practices as outlined in the Privacy Policy. 
                                </p>
                                </div>

                                <div className="terms_condition-text">
                                    <h3>8. Termination </h3>
                                    <p>8.1. Either party may terminate this Agreement at any time for any reason by providing written notice to the other party. <br />8.2. Upon termination, the User must cease all use of The Crypto Exchange's platform and services. 
                                </p>
                                </div>

                                <div className="terms_condition-text">
                                    <h3>9. Deposit and Withdrawal of Cryptocurrencies</h3>
                                    <p>9.1. The User may deposit cryptocurrencies to their Exchange wallet for trading purposes. 
                                        <br />9.2. The User acknowledges that The Crypto Exchange encourages timely withdrawal of cryptocurrencies from the platform for security reasons. <br />9.3. The User agrees to withdraw their cryptocurrencies from their Exchange wallet within 72 hours of completing a trade or depositing to the platform.
                                        <br />9.4. The Crypto Exchange shall not be held responsible for any loss of funds if the User fails to withdraw their cryptocurrencies within the allotted timeframe. 
                                    </p>
                                </div>

                                <div className="terms_condition-text">
                                    <h3>10. Governing Law and Dispute Resolution </h3>
                                    <p>10.1. This Agreement shall be governed by and construed in accordance with the laws of United States. 
                                        <br />10.2. Any disputes arising from or in connection with this Agreement shall be resolved through arbitration in accordance with the rules of [Arbitration Institution]. 
                                        By accessing, registering, or using the services provided by The Crypto Exchange, the User acknowledges that they have read, understood, and agreed to be bound by the terms and conditions of this Agreement.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer1 />
        </>
    )
}

export default PrivacyAgreement;