import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Accounts from "./pages/accounts";
import AddBankAccount from "./pages/add-bank-acc";
import AddDebitCard from "./pages/add-debit-card";
import BuySell from "./pages/buy-sell";
import PaymentMethod from "./pages/payment-method";
import Dashboard from "./pages/index";
import Landing from "./pages/landing";
import Locked from "./pages/lock";
import Otp1 from "./pages/otp-1";
import Otp2 from "./pages/otp-2";
import PrivacyAgreement from "./pages/privacy-agreement";
import PrivacyPolicy from "./pages/privacy-policy";
import Reset from "./pages/reset";
import Settings from "./pages/settings";
import SettingsAccount from "./pages/settings-account";
import Preferences from "./pages/settings-preferences";
import SettingsSecurity from "./pages/settings-security";
import Signin from "./pages/signin";
import Signup from "./pages/signup";
import TermsCondition from "./pages/terms-condition";
import VerifyStep1 from "./pages/verify-step-1";
import VerifyStep2 from "./pages/verify-step-2";
import VerifyStep3 from "./pages/verify-step-3";
import VerifyStep4 from "./pages/verify-step-4";
import VerifyStep5 from "./pages/verify-step-5";
import VerifyStep6 from "./pages/verify-step-6";
import Deposits from "./pages/deposits";
import Withdrawals from "./pages/withdrawals";
import Transactions from "./pages/transactions";
import PageNotFound from "./pages/page-not-found";
import  { ProtectedRoute,AuthenticateRoute } from "./ProtectedRoute";
import About from "./pages/about";
import ReferralProgram from "./pages/referral-program";
import Wallet from "./pages/wallet";
import Gettingstarted from "./pages/getting-started";
import Learn from "./pages/learn";
import Services from "./pages/services";
import ExternalWallet from "./pages/external-wallet";
import Logout from "./shared/Logout";
import InstantBuySellandWithdraw from "./pages/Instant-Buy-Sell-and-Withdraw";
import RealTimeQuotes from "./pages/Real-Time-Quotes";
import NonCustodial from "./pages/Non-Custodial";
import SafeandSecureCryptoWallet from "./pages/Safe-and-Secure-Crypto-Wallet";
import AdminLogin from "./pages/admin/AdminLogin";
import {AdminAuthenticateRoute, AdminRoute} from "./pages/admin/AdminRoute";
import CustomerList from "./pages/admin/CustomerList";
import CustomerDetails from "./pages/admin/CustomerDetails";
import Trades from "./pages/admin/Trades";
import CustomerAccounts from "./pages/admin/CustomerAccounts";
import Sales from "./pages/admin/Sales";
import Faq from "./pages/faq";
import UserAgreement from "./pages/user-agreement";
import ResetPassword from "./pages/reset-password";
import Otc from "./pages/otc";
import Contact from "./pages/contact";

import WIIBitcoin from "./pages/whatItIs/Bitcoin";
import WIILitecoin from "./pages/whatItIs/Litecoin";
import WIIBitcoinCash from "./pages/whatItIs/BitcoinCash";
import WIIXRP from "./pages/whatItIs/XRP";
import WIIEther from "./pages/whatItIs/Ether";
import HTBBitcoin from "./pages/howToBuy/Bitcoin";
import HTBLitecoin from "./pages/howToBuy/Litecoin";
import HTBEther from "./pages/howToBuy/Ether";
import HTBBitcoinCash from "./pages/howToBuy/BitcoinCash";
import HTBXRP from "./pages/howToBuy/XRP";

import Windsor from"./pages/static/serviceArea/Windsor";


const isAuthenticated = !!sessionStorage.getItem("at");

function Index() {
  return (
    <>
      <BrowserRouter>
        <div id="main-wrapper">
          <Routes>
            <Route 
              path="/admin/signin"
              element={
                <AdminAuthenticateRoute>
                  <AdminLogin/>
                </AdminAuthenticateRoute>
              }
            />
            
            <Route 
              path="/admin/customer"
              element={
                <AdminRoute>
                  <CustomerList/>
                </AdminRoute>
              }
            />

            <Route 
              path="/admin/customer/:guid"
              element={
                <AdminRoute>
                  <CustomerDetails/>
                </AdminRoute>
              }
            />

            <Route 
              path="/admin/trades"
              element={
                <AdminRoute>
                  <Trades/>
                </AdminRoute>
              }
            />
            <Route 
              path="/admin/customer-accounts"
              element={
                <AdminRoute>
                  <CustomerAccounts/>
                </AdminRoute>
              }
            />
            <Route 
              path="/admin/sales"
              element={
                <AdminRoute>
                  <Sales/>
                </AdminRoute>
              }
            />
            
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />

            <Route
              path="/exchange"
              element={
                <ProtectedRoute>
                  <BuySell />
                </ProtectedRoute>
              }
            />
            <Route
              path="/assets"
              element={
                <ProtectedRoute>
                  <Accounts />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route path="/" element={<Landing />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<PrivacyAgreement />} />
            <Route path="/user-agreement" element={<UserAgreement />} />
            <Route path="/getting-started" element={<Gettingstarted />} />

            <Route
              path="/withdrawals"
              element={
                <ProtectedRoute>
                  <Withdrawals />
                </ProtectedRoute>
              }
            />
            <Route
              path="/orders"
              element={
                <ProtectedRoute>
                  <Transactions />
                </ProtectedRoute>
              }
            />

            <Route
              path="/deposits"
              element={
                <ProtectedRoute>
                  <Deposits />
                </ProtectedRoute>
              }
            />

            <Route
              path="/payment-method"
              element={
                <ProtectedRoute>
                  <PaymentMethod />
                </ProtectedRoute>
              }
            />

            <Route
              path="/external-wallet"
              element={
                <ProtectedRoute>
                  <ExternalWallet />
                </ProtectedRoute>
              }
            />

            <Route 
              path="/settings-preferences" 
              element={
                <ProtectedRoute>
                  <Preferences />
                </ProtectedRoute>
              }
            />
            {/* 
            <Route path="/settings-security" element={<SettingsSecurity />} />
            <Route path="/settings-account" element={<SettingsAccount />} /> */}
            <Route
              path="/signin"
              element={
                <AuthenticateRoute>
                  <Signin />
                </AuthenticateRoute>
              }
            />

            <Route path="/signup" element={
              <AuthenticateRoute>
              <Signup />
            </AuthenticateRoute>
            } />
            <Route path="/reset" element={<Reset />} />
            <Route path="/reset-password" element={<ResetPassword/>} />
            <Route path="/buy-crypto-in-windsor" element={<Windsor/>} />

            {/* 
            <Route path="/add-bank-acc" element={<AddBankAccount />} />
            <Route path="/add-debit-card" element={<AddDebitCard />} />
            <Route path="/lock" element={<Locked />} />
            <Route path="/otp-1" element={<Otp1 />} />
            <Route path="/otp-2" element={<Otp2 />} />
            <Route path="/terms-condition" element={<TermsCondition />} />
            <Route path="/verify-step-1" element={<VerifyStep1 />} />
            <Route path="/verify-step-2" element={<VerifyStep2 />} />
            <Route path="/verify-step-3" element={<VerifyStep3 />} />
            <Route path="/verify-step-4" element={<VerifyStep4 />} />
            <Route path="/verify-step-5" element={<VerifyStep5 />} />
            <Route path="/verify-step-6" element={<VerifyStep6 />} />
            */}

            {/* <Route path="/demo" element={<Demo />} /> */}

            <Route path="/about" element={<About/>} />
            <Route path="/referral-program" element={<ReferralProgram/>} />
            <Route path="/wallet" element={<Wallet/>} />
            <Route path="/learn" element={<Learn/>} />
            <Route path="/faq" element={<Faq/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/otc" element={<Otc/>} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/Instant-Buy-Sell-and-Withdraw" element={<InstantBuySellandWithdraw />} />
            <Route path="/Real-Time-Quotes" element={<RealTimeQuotes />} />
            <Route path="/Non-Custodial" element={<NonCustodial />} />
            <Route path="/Safe-and-Secure-Crypto-Wallet" element={<SafeandSecureCryptoWallet />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/what-is-bitcoin-btc" element={<WIIBitcoin />} />
            <Route path="/what-is-ether" element={<WIIEther />} />
            <Route path="/what-is-litecoin" element={<WIILitecoin />} />
            <Route path="/what-is-bitcoin-cash" element={<WIIBitcoinCash />} />
            <Route path="/what-is-XRP" element={<WIIXRP />} />

            <Route path="/how-to-buy-bitcoin-canada" element={<HTBBitcoin />} />
            <Route path="/how-to-buy-ether" element={<HTBEther />} />
            <Route path="/how-to-buy-litecoin" element={<HTBLitecoin />} />
            <Route path="/how-to-buy-bitcoin-cash" element={<HTBBitcoinCash />} />
            <Route path="/how-to-buy-XRP" element={<HTBXRP />} />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default Index;
