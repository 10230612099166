import React, { useEffect, useRef, useState } from 'react'
import Footer1 from './../layout/footer1'
import Header3 from '../layout/header3'
import { useDispatch, useSelector } from 'react-redux'
import {
  commonError,
  commonSuccess,
  contactFormDetails,
  resetPostContactFormData,
  resetPostContactFormDataFailed,
} from '../../actions/customerActions'
import ReCAPTCHA from 'react-google-recaptcha'
import ButtonLoader from '../layout/buttonLoader'

function Contact() {
  const dispatch = useDispatch()
  const reCaptchaRef = useRef()

  const postContactFormData = useSelector((state) => state.customerReducer.postContactFormData)
  const postContactFormDataFailed = useSelector((state) => state.customerReducer.postContactFormDataFailed)

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    type: 'Contact Form',
    sent_by: 'user'
  })
  const [isContactFormBtnLoading, setIsContactFormBtnLoading] = useState(false)
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const onChange = (value) => {
    console.log('Captcha value:', value)
    setIsRecaptchaChecked(!!value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!validateForm()) {
      dispatch(commonError('Form submission failed. Please fill in all fields.'))
      return
    }

    // Check if reCAPTCHA is checked
    if (!isRecaptchaChecked) {
      dispatch(commonError('Please check the reCAPTCHA.'))
      return
    }

    // All validations passed, proceed to dispatch action
    dispatch(contactFormDetails(formData))
    setIsContactFormBtnLoading(true)
  }

  const validateForm = () => {
    return (
      formData.name.trim() !== '' && formData.email.trim() !== '' && formData.message.trim() !== ''
    )
  }

  useEffect(() => {
    if (Object.keys(postContactFormData).length !== 0 && postContactFormData.statusCode === 200) {
      dispatch(commonSuccess('Form submitted successfully!'))
      setFormData({
        name: '',
        email: '',
        message: '',
      })
      dispatch(resetPostContactFormData())
      setIsContactFormBtnLoading(false)

      // Reset the reCAPTCHA value
      reCaptchaRef.current.reset()
      setIsRecaptchaChecked(false)
    }
  }, [postContactFormData])

  useEffect(() => {
    if (postContactFormDataFailed && Object.keys(postContactFormDataFailed).length !== 0) {
      let errObj = postContactFormDataFailed
      dispatch(commonError(errObj.data.error_message || 'Something went wrong, please try again later'))
      dispatch(resetPostContactFormDataFailed())
      setIsContactFormBtnLoading(false)

      // Reset the reCAPTCHA value
      reCaptchaRef.current.reset()
      setIsRecaptchaChecked(false)
    }
  }, [postContactFormDataFailed])

  return (
    <>
      <Header3 />
      <div className='about-one sp-inners'>
        <div className='banners'>
          <h2>Contact</h2>
        </div>
        <div className='contact-form section-padding'>
          <div className='container'>
            <div className='row justify-content-center text-center'>
              <div className='col-xl-6'>
                <div className='section-title'>
                  <h2>Connect with us today!</h2>
                  <p>
                    Have a question or need assistance? Contact our friendly support team for prompt
                    assistance. We're here to help!
                  </p>
                </div>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-xl-6 px-5 mt-5'>
                <form onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='mb-3'>
                        <label htmlFor='contactEmail'>What is your email?</label>

                        <input
                          type='email'
                          className='form-control'
                          id='contactEmail'
                          placeholder='Enter your email'
                          name='email'
                          value={formData.email || ''}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='mb-3'>
                        <label htmlFor='contactName'>What is your name?</label>

                        <input
                          type='text'
                          className='form-control'
                          id='contactName'
                          placeholder='Enter your name'
                          name='name'
                          value={formData.name || ''}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12'>
                      <div className='mb-3 mb-7 mb-md-9'>
                        <label htmlFor='contactMessage'>Write your message</label>

                        <textarea
                          className='form-control'
                          id='contactMessage'
                          rows='15'
                          placeholder='Type your message...'
                          name='message'
                          value={formData.message || ''}
                          onChange={handleInputChange}
                          style={{ height: '120px' }}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className='row justify-content-center'>
                    <div className='col-auto mb-3 mb-7 mb-md-9'>
                      <ReCAPTCHA
                        ref={reCaptchaRef}
                        onChange={onChange}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE}
                      />
                    </div>
                  </div>

                  <div className='row justify-content-center'>
                    <div className='col-auto'>
                      <button
                        type='submit'
                        className={`btn btn-primary btn-loader ${
                          isContactFormBtnLoading ? 'active' : ''
                        }`}
                      >
                        <span>Send</span>
                        <ButtonLoader />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer1 />
    </>
  )
}

export default Contact
