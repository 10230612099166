import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decryptPassword, formatDate } from "../../utils/Encryption";
import {
  postQuotesAction,
  postTransferAction,
  resetPostQuotesAction,
  resetPostTransferAction,
  commonError,
  commonSuccess,
  resetpostTransferFailed,
  resetpostQuoteFailed,
} from "../../actions/customerActions";
import ButtonLoader from "../layout/buttonLoader";
import Gifloader from "../layout/gifloader";
import { EmailNotification } from "../../actions/authAction";
import CustomerVerificationPopup from "./CustomerVerificationPopup";
import { Link } from "react-router-dom";


const Deposits = ({ bankAccount }) => {
  const dispatch = useDispatch();


  const getTransferQuote = useSelector((state) => state.customerReducer.postQuoteData);
  const postTransferData = useSelector((state) => state.customerReducer.postTransferData);
  const postTransferQuoteFailed = useSelector((state) => state.customerReducer.postQuoteFailed);
  const postTransferFailed = useSelector((state) => state.customerReducer.postTransferfailed);


  const [isGetQuote, setGQ] = useState(false);
  const [isGQview, setGQV] = useState(false);
  const [isOrderSub, setOS] = useState(false);
  const [bankLists, setBankLists] = useState([]);
  const [usdAmount, setUsdAmount] = useState("");
  const [selectedBankObject, setSelectedBankObject] = useState({});
  const [selectedBankguid, setSelectedBankguid] = useState("");
  const [quoteData, setQuoteData] = useState({});
  const [transferedData, setTransferedData] = useState({});
  const [isQuoteBtnLoading, setQuoteBtnLoading] = useState(false);
  const [isTransferBtnLoading, setTransferBtnLoading] = useState(false);
  const [isloader, setIsLoader] = useState(true);
  const [seconds, setSeconds] = useState(0);  


  useEffect(() => {
    // Decrease the seconds every second
    const timer = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);
    // Clear the interval and reset the timer when it reaches 0
    if (seconds === 0) {
      clearInterval(timer);
      // You can also perform any action you need when the timer reaches 0
    }
    if (seconds === 0 && isGetQuote && isGQview) {
      toggleGQ();
    }
    return () => clearInterval(timer);
  }, [seconds]);

  useEffect(() => {
    if (Object.keys(postTransferQuoteFailed).length !== 0) {
      let errObj = postTransferQuoteFailed
      if (errObj?.status === 422 && errObj?.data?.message_code === "invalid_quote_amount") {
        dispatch(commonError("Quote amount is below the configured threshold"));
      }else {
        dispatch(commonError(errObj?.data?.error_message));
      }
      setQuoteBtnLoading(false);
      dispatch(resetpostQuoteFailed())
    }
  }, [postTransferQuoteFailed])

  useEffect(() => {
    if (Object.keys(postTransferFailed).length !== 0) {
      setTransferBtnLoading(false)
      let errObj = postTransferFailed
      if (errObj.status === 422) {
        dispatch(commonError(errObj.data.error_message))
      }
      dispatch(resetpostTransferFailed())
    }
  }, [postTransferFailed])

  useEffect(() => {
    if (bankAccount) {
      // console.table(bankAccount);
      let temp = bankAccount.filter((value) => value.state === "completed");
      setBankLists(temp);
      if (temp[0]) {
        setSelectedBankguid(temp[0].guid);
        setSelectedBankObject(temp[0]);
      }
    }
    setIsLoader(false)
  }, [bankAccount]);

  useEffect(() => {
    if (getTransferQuote && getTransferQuote.deliver_amount) {
      toggleGQ();
      setSeconds(ExpiryTimeFinder(getTransferQuote))
      setQuoteBtnLoading(false);
      setQuoteData(getTransferQuote);
      dispatch(resetPostQuotesAction());
    }
  }, [getTransferQuote]);

  useEffect(() => {
    if (postTransferData && postTransferData.amount) {
      if (postTransferData && postTransferData.failure_code === null) {
        // Create a new object with the desired properties
        const transformedData = {
          guid: postTransferData.guid,
          customer_guid:postTransferData?.customer_guid,
          transfer_type: postTransferData.transfer_type,
          asset: postTransferData.asset,
          side: postTransferData.side,
          amount: `$ ${(postTransferData.amount / 100).toFixed(2)}`,
          created_at: postTransferData.created_at,
          type: 'fiat-deposit',
          user_name: localStorage.getItem('displayName'),
          user_email: sessionStorage.getItem('user_email'),
        };
        // dispatch(EmailNotification(transformedData));
        dispatch(commonSuccess("Deposit initiation successful! Your request is being processed."));
      }
      toggleGQV();
      setTransferBtnLoading(false);
      setTransferedData(postTransferData); // Set the transformed data
      dispatch(resetPostTransferAction());
    }
  }, [postTransferData]);


  const toggleGQ = () => {
    setGQ(!isGetQuote);
    setGQV(!isGQview);
  };

  const toggleGQV = () => {
    setGQV(!isGQview);
    setOS(!isOrderSub);
  };

  const toggleOS = () => {
    setOS(!isOrderSub);
    setGQ(!isGetQuote);
  };

  const handleAccountChange = (event) => {
    const selectedBankGuid = event.target.value;
    setSelectedBankguid(selectedBankGuid);
    let selectedBankObj = bankLists?.find((e) => e.guid === selectedBankGuid);
    setSelectedBankObject(selectedBankObj);
  };

  const handleUsdAmountChange = (event) => {
    const inputValue = event.target.value;
    if (!/^\d*\.?\d*$/.test(inputValue)) {
      // Invalid input, handle error or ignore the input
      return;
    }
    setUsdAmount(event.target.value);
  };

  const handleUsdAmountBlur = (e) => {
    const inputValue = e.target.value;
    if (e.target.value) {
      const formattedValue = parseFloat(inputValue).toFixed(2);
      setUsdAmount(formattedValue);
    } else if(!e.target.value) {
      setUsdAmount("0.00");
    }
  };

  const postTransferQuote = () => {
    if (isQuoteBtnLoading) {
      return;
    }
    
    // console.log(usdAmount);
    if (usdAmount === null || usdAmount === "" || usdAmount === 0) {
      dispatch(commonError("Please enter valid amount"));
      // console.log('Invalid data');
      return;
    }
    let ExAmount = parseFloat(usdAmount) * 100;
    const reqData = {
      asset: "USD",
      customer_guid: decryptPassword(sessionStorage.guid),
      deliver_amount: ExAmount,
      product_type: "funding",
      side: "deposit",
    };
    setQuoteBtnLoading(true);
    dispatch(postQuotesAction(sessionStorage.at, reqData));
  };

  const postTrasfer = () => {
    if (isTransferBtnLoading) {
      return;
    }
    
    if (seconds == 0) {
      dispatch(commonError('Transaction time has expired'));
      return;
    }
    if (quoteData) {
      const reqData = {
        external_bank_account_guid: selectedBankguid,
        quote_guid: quoteData.guid,
        transfer_type: "funding",
      };
      setTransferBtnLoading(true);
      dispatch(postTransferAction(sessionStorage.at, reqData));
    }
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const ExpiryTimeFinder = (data) => {
    // Convert timestamps to Date objects
    const expiresAt = new Date(data.expires_at);
    const issuedAt = new Date(data.issued_at);
    // Calculate the time gap in milliseconds between the two timestamps
    const timeGapInMilliseconds = expiresAt - issuedAt;
    // Convert milliseconds to seconds
    const seconds = Math.floor((timeGapInMilliseconds % (1000 * 60)) / 1000);
    // Subtract 3 seconds to adjust for any potential delay or buffer
    if(seconds === 30) {
      return seconds - 10;
    }else {
      return seconds - 3;
    }
  }

  return (
    <>
      {isloader ? (
        <>
          <div className=" justify-content-center d-flex align-item-center my-5 py-5">
            <Gifloader />
          </div>
        </>
      ) : (
        <>
          <form
            method="post"
            name="myform"
            className="currency_validate fadeInDown"
          >
            <div className={isGetQuote ? "d-none" : ""}>
              <div className="mb-3">
                <label className="me-sm-2">Select Bank Account</label>
                <div className="input-group mb-3 igpcust">
                  <div className="input-group-prepend ">
                    <label className="input-group-text">
                      <img
                        src={require("../../images/piggy-bank.png")}
                        alt=""
                        className="img-fluid"
                      />
                    </label>
                  </div>
                  <select
                    name="currency"
                    className="form-control"
                    onChange={(e) => handleAccountChange(e)}
                  >
                    {bankLists?.map((item) => (
                      <option key={item.guid} value={item.guid}>
                        {`${item.plaid_institution_id}-${item.plaid_account_name} (${item.plaid_account_mask})`}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="text-end">
                  <Link to="/payment-method"
                    className={"text-link small"}
                  >
                    <span className="mdi mdi-plus"></span> <span>Add Payment Method</span>
                  </Link>
                </div>
              </div>

              <div className="mb-3">
                <label className="me-sm-2">Amount</label>
                <div className="input-group mb-3 igpcust dollarbox">
                <div className="input-group-prepend selpoabs ">
                    <label className="input-group-text small d-flex align-items-center gap-2 px-2">
                      <img
                        src={require("../../images/usd.png")}
                        alt=""
                        className="img-fluid"
                      />
                      <span>USD</span>
                    </label>
                   
                  </div>
                  <div className="dollor d-flex justify-content-start align-items-center px-0 ps-md-2 fw-bold dollorclr">$</div>
                  <input
                    type="text"
                    name="usd_amount"
                    className="form-control"
                    onChange={handleUsdAmountChange}
                    onBlur={(e) => handleUsdAmountBlur(e)}
                    value={usdAmount}
                    onKeyPress={(e) => {
                      const key = e.key;
                      if (key !== "." && isNaN(Number(key))) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {/* <div className="input-group-append ">
                    <label className="input-group-text small cursor-pointer">
                      <img
                        src={require("../../images/two-arrows.png")}
                        alt=""
                        className="img-fluid icon16"
                      />
                    </label>
                  </div> */}
                </div>
              </div>

              <div className="text-center mt-4">
                <button
                  type="button"
                  className={`btn btn-primary  btn-loader ${isQuoteBtnLoading ? "active" : ""
                    }`}
                  onClick={postTransferQuote}
                >
                  <span>Deposit</span>
                  <ButtonLoader />
                </button>
                {/* <div className="mt-3 alert alert-warning small iconalert">
                  <span className="mdi mdi-alert-outline"></span>
                  <span className="text-start">The deposit will be credited to the customer's account with in 24 to 48 hours</span>
                </div> */}
              </div>
            </div>

            <div className={isGQview ? "d-block fadeInDown" : "d-none"}>
              {/* <div className="ppmethod mb-3">
                    <ul className="list-unstyled smplist gqlist">
                      <li className="border-none bg-light p-2">
                        <div className="spm-icon" onClick={toggleGQ}>
                          <i className="las la-angle-left"></i>
                        </div>
                        <div className="spm-content">
                          <div className="spm-icon">
                            <img
                              src={require("../../images/bitcoin.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <p>
                            1 BTC ={" "}
                            <span className="text-primary fw-bold">
                              $38,382.00
                            </span>{" "}
                            USD
                          </p>
                        </div>
                        <div className="spm-icon">&nbsp;</div>
                      </li>
                    </ul>
                  </div> */}

              <div className="ppmethod">
                <ul className="list-unstyled smplist">
                  <li className="flex-column">
                    {/* <h4 className="fw-bold">Order Quote</h4> */}
                    <span className="small">
                      The order quote will expire in{" "}
                      <em className="quote-countdown">{seconds && seconds}</em>{" "}
                      seconds
                    </span>
                  </li>

                  <li className="flex-column">
                    <h4 className="fw-bold">Confirm Deposit</h4>
                  </li>

                  <li>
                    <div className="spm-content">Amount</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {quoteData &&
                          quoteData.deliver_amount &&
                          formatCurrency(quoteData.deliver_amount / 100)}
                      </span>{" "}
                      USD
                    </div>
                  </li>

                  <li>
                    <div className="spm-content">Deposit Date</div>
                    <div className="spm-icon">
                      <span className=" me-1">
                        {quoteData && formatDate(quoteData.issued_at)}
                      </span>
                    </div>
                  </li>

                  {/* <li>
                    <div className="spm-content">Fee</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        ${quoteData && quoteData.fee / 100}
                      </span>{" "}
                      USD
                    </div>
                  </li> */}

                  <li>
                    <div className="spm-content">From</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {selectedBankObject &&
                          `${selectedBankObject.plaid_institution_id}-${selectedBankObject.plaid_account_name} (${selectedBankObject.plaid_account_mask})`}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="text-center tbtnbox" style={{ cursor: isTransferBtnLoading ? "not-allowed" : "pointer" }}>
                <button
                  type="button"
                  className="btn btn-light me-3"
                  onClick={toggleGQ}
                  disabled={isTransferBtnLoading}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className={`btn btn-primary btn-loader ${isTransferBtnLoading ? "active" : ""
                    }`}
                  onClick={postTrasfer}

                >
                  <span>Confirm</span>
                  <ButtonLoader />
                </button>
              </div>
            </div>

            <div className={isOrderSub ? "d-block fadeInDown" : "d-none"}>
              <div className="ppmethod">
                <ul className="list-unstyled smplist">
                  <li className="flex-column">
                    <h4 className="fw-bold">Deposit Details </h4>
                  </li>

                  <li>
                    <div className="spm-content">State</div>
                    <div className="spm-icon text-primary fw-bold">
                      Processing
                    </div>
                  </li>
                  <li>
                    <div className="spm-content">Amount</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {transferedData &&
                          transferedData.amount &&
                          formatCurrency(transferedData.amount / 100)}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="spm-content">Deposit Date</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        {transferedData &&
                          formatDate(transferedData.created_at)}
                      </span>
                    </div>
                  </li>
                  {/* <li>
                    <div className="spm-content">Fee</div>
                    <div className="spm-icon">
                      <span className="text-primary me-1 fw-bold">
                        ${transferedData && transferedData.fee / 100}
                      </span>{" "}
                      USD
                    </div>
                  </li> */}

                  <li>
                    <div className="spm-content">From</div>
                    <span className="text-primary me-1 fw-bold">
                      {selectedBankObject &&
                        `${selectedBankObject.plaid_institution_id}-${selectedBankObject.plaid_account_name} (${selectedBankObject.plaid_account_mask})`}
                    </span>
                  </li>
                </ul>
              </div>

              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={toggleOS}
                >
                  Done
                </button>
              </div>
            </div>
          </form>
        </>
      )}
      <CustomerVerificationPopup />
    </>
  );
};

export default Deposits;
