import React from "react";
import Header3 from "../layout/header3";
import Footer1 from "../layout/footer1";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


function services() {
    return (
        <>
            {/* Meta Tag */}
            <Helmet>
                <title>Services | 1-on-1 Cryptocurrency Investment Consultation</title>
                <meta name="description" content="From buying to selling and storing, our services are designed to provide you with an easy-to-use crypto wallet app. Explore our platform today." />
            </Helmet>

            <Header3 />
            <div className="lear_page _services sp-inners">
                <div className="banners">
                    <h2>Services</h2>
                </div>
                <div className="main">
                    <div className="serv1">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="serv_aprent">
                                        <Link to="https://calendly.com/thecryptoexchange" target="_blank" className="btn btn-primary">
                                            Book a consultantion
                                        </Link>
                                        <h2>One on One Crypto Consultation</h2>
                                        <p><a href="https://cryptoexperts.ca/" style={{color: '#1355d7'}}>Cryptocurrency investment</a> has surged in popularity, drawing attention from investors seeking innovative portfolio expansion methods. At The Crypto Exchange, we offer a unique chance for individuals to engage in one-on-one crypto consultations with seasoned experts in cryptocurrency and blockchain technology. Receive personalized insights, strategies, and solutions to address your specific needs, goals, and inquiries. Whether in-person or online, connect with us to explore this exciting opportunity further.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="box">
                                        <div className="img">
                                            <img
                                                src={require("./../../images/services/serv_icon1.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="txt">
                                            <h4>Education and Information</h4>
                                            <p>Our Crypto Consultants provide you with in-depth knowledge and personalized guidance about the fundamentals of cryptocurrencies, blockchain technology, and their potential applications. You also gain insights into market trends, investment strategies, and risk management techniques from these experienced professionals.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="box">
                                        <div className="img">
                                            <img
                                                src={require("./../../images/services/icon1.2.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="txt">
                                            <h4>Review of Crypto Investments</h4>
                                            <p>Receive expert analysis of your current crypto investments from our qualified team. We'll offer recommendations on diversifying your portfolio, identifying potential opportunities, and implementing strategies to mitigate risks, including protecting against scams or fraudulent activities. Gain insights into the performance of your investments, spot areas for improvement, recognize red flags, conduct due diligence, and safeguard your assets from fraudulent schemes with our guidance.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="box">
                                        <div className="img">
                                            <img
                                                src={require("./../../images/services/serv_icon3.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="txt">
                                            <h4>Q&A and Clarifications</h4>
                                            <p>Have your questions answered directly by knowledgeable consultants. Clarify doubts, misconceptions, or uncertainties about cryptocurrencies, trading platforms, or related technologies. Benefit from interactive discussions that cater to your specific concerns.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="box">
                                        <div className="img">
                                            <img
                                                src={require("./../../images/services/serv_icon4.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="txt">
                                            <h4>Privacy and Confidentiality</h4>
                                            <p>One-on-one crypto consultations ensure that your privacy is respected and your information remains confidential with our Crypto Consultants. You get the freedom to discuss sensitive financial matters, investment strategies, and personal goals in a secure environment.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="box">
                                        <div className="img">
                                            <img
                                                src={require("./../../images/services/serv_icon5.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="txt">
                                            <h4>Security of Crypto Assets</h4>
                                            <p>Security of Crypto assets is paramount. Our Crypto Consultants provides you personalized advice on safeguarding your crypto assets against theft, fraud, or hacking. Moreover, you can learn about best practices for securing digital wallets, using secure exchanges, and protecting private keys.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="box">
                                        <div className="img">
                                            <img
                                                src={require("./../../images/services/serv_icon6.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="txt">
                                            <h4>Security Best Practices</h4>
                                            <p>Advisors educate clients on the latest security threats and vulnerabilities in the cryptocurrency ecosystem. They also enable you with knowledge and practical tips and techniques for enhancing the security of your digital assets and transactions. Stay informed about regulatory developments, compliance requirements, and industry standards related to cryptocurrency security.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="serv2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="img">
                                        <img
                                            src={require("./../../images/services/serv_img2.1.png")}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="txts">
                                        <h2>Crypto/Fiat On and Off Ramp</h2>
                                        <p>In cryptocurrency, an on-ramp is a platform that allows users to obtain crypto assets and enter their associated markets. Contrariwise, an off-ramp is a platform that assists users in selling their crypto assets or exiting from the cryptocurrency markets. If you are looking for a platform that provides you with both of these services, you need to visit The Crypto Exchange. Our Platform offers a simple and easy-to-use On and Off-Ramp that facilitates the smooth transition of assets between traditional fiat currencies and cryptocurrencies. Serving as a conduit, this platform facilitates the smooth conversion of funds between fiat and cryptocurrencies (on-ramp) or vice versa (off-ramp) utilizing cutting-edge Web3 technology. It abides by regulatory standards, incorporating Know Your Customer (KYC) and Anti-Money Laundering (AML) procedures to ensure compliance with financial regulations. Although centralized, The Crypto Exchange provides convenience and accessibility to individuals and corporations seeking to enter or exit the cryptocurrency market.</p>
                                        {/* <button className="btn btn-primary">Learn More</button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="txts">
                                        <h2>Secure Mobile Wallet</h2>
                                        <p>The Crypto Exchange offers a Secure Web3 Crypto Wallet providing users with a robust and trustless solution within the decentralized landscape. By leveraging the principles of Web3 technology, this wallet ensures top-notch security by removing the necessity for centralized intermediaries. Users can confidently engage in various crypto activities such as purchasing, storing, sending, receiving, and exchanging cryptocurrencies while retaining control over their private keys. The wallet's adherence to Web3 standards enhances its compatibility with decentralized applications (DApps) and smart contracts. With advanced encryption methods, multi-factor authentication, and biometric security features, our <a href="https://thecryptowallet.io/">wallet solution</a> prioritizes the protection of users' assets and sensitive data. Its focus on user-centric security mirrors the principles of decentralization, empowering individuals to maintain complete ownership and authority over their digital wealth in a secure and user-friendly manner. <br /> <br />
                                        Download it here or visit <a href="https://thecryptowallet.io/" target="_blank">www.thecryptowallet.io</a></p>
                                        <div className="download_sec pt-0">
                                            {/* <h4>Download</h4> */}

                                            <div className="gastore">
                                                {/* <a href="http://www.thecryptowallet.io/" target="_blank" title="Google Play store">
                                                    <img
                                                        src={require("../../images/google_button.png")}
                                                        alt=""
                                                        width="400"
                                                        className="img-fluid"
                                                    />
                                                </a> */}
                                                <a href="https://apps.apple.com/us/app/the-crypto-wallet/id6478397189" target="_blank" title="Apple store">
                                                    <img
                                                        src={require("../../images/apple_button.png")}
                                                        alt=""
                                                        width="400"
                                                        className="img-fluid"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="img">
                                        <img
                                            src={require("./../../images/services/serv_img2.2.png")}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="serv_aprent">
                                        <h2>Straight-Forward Process To Booking Crypto Consulting With Us</h2>
                                        <p style={{textAlign: 'left'}}>Looking forward to getting a free consultation session at <a href="https://thecryptoexchange.us/" style={{color: '#1355d7'}}>The Crypto Exchange</a> with our seasoned Crypto Consultants? We offer our customers a 15-minute free consultation conference call. Here’s how to book one for yourself:</p>
                                        
                                        <div style={{textAlign: 'left'}}>
                                            <p className="mb-0">● Visit our page.</p>
                                            <p className="mb-0">● Next, tap on the “book a consultation” option.</p>
                                            <p className="mb-0">● This will take you to an external Calendly site.</p>
                                            <p className="mb-0">● Tap on the “15 Minute Free Consultation” option. It asks you to select a date and time as per your time zone. Feed in the information and click next!</p>
                                            <p>● Now feed in all your personal information required and “schedule event.”</p>
                                        </div>

                                        <p style={{textAlign: 'left'}}>This will instantly book a free consultation for you. Our team will email you the Web conferencing details provided upon confirmation. On the designated time join the e-meet and get all your queries answered for free!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="serv3">
                        <div className="container">
                            <div className="_head">
                                <h2>What Services We Offer</h2>
                                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
                            </div>
                            <div className="_body">
                                <div className="d-flex">
                                    <div className="blk">
                                        <div className="box">
                                            <div className="_img">
                                                <img
                                                    src={require("./../../images/services/icon2.1.png")}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </div>
                                            <div className="_txt">
                                                <h4>Secure Wallet</h4>
                                                <p>Lorem ipsum dolor amet consect adiscing elit eiusmod tempor enime dolore magna aliqua.</p>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="_img">
                                                <img
                                                    src={require("./../../images/services/icon2.2.png")}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </div>
                                            <div className="_txt">
                                                <h4>Analysis & Planning</h4>
                                                <p>Duis aute irure dolor in reprehend voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blk">
                                        <img
                                            src={require("./../../images/services/serv_img2.png")}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                    <div className="blk">
                                        <div className="box">
                                            <div className="_img">
                                                <img
                                                    src={require("./../../images/services/icon2.3.png")}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </div>
                                            <div className="_txt">
                                                <h4>Fully Protection</h4>
                                                <p>Duis aute irure dolor in reprehend voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <div className="_img">
                                                <img
                                                    src={require("./../../images/services/icon2.4.png")}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </div>
                                            <div className="_txt">
                                                <h4>Crypto Trading</h4>
                                                <p>Lorem ipsum dolor amet consect adiscing elit eiusmod tempor enime dolore magna aliqua.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <Footer1 />
        </>
    );
}

export default services;