import React, { useEffect, useState } from 'react'
import Header3 from '../../layout/header3'
import Footer1 from '../../layout/footer1'
import AreaChart from '../../charts/area'
import { useDispatch, useSelector } from 'react-redux'
import { getBchFiveYearsData, resetBchFiveYearsData } from '../../../actions/customerActions'

const BitcoinCash = () => {
  const [oneYearValue, setOneYearValue] = useState([])
  const BchPricesForOneYear = useSelector((state) => state.customerReducer.getBCHFiveYearsData);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBchFiveYearsData())
  }, [])

  useEffect(() => {
    if (BchPricesForOneYear && BchPricesForOneYear.length !== 0) {
      setOneYearValue(BchPricesForOneYear)
      dispatch(resetBchFiveYearsData())
    }
  }, [BchPricesForOneYear])
  
  return (
    <>
      <Header3 />
      <div className='lear_page sp-inners'>
        {/* <div className='banners'>
          <h2>Learn</h2>
        </div> */}

        <div className='main'>
          <div className='container'>
            <div>
              <div className='txt'>
                <h3>How to Buy Bitcoin Cash?</h3>
                <div className='d-flex align-items-center justify-content-center'>
                  <img className="img-fluid py-4 w-50 learn-page-img" src={require('../../../images/learn/how_to_buy_btc_cash.png')} alt=""/>
                </div>
                
                <h3>Want To Buy Bitcoin Cash?</h3>
                <p>
                  Are you interested in investing in Bitcoin Cash (BCH)? Before you make a purchase, having a proper understanding and knowledge about what you are purchasing is essential.
                </p>
                <p>
                  Bitcoin Cash (BCH) is a decentralized digital currency that emerged from a split (hard fork) of the original Bitcoin blockchain. It was created in 2017 and the primary reason for creating Bitcoin Cash was to increase the block size limit of transactions, enabling the network to process a higher volume of transactions more quickly and at lower fees. Just like Bitcoin, the maximum supply of Bitcoin Cash is capped at 21 million coins, which ensures its scarcity and potential for value appreciation. Bitcoin Cash is a smart and straightforward choice because it is the more efficient and scalable form of digital currency.
                </p>
                <p>
                  Let's learn in detail why and how to buy Bitcoin Cash and where to do it safely and efficiently.
                </p>

                <div className="py-4 px-0 px-md-5">
                  <AreaChart BtcPriceEachDay={oneYearValue} cryptoName={'Bitcoin Cash'} />
                </div>
                <h3>Why Buy Bitcoin Cash?</h3>
                <p>
                  Here are the top reasons why you should buy bitcoin cash:
                </p>

                <h4>1. Faster Transactions</h4>
                <p>
                  Sometimes, it takes several minutes or even hours to confirm transactions during periods of network congestion. But Bitcoin Cash upgraded its block size from 1 MB to 8 MB and later to 32 MB. Because of this, it becomes possible to make transactions faster and within minutes as compared to Bitcoin. 
                </p>

                <h4>2. Lower Fees</h4>
                <p>
                  Transaction fees on the bitcoin cash are comparatively lower than those on the Bitcoin network. Due to its lower fees, it is more suitable to process everyday transactions such as buying goods and services or sending remittances, especially for microtransactions where high fees would be prohibitive.
                </p>

                <h4>3. Increasing Adoption</h4>
                <p>
                  Bitcoin Cash is getting accepted in more places worldwide by merchants and businesses in online and offline transactions. This increasing utility makes Bitcoin Cash a versatile addition to <a href='https://thecryptoexchange.ca/' style={{ color: '#1355d7' }}>digital wallets</a>, offering more options for spending and transacting with cryptocurrency.
                </p>

                <h4>4. Investment Potential</h4>
                <p>
                  Like other cryptocurrencies, the value of Bitcoin Cash also increases over time. Therefore, <a href='https://thecryptoexchange.ca/' style={{ color: '#1355d7' }}>investors</a>, aiming to capitalize on its potential growth can add Bitcoin Cash as part of a diversified portfolio
                </p>

                <img className="img-fluid py-4 w-100" src={require('../../../images/learn/how_to_buy_tce.png')} alt=""/>
                <h3>How Can You Buy Bitcoin Cash?</h3>
                <p>Follow the below process to learn how you can buy Bitcoin Cash:</p>

                <h4>Create An Account</h4>
                <p>
                  Once you've chosen a trustworthy exchange, create your account. While signing up, you need to fill in your personal information such as your name, phone number, and email address. Also, You have to submit your documents such as a photo ID or proof of address.
                </p>

                <h4>Deposit Funds</h4>
                <p>
                  After creating your account, decide how much you want to invest. There are various ways to deposit your funds including, bank transfers and credit cards. Choose the best way for you, enter the amount, and complete the transaction to transfer your amount to buy Bitcoin Cash.
                </p>

                <h3>Where Do I Buy Bitcoin Cash?</h3>
                <p>
                  <b>1. Cryptocurrency Exchanges:</b> Cryptocurrency exchanges such as The Crypto Exchange is one of the most popular places to purchase Bitcoin Cash. This exchange not only provides a user-friendly interface but also provides various tools to help you buy, sell, and manage your BCH holdings.
                </p>
                <p>
                  <b>2. Peer-to-Peer Platforms:</b> Online marketplaces that facilitate direct transactions between buyers and sellers without the need for an intermediary are known as P2P platforms.
                </p>
                <p>
                  <b>3. Bitcoin ATMs:</b> Another reliable option for you to buy Bitcoin Cash is using Bitcoin ATMs. To purchase BCH at a Bitcoin ATM, you should have cash or a debit card with you.
                </p>

                <div className='d-flex align-items-center justify-content-center'>
                  <img className="img-fluid py-4 w-100 learn-page-img" src={require('../../../images/learn/bit_vs_bitcash.png')} alt=""/>
                </div>
                <h3>Key Difference Between Bitcoin Cash And Bitcoin</h3>
                <h4>Block Size Limit:</h4>
                <p>
                  In Bitcoin, the block size limit was 1 MB whereas in Bitcoin Cash, the limit was increased to 8 MB at the time of its fork in August 2017. Later, the limit was increased to 32 MB which allows for more transactions per block, aimed at increasing transaction throughput and reducing fees.
                </p>

                <h4>Community and Support:</h4>
                <p>
                  Bitcoin has broader recognition, adoption, and infrastructure support. It is often viewed as the "original" Bitcoin and has the largest market capitalization among cryptocurrencies. On the other side, Bitcoin Cash has a smaller community and less adoption but has a dedicated following that believes in its vision of peer-to-peer electronic cash.
                </p>

                <h4>Scaling Approach:</h4>
                <p>
                  Bitcoin uses Segregated Witness (SegWit) to separate transaction signatures which allows more transactions to fit in each block and supports the lightning network for faster off-chain transactions. Whereas Bitcoin Cash (BCH) increases the block size limit to fit more transactions directly on the blockchain for quicker processing.
                </p>
                <p>
                  As demand for cryptocurrency grows, it's crucial to stay informed about market dynamics and the best methods for secure investments. Once you have a complete understanding of Bitcoin Cash, don't wait any longer. Invest securely in Bitcoin Cash today and secure your future in digital innovation and financial growth!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer1 />
    </>
  )
}

export default BitcoinCash
