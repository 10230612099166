import React, {useEffect,useState} from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = ({ priceData }) => {

  const [chartValue, setChartValue] = useState([]);

  const chartOptions = {
    // labels: ["Bitcoin", "Ethereum","USDC"],
    // colors: ["rgba(246, 146, 26)", "rgba(98, 127, 234)", "rgb(41, 117, 201)"],
    labels: priceData?.assetNameData,
    colors: priceData?.colorNameData,
    legend: {
      position: "bottom",
    },
    plotOptions: {
        pie: {
          customScale: 1,
        },
      },
    dataLabels: {
        style: {
          colors: ["transparent", "transparent", "transparent"],
        },
        dropShadow: {
          enabled: false,
        },
        stroke: {
          show: false,
          width: 0,
        },
    },
    tooltip: {
        y: {
          formatter: function (value) {
            return value +'%';
          },
        },
    },
  };

  useEffect(() => {
    if(priceData && priceData?.percentages){
        const numberArray = priceData?.percentages?.map(Number);
        setChartValue(numberArray)
    }
  }, [priceData]);

  return (
    <div className="pie-chart mb-5">
      <ReactApexChart options={chartOptions} series={chartValue} type="pie" height={350} />
    </div>
  );
};

export default PieChart;