import React, { useState, useEffect } from "react";
import { config } from '../../config';
import Persona from 'persona';
import {
    identityVerificationAction,
    resetIdentityVerificationAction, 
    getIdentityVerificationAction,
    resetGetIdentityVerificationAction,
    getIdentityVerificationList
} from "../../actions/customerActions";
import { useDispatch, useSelector } from "react-redux";
import { decryptPassword } from "../../utils/Encryption";
import { EmailNotification } from "../../actions/authAction";

const Verifykyc = ({ verificationOutcome, recheckCustomer, setVerificationUpdate, verificationUpdate, identityData, customerData }) => {

    const dispatch = useDispatch();
    let tempToken = sessionStorage.getItem("guid")
    let deToken = decryptPassword(tempToken)


    const postIdentityVerify = useSelector((state) => state.customerReducer.postIdentityVerifyData);
    const getIdentityVerify = useSelector((state) => state.customerReducer.getIdentityVerifyData);
    const getCustomerRes = useSelector((state) => state.customerReducer.getCustomerData);

    const [getVerified, setShow] = useState(false);
    const [isPerinfo, setPerinfo] = useState(false);
    const [isIddocument, setIddocument] = useState(false);
    const [isFaceverify, setFaceverify] = useState(false);
    const [verifyPopup, setVerifyPopup] = useState(false);
    const [perInfo, setPI] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [personaFlag, setPersonaFlag] = useState(false)
    const [personaState, setPersonaState] = useState("")
    
    const handleClose = () => setShow(false);
    const handleverifypopupClose = () => setVerifyPopup(false);

    const openverifypopup = async () => {
        setPersonaFlag(true);
        setIsActive(true);
        setVerificationUpdate("loading")
        // setVerifyPopup(true);
        if (identityData?.state === "waiting") {
            dispatch(getIdentityVerificationAction(sessionStorage.at, identityData.guid));
        } else {
            const verificationData = {
                "type": "kyc",
                "method": customerData?.type === "business" ? "business_registration" : "id_and_selfie",
                "customer_guid": deToken
            };
            dispatch(identityVerificationAction(sessionStorage.at, verificationData));
        }
    }

    useEffect(() => {
        if (postIdentityVerify && postIdentityVerify.guid && postIdentityVerify.method === 'id_and_selfie' && postIdentityVerify?.type === "kyc") {
            dispatch(resetIdentityVerificationAction());
            dispatch(getIdentityVerificationAction(sessionStorage.at, postIdentityVerify.guid));
            // console.log(postIdentityVerify);
        }
    }, [postIdentityVerify])

    useEffect(() => {
        if (Object.keys(getIdentityVerify).length !== 0 && getIdentityVerify.persona_inquiry_id && getIdentityVerify?.method === "id_and_selfie" && getIdentityVerify?.type === "kyc") {
            setPersonaState(getIdentityVerify?.persona_state);
            dispatch(resetGetIdentityVerificationAction());
            // console.log('redirect '+getIdentityVerify.persona_inquiry_id);
            if (getIdentityVerify?.persona_state === 'reviewing') {
                setIsActive(false);
            }
            
            if (personaFlag && getIdentityVerify?.persona_state !== "reviewing" && getIdentityVerify?.persona_state !== "processing") {
                openpersona(getIdentityVerify.persona_inquiry_id);
                setVerificationUpdate("")
            }

            // const url = 'https://inquiry.withpersona.com/verify?inquiry-id='+getIdentityVerify.persona_inquiry_id;
            // window.open(url, '_blank');
            // verifyCustIntervalRef.current = setInterval(() => {
            //     dispatch(getCustomer(sessionStorage.at,deToken));
            // }, 15 * 1000);
        } else if (Object.keys(getIdentityVerify).length !== 0 && getIdentityVerify.persona_inquiry_id === null && getIdentityVerify?.method === "id_and_selfie" && getIdentityVerify?.type === "kyc") {
            // console.log(getIdentityVerify);
            // console.log('getIdentityVerify refetching');
            setTimeout(() => {
                dispatch(getIdentityVerificationAction(sessionStorage.at, getIdentityVerify.guid));
            }, 2000);
        }
    }, [getIdentityVerify])

    useEffect(() => {
        if (Object.keys(getCustomerRes).length) {
            // console.log(getCustomerRes);
            if (getCustomerRes.state == "verified") {
                // console.log('verified');
                // setVerifyPopup(false);
                // clearInterval(verifyCustIntervalRef.current);
            }
        }
    }, [getCustomerRes])

    const handlePI = () => {
        setPI(true);
        setShow(false);
    };

    const togglePerinfo = () => {
        setPerinfo(!isPerinfo);
    };

    const toggleIddocument = () => {
        setIddocument(!isIddocument);
    };

    const toggleFaceverify = () => {
        setFaceverify(!isFaceverify);
    };

    const handleTverify = () => {
        setPI(false);
        setPerinfo(false);
        setIddocument(false);
        setFaceverify(false);
    }


    const openpersona = (inquiryid) => {
        const client = new Persona.Client({
            // This refers to a production demo template owned by Persona
            inquiryId: inquiryid,
            environment: config.PERSONA_ENVIRONMENT,
            onComplete: ({ inquiryId, status, fields }) => {
                // console.log(inquiryId);
                // console.log(status, fields);
                recheckCustomer('verifykyc');
                setIsActive(false);
                setVerificationUpdate("storing");
                // send KYC verification mail using frontend
                // const transformedData = {
                //     customer_guid: deToken,
                //     type: 'kycVerification',
                //     user_email: sessionStorage.getItem('user_email'),
                // };
                // dispatch(EmailNotification(transformedData))
                // console.log('onComplete');
            },
            onCancel: ({ inquiryId, sessionToken }) =>{
                dispatch(getIdentityVerificationList(sessionStorage.at, deToken));
                // console.log('onCancel')
                setVerificationUpdate("")
                setIsActive(false);
                setPersonaFlag(false);
            },
            onError: (error) => console.log(error),
        });
        client.open();
    }

    return (
        <>

            <button disabled={(verificationUpdate === "completed" && verificationOutcome === "passed") || verificationUpdate === "failed" || verificationUpdate === "storing" || verificationUpdate === "" || verificationUpdate === "loading" || isActive || personaState === 'reviewing' || personaState === 'processing'} onClick={openverifypopup} type="submit" className="btn btn-outline-primary btn-cussm">Verify</button>
            <div>{personaState !== "completed" && <span className={`mw-badge badge badge-warning text-capitalize badge-max`}>{personaState}</span>}</div>

            {/* <Modal centered show={verifyPopup} className="tce-popup w350px fadeInDown">
                <div className="tce-ppcon ">
                    <div className="tce-ppcls" onClick={handleverifypopupClose}><span>x</span></div>
                    <div className="getverified fadeInDown">
                        <Modal.Title id="contained-modal-title-vcenter">
                            Get Verified
                        </Modal.Title>
                        <Modal.Body>
                            <div className="tce-ppimg"><img src={require("../../images/Verify-Identity.jpg")} alt="" className="img-fluid" /></div>
                            <div className="mb-4">
                                <GifloaderMini />
                            </div>
                            <h2 className="text-center">Kindly proceed with the verification process.</h2>
                        </Modal.Body>
                    </div>
                </div>
            </Modal>


            <Modal centered show={getVerified} className="tce-popup w350px fadeInDown">
                <div className="tce-ppcon ">
                    <div className="tce-ppcls" onClick={handleClose}><span>x</span></div>

                    <div className="getverified fadeInDown">
                        <Modal.Title id="contained-modal-title-vcenter">
                            Get Verified
                        </Modal.Title>
                        <Modal.Body>
                            <div className="tce-ppimg"><img src={require("../../images/Verify-Identity.jpg")} alt="" className="img-fluid" /></div>
                            <h2 className="text-center">Get verified to fund your account</h2>
                            <p className="text-center">To fund your account with crypto and cas, we'll ask for a few personal details. You'll also need to upload your ID and take a selfe.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary w-100" onClick={handlePI}>Verify</button>
                        </Modal.Footer>
                    </div>


                </div>

            </Modal>

            <Modal centered show={perInfo} className="tce-popup fadeInDown">
                <div className="tce-ppcon ">
                    <div className="tce-ppcls" onClick={handleTverify}><span>x</span></div>
                    <div className="getverified">
                        <Modal.Body>
                            <h2 className="mb-5">Verify your identity</h2>
                            <form>
                                <div className="perinfo fadeInDown">
                                    <div className="text-left vpoints">
                                        <span className="vp-number">
                                            <span className={isPerinfo ? 'd-none' : ''}>1</span>
                                            <svg className={isPerinfo ? 'checkmark' : 'checkmark d-none'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                            </svg>
                                        </span>
                                        <div className="confrom">
                                            <h4>Personal information</h4>
                                            <div className={isPerinfo ? 'form-group mb-0 d-none' : 'form-group mb-0'} >
                                                <label>Occupation</label>
                                                <select name="currency" className="form-control">
                                                    <option defaultValue="bitcoin">
                                                        Computer and IT
                                                    </option>
                                                    <option defaultValue="litecoin">
                                                        Computer and IT
                                                    </option>
                                                </select>
                                                <div className='btncontinue'>
                                                    <button type="button" className="btn btn-primary" onClick={togglePerinfo}>Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className={isPerinfo ? 'iddocument fadeInDown anidelay' : 'iddocument d-none'}>
                                    <div className="text-left vpoints">
                                        <span className="vp-number">
                                            <span className={isIddocument ? 'd-none' : ''}>2</span>
                                            <svg className={isIddocument ? 'checkmark' : 'checkmark d-none'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                            </svg>
                                        </span>
                                        <div className="confrom">
                                            <h4>Identity document
                                                <span className={isIddocument ? 'd-none' : ''}>Passport, Driver's License, or ID card</span>
                                            </h4>
                                            <div className={isIddocument ? 'form-group mb-0 d-none' : 'form-group mb-0'}>
                                                <label>Document type</label>
                                                <select name="currency" className="form-control">
                                                    <option value="Passport">
                                                        Passport
                                                    </option>
                                                    <option value="Driver">
                                                        Driver's License
                                                    </option>
                                                    <option value="idcard">
                                                        ID card
                                                    </option>
                                                </select>
                                                <div className="idupload">
                                                    <i className="mdi mdi-camera"></i>
                                                    <button className="btn btn-primary">Use webcam</button>
                                                    <span className="my-2">or</span>
                                                    <button className="btn btn-outline-primary">Upload Photo</button>

                                                </div>

                                                <div className="btncontinue">
                                                    <button type="button" className="btn btn-primary" onClick={toggleIddocument} >Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={isIddocument ? 'faceverify fadeInDown anidelay' : 'faceverify fadeInDown d-none'}>
                                    <div className="text-left vpoints">
                                        <span className="vp-number">
                                            <span className={isFaceverify ? 'd-none' : ''}>3</span>
                                            <svg className={isFaceverify ? 'checkmark' : 'checkmark d-none'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                            </svg>
                                        </span>
                                        <div className="confrom">
                                            <h4>Face verification
                                                <span>Please take or upload photo, so we can match thsi with the photo on the ID</span>
                                            </h4>
                                            <div className={isFaceverify ? 'form-group mb-0 d-none' : 'form-group mb-0'}>
                                                <div className="idupload">
                                                    <i className="mdi mdi-camera"></i>
                                                    <button className="btn btn-primary">Use webcam</button>
                                                    <span className="my-2">or</span>
                                                    <button className="btn btn-outline-primary">Upload Photo</button>

                                                </div>
                                                <div className="btncontinue">
                                                    <button type="button" className="btn btn-primary" onClick={toggleFaceverify} >Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className={isFaceverify ? 'ty-verify fadeInDown anidelay' : 'ty-verify fadeInDown d-none'}>
                                    <h2>Thank you for submitting your verification details!</h2>
                                    <p>You will be notified when your account verification is complete.</p>
                                    <div className="btncontinue">
                                        <button type="button" className="btn btn-primary" onClick={handleTverify}>Continue</button>
                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                    </div>
                </div>
            </Modal> */}
        </>
    );
}

export default Verifykyc;