import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
    commonError,
    getTrades,
    resetGetTrades,
    resetGetTradesFailed,
    commonSuccess
} from "../../actions/customerActions";
import CustomerVerificationPopup from "../shared/CustomerVerificationPopup";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { findNameForCrypto, formatDate, formatDateHypen,renderAddress } from "../../utils/Encryption";
import { useNavigate } from "react-router-dom";
import Tooltip from 'react-bootstrap/Tooltip';
import Gifloader from "../layout/gifloader";
import ReactPaginate from "react-paginate";
import bitcoinLogo from "../../images/bitcoin.png";
import ethereumLogo from "../../images/ethereum.png";
import usdcEthereumLogo from "../../images/bch.png";
import RegenerateCustomerToken from "../shared/regenerateCustomerToken";

function Transaction() {
    const navigate = useNavigate()
    const dispatch = useDispatch();


    const getTransactionData = useSelector((state) => state.customerReducer.getTradesData);
    const getTransactionFailed = useSelector((state) => state.customerReducer.getTradesFailed);


    const [getVerified, setShow] = useState(false);
    const [perInfo, setPI] = useState(false);
    const [isPerinfo, setPerinfo] = useState(false);
    const [isIddocument, setIddocument] = useState(false);
    const [isFaceverify, setFaceverify] = useState(false);
    const [sortTrades, setSortedTrades] = useState([]);
    const [isFilter, setIsFilter] = useState(false);
    const [trades, setTrades] = useState([]);
    const [tradesLoader, setTradesLoader] = useState(true);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [fromPrice, setFromPrice] = useState("");
    const [toPrice, setToPrice] = useState("");
    const [type, setType] = useState("");
    const [status, setStatus] = useState("");
    const [currency, setCurrency] = useState("");
    const [pageNum, setPageNum] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        dispatch(getTrades(sessionStorage.at));
    }, []);

    useEffect(() => {
        // Determine the divider and sign based on the item symbol property
        if (Object.keys(getTransactionData).length !== 0) {
            let modifiedArray = getTransactionData.objects.map((item) => {
                let divider;
                let sign;
                if (item.symbol === "BTC-USD") {
                    sign = "BTC";
                    divider = 100000000; // 8 decimal places
                } else if (item.symbol === "ETH-USD") {
                    sign = "ETH";
                    divider = 1000000000000000000; // 18 decimal places
                } else if (item.symbol === "USDC-USD") {
                    sign = "USDC";
                    divider = 1000000; // 6 decimal places
                } 
                let recAmt =
                    item.side === "buy"
                    ? (item.receive_amount / divider)?.toFixed(8)
                    : (item.deliver_amount / divider)?.toFixed(8);
                let delAmt =
                    item.side === "buy"
                        ? (item.deliver_amount / 100).toLocaleString(
                            undefined,
                            {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }
                        )
                        : (item.receive_amount / 100).toLocaleString(
                            undefined,
                            {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }
                        );
                return {
                    ...item,
                    sign,
                    purchaseQuantity: recAmt,
                    purchasePrice: delAmt,
                };
            });
            setIsFilter(false)
            setTradesLoader(false);
            setTrades(modifiedArray);
            dispatch(resetGetTrades());
        }
    }, [getTransactionData]);

    useEffect(() => {
        if (Object.keys(getTransactionFailed).length !== 0) {
            let errObj = getTransactionFailed;
            if (errObj && errObj.data && errObj.data.error_message) {
                dispatch(commonError(errObj.data.error_message));
                dispatch(resetGetTradesFailed());
                if (
                    errObj.data.error_message == "Signature has expired" ||
                    errObj.data.message_code == "authentication_failed"
                ) {
                    navigate("/logout");
                }
            }
        }
    }, [getTransactionFailed]);


    const handleFilter = () => {
        setIsFilter(true);
        let filteredTradesTemp = trades.filter((trade) => {
            // Filter by date
            const fromDateStr = fromDate ? new Date(fromDate).toISOString() : null;
            const toDateStr = toDate ? new Date(toDate + "T23:59:59.000Z").toISOString() : null;
            const dateFilter =
                (!fromDateStr || trade.created_at >= fromDateStr) &&
                (!toDateStr || trade.created_at <= toDateStr);

            // Filter by price
            const priceFilter =
                (!fromPrice || Number(trade.purchasePrice) >= Number(fromPrice)) &&
                (!toPrice || Number(trade.purchasePrice) <= Number(toPrice));

            // Filter by type
            const typeFilter = !type || trade.side === type;

            // Filter by currency
            const currencyFilter = !currency || trade.symbol.includes(currency);

            // Filter by status
            const statusFilter = !status || trade.state === status;

            // Apply all filters together
            return dateFilter && priceFilter && typeFilter && statusFilter && currencyFilter;
        });
        setSortedTrades(filteredTradesTemp);
        setPageNum(0);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "fromDate":
                setFromDate(value);
                break;
            case "toDate":
                setToDate(value);
                break;
            case "fromPrice":
                setFromPrice(value);
                break;
            case "toPrice":
                setToPrice(value);
                break;
            case "type":
                setType(value);
                break;
            case "currency":
                setCurrency(value);
                break;
            case "status":
                setStatus(value);
                break;
        }
    };
    const handlePI = () => {
        setPI(true);
        setShow(false);
    }

    const handleClose = () => setShow(false);

    const togglePerinfo = () => {
        setPerinfo(!isPerinfo);
    };

    const toggleIddocument = () => {
        setIddocument(!isIddocument);
    };

    const toggleFaceverify = () => {
        setFaceverify(!isFaceverify);
    };

    const handleTverify = () => {
        setPI(false);
        setPerinfo(false);
        setIddocument(false);
        setFaceverify(false);
    }

    const clearFilter = () => {
        setIsFilter(false)
        setFromDate("")
        setToDate("")
        setFromPrice("")
        setToPrice("")
        setType("")
        setStatus("")
        setSortedTrades([]);
        setPageNum(0);
    }
    const symbolMappings = [
        {
            logo: bitcoinLogo,
            name: "Bitcoin",
            symbol: "BTC",
        }, {
            logo: ethereumLogo,
            name: "Ethereum",
            symbol: "ETH",
        }, {
            logo: usdcEthereumLogo,
            name: "USDC",
            symbol: "USDC",
        },
    ];
    
    // Pagination calculations
    const itemsPerPage = 20;
    let pageCount = (sortTrades?.length > 0 || isFilter) ? Math.ceil(sortTrades.length / itemsPerPage) : Math.ceil(trades.length / itemsPerPage);
    const endOffset = pageNum + itemsPerPage;
    let currentItems = (sortTrades?.length > 0 || isFilter) ? sortTrades.slice(pageNum, endOffset) : trades.slice(pageNum, endOffset);

    // Changing the page number
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % trades.length;
        setPageNum(newOffset);
        setCurrentPage(event.selected);
    };

    const setStatusClassName = (state) => {
        if (state === "completed") {
            return "mw-badge badge badge-success"
          } else if (state === "cancelled" || state === "failed") {
            return "mw-badge badge badge-danger1"
          } else {
            return "mw-badge badge badge-warning"
          }
      }

    const copyaddress = (guid) => {
        const textArea = document.createElement('textarea'); // Create a new <textarea> element in the DOM.
        textArea.value = guid; // Set the value of the <textarea> to the provided 'guid'.
        document.body.appendChild(textArea); // Append the <textarea> element to the document's body.
        textArea.select(); // Select the content within the <textarea>.
        document.execCommand('copy'); // Execute the 'copy' command to copy the selected text to the clipboard.
        document.body.removeChild(textArea); // Remove the temporary <textarea> element from the document's body.
        dispatch(commonSuccess('Text copied to clipboard'));
        setCopied(true);

        // Reset the "Copied" text back to "Copy" after a delay (e.g., 2 seconds).
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    const Items = ({ currentItems }) => {
        return (
            <>
                <div className="card-body pt-1">
                    <>
                        <div className="transaction-table">
                            <div className="table-responsive">
                                <table className="table mb-0 table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th>GUID</th>
                                            <th>Date</th>
                                            <th>Type</th>
                                            <th>Fee</th>
                                            <th>Amount</th>
                                            <th>Total(USD)</th>
                                            <th className="text-center">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            currentItems.map((item) => (
                                                <tr key={item.guid}>
                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                            <OverlayTrigger overlay={<Tooltip>{item.guid}</Tooltip>}>
                                                            <span>
                                                                {item.guid}
                                                            </span>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger overlay={<Tooltip> {copied ? "Copied" : "Copy"}</Tooltip>}>
                                                            <span className="text-primary px-2" type="button" id="button-addon2" onClick={() => copyaddress(item.guid)}>
                                                                <i className="fa fa-copy"></i>
                                                            </span>
                                                            </OverlayTrigger>                                                    
                                                    </td>
                                                    <td style={{ whiteSpace: 'nowrap' }}>{formatDateHypen(item.created_at)}</td>
                                                    <td style={{ whiteSpace: 'nowrap' }}><span className={`${item.side === 'buy' ? 'mw-badge badge badge-primary' : 'mw-badge badge badge-danger'}`}>{(item.side === "buy" && "BOUGHT") || (item.side === "sell" && "SOLD")}</span></td>
                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                        <span className="text-primary fw-bold">{(item.fee / divideByAsset(item.sign))}</span> {item.sign}
                                                    </td>
                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                        <span className="text-primary fw-bold">{item.sign === "USDC" ? parseFloat(item.purchaseQuantity).toFixed(4) : item.purchaseQuantity}</span> {item.sign}
                                                    </td>
                                                    <td style={{ whiteSpace: 'nowrap' }}> $<span className="text-primary fw-bold">{item.purchasePrice}</span> USD</td>

                                                    <td style={{ whiteSpace: 'nowrap' }} className="text-center">
                                                        <span className={setStatusClassName(item.state)}>{item.state}</span>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                </div>
            </>
        )
    }

    // Calculate the value based on asset
    const divideByAsset = (asset) => {
        if (asset === "USDC") return 100;
        else if (asset === "BTC") return 100000000;
        else if (asset === "ETH") return 1000000000000000000;
        else if (asset === "USDC") return 1000000;
    }

    return (
        <>
    <RegenerateCustomerToken/>

            <Header2 />
            <Sidebar />

            <div className="content-body dash-main transactions">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">
                                Orders{" "}
                            </h4>
                        </div>

                        <div className="filterset">
                            <div className="form-group">
                                <label className="me-sm-2 mb-2">Date</label>
                                <div className="input-group">

                                    <input
                                        type="date"
                                        name="fromDate"
                                        className="form-control"
                                        value={fromDate}
                                        placeholder="From"
                                        onChange={handleChange}
                                        max={new Date().toISOString().split("T")[0]}
                                    />
                                    <input
                                        type="date"
                                        name="toDate"
                                        className="form-control"
                                        value={toDate}
                                        placeholder="To"
                                        onChange={handleChange}
                                        max={new Date().toISOString().split("T")[0]}
                                    />
                                </div>
                            </div>

                            {/* <div className="form-group">
                                <label className="me-sm-2 mb-2">Price</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text">
                                            <i className="las la-dollar-sign"></i>
                                        </label>
                                    </div>

                                    <input
                                        type="text"
                                        name="fromPrice"
                                        className="form-control"
                                        value={fromPrice}
                                        placeholder="From"
                                        onChange={handleChange}
                                    />
                                    <input
                                        type="text"
                                        name="toPrice"
                                        className="form-control"
                                        value={toPrice}
                                        placeholder="To"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div> */}
                            <div className="form-group w-175px">
                                <label className="me-sm-2 mb-2 mb-2">Currency</label>
                                <select
                                    name="currency"
                                    className="form-control"
                                    onChange={handleChange}
                                    value={currency}
                                >
                                    <option value="">All</option>
                                    {symbolMappings.map((symbol, index) => (
                                        <option key={index} value={symbol.symbol}>
                                            {symbol.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group w-125px">
                                <label className="me-sm-2 mb-2">Type</label>
                                <select
                                    name="type"
                                    className="form-control"
                                    onChange={handleChange}
                                    value={type}
                                >
                                    <option value="">All</option>
                                    <option value="buy">Buy</option>
                                    <option value="sell">Sell</option>

                                </select>
                            </div>

                            <div className="form-group w-175px">
                                <label className="me-sm-2 mb-2 mb-2">Status</label>
                                <select
                                    name="status"
                                    className="form-control"
                                    onChange={handleChange}
                                    value={status}
                                >
                                    <option value="">All</option>
                                    <option value="storing">Storing</option>
                                    <option value="pending">Pending</option>
                                    <option value="cancelled">Cancelled</option>
                                    <option value="settling">Settling</option>
                                    <option value="completed">Completed</option>
                                    <option value="failed">Failed</option>
                                </select>
                            </div>

                            <div className="p-2">

                                <button
                                    className="btn btn-primary mw-auto mt-4"
                                    onClick={handleFilter}>
                                    Submit
                                </button>

                            </div>

                            <div className="p-2">
                                <OverlayTrigger overlay={
                                    <Tooltip>
                                        Clear
                                    </Tooltip>
                                }>
                                    <button
                                        className="btn btn-secondary mw-auto mt-4"
                                        onClick={clearFilter}>
                                        <span className="mdi mdi-close"></span>
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            {
                                tradesLoader ? (
                                    <div className="card-body justify-content-center d-flex align-items-center">
                                        <Gifloader />
                                    </div>
                                ) : (
                                    <div className={tradesLoader ? "d-none" : "d-block"}>
                                        {currentItems.length > 0 ? (
                                            <div>
                                                <Items currentItems={currentItems} />
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel="Next"
                                                    onPageChange={handlePageClick}
                                                    pageCount={pageCount}
                                                    previousLabel="Previous"
                                                    containerClassName={
                                                        "pagination justify-content-center my-3"
                                                    }
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    forcePage={pageNum === 0 ? 0 : currentPage}
                                                />
                                            </div>
                                        ) : (
                                            <div className="text-center py-5 text-capitalize">
                                                <p>No trades found.</p>
                                            </div>
                                        )}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>


            <Modal centered show={getVerified} className="tce-popup w350px fadeInDown">
                <div className="tce-ppcon ">
                    <div className="tce-ppcls" onClick={handleClose}>
                        <span>x</span>
                    </div>

                    <div className="getverified fadeInDown">
                        <Modal.Title id="contained-modal-title-vcenter">
                            Get Verified
                        </Modal.Title>

                        <Modal.Body>
                            <div className="tce-ppimg">
                                <img src={require("../../images/Verify-Identity.jpg")} alt="" className="img-fluid" />
                            </div>
                            <h2 className="text-center">Get verified to fund your account</h2>
                            <p className="text-center">To fund your account with crypto and cas, we'll ask for a few personal details. You'll also need to upload your ID and take a selfe.</p>
                        </Modal.Body>

                        <Modal.Footer>
                            <button className="btn btn-primary w-100" onClick={handlePI}>Verify</button>
                        </Modal.Footer>
                    </div>
                </div>
            </Modal>


            <Modal centered show={perInfo} className="tce-popup fadeInDown">
                <div className="tce-ppcon ">
                    <div className="tce-ppcls" onClick={handleTverify}>
                        <span>x</span>
                    </div>

                    <div className="getverified">
                        <Modal.Body>
                            <h2 className="mb-5">Verify your identity</h2>
                            <form>
                                {/* <h4 className="text-left vpoints"><span className="vp-number bg-primary"><i className="las la-check"></i></span> Personal information</h4> */}
                                <div className="perinfo fadeInDown">
                                    <div className="text-left vpoints">
                                        <span className="vp-number">
                                            <span className={isPerinfo ? 'd-none' : ''}>1</span>
                                            <svg className={isPerinfo ? 'checkmark' : 'checkmark d-none'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                            </svg>
                                        </span>

                                        <div className="confrom">
                                            <h4>Personal information</h4>
                                            <div className={isPerinfo ? 'form-group mb-0 d-none' : 'form-group mb-0'} >
                                                <label>Occupation</label>
                                                <select name="currency" className="form-control">
                                                    <option value="bitcoin">
                                                        Computer and IT
                                                    </option>
                                                    <option value="litecoin">
                                                        Computer and IT
                                                    </option>
                                                </select>
                                                <div className='btncontinue'>
                                                    <button type="button" className="btn btn-primary" onClick={togglePerinfo}>Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className={isPerinfo ? 'iddocument fadeInDown anidelay' : 'iddocument d-none'}>
                                    <div className="text-left vpoints">
                                        <span className="vp-number">
                                            <span className={isIddocument ? 'd-none' : ''}>2</span>
                                            <svg className={isIddocument ? 'checkmark' : 'checkmark d-none'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                            </svg>
                                        </span>

                                        <div className="confrom">
                                            <h4>Identity document
                                                <span className={isIddocument ? 'd-none' : ''}>Passport, Driver's License, or ID card</span>
                                            </h4>
                                            <div className={isIddocument ? 'form-group mb-0 d-none' : 'form-group mb-0'}>
                                                <label>Document type</label>
                                                <select name="currency" className="form-control">
                                                    <option value="Passport">
                                                        Passport
                                                    </option>
                                                    <option value="Driver">
                                                        Driver's License
                                                    </option>
                                                    <option value="idcard">
                                                        ID card
                                                    </option>
                                                </select>

                                                <div className="idupload">
                                                    <i className="mdi mdi-camera"></i>
                                                    <button className="btn btn-primary">Use webcam</button>
                                                    <span className="my-2">or</span>
                                                    <button className="btn btn-outline-primary">Upload Photo</button>
                                                </div>
                                                <div className="btncontinue">
                                                    <button type="button" className="btn btn-primary" onClick={toggleIddocument} >Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={isIddocument ? 'faceverify fadeInDown anidelay' : 'faceverify fadeInDown d-none'}>
                                    <div className="text-left vpoints">
                                        <span className="vp-number">
                                            <span className={isFaceverify ? 'd-none' : ''}>3</span>
                                            <svg className={isFaceverify ? 'checkmark' : 'checkmark d-none'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                            </svg>
                                        </span>

                                        <div className="confrom">
                                            <h4>Face verification
                                                <span>Please take or upload photo, so we can match this with the photo on the ID</span>
                                            </h4>
                                            <div className={isFaceverify ? 'form-group mb-0 d-none' : 'form-group mb-0'}>
                                                <div className="idupload">
                                                    <i className="mdi mdi-camera"></i>
                                                    <button className="btn btn-primary">Use webcam</button>
                                                    <span className="my-2">or</span>
                                                    <button className="btn btn-outline-primary">Upload Photo</button>
                                                </div>

                                                <div className="btncontinue">
                                                    <button type="button" className="btn btn-primary" onClick={toggleFaceverify} >Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={isFaceverify ? 'ty-verify fadeInDown anidelay' : 'ty-verify fadeInDown d-none'}>
                                    <h2>Thank you for submitting your verification details!</h2>
                                    <p>You will be notified when your account verification is complete.</p>
                                    <div className="btncontinue">
                                        <button type="button" className="btn btn-primary" onClick={handleTverify}>Continue</button>
                                    </div>
                                </div>
                            </form>

                        </Modal.Body>
                    </div>
                </div>
            </Modal>

            <CustomerVerificationPopup />
        </>
    );
}

export default Transaction;
