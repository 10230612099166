import React from "react";
import Header3 from "../layout/header3";
import Footer1 from "../layout/footer1";

const UserAgreement = () => {
    return (
        <>
            <Header3 />
            <div className="terms_condition">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="terms_condition-content">
                                <div className="terms_condition-text text-justify">
                                    <h3>Cybrid User Agreement</h3>
                                    <p className="lh-lg">
                                        This user agreement (the “<strong>Agreement</strong>”) is an
                                        agreement between the user that accept this Agreement (“
                                        <strong>User</strong>”) Cybrid. If User is located in the
                                        United States, then Cybrid refers to:{" "}
                                        <strong>CybridUS</strong>: Cybrid Inc., a Delaware entity
                                        having its principal place of business at 511 S. Orange
                                        Avenue, Unit #673, Newark, NJ 07103 USA (“
                                        <strong>Cybrid US</strong>”); or if User identified below is
                                        located in Canada or otherwise outside of the United States,
                                        then Cybrid refers to: Cybrid Canada: Cybrid Canada Inc., a
                                        Canadian entity having its principal place of business at
                                        161 Bay Street, 27th Floor, PO Box 508, Toronto, ON, M5J 2S1
                                        Canada (“<strong>Cybrid Canada</strong>”). This Agreement
                                        governs User use of the services (the “
                                        <strong>Services</strong>”) available through the Cybrid
                                        website <a href="https://www.cybrid.xyz"><u>https://www.cybrid.xyz</u></a> (“<strong>Site</strong>”) and
                                        any mobile or web applications (each, an “
                                        <strong>App</strong>”) through which they may be made
                                        available. By checking an opt-in box, clicking on “I accept”
                                        or otherwise accepting this Agreement on the sign-up page,
                                        installing the App, or otherwise accessing or using the
                                        Services, User acknowledges that they have read, understood,
                                        and agree to be bound by and comply with the terms of this
                                        Agreement. If User is using the Services on behalf of an
                                        entity, partnership, or other organization, then User
                                        represents that they:
                                    </p>
                                    <ol>
                                        <li>
                                            (i) Are an authorized representative of that entity with the
                                            authority to bind that entity to this Agreement and
                                        </li>
                                        <li>
                                            (ii) Such entity agrees to be bound by this Agreement. If
                                            User does not agree to the terms of this Agreement, then
                                            they are not permitted to use the Services.
                                        </li>
                                    </ol>
                                    <p className="text-justify">
                                        <strong>ARBITRATION NOTICE.</strong> BY ACCEPTING THIS
                                        AGREEMENT, USER AND CYBRID ARE EACH WAIVING THE RIGHT TO A
                                        TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR
                                        REPRESENTATIVE PROCEEDING. USER AGREES TO GIVE UP USER RIGHT
                                        TO GO TO COURT to assert or defend User rights under this
                                        contract (except for matters that may be taken to small
                                        claims court). User rights will be determined by a single
                                        arbitrator and NOT a judge or jury. See the Arbitration
                                        provision below
                                    </p>
                                    <p className="text-justify">
                                        <strong>FINANCIAL SERVICES:</strong> Cybrid is not a Crypto
                                        wallet, custodian, Fiat money transmitter or bank and
                                        provides only limited financial services consisting of only
                                        buying or selling Crypto from or to User. The Cybrid
                                        Services are, however, integrated with certain third party
                                        servicers who generally are licensed entities and may be
                                        banks, that Cybrid calls “<strong>Partners</strong>”.
                                    </p>
                                    <p className="text-justify">
                                        Cybrid recommends that User saves or phires a copy of this
                                        Agreement for their records. If not defined in the body of
                                        this Agreement, captalized used in this Agreement are
                                        defined in the glossary at the end of this Agreement.
                                    </p>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <div>
                                        <h3>1. Services:</h3>
                                        <p>
                                            The Services consist of two elements:
                                        </p>
                                        <ol>
                                            <li>
                                            <span>(i) Collecting,
                                            storing, processing and communicating Instructions and
                                            other information to, from and among User, Partners and
                                            Cybrid (the “<strong>Data Transaction Services</strong>”);
                                            and </span>
                                            </li>
                                            <li>
                                            <span>
                                            (ii) Sale or purchase of Crypto to or from Users in
                                            two-party transactions (“<strong>Crypto Sales</strong>”).
                                            User may make certain selections of Services that they
                                            wish to procure through the Application or the Account.
                                            </span>
                                            </li>
                                        </ol>
                                        <p>
                                            Cybrid will provide the Services to User for the term of
                                            this Agreement, subject to the payment of applicable Fees
                                            and compliance with the terms of this Agreement. As part
                                            of the Services, Cybrid hereby grants to User a
                                            non-exclusive, non-transferable, non-assignable right to
                                            use the Services, as per the terms of this Agreement. User
                                            acknowledges that the Services are cloud-based and hosted
                                            services and no copies of the Services or Cybrid System
                                            will be delivered to User, other than (where available)
                                            the App, subject to the EULA. The Services shall be used
                                            by User solely for User own purposes and Cybrid does not
                                            convey any right, title or interest in the Services or
                                            Cybrid System to User. User right to use the Services
                                            shall terminate upon any termination of this Agreement or
                                            any suspension or termination of the supply of the
                                            Services to User. Services are available in only Active
                                            Jurisdictions, as indicated in the Account or the Site.
                                        </p>
                                        <p>
                                            <strong>1.1. Data Processing Services</strong>
                                        </p>
                                        <br />
                                        <p>Partner Agreement Acceptance – Partners</p>
                                        <p>
                                            In the course of providing the Services, Cybrid or Partner
                                            may present Partner Agreement to User for acceptance via
                                            the Account. If User does not accept the applicable
                                            Partner Agreement, then User may not carry out any Payment
                                            Transactions with them. Once accepted by the User, such
                                            Partner Agreement forms a binding legal agreement between
                                            User and the Partner and Cybrid is named as a third party
                                            beneficiary thereunder entitled to enforce the terms
                                            thereof versus User but without prejudice to the rights of
                                            Partners thereunder. Cybrid reserves the right to add or
                                            remove compatibility with any existing or new Partners
                                            without prejudice to the rights of User under Partner
                                            Agreement, such as they may be. The data processing
                                            services are to communicate User Instructions and provide
                                            reporting with respect thereto to, from and among User,
                                            Partners and Cybrid.
                                        </p>
                                        <p>Payment Transactions – Partners</p>
                                        <p>
                                            Except as expressly set out herein, Cybrid shall not
                                            perform any Fiat or Crypto Payment Transactions for User.
                                            User may, however, use the Services to communicate User
                                            Instructions to a Partner that is party to Partner
                                            Agreement with the User. Such Partner - engaged directly
                                            by the User- may perform a Fiat or Crypto Payment
                                            Transaction on behalf of User pursuant to such Partner
                                            Agreement. User is responsible for Payment Transactions
                                            that it initiates under Partner Agreement.
                                        </p>
                                        <p>
                                            User grants Cybrid the right and Cybrid has the right to
                                            intervene in any Instructions to instruct Partner to
                                            suspend, reverse or investigate a Payment Transaction
                                            where Cybrid deems the same necessary and in the best
                                            interests of User, and Other User, Partner or Cybrid or
                                            where required by a Regulatory Authority.
                                        </p>
                                        <p>
                                            <strong>1.2. Crypto Sales</strong>
                                        </p>
                                        <p>
                                            From time to time Cybrid shall offer to sell or purchase
                                            Crypto for immediate delivery from or to User as per
                                            prices and for Fees set out in the Account. When User
                                            wishes to engage in a Crypto Sale, they shall be invited
                                            to accept the terms thereof in the Account and also
                                            provide their corresponding Wallet, User Bank Account and
                                            other information necessary to carry out the Payment
                                            Transaction. All disclosure provided by Cybrid in respect
                                            of a Crypto Sale (e.g. price) is incorporated herein by
                                            reference and forms part of this Agreement.
                                        </p>
                                        <p>
                                            User represents that any Wallet they use or propose to use
                                            in respect of a Crypto Sale belongs to the User only and
                                            not to any third party
                                        </p>
                                        <p>
                                            As required by Laws and Regulatory Authorities, Cybrid
                                            monitors User Wallet activity before and after Crypto
                                            Sales in order to prevent abuse of the Services by
                                            terrorists, criminals and money launderers. User shall
                                            cooperate with such monitoring and shall supply Cybrid
                                            with such information as Cybrid requires to initiate,
                                            complete or investigate any Crypto Sale or other
                                            transaction where the Services are involved.
                                        </p>
                                        <p>
                                            While Cybrid will use commercially reasonable efforts to
                                            deliver each Crypto purchased to the User Wallet
                                            immediately following its receipt of payment of the
                                            corresponding Fiat, delivery thereof may be delayed by
                                            fraud screening, Crypto network slowdowns or other
                                            reasonable basis of delay. Cybrid assumes no liability for
                                            the variance in value of Crypto between when User accepts
                                            a Crypto Sale and when the corresponding Crypto is
                                            delivered to the User Wallet.
                                        </p>

                                        <p>
                                            <strong>1.3. Return Policy</strong>
                                        </p>
                                        <p>
                                            <strong>
                                                ALL CRYPTO SALE TRANSACTIONS ARE FINAL AND IRREVERSIBLE
                                                AS OF WHEN THE TERMS THEREOF ARE ACCEPTED BY USER.
                                                CRYPTO SALE TRANSACTIONS CANNOT BE CANCELLED AND THERE
                                                ARE NO RETURNS.
                                            </strong>
                                        </p>
                                        <p>
                                            Payment Transactions with Partners are subject to such
                                            cancellation and return policies as are set out in Partner
                                            Agreements.
                                        </p>
                                        <br />
                                        <p>
                                            <strong>1.4. Errors </strong>
                                        </p>
                                        <p>
                                            User shall immediately notify Cybrid of any errors by
                                            Cybrid or User in the supply or use of the Services. Where
                                            practicable, Cybrid shall use commercially reasonable
                                            efforts to investigate errors, but makes no representation
                                            as to its ability to correct the error. User shall provide
                                            Cybrid with any information necessary to investigate an
                                            error in a Transaction or an ensuring Payment Transaction.
                                            Some Payment Transactions, such as wire transfers, Crypto
                                            Sales or exchanges of Crypto for Fiat (or vice versa) and
                                            Crypto transmissions are irreversible, so User agrees to
                                            exercise extreme caution when initiating any Payment
                                            Transaction by way of Instructions delivered one of its
                                            Partners through the Services.
                                        </p>
                                    </div>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <div>
                                        <h3>2. Cybrid Account</h3>
                                        <p>
                                            Cybrid shall provide User with a unique and private Data
                                            Account accessible through the Service. The Account shall
                                            be a record of User Transactions and Fees. Cybrid shall
                                            provide User with access codes for the Account. User shall
                                            not disclose such codes or permit any third party to use
                                            them. User has exclusive responsibility for the use of
                                            User Account. Cybrid will invite User to enter certain
                                            preferences and specifications within the Application or
                                            the Account that will apply to the Services; User assumes
                                            exclusive responsibility for such selections even if they
                                            contain errors by User or result in losses to User. Any
                                            additional terms and conditions posted to the Site with
                                            respect to the Account or specific Services preferences
                                            selected by User are incorporated herein by reference.
                                            User shall notify Cybrid by email immediately of any loss
                                            or disclosure, whether voluntary or otherwise, of any
                                            Account password or access code to a third party.
                                        </p>
                                        <p>
                                            Upon User’s request, Cybrid will also issue User a User ID
                                            associated with the Account (“<strong>User ID</strong>”).
                                            User may share their User ID only with officers,
                                            directors, bookkeepers, accountants or other User
                                            personnel that are directly employed or engaged by User (“
                                            <strong>User Personnel</strong>”) provided that User binds
                                            such third parties to undertakings of confidentiality and
                                            to also honor the terms hereof. User Personnel may only
                                            access and use the Services through the User ID and in
                                            compliance with this Agreement; User will not allow User
                                            Personnel to share the User ID with third parties. User is
                                            responsible for all activity occurring under its User ID
                                            whether by User Personnel or otherwise. Cybrid reserves
                                            the right to replace the User ID in its sole discretion
                                            for any reason or for no reason. Any User Personnel who
                                            accesses the Services does so subject to this Agreement
                                            and User shall ensure that User Personnel comply with the
                                            terms hereof.
                                        </p>
                                        <p>
                                            User shall provide, at User’s own expense, all necessary
                                            hardware, Wallets, User Bank Accounts, applications and
                                            internet connectivity necessary to access the Services.
                                            User acknowledges that the internet can be unpredictable
                                            in performance, unsecure and may, from time to time,
                                            impede access to the Services or performance hereunder.
                                            User agrees that Cybrid is not responsible for any
                                            internet outages, unsecure WIFI or other connections or
                                            any other interference with User’s use of or access to the
                                            Services or security breaches arising from any User Device
                                            and User waives any and all claims against Cybrid in
                                            connection therewith.
                                        </p>
                                    </div>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <div>
                                        <h3>3. Limitations</h3>
                                        <p>
                                            <strong>Prohibited Users</strong>
                                        </p>
                                        <br />
                                        <p>
                                            The following Persons are prohibited from using the
                                            Services:
                                        </p>
                                        <ol>
                                            <li>
                                                (i) Persons who appear on the U.S. Department of the
                                                Treasury, Office of Foreign Assets Control (OFAC),
                                                Specially Designated Nationals List (SDN);
                                            </li>
                                            <li>
                                                (ii) Persons who are less than 18 years of age;
                                            </li>
                                            <li>
                                                (iii) Persons, or their Affiliates who have procured
                                                services from Cybrid and have been terminated for cause by
                                                Cybrid;
                                            </li>
                                            <li>
                                                (iv) Persons who are prohibited by Partner from using
                                                Partner Services and
                                            </li>
                                            <li>
                                                (v) Individual consumers. The Services may not be used for
                                                individual consumer use. User must be a business,
                                                charitable organization or not-for-profit organization to
                                                use the Services. Cybrid reserves the right to decline to
                                                provide Services or terminate Services to one or another
                                                type of business; Cybrid shall notify User of prohibited
                                                business types through the Site, the Account or the App.
                                                In any case, any business that is illegal or operates in
                                                support of illegal activity is prohibited from using the
                                                Services.
                                            </li>
                                        </ol>

                                        <p>
                                            <strong>Limitations on Use</strong>
                                        </p>
                                        <p>
                                            User shall not use the Services for any illegal,
                                            fraudulent or other prohibited activity. If Cybrid
                                            suspects that User may be engaging in or have engaged in a
                                            fraudulent, illegal or prohibited activity, including any
                                            violation of this Agreement or a Partner Agreement, User
                                            access to the Services may be suspended or terminated at
                                            the sole discretion of Cybrid. User shall not use the
                                            Services to resell them or to supply their own Crypto
                                            exchange or sale business or other money services
                                            business. User represents that all Crypto Sale
                                            transactions hereunder are completed for their own private
                                            individual investment purposes only and not to operate a
                                            money services or other form of regulated Fiat or Crypto
                                            business involving the sale, storage, purchases or trade
                                            in Crypto. Additionally, Cybrid may report the transaction
                                            to the relevant law enforcement agency or Regulatory
                                            Authority. Without limitation, User shall not make or
                                            attempt to make Payment Transactions as consideration for
                                            or in connection with:
                                        </p>
                                        <ol>
                                            <li>● Any illegal act;</li>
                                            <li>● Any other category or payer that Cybrid decides to
                                                prohibit, in its sole discretion.</li>
                                            <li>● Cigarettes, tobacco or e-cigarettes;</li>
                                            <li>● Debt elimination, consolidation, or reduction services;</li>
                                            <li>● Drugs, alcohol, or drug paraphernalia, or items that may
                                                represent these uses;</li>
                                            <li>● Fireworks;</li>
                                            <li>● Forex or binary or any other variation of trading;</li>
                                            <li>● Goods or services that infringe on the intellectual
                                                property rights of a third party;
                                            </li>
                                            <li>● Illegal wildlife trade;</li>
                                            <li>● Items that promote hate, violence, racial intolerance,
                                                or exploitation of a crime;
                                            </li>
                                            <li>● Payday loans;</li>
                                            <li>● Replica and/or unlicensed branded goods; or</li>
                                            <li>
                                                ● Weapons (including without limitation, knives, guns,
                                                firearms or ammunition);
                                            </li>
                                        </ol>
                                        <p>
                                            User shall not itself and shall not permit any User
                                            Personnel or any other third party to:
                                        </p>
                                        <ol>
                                            <li>
                                                (i) Permit any party to access or use the Services other
                                                than the User Personnel authorized under this Agreement;
                                            </li>
                                            <li>
                                                (ii) Modify, adapt, alter or translate any software of
                                                Cybrid Systems underlying the Services;
                                            </li>
                                            <li>
                                                (iii) License, lease, rent, loan, distribute, or otherwise
                                                transfer the Services to any third party;
                                            </li>
                                            <li>
                                                (iv) Except if, and solely to the extent that, such a
                                                restriction is impermissible under Law, reverse engineer,
                                                decompile, disassemble, or otherwise derive or determine
                                                or attempt to derive or determine the source code (or
                                                algorithms, structure or organization) of any software or
                                                Cybrid Systems underlying the Services;
                                            </li>
                                            <li>
                                                (v) Use or copy the any software or Cybrid Systems
                                                underlying the Services except as expressly allowed
                                                hereunder;
                                            </li>
                                            <li>
                                                (vi) Conduct or promote any illegal activities while using
                                                the Services;
                                            </li>
                                            <li>
                                                (vii) Use the Services to generate unsolicited email
                                                advertisements or spam;
                                            </li>
                                            <li>
                                                (viii) Use the Services to stalk, harass or harm another
                                                individual;
                                            </li>
                                            <li>
                                                (ix) Use any high volume automatic, electronic or manual
                                                process to access, search or harvest information from the
                                                Services (including without limitation robots, spiders or
                                                scripts);
                                            </li>
                                            <li>
                                                (x) Except if, and solely to the extent that, such a
                                                restriction is impermissible under Law, interfere in any
                                                way with the proper functioning of the Services or
                                                interfere with or disrupt any servers or networks
                                                connected to the Services, or disobey any requirements,
                                                procedures, policies or regulations of networks connected
                                                to the Services;
                                            </li>
                                            <li>
                                                (xi) Attempt to gain access to secured portions of the
                                                Services to which it does not possess access rights;
                                            </li>
                                            <li>
                                                (xii) Upload or transmit any form of virus, worm, Trojan
                                                horse, or other malicious code;
                                            </li>
                                            <li>
                                                (xiii) Use any robot, spider, other automatic device, or
                                                manual process to extract, “screen scrape”, monitor,
                                                “mine”, or copy any static or dynamic web page on the
                                                Services or the content contained on any such web page for
                                                commercial use without our prior express written
                                                permission;
                                            </li>
                                            <li>
                                                (xiv) Impersonate any person or entity, or otherwise
                                                misrepresent its affiliation with a person or entity;
                                            </li>
                                            <li>
                                                (xv) Mirror or frame the Services or any content, place
                                                pop-up windows over its pages, or otherwise affect the
                                                display of its pages; or
                                            </li>
                                            <li>
                                                (xvi) Publicly display or publicly perform any part of the
                                                Services. User may not use the Services for any purpose
                                                other than a purpose for which the Services are expressly
                                                designed. If User is prohibited under Laws from using the
                                                Services, User may not use them.
                                            </li>
                                        </ol>
                                    </div>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <h3>4. Crypto Risks</h3>
                                    <p>
                                        Depending on User Instructions to Partners, Payment
                                        Transactions may include the purchase, transmission or sale
                                        of Crypto. Every Crypto Sale also involves Crypto.
                                    </p>
                                    <p>
                                        By using the Services, User understands that there are
                                        substantial risks associated with the purchase, sale,
                                        holding and use of Crypto, and User is agreeing to
                                        familiarize itself and assume all such risks, including, but
                                        not limited to the following:
                                    </p>

                                    <ol type="a">
                                        <li>
                                            a) Crypto is not insured in any way by Partner, Cybrid or
                                            any governmental authority; there is no deposit (e.g.
                                            FDIC) insurance or other insurance on Crypto;
                                        </li>
                                        <li>
                                            b) Price and liquidity of Crypto has been, and maybe,
                                            subject to large fluctuations on any given day and User
                                            may lose any and all value in Crypto at any time;
                                        </li>
                                        <li>
                                            c) Some Crypto exchanges and Wallets – like those that
                                            Partners may supply - have been subject to cyberattacks
                                            and other technical issues that have resulted in the loss
                                            or theft of Crypto to their users and there is a risk that
                                            a similar cyberattack could affect Payment Transactions
                                            and result in the theft or loss of User’s Crypto for which
                                            User cannot recover;
                                        </li>
                                        <li>
                                            d) Crypto is not part of a central bank that can take
                                            corrective measures to protect the value of Crypto in a
                                            crisis;
                                        </li>
                                        <li>
                                            e) Changes to Law may adversely affect the use, transfer,
                                            exchange, or value of User Crypto and such changes may be
                                            sudden and without notice;
                                        </li>
                                        <li>
                                            f) Crypto is not legal tender and is not backed by a
                                            government; and
                                        </li>
                                        <li>
                                            g) Crypto has value only from the continued willingness of
                                            market participants to use Crypto, thus Crypto is
                                            susceptible to loss of confidence, which could collapse
                                            demand relative to supply and may result in permanent and
                                            total loss of value of a particular Crypto asset if the
                                            market for such asset disappears
                                        </li>
                                    </ol>
                                    <p>
                                        The risks set out above may, for example, manifest
                                        themselves in every Crypto Sale. By making a Payment
                                        Transaction, User agrees to assume exclusive liability for
                                        that risk
                                    </p>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <div>
                                        <h3>5. Cybrid Intellectual Property Rights.</h3>
                                        <p>
                                            Cybrid expressly reserves all Intellectual Property Rights
                                            in the Services, Cybrid System and all materials provided
                                            by Cybrid hereunder. All right, title and interest in the
                                            Services and all other materials provided by Cybrid
                                            hereunder, any update, adaptation, translation,
                                            customization or derivative work thereof, and all
                                            Intellectual Property Rights therein will remain with
                                            Cybrid or its licensors. Cybrid reserves the right, in its
                                            sole discretion, to change, modify, add, or remove
                                            portions of the Services without prior notice to User or
                                            consent of User. Certain of the names, logos, trademarks,
                                            trade names, service marks, content, visual interfaces,
                                            interactive features, information, compilation, computer
                                            code, products, services, and other materials displayed on
                                            the Services (“<strong>Cybrid Materials</strong>”), are
                                            protected by Intellectual Property Rights Laws of the
                                            United States and Canada and other jurisdictions.
                                        </p>
                                    </div>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <div>
                                        <h3>6. User Data.</h3>
                                        <p>
                                            <strong>Accuracy</strong>
                                        </p>
                                        <p>
                                            User has sole responsibility for the accuracy,
                                            appropriateness and completeness of all User Data. Cybrid
                                            will use the User Data it is provided in performing the
                                            Services and is not responsible for reviewing, validating
                                            or otherwise confirming the accuracy, appropriateness or
                                            completeness of User Data including but not limited to
                                            Instructions.
                                        </p>
                                        <p>
                                            <strong>Background Checks</strong>
                                        </p>
                                        <p>
                                            User hereby authorizes Cybrid to, directly or through
                                            third parties, make any inquiries and conduct any
                                            investigation to verify User identity and risk parameters
                                            associated with actual or proposed Payment Transactions.
                                        </p>
                                        <p>
                                            <strong>Security</strong>
                                        </p>
                                        <p>
                                            Cybrid will take reasonable steps to help protect User
                                            Data. However, User understands and agrees that such steps
                                            do not guarantee that the Services are invulnerable to all
                                            security breaches or immune from viruses, security threats
                                            or other vulnerabilities. Cybrid reserves the right to
                                            cooperate with local, state and federal authorities in
                                            investigations of improper or unlawful activities and this
                                            may require the disclosure of User’s personal information.
                                            Cybrid may also report to other organizations about
                                            improper or unlawful user activities and this reporting
                                            may include disclosure of personal information relating to
                                            those individuals conducting such improper or unlawful
                                            activities.
                                        </p>
                                        <p>
                                            User shall secure User Data in its possession or under its
                                            control. User assumes exclusive responsibility for
                                            ensuring the security of User Device and the Data on it.
                                            Cybrid is not liable for the operation or failure of User
                                            Devices or those of any third party, including but not
                                            limited to processors, hosting services, internet service
                                            providers and other Partners. User shall not operate User
                                            Device in a manner that does not meet the applicable
                                            security requirements of Cybrid, indicated in the Account
                                            or on the Site, or those of Partners.
                                        </p>
                                        <p>
                                            Cybrid is not responsible for performing, and is not
                                            liable for any failure to perform, any back-up of any User
                                            Data or other data provided, transmitted, processed, or
                                            stored by User in or through the Services. It is User’s
                                            responsibility to back-up onto a User Device all User
                                            Data, including all data and records that User submits to
                                            Cybrid.
                                        </p>
                                        <p>
                                            <strong>Collection and Sharing</strong>
                                        </p>
                                        <p>
                                            Subject to the Cybrid Privacy Policy, posted at{" "}
                                            <a
                                                href="https://www.cybrid.xyz"
                                                target="_blank"
                                            >
                                                <u>https://www.cybrid.xyz</u>
                                            </a>{" "}
                                            User and each of User Personnel hereby grant Cybrid the
                                            right to collect, store, use and disclose User Data for
                                            the purpose of providing the Services and its integration
                                            with Partner Services selected by User. Where User Data
                                            includes data concerning third parties, User states that
                                            it has obtained the necessary consents for Cybrid to
                                            collect, process, store such data hereunder from the
                                            relevant data subjects. Data collected by Cybrid is
                                            subject to the Cybrid Privacy Policy, posted at the Site
                                            and incorporated herein by reference. Subject to Laws,
                                            where Cybrid is subject to a subpoena request for User
                                            Data, Cybrid shall provide User with an opportunity to
                                            contest the request, failing which Cybrid shall cooperate
                                            with the request.
                                        </p>
                                        <p>
                                            User hereby authorizes Cybrid and each Partner to each
                                            obtain from the others and disclose to the others User
                                            Data in so far as is necessary to supply their respective
                                            services either hereunder or under their respective
                                            Partner Agreements.
                                        </p>
                                        <p>
                                            User hereby instructs each Partner to share with Cybrid
                                            any and all Payment Transaction information and other
                                            information held or obtained by Partner in respect of User
                                            and its Payment Transactions. Cybrid is authorized to use
                                            such User Data to perform under this Agreement and each
                                            Partner Integration Agreement. User consents to all
                                            required disclosures to provide the Services.
                                        </p>
                                        <p>
                                            The Services requires certain Data concerning User,
                                            including but not limited to User name, address, phone
                                            number, email address, bank account information, Wallet
                                            addresses, Other User information and Partner credentials
                                            and account information. User hereby instructs Cybrid to
                                            disclose User Data to Partners and other third parties as
                                            required to deliver Instructions and otherwise perform the
                                            Services. Cybrid has no liability for any collection,
                                            processing, storage, use or disclosure of User Data by any
                                            Partner or any other third party. Cybrid reserves the
                                            right to decline to share User Data with any third party
                                            where Cybrid believes that such sharing may expose User or
                                            Cybrid to excessive security, financial or reputational
                                            risk, provided however that Cybrid shall never be liable
                                            for any act or omission of any third party with respect to
                                            User Data or otherwise. Cybrid makes no warranty,
                                            representation, endorsement, or guarantee regarding, and
                                            accepts no responsibility or liability for, the quality,
                                            content, nature, veracity or reliability of any User Data
                                            whether or not it was supplied by User.
                                        </p>
                                        <p>
                                            Where required by Law, Cybrid will disclose User Data to
                                            law enforcement agencies.
                                        </p>
                                        <p>
                                            <strong>User Data Retention</strong>
                                        </p>
                                        <p>
                                            Cybrid reserves the right to keep User Data for the term
                                            of this Agreement and for <strong>five (5) years </strong>
                                            thereafter.
                                        </p>
                                        <p>
                                            <strong>Backups</strong>
                                        </p>
                                        <p>
                                            User shall not rely on the Services as its sole repository
                                            of Payment Transaction data; User shall periodically
                                            download and backup Payment Transaction data outside of
                                            the Services for safekeeping.
                                        </p>
                                        <p>
                                            <strong>Feedback</strong>
                                        </p>
                                        <p>
                                            In the event that User provides Cybrid any ideas,
                                            thoughts, criticisms, suggested improvements or other
                                            feedback related to the Services or Partner Services (“
                                            <strong>Feedback</strong>”), User agrees that Cybrid may
                                            use the Feedback to modify the Services and that User will
                                            not be due any compensation, including any royalty related
                                            to the product or service that incorporates the Feedback.
                                            User hereby grants Cybrid a worldwide, royalty-free, fully
                                            paid, perpetual, irrevocable license to use, reproduce,
                                            modify, translate, distribute, perform, display, import,
                                            sell, offer for sale, make, have made and otherwise
                                            exploit the Feedback in any form, media, or technology,
                                            whether now known or hereafter developed, and to allow
                                            others to do the same. This is true whether User provides
                                            the Feedback on the Services or through any other method
                                            of communication with Cybrid.
                                        </p>

                                        <p>
                                            <strong>Limitations</strong>
                                        </p>
                                        <br />

                                        <p>
                                            User shall not take possession of or enter into the
                                            Account any data:
                                        </p>
                                        <ol>
                                            <li>
                                                (i) That User does not have the lawful right to copy,
                                                transmit, distribute, and display (including any User Data
                                                that would violate any confidentiality or fiduciary
                                                obligations that User might have with respect to the User
                                                Data);
                                            </li>
                                            <li>
                                                (ii) For which User does not have the consent or
                                                permission from the owner of any personally identifiable
                                                information contained in the User Data;
                                            </li>
                                            <li>
                                                (iii) That infringes, misappropriates or otherwise
                                                violates any Intellectual Property Rights or violates any
                                                privacy rights of any third party;
                                            </li>
                                            <li>
                                                (iv) That is false or misleading;
                                            </li>
                                            <li>
                                                (v) That is defamatory, obscene, or offensive;
                                            </li>
                                            <li>
                                                (vi) That violates, or encourages any conduct that would
                                                violate, any Laws or regulation or would give rise to
                                                civil or criminal liability; or
                                            </li>
                                            <li>
                                                (vii) That contains any viruses, trojan horses, spyware,
                                                malware, worms, time bombs, cancelbots, or other disabling
                                                devices or other harmful component intended to damage,
                                                detrimentally interfere with, surreptitiously intercept or
                                                expropriate any system, data or personal information.
                                            </li>
                                            <li></li>
                                        </ol>

                                        <p>
                                            <strong>Financial Data Processing, Not Advice</strong>
                                        </p>

                                        <p>
                                            The Services shall enable the storage of User Data that is
                                            of a financial nature. User acknowledges, however, that
                                            the Services do not include the supply of any accounting,
                                            financial, investment, legal or other professional advice.
                                            Cybrid is not a banking, accounting or other professional
                                            services firm. Cybrid does not hold any licenses for the
                                            supply of any banking, accounting, financial, investment,
                                            legal or other professional advice and none of the
                                            Services shall be construed as including any such
                                            services. Data presented in the Services, such as
                                            financial information, for example, shall not be construed
                                            as reflective of the financial status of User. User shall
                                            be exclusively responsible for retaining any banking,
                                            accounting, financial, investment, legal and other
                                            professional advice.
                                        </p>

                                        <p>
                                            <strong>Communications Monitoring</strong>
                                        </p>

                                        <p>
                                            Cybrid may monitor and record support-related and other
                                            outbound chats or calls to User, as well as inbound chats
                                            or calls to Cybrid by User or User’s representatives, for
                                            compliance, support, training and other purposes. User
                                            agrees that Cybrid may record any correspondence or call
                                            between User and Cybrid relating to the Services, and
                                            agrees to
                                        </p>
                                        <ol>
                                            <li>(i) Notify all relevant User employees that calls with
                                                Cybrid may be monitored and
                                            </li>
                                            <li>
                                                (ii) Indemnify and hold harmless Cybrid from any claim
                                                arising as a result of Cybrid’s monitoring or recording of
                                                calls between Cybrid and User’s representatives.
                                            </li>
                                        </ol>
                                    </div>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <div>
                                        <h3>7. Indemnification</h3>
                                        <p>
                                            <p>
                                                User shall defend, indemnify, and hold harmless Cybrid,
                                                its employees, officers, directors Affiliates,
                                                suppliers, licensors, Partners and other customers
                                                against any and all liability including damages,
                                                recoveries, deficiencies, interest, penalties, losses
                                                and reasonable attorney’s fees arising out of or
                                                relating to:
                                            </p>
                                            <ol>
                                                <li>(i) Any breach of the terms hereof;</li>
                                                <li>(ii) Any breach of a Partner Agreement;</li>
                                                <li>(iii) Any violation of any Laws;</li>
                                                <li>(iv) Any use of User Data by User or a Partner or other
                                                    third party;
                                                </li>
                                                <li>(v) User use of the Services, including in combination
                                                    with any third party service;
                                                </li>
                                                <li>(vi) Any Transaction; </li>
                                                <li>(vii) Any financial transaction occurring as a result of
                                                    data communicated via the Services;
                                                </li>
                                                <li>(viii) Any act or omission of any Partner or User; </li>
                                                <li>
                                                    (ix) Costs incurred by Cybrid enforcing the terms hereof
                                                    or responding to any subpoena relating to User, User
                                                    Data or a Partner;
                                                </li>
                                                <li>
                                                    (x) Any claim by a governmental taxing authority; or
                                                </li>
                                                <li>
                                                    (xi) Any dispute between User and any third party or
                                                    User Personnel.
                                                </li>
                                            </ol>
                                        </p>
                                    </div>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <div>
                                        <h3>8. Term, Termination and Suspension</h3>
                                        <p>
                                            <strong>Term</strong>
                                        </p>
                                        <p>
                                            The term of this Agreement (“<strong>Term</strong>”) shall
                                            begin as of when User accepts this Agreement, completes
                                            the Application or obtains an Account and shall continue
                                            on a month to month basis until terminated in accordance
                                            with the terms hereof.
                                        </p>
                                        <p>
                                            <strong>Termination</strong>
                                        </p>
                                        <p>
                                            Either party may terminate this Agreement at any time for
                                            any reason or for no reason. User may terminate by closing
                                            their Account or such other means as the Services may
                                            provide. Cybrid may terminate this Agreement by notice to
                                            User through the Account, by email to the contact
                                            information provided in the Application or by other
                                            electronic notice to other contact information provided by
                                            User to Cybrid.
                                        </p>
                                        <p>
                                            <strong>Suspension</strong>
                                        </p>
                                        <br />
                                        <p>
                                            Cybrid may, at its discretion, suspend User access to or
                                            otherwise modify, the Services and any component thereof,
                                            without notice in order to:
                                        </p>
                                        <ol>
                                            <li>
                                                (i) Prevent damages to, or degradation of the integrity
                                                of, Cybrid’s internet network;
                                            </li>
                                            <li>(ii) Comply with any Law;</li>
                                            <li>(iii) Otherwise protect Cybrid from potential legal
                                                liability or harm to its reputation or business; or
                                            </li>
                                            <li>
                                                (iv) Because Cybrid has opted to change the Services.
                                                Cybrid will use commercially reasonable efforts to notify
                                                User of the reason(s) for such suspension or termination
                                                action as soon as reasonably practicable. Nothing
                                                contained in this Agreement will be construed to limit
                                                Cybrid’s actions or remedies or act as a waiver of
                                                Cybrid’s rights in any way with respect to any of the
                                                foregoing activities. Cybrid will not be responsible for
                                                any loss or damages incurred by User as a result of any
                                                termination or suspension of access to or use of the
                                                Services.
                                            </li>
                                        </ol>
                                    </div>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <div>
                                        <h3>9. User Support.</h3>
                                        <p>
                                            Cybrid will use commercially reasonable efforts to provide
                                            User with technical support services relating to the
                                            Services via its technical support website, email, or
                                            telephone.
                                        </p>
                                        <p>
                                            Cybrid may update the Services in its sole discretion
                                            which updates may alter, add or remove functionality of
                                            the Services. Cybrid may also, from time to time, schedule
                                            downtime for maintenance and upgrades to the Services.
                                        </p>
                                    </div>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <div>
                                        <h3>10. Fees.</h3>
                                        <p>
                                            <strong>Cybrid Fees</strong>
                                        </p>
                                        <p>
                                            Access to the Services may require User to pay Fees, as
                                            may be further described on the Site, the App or in the
                                            Account, all of which disclosure is included herein by
                                            reference. All Fees are non-refundable, unless otherwise
                                            provided herein. Cybrid reserves the right to amend the
                                            Fees by posting new Fees on the Site, in the App or in the
                                            Account; such changes shall take effect within thirty (30)
                                            days unless accepted by User earlier or are deemed
                                            accepted if User does not close their Account within such
                                            delay.
                                        </p>
                                        <p>
                                            <strong>Partner Fees</strong>
                                        </p>
                                        <p>
                                            User shall pay Partner Fees to Partners pursuant to
                                            Partner Agreement each as may be further described on the
                                            Site, the App or in the Account all of which disclosure is
                                            included herein by reference. Partners retain the right to
                                            amend Partner Fees as per Partner Agreement. Partner shall
                                            collect Partner Fees as per Partner Agreement.
                                        </p>
                                        <p>
                                            <strong>Payment of Fees</strong>
                                        </p>
                                        <p>
                                            Payment of Fees related to Crypto Sales Payment
                                            Transactions shall be as per disclosure in each such
                                            Payment Transaction that User authorizes and shall be
                                            set-off from the corresponding Crypto or Fiat settlements
                                            made by Cybrid.
                                        </p>
                                        <p>
                                            Unless otherwise agreed through disclosure in the Account
                                            or in writing, User hereby instructs Cybrid to provide
                                            each Partner with a standing Instruction to settle all
                                            Fees owing to Cybrid and all Partner Fees to Cybrid and
                                            Partner by set-offs from their Payment Transactions or by
                                            debits from User Bank Account or Wallet, as the case may
                                            be. User shall accept such direct debit or similar
                                            consents as may be required to give effect to the
                                            foregoing.
                                        </p>
                                        <p>
                                            On request by Cybrid, User shall authorize Cybrid to
                                            collect payment of Fees by set-offs from a credit card,
                                            debit card or other means of payment; User authorizes
                                            Cybrid to charge all Fees and other amounts owing
                                            hereunder from such payment method. If User pays any Fees
                                            with a credit card, Cybrid may seek pre-authorization of
                                            User’s credit card account prior to User’s purchase to
                                            verify that the credit card is valid and has the necessary
                                            funds or credit available to cover User’s purchase.
                                        </p>
                                        <p>
                                            The Services may include functionality for activating,
                                            updating, or canceling recurring payments for periodic
                                            charges. If User activates or updates recurring payments
                                            through the Services, User authorizes Cybrid to
                                            periodically charge, on a going-forward basis and until
                                            cancellation of either the recurring payments or User’s
                                            account, all accrued sums on or before the payment due
                                            date for the accrued sums. If User uses the Services to
                                            update or cancel any existing authorized one-time or
                                            recurring payment, it may take up to 10 business days for
                                            the update or cancellation to take effect.
                                        </p>
                                        <p>
                                            Without limitation, Cybrid reserves the right to suspend
                                            the Services until all Fees or other amounts owing
                                            hereunder are paid in full or terminate this Agreement for
                                            late payment.
                                        </p>
                                        <p>
                                            Fees quoted do not include, and User shall hold Cybrid
                                            harmless from any fees incurred by failed Transactions due
                                            to insufficient funds/crypto in the User account, all
                                            sales, use, gross receipts, value-added, personal property
                                            or other taxes, and all applicable duties, tariffs,
                                            assessments, export and import fees or similar charges
                                            (including interest and penalties imposed thereon) on the
                                            transaction contemplated herein, other than taxes based on
                                            the net income or profits of Cybrid.
                                        </p>
                                        <p>
                                            <strong>
                                                Crypto Sales Settlements ACH Consent / PAD Authorization
                                            </strong>
                                        </p>
                                        <p>
                                            For each Payment Transaction, User authorizes Cybrid,
                                            Partner or a designee of either to debit the User Bank
                                            Account. US Users hereby agree to the ACH Authorization
                                            set out at the end of this Agreement and Canadian or other
                                            non-US Users agree to the Pre-Authorized Debit Agreement
                                            set out at the end of this Agreement (each, an “
                                            <strong>ACH Authorization</strong>”). The User Bank
                                            Account used in respect of the ACH Authorization shall be
                                            the account supplied for such purpose by the User through
                                            the Account, which account number is deemed to be included
                                            in this Agreement. Cybrid may use the ACH Authorization to
                                            take or settle all Fiat payments with respect to Crypto
                                            Sales and also to debit the User Bank Account for any and
                                            all liabilities of User to Crypto hereunder.
                                        </p>
                                        <p>
                                            <strong>
                                                Insufficient Funds / NSF and failed Transactions
                                            </strong>
                                        </p>
                                        <p>
                                            In the event that the Transaction cannot be completed due
                                            to the Account not having sufficient funds or crypto, the
                                            Transaction will either be reversed or the User will be
                                            required to pay any Fees associated with the failed
                                            Transaction, including fees that may be owed to Cybrid’s
                                            Partners or third parties who assist in providing the
                                            Services.
                                        </p>
                                        <p>
                                            Without limitation, Cybrid reserves the right to suspend
                                            the Services until all Fees or other amounts owing
                                            hereunder are paid in full or terminate this Agreement for
                                            failed Transactions.
                                        </p>
                                    </div>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <div>
                                        <h3>11. Confidential Information.</h3>
                                        <p>
                                            Each party acknowledges that it may directly or indirectly
                                            disclose Confidential Information to the other party in
                                            the course of negotiation of and performance of this
                                            Agreement. All such Confidential Information disclosed
                                            hereunder shall remain the sole property of the disclosing
                                            party (or other third party), and the receiving party
                                            shall have no interest in, or rights with respect thereto,
                                            except as set forth herein. Each party agrees to treat
                                            such Confidential Information with the same degree of care
                                            and security as it treats its most confidential
                                            information. Each party may disclose such Confidential
                                            Information to employees and agents who require such
                                            knowledge to perform services under this Agreement. Except
                                            as otherwise contemplated by this Agreement, neither party
                                            shall disclose the Confidential Information of the other
                                            party to any third party without the prior written consent
                                            of the disclosing party, and the duty of confidentiality
                                            created by this section shall survive any termination of
                                            the Agreement. User Data shall, without limitation, be
                                            User Confidential Information. Cybrid Data shall, without
                                            limitation, be Cybrid Confidential Information.
                                        </p>
                                    </div>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <div>
                                        <h3>12. User Representations and Warranties.</h3>
                                        <p>User represents and warrants to Cybrid that:</p>
                                        <ol>
                                            <li>
                                                ● User has the legal authority to bind User organization
                                                to this Agreement and to perform hereunder and under
                                                Partner Agreement to which User is a party. User is the
                                                exclusive owner of the Account and is not operating the
                                                Account on behalf of any third party.
                                            </li>
                                            <li>
                                                ● User has the legal capacity to enter into this Agreement
                                                and perform User obligations hereunder.
                                            </li>
                                            <li>
                                                ● User is a business, charitable organization or
                                                not-for-profit organization and shall use the Services for
                                                only business purposes and not for individual consumer
                                                purposes.
                                            </li>
                                            <li>● User shall immediately advise Cybrid of defects in the
                                                Services or any claim or threatened claim against Cybrid.
                                                User shall immediately notify Cybrid of any defects in a
                                                Product for which a Payment Transactions have been used as
                                                a payment method.
                                            </li>
                                            <li>
                                                ● User use of the Services conforms to all Laws and the
                                                terms of this Agreement.
                                            </li>
                                        </ol>
                                    </div>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <div>
                                        <h3>13. No Warranties by Cybrid.</h3>
                                        <p>
                                            <strong>Content</strong>
                                        </p>
                                        <p>
                                            Content from Partners, Other Users, suppliers,
                                            advertisers, and other third parties may be made available
                                            to User through the Services. Cybrid does not control such
                                            content; User agrees that Cybrid is not responsible for
                                            any such content. Cybrid does not make any guarantees
                                            about the accuracy, currency, suitability, or quality of
                                            the information in such content and Cybrid assumes no
                                            responsibility for unintended, objectionable, inaccurate,
                                            misleading, or unlawful content made available by other
                                            users, advertisers, and other third parties or violation
                                            of any third party rights related to such content. The
                                            Services may contain links to websites not operated by
                                            Cybrid. Cybrid is not responsible for the content,
                                            products, materials, or practices (including privacy
                                            practices) of such websites. User understands that by
                                            using the Services, User may be exposed to third-party
                                            websites that User finds offensive, indecent or otherwise
                                            objectionable. Cybrid makes no warranty, representation,
                                            endorsement, or guarantee regarding, and accepts no
                                            responsibility for, the quality, content, nature or
                                            reliability of third party websites, products or services
                                            accessible by hyperlink or otherwise from the Site or
                                            Services including but not limited to Partner Services.
                                            Cybrid provides these links for User’s convenience only
                                            and does not control such third parties. Cybrid’s
                                            inclusion of links to such links or integrations does not
                                            imply any endorsement of the materials on such third party
                                            services or any association with their operators. The
                                            Services may contain links to sites that are operated by
                                            Cybrid but which operate under different terms. It is
                                            User’s responsibility to review the privacy policies and
                                            terms and conditions of any other site User visits. USER
                                            AGREES THAT IN NO EVENT WILL CYBRID BE LIABLE TO USER IN
                                            CONNECTION WITH ANY WEBSITES, CONTENT, MATERIALS, OR
                                            PRACTICES OF ANY THIRD PARTY.
                                        </p>
                                        <p>
                                            <strong>Services</strong>
                                        </p>
                                        <p>
                                            THE SERVICES AND ALL MATERIAL OR CONTENT AVAILABLE THROUGH
                                            THE SERVICE ARE PROVIDED “AS IS”, “AS AVAILABLE”, WITH ALL
                                            FAULTS AND WITHOUT WARRANTIES, REPRESENTATIONS OR
                                            CONDITIONS OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY
                                            APPLICABLE LAW, CYBRID HEREBY DISCLAIMS ALL WARRANTIES,
                                            EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, REPRESENTATIONS
                                            OR CONDITIONS, WHETHER WRITTEN OR ORAL, INCLUDING ANY
                                            IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE,
                                            NONINFRINGEMENT, SECURITY, RELIABILITY, COMPLETENESS,
                                            QUIET ENJOYMENT, ACCURACY, QUALITY, INTEGRATION OR FITNESS
                                            FOR A PARTICULAR PURPOSE, AND ANY WARRANTY ARISING OUT OF
                                            COURSE OF DEALING, USAGE, OR TRADE. CYBRID DOES NOT
                                            WARRANT THAT THE SERVICES WILL OPERATE WITHOUT
                                            INTERRUPTION OR BE ERROR FREE. NO ADVICE OR INFORMATION,
                                            WHETHER ORAL OR WRITTEN, OBTAINED BY USER FROM THE
                                            SERVICES OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE
                                            SERVICES WILL CREATE ANY WARRANTY REGARDING ANY OF THE
                                            CYBRID ENTITIES OR THE SERVICES THAT IS NOT EXPRESSLY
                                            STATED IN THIS AGREEMENT. USER ASSUMES ALL RISK FOR ANY
                                            DAMAGE THAT MAY RESULT FROM USER’S USE OF OR ACCESS TO THE
                                            SERVICES, USER’S DEALING WITH ANY PARTNER OR OTHER USER,
                                            AND ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE
                                            SERVICES. USER UNDERSTANDS AND AGREES THAT USER USES THE
                                            SERVICES, AND USES, ACCESSES, DOWNLOADS, OR OTHERWISE
                                            OBTAINS MATERIALS OR CONTENT THROUGH THE SERVICES AND ANY
                                            ASSOCIATED SITES OR SERVICES, AT USER’S OWN DISCRETION AND
                                            RISK, AND THAT USER IS SOLELY RESPONSIBLE FOR ANY DAMAGE
                                            TO USER’S PROPERTY (INCLUDING USER’S COMPUTER SYSTEM OR
                                            MOBILE 14 DEVICE USED IN CONNECTION WITH THE SERVICES), OR
                                            THE LOSS OF DATA THAT RESULTS FROM THE USE OF THE SERVICES
                                            OR THE DOWNLOAD OR USE OF MATERIAL OR CONTENT.
                                        </p>
                                        <p>
                                            SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF WARRANTIES
                                            AND USER MAY HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION
                                            TO JURISDICTION
                                        </p>
                                    </div>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <div>
                                        <h3>14. Limitation of Liability.</h3>
                                        <p>
                                            IN NO EVENT WILL CYBRID BE LIABLE FOR DAMAGES ARISING OUT
                                            OF OR IN ANY WAY CONNECTED TO THIS AGREEMENT EXCEEDING
                                            FEES PAID TO CYBRID IN RESPECT OF THE SERVICES DURING THE
                                            THREE (3) MONTHS PRIOR TO THE EVENT GIVING RISE TO
                                            LIABILITY, EXCEPT FOR CYBRID LIABILITY TO SETTLE CRYPTO OR
                                            FIAT CONSIDERATION IN A CRYPTO SALE WHERE CYBRID LIABILITY
                                            SHALL BE UP TO THE AMOUNT OF SUCH AMOUNT OWING BUT NOT
                                            SETTLED.
                                        </p>
                                        <p>
                                            THE EXISTENCE OF ONE OR MORE CLAIMS UNDER THIS AGREEMENT
                                            WILL NOT INCREASE THE MAXIMUM LIABILITY AMOUNT. IN NO
                                            EVENT SHALL PARTNERS HAVE ANY LIABILITY ARISING OUT OF OR
                                            IN ANY WAY CONNECTED TO THIS AGREEMENT.
                                        </p>
                                        <p>
                                            IN NO EVENT SHALL CYBRID BE LIABLE TO USER FOR ANY
                                        </p>
                                        <ol>
                                            <li>(I)
                                                SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL
                                                DAMAGES,
                                            </li>
                                            <li>
                                                (II) LOST SAVINGS, PROFITS, DATA, USE, OR
                                                GOODWILL,
                                            </li>
                                            <li>
                                                (III) BUSINESS INTERRUPTION EVEN IF NOTIFIED IN
                                                ADVANCE OF SUCH POSSIBILITY, OR
                                            </li>
                                            <li>
                                                (IV) PERSONAL OR PROPERTY
                                                DAMAGE ARISING OUT OF OR IN ANY WAY CONNECTED TO THIS
                                                AGREEMENT, REGARDLESS OF CAUSE OF ACTION OR THE THEORY OF
                                                LIABILITY, WHETHER IN CONTRACT, TORT (INCLUDING
                                                NEGLIGENCE, GROSS NEGLIGENCE, FUNDAMENTAL BREACH, BREACH
                                                OF A FUNDAMENTAL TERM) OR OTHERWISE. IN NO EVENT SHALL
                                                CYBRID BE LIABLE FOR PROCUREMENT OR COSTS OF SUBSTITUTE
                                                PRODUCTS OR SERVICES. THE FOREGOING LIMITATIONS WILL APPLY
                                                NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY
                                                LIMITED REMEDY HEREIN.
                                            </li>
                                        </ol>
                                        <p>
                                            TO THE EXTENT THAT CYBRID MAY NOT, AS A MATTER OF LAW,
                                            DISCLAIM ANY IMPLIED WARRANTY OR LIMIT LIABILITIES, THE
                                            SCOPE AND DURATION OF SUCH WARRANTY AND THE EXTENT OF
                                            CYBRID’S LIABILITY WILL BE THE MINIMUM PERMITTED UNDER
                                            SUCH LAW.
                                        </p>

                                        <p>
                                            Cybrid shall not be liable for any claims, losses or
                                            liabilities related to any Product, User or Partner.
                                        </p>
                                    </div>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <div>
                                        <h3>15. Notices.</h3>
                                        <p>
                                            Notices sent to either Party shall be effective when
                                            delivered in person or by email, one (1) day after being
                                            sent by overnight courier, or two (2) days after being
                                            sent by first class mail postage prepaid to the official
                                            contact designated below and immediately after being
                                            received by the other party’s server. Notices must be in
                                            writing and addressed as follows:
                                        </p>
                                        <p>For Cybrid, addressed to ______________________; and</p>
                                        <p>
                                            For User, to the addresses or email address provided by
                                            User on Application (as such address or email address may
                                            be updated by User from time to time in accordance with
                                            this Agreement).
                                        </p>
                                        <p>
                                            Cybrid may change its contact information by giving notice
                                            of such change to the User. User may change its contact
                                            information by using the currently available interfaces on
                                            Cybrid’s website. For contractual purposes, User (i)
                                            consents to receive communications from Cybrid in an
                                            electronic form; and (ii) agree that all terms and
                                            conditions, agreements, notices, documents, disclosures,
                                            and other communications (“<strong>Communications</strong>
                                            ”) that Cybrid provides to User electronically satisfy any
                                            legal requirement that such Communications would satisfy
                                            if it were in writing. User’s consent to receive
                                            Communications and do business electronically, and
                                            Cybrid’s agreement to do so, applies to all of User’s
                                            interactions and transactions with Cybrid. The foregoing
                                            does not affect User’s non-waivable rights. If User
                                            withdraws such consent, from that time forward, User must
                                            stop using the Services. The withdrawal of User’s consent
                                            will not affect the legal validity and enforceability of
                                            any obligations or any electronic Communications provided
                                            or business transacted between Cybrid prior to the time
                                            User withdraws its consent.
                                        </p>
                                        <p>
                                            By providing Cybrid with User’s mobile telephone number,
                                            User consents to receiving text messages at that number as
                                            requested for account verification, invitations, and other
                                            purposes related to the Services. While Cybrid does not
                                            charge a fee for text messages, User’s carrier may charge
                                            standard messaging, data, and other fees. User is
                                            responsible for these charges. Cybrid may send and receive
                                            text messages through cellular telephone operators or
                                            other networks, and the level of reliability may vary.
                                            Cybrid is not responsible for the timeliness or final
                                            delivery of the message, as this is out of our control and
                                            is the responsibility of the cellular telephone operator
                                            or other networks.
                                        </p>
                                    </div>
                                </div>{" "}

                                <div className="terms_condition-text text-justify">
                                    <h3>16. Governing Law and Arbitration.</h3>
                                    <p>
                                        Any controversy or claim arising out of or relating to this
                                        contract, or the breach thereof, shall be settled by
                                        arbitration administered by the American Arbitration
                                        Association under its Commercial Arbitration Rules unless
                                        indicated otherwise below, and judgment on the award
                                        rendered by the arbitrator(s) may be entered in any court
                                        having jurisdiction thereof. If User is located in the
                                        United States, this Agreement shall be governed by and
                                        construed in accordance with the laws of the State of New
                                        Jersey, USA, and the venue of any arbitration commenced
                                        under this section shall be in Newark, New Jersey, under the
                                        rules of the American Arbitration Association. If User is
                                        located in Canada or otherwise outside of the United States,
                                        this Agreement shall be governed and construed in accordance
                                        with the laws of the Province of Ontario, Canada and the
                                        venue of any arbitration commenced under this section shall
                                        be in Toronto, Ontario under the rules of the Arbitration
                                        Act (Ontario).
                                    </p>
                                    <p>
                                        BOTH USER AND CYBRID AGREE TO HAVE ALL DISPUTES, CLAIMS OR
                                        CONTROVERSIES ARISING OUT OF OR RELATING TO THIS AGREEMENT
                                        DECIDED BY NEUTRAL BINDING ARBITRATION AND ARE GIVING UP ANY
                                        RIGHTS IT MIGHT POSSESS TO HAVE THOSE MATTERS LITIGATED IN A
                                        COURT OR JURY TRIAL. BY AGREEING TO THIS AGREEMENT, BOTH
                                        USER AND CYBRID ARE GIVING UP THEIR RESPECTIVE JUDICIAL
                                        RIGHTS TO DISCOVERY AND APPEAL EXCEPT TO THE EXTENT THAT
                                        THEY ARE SPECIFICALLY PROVIDED FOR UNDER THIS AGREEMENT. IF
                                        ANY PARTY REFUSES TO SUBMIT TO ARBITRATION AFTER AGREEING TO
                                        THIS PROVISION, THAT PARTY MAY BE COMPELLED TO ARBITRATE
                                        UNDER FEDERAL OR STATE OR PROVINCIAL LAW. BOTH USER AND
                                        CYBRID CONFIRM THEIR AGREEMENT TO THIS ARBITRATION PROVISION
                                        IS VOLUNTARY.
                                    </p>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <h3>17. General Provisions.</h3>
                                    <p>
                                        17.1. Electronic Consent. This Agreement and all
                                        electronically executed documents related hereto are legally
                                        binding in the same manner as are hard copy documents
                                        executed by hand signature when
                                    </p>

                                    <ol>
                                        <li>
                                            (1) User electronic signature is associated with the Agreement and related documents,
                                        </li>
                                        <li>
                                            (2) User consents and intends to be bound by the
                                            Agreement and related documents, and
                                        </li>
                                        <li>
                                            (3) the Agreement is
                                            delivered in an electronic record capable of retention by
                                            the recipient at the time of receipt (i.e., print or
                                            otherwise store the electronic record). User agrees
                                        </li>
                                        <ol className="d-block">
                                                <li>(i) that
                                                    the Agreement and related documents shall be effective by
                                                    electronic means
                                                </li>
                                                <li>(ii) to be bound by the terms and
                                                    conditions of this Agreement and related documents and </li>
                                                <li>(iii)
                                                    that User has the ability to print or otherwise store the
                                                    Agreement and related documents.
                                                </li>
                                            </ol>
                                    </ol>
                                    <p>
                                        17.2. Assignment. User may not assign this Agreement or any
                                        of its rights or obligations hereunder to any third party
                                        without prior written consent of Cybrid. Any assignment in
                                        violation of this section shall be void. Cybrid may assign
                                        this Agreement without restriction and without any notice to
                                        User. The terms of this Agreement shall be binding upon
                                        permitted successors and assigns. User agrees that on a sale
                                        of all or substantively all of the assets of Cybrid, Cybrid
                                        may include in the sale the copy of User’s payment method
                                        (e.g. User Bank Account information, Wallet information,
                                        credit card information) used to pay Fees hereunder or
                                        Merchant Fees under Merchant Terms.
                                    </p>

                                    <p>
                                        17.3. Right to List as a User. User agrees that Cybrid may
                                        utilize User’s entity name in listings of current customers.
                                        Use of User’s name in any other marketing materials or press
                                        announcements will be submitted to User in advance for
                                        approval, and such approval will not be unreasonably
                                        withheld.
                                    </p>

                                    <p>
                                        17.4. Compliance with Export Regulations. User has or shall
                                        obtain in a timely manner all necessary or appropriate
                                        licenses, permits or other governmental authorizations or
                                        approvals; shall indemnify and hold Cybrid harmless from,
                                        and bear all expense of, complying with all foreign or
                                        domestic laws, regulations or requirements pertaining to the
                                        importation, exportation, or use of the technology to be
                                        developed or provided herein. User shall not directly or
                                        indirectly export or re-export (including by transmission)
                                        any regulated technology to any country to which such
                                        activity is restricted by regulation or statute, without the
                                        prior written consent, if required, of the administrator of
                                        export laws.
                                    </p>

                                    <p>
                                        17.5. European Union Residents. If User resides in the
                                        European Union (EU) or if any transfer of information
                                        between User and the Services is governed by the European
                                        Union Data Protection Directive or national laws
                                        implementing that Directive, then User consents to the
                                        transfer of such information outside of the European Union
                                        to its country and to such other countries as may be
                                        contemplated by the features and activities provided by the
                                        Services.
                                    </p>

                                    <p>
                                        17.6. Construction. Except as otherwise provided herein, the
                                        Parties' rights and remedies under this Agreement are
                                        cumulative. The term “including” means “including without
                                        limitation.” The headings of sections of this Agreement are
                                        for reference purposes only and have no substantive effect.
                                    </p>

                                    <p>
                                        17.7. Force Majeure. User acknowledges and understands that
                                        if Cybrid is unable to provide Services as a result of a
                                        force majeure event Cybrid will not be in breach of this
                                        Agreement and will not be liable for delays caused by such
                                        event. A force majeure event means any event beyond the
                                        control of Cybrid.
                                    </p>

                                    <p>
                                        17.8. Severable. NOTHING IN THIS AGREEMENT WILL AFFECT ANY
                                        NON-WAIVABLE STATUTORY RIGHTS THAT APPLY TO USER. Any
                                        provision hereof found by a tribunal of competent
                                        jurisdiction to be illegal or unenforceable shall be
                                        automatically conformed to the minimum requirements of law,
                                        and the validity, legality, and enforceability of all other
                                        provisions shall remain in full force and effect.
                                    </p>

                                    <p>
                                        17.9. Waiver. The failure to exercise, or delay in
                                        exercising, a right, power or remedy provided in this
                                        Agreement or by law in one instance shall not preclude
                                        enforcement thereof on future occasions.
                                    </p>

                                    <p>
                                        17.10. Independent Contractors.User’s relationship to Cybrid
                                        is that of an independent contractor, and neither Party is
                                        an agent or partner of the other. User will not have, and
                                        will not represent to any third party that it has, any
                                        authority to act on behalf of Cybrid.
                                    </p>

                                    <p>
                                        17.11. Third Party Beneficiaries. Each Partner is a third
                                        party beneficiary hereunder for purposes of indemnity and
                                        limitations on liability.
                                    </p>

                                    <p>
                                        17.12. Entire Agreement. In the event of any conflict
                                        between this Agreement and the information provided during
                                        Application or otherwise on the Site, in the App or in the
                                        EULA, this Agreement shall control. In the event of any
                                        inconsistency between the body of this Agreement and the
                                        Cybrid Privacy Policy, the former shall prevail. This
                                        Agreement constitutes the entire agreement between the
                                        Parties with respect to the subject matter hereof and
                                        supersedes any and all prior agreements, negotiations, or
                                        other communications, whether written or oral. This
                                        Agreement may be executed in one or more counterparts, each
                                        of which shall be deemed an original and all of which shall
                                        be taken together and deemed to be one instrument. A printed
                                        version of this Agreement and of any notice given in
                                        electronic form shall be admissible in judicial or
                                        administrative proceedings based upon or relating to this
                                        Agreement to the same extent and subject to the same
                                        conditions as other business documents and records
                                        originally generated and maintained in printed form.
                                    </p>

                                    <p>
                                        17.13. Amendments. Cybrid reserves the right to change this
                                        Agreement at any time and from time to time upon notice by
                                        posting revisions to this Agreement (including the
                                        description of the Services) on the Site. Continued use of
                                        the Services after User becomes aware of any such changes
                                        shall constitute User’s consent to such changes. User is
                                        responsible for regularly reviewing the most current version
                                        of this Agreement which is available on Cybrid’s website.
                                    </p>

                                    <p>
                                        17.14. English Language. It is the express wish of the
                                        parties that this Agreement and all related documents be
                                        drawn up in English.
                                    </p>

                                    <p>
                                        17.15. Survival. Upon termination or expiration of this
                                        Agreement for any reason:
                                    </p>
                                    <ol>
                                        <li>
                                        (i) All rights and obligations of
                                        both Parties (except for User’s payment of all sums then
                                        owing), including all licenses granted hereunder, shall
                                        immediately terminate except as provided below;
                                        </li>
                                        <li>
                                        (ii) Within
                                        thirty (30) days after the effective date of termination,
                                        each Party shall comply with the obligations to return or
                                        destroy all Confidential Information. The following Sections
                                        will also survive expiration or termination of this
                                        Agreement for any reason: the introductory paragraph,
                                        Sections 3 Limitations, 4 Cybrid Intellectual Property
                                        Rights, 7 Indemnity, 10 Fees, 11 Confidential Information,
                                        12 User Warranties, 13 No Warranties by Cybrid, 14
                                        Limitation of Liability, 15 Notices, 16 Governing Law and
                                        Arbitration, 17 General Provisions, and 18 Glossary.
                                        </li>
                                    </ol>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <h3>18. Glossary.</h3>
                                    <p>
                                        The following words used in this Agreement are defined as
                                        follows:
                                    </p>

                                    <p>
                                        <strong>Account</strong> means a data-only account made
                                        available to User by Cybrid through the Site or the App
                                        through which User can deliver Instructions and send or
                                        receive other information in relation to the Services,
                                        Transactions, and Payment Transactions.
                                    </p>

                                    <p>
                                        <strong>Affiliate</strong> means, in relation to a Person,
                                        another Person that directly or indirectly owns or controls,
                                        is owned or controlled by, or is under common ownership or
                                        common control with the Person, or a Person's principal
                                        partners, shareholders, or owners of some other ownership
                                        interest.
                                    </p>

                                    <p>
                                        <strong>Agreement</strong> means this agreement made between
                                        Cybrid and User.
                                    </p>

                                    <p>
                                        <strong>App</strong> means an application (if any) available
                                        for download to certain mobile devices through which User
                                        can access the Services, subject to the EULA.
                                    </p>

                                    <p>
                                        <strong>Application</strong> means the form or application
                                        completed by User when registering for or applying for the
                                        Services all of which is incorporated herein by reference.
                                    </p>

                                    <p>
                                        <strong>Confidential Information</strong> means all
                                        proprietary, secret, or confidential information or data
                                        relating to either party and its affiliates, operations,
                                        employees, products or services, clients, customers or
                                        potential customers. Confidential Information shall include
                                        customer lists, cardholder account numbers, pricing
                                        information, computer access codes, instruction and/or
                                        procedural manuals, and the terms and conditions of this
                                        Agreement. Information shall not be considered Confidential
                                        Information to the extent, but only to the extent, that such
                                        information is:
                                    </p>
                                    <ol>
                                        <li>
                                            (i) Already known to the receiving party
                                            free of any restriction at the time it is obtained;
                                        </li>
                                        <li>
                                            (ii)
                                            Subsequently learned from an independent third party free of
                                            any restriction and without breach of this Agreement; or
                                        </li>
                                        <li>
                                            (iii)
                                            Becomes publicly available through no wrongful act of the
                                            receiving party;
                                        </li>
                                        <li>
                                            (iv) Independently developed by the
                                            receiving party without reference to any Confidential
                                            Information of the other; or
                                        </li>
                                        <li>
                                            (v) Required to be disclosed by Law.
                                        </li>
                                    </ol>
                                    <p>
                                        <strong>Crypto</strong> means virtual currency that is not a
                                        security in any of the jurisdictions where User or Cybrid
                                        are located (e.g., Bitcoin).
                                    </p>

                                    <p>
                                        <strong>Cybrid Data</strong> means information concerning
                                        Services or provided to User by Cybrid through the Account
                                        or otherwise.
                                    </p>

                                    <p>
                                        <strong>Cybrid Privacy Policy</strong> means the privacy
                                        policy of Cybrid appended to this agreement posted at the
                                        Site such as it is from time to time.
                                    </p>

                                    <p>
                                        <strong>Cybrid System</strong> means a cloud-based system
                                        operated by Cybrid that allows User to access User Account
                                        and the Services.
                                    </p>

                                    <p>
                                        <strong>Data</strong> means User Data or Cybrid Data.
                                    </p>

                                    <p>
                                        <strong>Data Protection Legislation</strong> means all
                                        applicable data protection laws, including all UK data
                                        protection laws, the General Data Protection Regulation
                                        ((EU) 2016/679) and any other directly applicable European
                                        Union regulation (or directive) relating to privacy.
                                    </p>

                                    <p>
                                        <strong>Effective Date</strong> has the meaning set out in
                                        the preamble of this Agreement or otherwise in the
                                        Application.
                                    </p>

                                    <p>
                                        <strong>EULA</strong> means an end-user license agreement
                                        pursuant to which the App is licensed to the User for the
                                        limited purpose of accessing the Services.
                                    </p>

                                    <p>
                                        <strong>Fees</strong> means fees payable by User for use of
                                        the Services, as disclosed on the Site, the App or in the
                                        Account.
                                    </p>

                                    <p>
                                        <strong>Fiat</strong> means currency issued by a sovereign
                                        nation or national bank (e.g. USD).
                                    </p>

                                    <p>
                                        <strong>Instructions</strong> means User or Other User
                                        instructions as to a Payment Transaction delivered or
                                        received in a manner that is compatible with the Services,
                                        such as they may be from time to time.
                                    </p>

                                    <p>
                                        <strong>Intellectual Property Rights</strong> means all
                                        patent rights, copyright rights, mask work rights, moral
                                        rights, rights of publicity, trademark, trade dress, works
                                        of authorship, inventions, discoveries and service mark
                                        rights, goodwill, trade secret rights and other intellectual
                                        property rights as may now exist or hereafter come into
                                        existence, and all applications therefore and
                                        registrations,renewals and extensions thereof, under the
                                        laws of any state, country, territory or other jurisdiction.
                                    </p>

                                    <p>
                                        <strong>Laws</strong> shall mean laws, statutes, codes,
                                        ordinances, orders, decrees, rules, regulations, and
                                        municipal by-laws, whether domestic or foreign, all
                                        judgments, orders, writs, injunctions, decisions, rulings,
                                        decrees, and awards of any government authority having
                                        jurisdiction.
                                    </p>

                                    <p>
                                        <strong>Active Jurisdictions</strong> means those
                                        jurisdictions in which Cybrid offers the Services, such as
                                        they may be from time to time.
                                    </p>

                                    <p>
                                        <strong>Other User</strong> means a use of Cybrid Services
                                        that is not the User under this Agreement.
                                    </p>

                                    <p>
                                        <strong>Partner Agreement</strong> means an agreement
                                        between User and a Partner concerning the supply of Partner
                                        Services to User.
                                    </p>

                                    <p>
                                        <strong>Partner Fees</strong> means fees charged to User by
                                        Partner under a Partner Agreement.
                                    </p>

                                    <p>
                                        <strong>Partner Integration Agreement</strong> means an
                                        agreement between Partner and Cybrid pursuant to which
                                        Partner has agreed to integrate Partner Services with the
                                        Services of Cybrid.
                                    </p>

                                    <p>
                                        <strong>Partner</strong> means a third-party provider of
                                        Crypto or Fiat financial, payment, exchange, transmission,
                                        or remittance services that:
                                    </p>

                                    <ol type="i">
                                        <li>(i) Is party to a Partner Integration with Cybrid;</li>
                                        <li>(ii) That Cybrid indicates in the Account is compatible with the Services; and</li>
                                        <li>(iii) Has entered into Partner Agreement with User pursuant to which
                                            the Partner provides Partner Services to User.</li>
                                    </ol>

                                    <p>
                                        <strong>Partner Services</strong> means the services of a
                                        Partner provided pursuant to a Partner Agreement.
                                    </p>

                                    <p>
                                        <strong>Party</strong> means either User or Cybrid, and{" "}
                                        <strong>Parties</strong> means both User and Cybrid.
                                    </p>

                                    <p>
                                        <strong>Payment</strong> means payment under a Payment
                                        Transaction, including settlement of Funds or Crypto.
                                    </p>

                                    <p>
                                        <strong>Payment Transaction</strong> means
                                    </p>

                                    <ol>
                                        <li>
                                            (i) Such Fiat or
                                            Crypto transactions as Partners make under Partner
                                            Agreements; or
                                        </li>
                                        <li>
                                            (ii) A Crypto Sale hereunder between User and
                                            Cybrid.
                                        </li>
                                    </ol>

                                    <p>
                                        <strong>Person</strong> is to be broadly interpreted and
                                        includes an individual, a corporation, a partnership, a
                                        trust, an unincorporated organization, the government of a
                                        country or any political subdivision thereof, or any agency
                                        or department of any such government, and the executors,
                                        administrators, or other legal representatives of an
                                        individual in such capacity.
                                    </p>

                                    <p>
                                        <strong>Product</strong> means any product or service for
                                        sale or provided or procured by User or for which a Partner
                                        is used to make payment or for which Services are used to
                                        assist in a payment.
                                    </p>

                                    <p>
                                        <strong>Regulatory Authority</strong> means a government
                                        bank, ministry, or other agency having legal jurisdiction
                                        over the User or Cybrid.
                                    </p>
                                    <br></br>

                                    <p>
                                        <strong>Site</strong> means{" "}
                                        <a href="https://www.cybrid.xyz">
                                            <u>https://www.cybrid.xyz</u>
                                        </a>
                                        .
                                    </p>
                                    <br></br>

                                    <p>
                                        <strong>Term</strong> has the meaning set out in Section 8.
                                    </p>

                                    <p>
                                        <strong>Transaction</strong> means sending or attempted
                                        sending of Data, by way of the Services, between any of
                                        User, Cybrid, Partner, an Other User, or another third
                                        party.
                                    </p>

                                    <p>
                                        <strong>User Bank Account</strong> means a bank or financial
                                        account of the User identified by User as being an account
                                        from which: Partner may debit or deposit funds under Partner
                                        Agreement; and from which Cybrid, or its designee, may debit
                                        Fees or other amounts owing hereunder.
                                    </p>

                                    <p>
                                        <strong>User Data</strong> means any and all non-public
                                        identifiable personal information of Users.
                                    </p>

                                    <p>
                                        <strong>User Device</strong> means a computer system,
                                        tablet, phone, authenticator, or Wallet used by User to
                                        access the Services, Partner Services, or manage Data or
                                        User business.
                                    </p>

                                    <p>
                                        <strong>User</strong> means you, and if you are using the
                                        Services on behalf of a company, entity, or organization,
                                        the entity, partnership, or organization, then ‘you’ also
                                        includes such entity.
                                    </p>

                                    <p>
                                        <strong>Wallet</strong> means the Crypto wallet of User or
                                        Cybrid.
                                    </p>

                                    <p className="text-center">
                                        <strong>ACH Consent</strong>
                                    </p>
                                    <p>
                                        User agrees that information required to complete the ACH
                                        Consent herein may be completed within the Account where
                                        provided for by Cybrid.
                                    </p>

                                    <p>
                                        <u>
                                            For US Users only the following ACH Consent shall apply
                                        </u>
                                    </p>
                                    <p>
                                        <strong>US ACH CONSENT</strong>
                                    </p>
                                    <p>
                                        User desires to effect settlement of credits and debits from
                                        the User Deposit Account by means of ACH and/or wire
                                        transfer in conjunction with the Services for its Users by
                                        Cybrid. In accordance with this desire, User authorizes
                                        Cybrid and/or its affiliates to initiate debit and credit
                                        entries to the User Deposit Account (the details of which
                                        are set out below). User agrees to maintain sufficient funds
                                        in User Deposit Account to cover debit transactions. By
                                        signing this authorization, User states that it has
                                        authority to agree to such transactions and that the User
                                        Deposit Account indicated is a valid and legitimate account
                                        for the handling of these transactions. This authority is to
                                        remain in effect until Cybrid receives written notice from
                                        User revoking it. This authorization is for the payment for
                                        Transactions, or any other sums owed to Cybrid or its
                                        Affiliates. User also certifies that the appropriate
                                        authorizations are in place to allow User to authorize this
                                        method of settlement. All changes to the identification of
                                        the User Deposit Account under this authorization must be
                                        made in writing in accordance with the Agreement. User
                                        understands that if the information supplied as to the ABA
                                        Routing Number and Account Number of the User Deposit
                                        Account is incorrect, and funds are incorrectly deposited,
                                        Cybrid will attempt to assist User in the recovery of such
                                        funds but has no liability as to restitution of the same.
                                        Cybrid’s assistance in recovering the funds, where
                                        available, will be billed to User at Cybrid’s current hourly
                                        rate for such work. User acknowledges that the origination
                                        of ACH transactions to the User Deposit Account must comply
                                        with the provisions of U.S. law.
                                    </p>

                                    <p>
                                        DETAILS FOR REPETITIVE TRANSFERS TO AND FROM USER DEPOSIT
                                        ACCOUNT ARE AS PROVIDED VIA THE ACCOUNT OR AS OTHERWISE
                                        REQUIRED BY CYBRID.
                                    </p>
                                    <p>
                                        <u>
                                            For Canadian Users only the following ACH Consent shall
                                            apply:
                                        </u>
                                    </p>

                                    <p className="text-center">
                                        <strong>CANADIAN</strong>
                                    </p>
                                    <p className="text-center">
                                        <strong>
                                            {" "}
                                            PRE-AUTHORIZED PAYMENT PLAN (“PAD”) AUTHORIZATION / ACH
                                            CONSENT
                                        </strong>
                                    </p>

                                    <p>
                                        User hereby authorizes Cybrid to draw on or deposit to the
                                        account maintained by User at the bank identified below, on
                                        a blank cheque provided by User or otherwise identified for
                                        Cybrid for such purposes (the bank being “Bank and such
                                        account the “<strong>Designated Account</strong>”). The
                                        foregoing consent is provided pursuant to the Agreement
                                        between User and Cybrid (the “<strong>Agreement</strong>”)
                                        and is to allow for Cybrid to settle or to receive
                                        provisional funding of User fees pursuant to the Agreement,
                                        for the purpose of paying all regular payments and all other
                                        amounts owing by User to Cybrid pursuant to the terms of the
                                        Agreement, including without limitation, charges, fines,
                                        fees, penalties, payment of current or past due amounts for
                                        equipment lease, rental or purchase and all other amounts
                                        owing in the event of a default under the Agreement and for
                                        paying all amounts owing under any other agreement with
                                        Cybrid or any of its affiliates for any related services
                                        (the “PAD Authorization”). In respect of payment of any
                                        amounts owing all regular recurring payments will be debited
                                        on or about the 17th day of each month (or on such other
                                        day, each month, mutually agreeable to the parties), in each
                                        case, in the amounts set out in the Agreement.
                                    </p>

                                    <p>
                                        For debits other than regular recurring debits and/or
                                        one-time payments owing in connection with the Agreement,
                                        User understands that Cybrid will obtain User’s
                                        authorization prior to initiating any such debit.
                                    </p>

                                    <p>
                                        In particular, User further agrees that if any payment is
                                        dishonored by the Bank for any reason, then Cybrid shall be
                                        entitled to issue another debit in substitution for the
                                        dishonored debit. User acknowledges that this PAD
                                        Authorization is provided for the benefit of Cybrid and the
                                        Bank and is provided in consideration of the Bank agreeing
                                        to process debits against User’s Designated Account in
                                        accordance with the rules of the Canadian Payments Card
                                        Organization.
                                    </p>
                                    <p>
                                        {" "}
                                        [USER BANK ACCOUNT INFORMATION IS AS PROVIDED VIA THE
                                        ACCOUNT OR BY SUCH OTHER MEANS AS CYBRID MAY REQUIRE].
                                    </p>
                                    <p>
                                        {" "}
                                        USER shall be charged a fee for each credit and debit, that
                                        cannot be processed and all subsequent funding may be
                                        suspended until USER either (i) notifies Cybrid that credits
                                        and debits can be processed or (ii) a new Authorization is
                                        signed by USER. Bank must be able to process or accept
                                        credits and debits electronically.
                                    </p>

                                    <p>
                                        Bank’s treatment of each debit shall be the same as if USER
                                        had issued its check authorizing the Bank to pay as
                                        indicated and to debit the amount specified to USER’s
                                        Designated Account. USER confirms that this means,
                                        in part, that the Bank is not required to verify that a
                                        pre-authorized debit has been issued in accordance with
                                        USER’s instructions or that some precondition to payment
                                        has been met or satisfied.
                                    </p>

                                    <p>
                                        This PAD Authorization may be canceled at any time by
                                        written notice by USER to Cybrid which notice shall be
                                        effective five business days after receipt. To obtain a
                                        sample cancellation form, or for more information on the
                                        right to cancel this PAD Authorization, User understands
                                        that User may contact Bank or visit{" "}
                                        <a href="https://www.cdnpay.ca" target="_blank"><u className="text-primary">www.cdnpay.ca</u></a>. This PAD
                                        Authorization applies only to the method of payment and User
                                        agrees that revocation of this PAD Authorization does not
                                        terminate or otherwise having any bearing on the Agreement.
                                        This PAD Authorization may be discontinued at any time by
                                        Cybrid without notice. Delivery of this PAD Authorization,
                                        to Cybrid, constitutes delivery by USER to Bank. USER
                                        confirms that the debits authorized hereunder are for
                                        business purposes. USER hereby waives the right to receive
                                        any notice, written or otherwise, from Cybrid of the amount
                                        to be debited and the date(s) on which such debits are to be
                                        processed, as well as notice of any and all future changes
                                        to the amounts or payment dates.{" "}
                                    </p>
                                    <p>
                                        <strong>
                                            User hereby waives the “Pre-notification/Confirmation”
                                            requirements set out in Appendix II of Rule H1
                                            (“Pre-authorized Debits”) of the Canadian Payments Card
                                            Organization.
                                        </strong>
                                    </p>

                                    <p>
                                        User understands that User has recourse rights if any debit
                                        does not comply with this PAD Authorization. For example,
                                        User has the right to receive reimbursement for any debit
                                        that is not authorized or is not consistent with this PAD
                                        Authorization. To obtain more information on User recourse
                                        rights, User may contact the Bank or visit{" "}
                                        <a href="https://www.cdnpay.ca" target="_blank"><u className="text-primary">www.cdnpay.ca</u></a>.
                                    </p>

                                    <p>
                                        USER consents to the disclosure of any relevant information
                                        contained in the Agreement and this PAD Authorization to the
                                        financial institution for purposes of the Automatic Debit
                                        Program.{" "}
                                    </p>
                                    <p>
                                        <strong>
                                            Cybrid may assign this PAD Authorization, whether directly
                                            or indirectly, by operation of law, change of control, or
                                            otherwise, by providing at least 10 days prior notice to
                                            User.
                                        </strong>
                                    </p>

                                    <p>
                                        All capitalized terms used in this PAD Authorization and not
                                        otherwise defined shall have the meaning set out in the
                                        Agreement.
                                    </p>

                                    <p>
                                        User understands that User may contact Cybrid at the address
                                        or phone number in the Agreement to make inquiries, obtain
                                        information, or seek any recourse rights.
                                    </p>
                                </div>


                                <div className="terms_condition-text text-justify">
                                    <h3 className="text-center">
                                        <strong>Appendix A</strong>
                                    </h3>
                                    <p className="text-center">
                                        <strong>PRIVACY POLICY</strong>
                                    </p>
                                    <p className="text-center">LAST UPDATED October 10, 2023</p>
                                    <p>
                                        Cybrid, Inc. and its affiliates (“<strong>Cybrid</strong>”)
                                        knows that you care about how your personal information is
                                        used and disclosed, and we take your privacy seriously. Our
                                        primary goal is to provide you with exceptional service, and
                                        we understand that you may have questions or concerns
                                        regarding your personal information and how it will be used.
                                        As a company operating in multiple jurisdictions, we follow
                                        the General Data Protection Regulation (GDPR) to protect
                                        personal information. To make this privacy notice easy to
                                        find, we make it available from the home page of the Cybrid
                                        website at Cybrid.xyz (the "<strong>Website</strong>"). You
                                        may also email us at <strong>support@cybrid.xyz</strong>{" "}
                                        with any privacy-related questions you have.
                                    </p>
                                    <p>
                                        <strong>To Whom Does This Privacy Policy Apply?</strong>
                                    </p>
                                    <p>
                                        This privacy notice applies to all information we collect
                                        through our Services from current and former Cybrid users,
                                        including you. “Services” means any products, services,
                                        content, features, technologies, or functions, and all
                                        related websites, applications and services offered to you
                                        by Cybrid, Inc. in connection with a Cybrid account. When
                                        you are no longer our customer, we continue to disclose your
                                        information as described in this statement.
                                    </p>
                                    <p>
                                        We share your information with other Cybrid companies (“
                                        <strong>Affiliates</strong>”), as well as trusted third
                                        parties and service providers, in order to offer our
                                        Services and fulfill legal requirements. Personal
                                        information that we process and collect may be transferred
                                        between companies, Services, and employees affiliated with
                                        us as a normal part of conducting business and offering our
                                        Services to you.
                                    </p>
                                    <p>
                                        As the Services evolve we may occasionally update this
                                        privacy notice to reflect changes to our business, Services,
                                        or applicable laws. If the revised version requires notice
                                        in accordance with applicable law, we will provide you with
                                        30 days prior notice by posting notice of the change on the
                                        "<strong>Privacy Policy</strong>" page of our website,
                                        otherwise the revised Privacy notice will be effective as of
                                        the published effective date.
                                    </p>
                                    <p>
                                        Throughout this statement, we use the term "
                                        <strong>personal information</strong>" to describe
                                        information that can be associated with a specific person
                                        and can be used to identify that person. We do not consider
                                        personal information to include information that has been
                                        aggregated and/or anonymized so that it does not identify a
                                        specific person.
                                    </p>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <h3>What Information Do We Collect?</h3>
                                    <p>
                                        When you visit our website or use one of our Services, we
                                        collect your IP address, and standard web log information,
                                        such as your browser type and the pages you accessed on our
                                        website. We also may collect Geolocation Information (defined
                                        below). If you do not agree to our collection of this
                                        information, you may not be able to use our Service.
                                    </p>
                                    <p>
                                        If you open a Cybrid account, we collect the following
                                        information from you:
                                    </p>
                                    <ol>
                                        <li>
                                            <span>
                                        ● <u>Account Information</u> - text-enabled cellular/wireless
                                        telephone number, machine or mobile device ID, and other
                                        similar information;
                                            </span>
                                        </li>
                                        <li>
                                        <span>
                                        ● <u>Identification Information</u> - your name, street address,
                                        email address, date of birth, and Social Security Number
                                        ("SSN") (or other government-issued verification numbers);
                                        </span>
                                        </li>
                                        <li>
                                        <span>
                                        ● <u>Device Information</u> - information about you: (a) from your
                                        mobile device or computer, such as your device type, machine
                                        or mobile device identification number, Geolocation
                                        Information, time zone, language setting, browser type, and
                                        IP address, and (b) from third parties for purposes of
                                        transaction processing, identity verification, fraud
                                        detection or prevention, and other similar purposes;
                                        </span>
                                        </li>
                                        <li>
                                        <span>
                                        ● <u>Geolocation Information</u> - information that identifies with
                                        reasonable specificity your location by using, for instance,
                                        longitude and latitude coordinates obtained through GPS,
                                        Wi-Fi, or cell site triangulation. We will collect this data
                                        for fraud and risk purposes. In addition, some of our
                                        Services may ask you for permission to disclose your current
                                        location within your device settings to enhance our
                                        Services. If you do not agree to our collection of
                                        Geolocation Information, our Services may not function
                                        properly when you try to use them. For information about
                                        your ability to restrict the collection and use of
                                        Geolocation Information to enhance our Services, please
                                        refer to the settings available in your device;
                                        </span>
                                        </li>
                                        <li>
                                        <span>
                                        ●  <u>Financial Information</u> - bank account online login
                                        information, bank account, and routing numbers linked to
                                        your Cybrid account;
                                        </span>
                                        </li>
                                    </ol>
                                    <p>
                                        We may also collect the above data from other third parties,
                                        including service providers, internet service providers,
                                        merchants, credit reporting agencies, government entities,
                                        data brokers, data analytics providers, advertising networks
                                        and financial institutions.{" "}
                                    </p>
                                    <p>
                                        We are committed to providing a safe, secure and all around
                                        great service. Therefore, before permitting you to use the
                                        Services, we may require additional information from you we
                                        can use to verify your identity, address or other
                                        information to comply with law or to manage risk and
                                        compliance throughout our relationship. We may also obtain
                                        information about you from third parties such as identity
                                        verification, fraud prevention and similar services.
                                    </p>
                                    <p>
                                        When you are using the Services, we collect information
                                        about your account transactions and we may collect
                                        Geolocation Information and/or information about your
                                        computer or your operating systems or other access device
                                        for fraud prevention and other similar purposes.
                                    </p>
                                    <p>
                                        Finally, we may collect additional information from or about
                                        you in other ways not specifically described here. For
                                        example, we may collect information related to your contact
                                        with our customer support team, store results when you
                                        respond to a survey, or when you use another Cybrid service.
                                    </p>
                                    <p>
                                        The Services are not directed to children under the age of
                                        13. If we obtain actual knowledge that we have collected
                                        personal information from a child under the age of 13, we
                                        will promptly delete it, unless we are legally obligated to
                                        retain such data. Contact us if you believe that we have
                                        mistakenly or unintentionally collected information from a
                                        child under the age of 13.
                                    </p>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <h3>How Do We Use Cookies?</h3>
                                    <p>
                                        When you visit or use our Services, or visit a third-party website or mobile application through which we
                                        offer our services, we and certain business partners and vendors may use cookies and other tracking
                                        technologies (collectively, "Cookies"). We use Cookies to recognize you as a customer; customize
                                        Services, other content and advertising; measure the effectiveness of promotions; perform a wide range
                                        of analytics; mitigate risk and prevent potential fraud; and to promote trust and safety across our
                                        Services.
                                    </p>
                                    <p>
                                        Certain Services are only available through the use of Cookies, so if you choose to disable or decline
                                        Cookies, your use of certain Services may be limited or not possible.
                                    </p>
                                    <p>
                                        Do Not Track ("DNT") is an optional browser setting that allows you to express your preferences
                                        regarding tracking by advertisers and other third-parties. We do not respond to DNT signals.
                                    </p>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <h3>How Do We Store and Protect Personal Information?</h3>
                                    <p>
                                        We store and process your personal information using third party servers located in data centers in the
                                        United States. This information is protected by physical, electronic and procedural safeguards in
                                        compliance with applicable US federal and state laws and regulations. We also use computer safeguards
                                        such as firewalls and data encryption, we enforce physical access controls to our office and files, and we
                                        authorize access to personal information only for those employees who require it to fulfill their job
                                        responsibilities.
                                    </p>
                                    <p>
                                        We strive to ensure security on our systems. Despite our efforts, we cannot guarantee that personal
                                        information may not be accessed, disclosed, altered or destroyed by breach of our administrative,
                                        managerial and technical safeguards. Therefore, we urge you to take adequate precautions to protect
                                        your personal information as well, including never sharing your Cybrid password with anyone.
                                    </p>
                                    <p>
                                        If Cybrid learns of a systems security breach, we may attempt to notify you electronically so that you can
                                        take appropriate protective steps. By using the Services, you agree that Cybrid may communicate with
                                        you electronically. Cybrid may post a notice on the website or mobile application if a security breach
                                        occurs. We may also send an email to you at the email address you have provided to us. Depending on
                                        where you live, you may have a legal right to receive notice of a security breach in writing. To receive
                                        free written notice of a security breach (or to withdraw your consent from receiving electronic notice of
                                        a security breach), please email us at <strong>support@cybrid.xyz.</strong>
                                    </p>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <h3>How Long Do We Store Your Personal Information?</h3>
                                    <p>
                                        We retain your Personal Information for as long as needed or permitted in context of the purpose for
                                        which it was collected and consistent with applicable law.
                                    </p>
                                    <p>
                                        The criteria used to determine our retention period is as follows:
                                    </p>
                                    <p>
                                        Personal Information used for the ongoing relationship between you and Cybrid is stored for the
                                        duration of the relationship plus a period of 10 years, unless we need to keep it longer, such as:
                                    </p>
                                    <ol>
                                        <li>
                                        ● A legal obligation or compliance with laws to which we are subject is retained consistent with the
                                        applicable law, such as under Anti-Money Laundering obligations;
                                        </li>
                                        <li>● Litigation, investigations, audit, and compliance practices, or to protect against legal claims.</li>
                                        <li>
                                        ● Customers also have various data retention and deletion rights enshrined by law throughout the
                                        world including, but not limited to, GDPR, CCPA, and PIPEDA.
                                        </li>
                                    </ol>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <h3>How Do We Use the Personal Information We Collect?</h3>
                                    <p>
                                        Our primary purpose in collecting personal information is to provide you with a safe, smooth, and
                                        efficient experience. We may use your personal information to:
                                    </p>
                                    <ol>
                                        <li>● Provide the services and customer support you request;</li>
                                        <li>● Process transactions and send notices about your transactions activity;</li>
                                        <li>● Resolve disputes, collect fees, and troubleshoot problems;</li>
                                        <li>● Prevent potentially fraudulent, prohibited or illegal activities, and enforce our User Agreement
                                        through the use of our risk and fraud tools which may include use of Account Information,
                                        Identification Information, Financial Information, Device Information, and Geolocation
                                        Information;
                                        </li>
                                        <li>
                                        ● Create an account connection between your Cybrid account and a third-party account or
                                        platform;
                                        </li>
                                        <li>
                                        ● Customize, personalize, measure, and improve our services and the content and layout of our
                                        website;
                                        </li>
                                        <li>● Send you updates about new products and services that we are offering;</li>
                                        <li>● Compare information for accuracy and verify it with third parties; and</li>
                                        <li>● Perform other duties as required by law.</li>
                                    </ol>
                                    <p>
                                        If you elect to disclose your Geolocation Information, we will use this information to enhance the
                                        security of the Services and we may use this information to provide you with location-specific options,
                                        functionality, and offers.
                                    </p>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <h3>How Do We Disclose Personal Information to Other Parties?</h3>
                                    <p>
                                        Cybrid does not disclose your personal information with third parties for their promotional or marketing
                                        purposes.
                                    </p>
                                    <p>
                                        Some personal information is public information and may be seen by anyone on the internet, whether or
                                        not they have a Cybrid account. Public information may also be seen, accessed, reshared or downloaded
                                        through Cybrid's APIs or third-party services that integrate with our products.
                                    </p>

                                    <p>
                                        We may disclose your personal information with:
                                    </p>
                                    <ol>
                                        <li>● Our affiliates, but only for purposes allowed by this document;</li>
                                        <li>
                                        ● Companies that Cybrid, Inc. plans to merge with or be acquired by or, in the event of any
                                        bankruptcy, a bankruptcy estate. Should such a combination occur, we will require that the new
                                        combined entity follow this privacy notice with respect to your personal information. If your
                                        personal information could be used contrary to this statement, you will receive prior notice and
                                        the opportunity to communicate preferences you may have, if applicable;
                                        </li>
                                        <li>
                                        ● Third party firms through which Cybrid offers its services;
                                        </li>
                                        <li>
                                        ● Law enforcement, government officials, or other third parties if PayPal is compelled to do so by a
                                        subpoena, court order or similar legal procedure; when it is necessary to do so to comply with
                                        law; or where the disclosure of personal information is reasonably necessary to prevent physical
                                        harm or financial loss, to report suspected illegal activity, or to investigate violations of the
                                        Cybrid User Agreement, or as otherwise required by law;
                                        </li>
                                        <li>
                                        ● Third party service providers who assist us in providing Services to you or who provide fraud
                                        detection or similar services on our or any vendor’s behalf;
                                        </li>
                                        <li>
                                        ● Service providers under contract who help with parts of our business operations (for example,
                                        fraud prevention, payment processing, or technology services). Our contracts dictate that these
                                        service providers only use your information in connection with the services they perform for us
                                        and not for their own benefit; and
                                        </li>
                                        <li>
                                        ● Other third-parties with your consent or at your direction to do so, including if you authorize an
                                        account connection with a third-party account or platform.
                                        </li>
                                    </ol>
                                    <p>
                                        For the purposes of this privacy notice, an "account connection" with such a third party is a connection
                                        you authorize or enable between your Cybrid account and a non-Cybrid account, payment instrument, or
                                        platform that you lawfully control or own. When you authorize such a connection, Cybrid and the
                                        third-party will exchange your personal information and other information directly. Examples of account
                                        connections include, without limitation: connecting your Cybrid account to a third-party data
                                        aggregation or financial services company, if you provide such company with your Cybrid account log-in
                                        credentials.
                                    </p>
                                    <p>
                                        If you connect your Cybrid account to other financial accounts, directly or through a third-party service
                                        provider, we may have access to your account balance and account and transactional information, such
                                        as purchases and funds transfers. If you choose to create an account connection, we may receive
                                        information from the third-party about you and your use of the third-party’s service. We will use all such
                                        information that we receive from a third-party via an account connection in a manner consistent with
                                        this privacy notice.
                                    </p>
                                    <p>
                                        Information that we disclose with a third-party based on an account connection will be used and
                                        disclosed in accordance with the third-party’s privacy practices. Before authorizing an account
                                        connection, you should review the privacy notice of any third-party that will gain access to your personal
                                        information as part of the account connection. For example, personal information that Cybrid discloses
                                        with a third-party account or platform may in turn be disclosed with certain other parties, including the
                                        general public, depending on the account’s or platform’s privacy practices.
                                    </p>
                                    <p>
                                        If you choose to use Siri or iMessage to use the Services, such use is subject to Apple’s terms and
                                        conditions for use of iMessage and/or Siri, as applicable, and the terms of the Cybrid User Agreement. By
                                        using Siri or iMessage, you authorize us to disclose some of your Cybrid account data (including
                                        transaction instructions and transaction notes) with Apple to allow it to facilitate transaction requests
                                        made through Siri or iMessage on iOS. Data disclosed with Apple will be used pursuant to Apple’s
                                        then-current user agreements and privacy policies. You can grant or revoke Apple’s access to Cybrid on
                                        iOS at any time under the “Siri” or “iMessage” settings on your iPhone.
                                    </p>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <h3>How Can You Access and Change Your Personal Information?</h3>
                                    <p>
                                        You can review and update your personal information in your account settings at any time by logging in
                                        to your account.
                                    </p>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <h3>California</h3>
                                    <p>
                                        Residents of California have certain rights under the California Consumer Privacy Act (CCPA), and Cybrid
                                        provides the following disclosures regarding the collection, use, and disclosure of personal information.
                                    </p>
                                    <p>
                                        We may collect and process information about California residents, and use such information, as defined
                                        in this Privacy Policy.
                                    </p>
                                    <p>
                                        Under the CCPA, California residents have the following rights:
                                    </p>
                                    
                                    <ol>
                                        <li>● Right to Know: California residents have the right to request information about the categories
                                        and specific pieces of personal information collected, the categories of sources from which it was
                                        collected, the business purposes for collecting or selling the information, and the categories of
                                        third parties with whom it is shared.
                                    </li>
                                        <li>
                                        ● Right to Delete: California residents have the right to request the deletion of their personal
                                        information.
                                        </li>
                                        <li>● Right to Opt-Out: California residents have the right to opt-out of the sale of their personal
                                        information if applicable.
                                        </li>
                                        <li>
                                        ● Right to Non-Discrimination: California residents have the right not to be discriminated against
                                        for exercising their privacy rights under the CCPA.
                                        </li>
                                    </ol>
                                    <p>
                                        How to Exercise Your Rights:
                                    </p>
                                    <ol>
                                        <li>● California residents can exercise their rights by emailing legal@cybrid.xyz.</li>
                                    </ol>
                                    <p>
                                        If you have any questions or concerns about our CCPA compliance or your privacy rights, please contact
                                        us at <strong>legal@cybrid.xyz.</strong>
                                    </p>

                                    <br />
                                    <br />

                                    <p>
                                        <strong>Links to Other Services or Sites</strong>
                                    </p>
                                    <p>
                                        The Services may contain links to (or allow you to link to) other third-party services or websites. Cybrid
                                        does not control the information collection of third-party services or websites that can be reached
                                        through such links. We encourage our users to be aware when they are linking to a third-party service or
                                        website and to read the privacy notices of any third-party service or website that collects personally
                                        identifiable information.
                                    </p>
                                    <p>
                                        <strong>Data use based on your consent</strong>
                                    </p>

                                    <br />

                                    <p>
                                        Cybrid uses certain third party service providers in order to provide the Services to you.
                                    </p>
                                    <p>
                                        <strong>
                                            In using the Services, you consent to Cybrid providing any Personal Information as may be required
                                            for third parties to provide the Services to you.
                                        </strong>
                                    </p>
                                    <p>
                                        You have the right to withdraw your consent at any time on a go-forward basis (which will not affect our
                                        prior use of your data, based on your previously given consent). You may change your device-based or
                                        in-app settings anytime
                                    </p>
                                </div>

                                <div className="terms_condition-text text-justify">
                                    <h3>How Can You Contact Us?</h3>
                                    <p>
                                        If you have questions or concerns regarding this privacy notice, or any feedback pertaining to your
                                        privacy and the Services that you would like us to consider, please email us at <strong>support@cybrid.xyz.</strong>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer1 />
        </>
    );
};

export default UserAgreement;
