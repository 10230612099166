import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ButtonLoader from "../../layout/buttonLoader";
import { useSelector, useDispatch } from "react-redux";
import { commonError, commonSuccess } from "../../../actions/customerActions";
import { signInAdmin, resetLogInFailure, resetLogInSuccess } from "../../../actions/adminActions";
import { useNavigate } from "react-router-dom";
import { decryptPassword, encryptPassword } from "../../../utils/Encryption";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  
  const signInSuccess = useSelector((state) => state.adminReducer.signInSuccess);
  const signInFailError = useSelector((state) => state.adminReducer.signInFailed);


  const [formData, setFormData] = useState({user: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(localStorage.getItem("armu"));


  useEffect(() => {
    // Check if "Remember me" is selected and load saved email and password from localStorage
    if (localStorage.getItem("armu")) {
      const savedEmail = localStorage.getItem("armu");
      const savedPassword = localStorage.getItem("armp");

      if (savedEmail && savedPassword) {
        setFormData({
          email: decryptPassword(savedEmail),
          password: decryptPassword(savedPassword),
        });
      }
    }
  }, [rememberMe]);

  // Checking if signin success or not
  useEffect(() => {
    if (Object.keys(signInSuccess).length > 0) {
      if (signInSuccess?.data?.jwtToken) {
        sessionStorage.setItem(
          "a_at",
          signInSuccess.data.jwtToken
        );
        if (signInSuccess?.data?.email) {
          localStorage.setItem(
            "email",
            signInSuccess.data.email
          )
        }
        setIsLoading(false);
        dispatch(commonSuccess("Logged in successfully"));
        dispatch(resetLogInSuccess());
        navigate("/admin/customer");
      } else {
        setIsLoading(false);
        navigate("/admin/login");
      }
    }
  }, [signInSuccess]);

  // Signin failed condition
  useEffect(() => {
    if (signInFailError && signInFailError.code) {
      setIsLoading(false);
      switch (signInFailError.code) {
        case "ERR_BAD_REQUEST":
          dispatch(commonError("You have entered an invalid email or password"));
          break;
        default:
          dispatch(commonError("Something went wrong. Please try again."));
          break;
      }
      dispatch(resetLogInFailure());
    }
  }, [signInFailError]);

  // Handling the onChange for inputs
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const maxLength = 60;
    const sanitizedValue = value.slice(0, maxLength);
    setFormData((prevFormData) => ({ ...prevFormData, [name]: sanitizedValue }));
  };

  // Handling the form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = {};

    // Check all the fields are filled, not empty
    if (!formData.email?.trim() || !formData.password?.trim()) {
      dispatch(commonError("Please fill in all fields."));
      return;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      dispatch(commonError("Invalid email format"));
      return; // Return early if there's an email format error
    }

    // Remember me condition
    if (rememberMe) {
      localStorage.setItem("armu", encryptPassword(formData.email));
      localStorage.setItem("armp", encryptPassword(formData.password));
    } else {
      localStorage.removeItem("armu");
      localStorage.removeItem("armp");
    }
    setIsLoading(true);
    setTimeout(() => {
      dispatch(signInAdmin({
        username: formData.email,
        password: formData.password
      }));
    }, 3000)

  };
  

  return (
    <>
      <div className="authincation section-padding ">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="mini-logo text-center my-5">
                <Link to={"/"}>
                  <img
                    src={require("../../../images/logo-with-text.png")}
                    alt=""
                  />
                </Link>
              </div>

              <div className="auth-form card">
                <div className="card-header justify-content-center">
                  <h4 className="card-title">Admin Sign In</h4>
                </div>

                <div className="card-body">
                  <form
                    method="post"
                    name="myform"
                    className="signin_validate"
                    onSubmit={handleSubmit}
                  >
                    <div className="mb-3">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="hello@example.com"
                        name="email"
                        value={formData.email}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>

                    <div className="mb-3">
                      <label>Password</label>
                      <div className="input-group">
                        <input
                          type={viewPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="password"
                          name="password"
                          value={formData.password}
                          onChange={(e) => handleInputChange(e)}
                        />
                        <div className="input-group-append" onClick={() => setViewPassword(!viewPassword)}>
                          <span className="input-group-text bg-transparent cursor-pointer">
                            {viewPassword ? <FontAwesomeIcon icon="eye" /> : <FontAwesomeIcon icon="eye-slash" />}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-4 mb-2">
                      <div>
                        <label className="toggle">
                          <input
                            className="toggle-checkbox"
                            type="checkbox"
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                          />
                          <span className="toggle-switch"></span>
                          <span className="toggle-label">Remember me</span>
                        </label>
                      </div>
                    </div>
                    <div className="text-center" style={{marginBottom: "1rem"}}>
                      <button
                        type="submit"
                        className={`btn btn-primary w-100 btn-loader ${isLoading ? "active" : ""}`}
                      >
                        <span>Sign In</span>
                        <ButtonLoader />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
