import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import Modal from 'react-bootstrap/Modal';
import Deposits from "../shared/Deposits";
import { useDispatch, useSelector } from "react-redux";
import { decryptPassword } from "../../utils/Encryption";
import {
    commonError,
    getCustomer,
    getCustomerAccountDetails,
    getExternalBankAccountListAction,
    getMarketPrice,
    resetGetExternalBankAccountListAction,
    resetgetExternalBankAccountListFailed,
} from "../../actions/customerActions";
import RecentMoneyTransfer from "../shared/recentMoneyTransfer";
import YourWalletBalance from "../shared/YourWalletBalance";
import Verifymodule from "../shared/verifymodule";
import Depositcrypto from "../shared/Depositcrypto";
import { useLocation, useNavigate } from "react-router-dom";
import RegenerateCustomerToken from "../shared/regenerateCustomerToken";
import DownloadWallet from "../shared/DownloadWallet";


function BuySell() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let tempToken = sessionStorage.getItem("guid");
    let deToken = decryptPassword(tempToken);
    const location = useLocation();
    const marKetPriceInterval = 30 ;//30 seconds
    let livePriceInterval;


    const getExternalBankAccountListData = useSelector((state) => state.customerReducer.getExternalBankAccountListData);
    const getExternalBankAccountListFailed = useSelector((state) => state.customerReducer.getExternalBankAccountListFailed);

    
    const [activeTab, setActiveTab] = useState('bank');
    const [Depositpop, setShow] = useState(false);
    const [SelectDeposit, setSDShow] = useState(false);
    const [DepositFT, setDFTShow] = useState(false);
    const [bankAccounts, setBankAccounts] = useState([]);


    // Fetch crypto currencies market price
    useEffect(() => {
        dispatch(getMarketPrice(sessionStorage.at));
        // Update the market price 30 seconds once
        livePriceInterval = setInterval(() => {
            dispatch(getMarketPrice(sessionStorage.at));
        }, marKetPriceInterval * 1000);
        dispatch(getCustomer(sessionStorage.at, deToken))
        dispatch(getExternalBankAccountListAction(sessionStorage.at, deToken))
        dispatch(getCustomerAccountDetails(sessionStorage.at, deToken))

        return () => {
            clearInterval(livePriceInterval);
        };
    }, [])

    // Fetch external bank account list data
    useEffect(() => {
        if (getExternalBankAccountListData && getExternalBankAccountListData.objects) {
            // List of bank accounts data 
            setBankAccounts(getExternalBankAccountListData.objects)
            dispatch(resetGetExternalBankAccountListAction())
        }
    }, [getExternalBankAccountListData]);

    // If external bank account list data failed
    useEffect(() => {
        if (Object.keys(getExternalBankAccountListFailed).length !== 0) {
            let errObj = getExternalBankAccountListFailed
            if (errObj && errObj.data && errObj.data.error_message) {
                dispatch(commonError(errObj.data.error_message))
                dispatch(resetgetExternalBankAccountListFailed())
                if (errObj.data.error_message == "Signature has expired") {
                    // console.log("FAILED",errObj.data);
                    navigate("/logout");
                }
                else if(errObj.data.message_code == "authentication_failed"){
                    // console.log("FAILED",errObj.data);
                    navigate("/logout");
                }
            }
        }
    }, [getExternalBankAccountListFailed])

    // Crypto tab change using url
    useEffect(() => {
        const hashValue = location.hash.substring(1); // Remove the '#' from the hash
        if (hashValue === 'crypto') {
            setActiveTab('crypto');
        }
    }, [location.hash]);


    const handleClose = () => setShow(false);
    const handleShow = () => {
        setSDShow(false);
        setShow(true);
    }

    const handleSDClose = () => setSDShow(false);
    const handleSDShow = () => {
        setSDShow(true);
        setShow(false);
        setDFTShow(false);
    }

    const handleDFTClose = () => setDFTShow(false);
    const handleDFTShow = () => {
        setDFTShow(true);
        setSDShow(false);
    }
    

    return (
        <>
    <RegenerateCustomerToken/>
            <Header2 />
            <Sidebar />

            <div className="content-body dash-main deposits">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card fadeInDown">
                                <div className="card-header">
                                    <h4 className="card-title">Deposit </h4>
                                </div>
                                <div className="card-body p-0">
                                    {/* <div className="buy-sell-widget">
                                        <Deposits bankAccount={ bankAccounts}/>
                                    </div> */}

                                    <div className="buy-sell-widget dashtabs">
                                        <Tabs
                                            activeKey={activeTab}
                                            id="uncontrolled-tab-example"
                                            className="m-0"
                                            onSelect={(tab) => setActiveTab(tab)}
                                        >
                                            <Tab eventKey="bank" title="bank">
                                                <Deposits bankAccount={ bankAccounts}/>
                                            </Tab>
                                            <Tab eventKey="crypto" title="crypto">
                                                <Depositcrypto />
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <DownloadWallet />
                        </div>            
                        <div className="col-md-5">
                            <YourWalletBalance/>
                        </div>
                        {/* { <div className="col-md-4"> 
                            <Verifymodule />
                         </div>} */}
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">
                                        Recent Deposits{" "}
                                    </h4>
                                </div>

                                <RecentMoneyTransfer typeis="deposit" />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal centered show={Depositpop} size="md" className="tce-popup fadeInDown">
                <div className="tce-ppcon ">
                    <div className="tce-ppcls" onClick={handleClose}><span>x</span></div>

                    <div className="getverified fadeInDown">
                        <Modal.Title>
                            Deposit
                        </Modal.Title>

                        <ul className="list-unstyled smplist assetslit">
                            <li className="border-none">
                                <form className="w-100">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search assets" />
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="basic-addon2"><i
                                                className="fa fa-search text-white"></i></span>
                                        </div>
                                    </div>
                                </form>
                            </li>

                            <li onClick={handleSDShow}>
                                <div className="spm-icon">
                                    <img
                                        src={require("../../images/bitcoin.png")}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="spm-content">
                                    <h4>BTC</h4>
                                    <p>Bitcoin</p>
                                </div>
                                <div className="spm-icon">0.00025</div>
                            </li>

                            <li>
                                <div className="spm-icon">
                                    <img
                                        src={require("../../images/ethereum.png")}
                                        alt=""
                                        className="img-fluid"
                                    />    
                                </div>
                                <div className="spm-content">
                                    <h4>ETH</h4>
                                    <p>Ethereum</p>
                                </div>
                                <div className="spm-icon">0.00105</div>
                            </li>

                            <li>
                                <div className="spm-icon">
                                    <img
                                        src={require("../../images/binance.png")}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="spm-content">
                                    <h4>BINC</h4>
                                    <p>Binance</p>
                                </div>
                                <div className="spm-icon">0.00025</div>
                            </li>

                            <li>
                                <div className="spm-icon">
                                    <img
                                        src={require("../../images/LTC.png")}
                                        alt=""
                                        className="img-fluid"
                                    />    
                                </div>
                                <div className="spm-content">
                                    <h4>LTC</h4>
                                    <p>Light coin</p>
                                </div>
                                <div className="spm-icon">0.00105</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal>


            <Modal centered show={SelectDeposit} size="md" className="tce-popup fadeInDown">
                <div className="tce-ppcon ">
                    <div className="tce-ppcls left" onClick={handleShow}>
                        <i className="las la-arrow-left"></i>
                    </div>
                    <div className="tce-ppcls" onClick={handleSDClose}>
                        <span>x</span>
                    </div>


                    <div className="getverified fadeInDown">
                        <Modal.Title>
                            Deposit BTC
                        </Modal.Title>

                        <ul className="list-unstyled smplist">
                            <li>
                                <div className="spm-icon">
                                    <img
                                        src={require("../../images/payment.png")}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="spm-content">
                                    <h4>Canada Bank Transfer</h4>
                                    <p className="small">Funds will typically arrive within 1 business day.</p>
                                </div>
                                <div className="spm-icon"><i className="fa fa-angle-right"></i></div>
                            </li>

                            <li onClick={handleDFTShow}>
                                <div className="spm-icon">
                                    <img
                                        src={require("../../images/logo-icon-c.png")}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="spm-content">
                                    <h4>The Crypto Exchange</h4>
                                    <p className="small">Transfer funds instantly from TheCryptoExchange.com to Crypto Exchange pro</p>
                                </div>
                                <div className="spm-icon">
                                    <i className="fa fa-angle-right"></i>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal>


            <Modal centered show={DepositFT} size="md" className="tce-popup fadeInDown">
                <div className="tce-ppcon ">
                    <div className="tce-ppcls left" onClick={handleSDShow}>
                        <i className="las la-arrow-left"></i>
                    </div>
                    <div className="tce-ppcls" onClick={handleDFTClose}>
                        <span>x</span>
                    </div>

                    <div className="getverified fadeInDown">
                        <Modal.Title>
                            Deposit BTC
                        </Modal.Title>

                        <Modal.Body className="py-0">
                            <div className="ppmethod">
                                <label className="m-0 p-2">From</label>
                                <ul className="list-unstyled smplist">
                                    <li>
                                        <div className="spm-icon">
                                            <img
                                                src={require("../../images/logo-icon-c.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="spm-content">

                                            <h4>The Crypto Exchange</h4>
                                            <p className="small">$109.2 USD</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            
                            <div className="ppmethod">
                                <label className="m-0 p-2">To</label>
                                <ul className="list-unstyled smplist">
                                    <li>
                                        <div className="spm-icon">
                                            <img
                                                src={require("../../images/logo-icon-d.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="spm-content">
                                            <h4>Default Portfolio</h4>
                                            <p className="small">$0.000 USD</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <label className="m-0 p-2">Amount</label>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    name="usd_amount"
                                    className="form-control text-end"
                                    value=""
                                    placeholder="0.00"
                                />
                                <div className="input-group-append">
                                    <label className="input-group-text">
                                        <i className="fa fa-bitcoin"></i>
                                    </label>
                                </div>
                            </div>

                            <div className="ppmethod">
                                <ul className="list-unstyled smplist">
                                    <li>
                                        <div className="spm-content">
                                            <h4 >Available to deposit </h4>
                                            <p className="small">Funds on hold</p>
                                        </div>
                                        <div className="spm-icon twoline">
                                            <h4 className="small">$109.22 USD </h4>
                                            <p className="small">0.00</p>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="spm-content">
                                            <h4>Fee</h4>
                                        </div>
                                        <div className="spm-icon twoline">
                                            <h4 className="small">$0.00 USD </h4>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="spm-content">
                                            <h4>Limit</h4>
                                        </div>
                                        <div className="spm-icon twoline">
                                            <h4 className="small">Unlimited</h4>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="spm-content">
                                            <h4>Processing time</h4>
                                        </div>
                                        <div className="spm-icon twoline">
                                            <h4 className="small">Instant</h4>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <button className="btn btn-primary w-100 mb-3">Deposit</button>
                        </Modal.Body>
                    </div>
                </div>
            </Modal>

            {/* <Modal centered show={DepositFT} size="md" className="tce-popup fadeInDown">
                <div className="tce-ppcon ">
                    <div className="tce-ppcls left" onClick={handleSDShow}><i className="las la-arrow-left"></i></div>

                    <div className="tce-ppcls" onClick={handleDFTClose}><span>x</span></div>

                    <div className="getverified fadeInDown">
                        <Modal.Title>
                            Deposit BTC
                        </Modal.Title>


                        <Modal.Body className="py-0">
                            <div className="ppmethod">
                                <label className="m-0 p-2">Asset</label>

                                <ul className="list-unstyled smplist">
                                    <li>
                                        <div className="spm-icon">
                                            <img
                                                src={require("../../images/bitcoin.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="spm-content">
                                            <h4>The Crypto Exchange</h4>
                                            <p className="small">0.000001 BTC available</p>
                                        </div>
                                        <div className="spm-icon"><i className="fa fa-angle-right"></i></div>
                                    </li>
                                </ul>
                            </div>
                            <div className="ppmethod">
                                <label className="m-0 p-2">Network</label>
                                <ul className="list-unstyled smplist">
                                    <li>
                                        <div className="spm-icon">
                                            <img
                                                src={require("../../images/ethereum.png")}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="spm-content">
                                            <h4>Ethereum</h4>                                           
                                        </div>
                                    </li>


                                </ul>
                            </div>

                            <div className="alert alert-warning">
                                Only deposit 1BTC from Ethereum network. Deposits of other assets or from other networks will be lost.
                            </div>

                            <div className="px-5">
                                <img
                                    src={require("../../images/QR-code.png")}
                                    alt=""
                                    className="img-fluid"
                                />
                            </div>


                            <div className="ppmethod">
                                <ul className="list-unstyled smplist">
                                    <li>
                                        <div className="spm-content">
                                            <p className="small">New wallet address</p>
                                            <h4>0x608b...fdf165</h4>

                                        </div>
                                        <div className="spm-icon">
                                            <button className="btn btn-light  btn-icon"><i className="las la-copy"></i></button>
                                            <button className="btn btn-light btn-icon ms-2"><i className="las la-ellipsis-v"></i></button>
                                        </div>
                                    </li>

                                </ul>
                            </div>

                            <div className="ppmethod">
                                        <ul className="list-unstyled smplist">
                                            <li >
                                                <div className="spm-content">                                                   
                                                    <h4> Minimum deposit</h4>
                                                </div>  
                                                <div className="spm-icon">5 BTC</div>                                                                                             
                                            </li>

                                            <li >
                                                <div className="spm-content">                                                   
                                                    <h4>Required network</h4>
                                                    <p>confirmations</p>
                                                </div>  
                                                <div className="spm-icon">3 confirmations</div>                                                                                             
                                            </li>

                                            <li >
                                                <div className="spm-content">                                                   
                                                    <h4>Processing time </h4>
                                                </div>  
                                                <div className="spm-icon">14 minutes</div>                                                                                             
                                            </li>
                                          

                                            </ul>
                                    </div>


                            <button className="btn btn-primary w-100 mb-3">Deposit</button>



                        </Modal.Body>
                    </div>


                </div>

            </Modal> */}
        </>
    );
}

export default BuySell;
