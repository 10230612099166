import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../element/page-title";
import { logoutUser } from "../../../actions/authAction";

function AdminSidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [show, setShow] = useState(false);


  // Logout function
  const handleLogout = () => {
    // Dispatch the logoutUser action
    dispatch(logoutUser());
    // Clear the token from local storage
    localStorage.removeItem("a_at");
    localStorage.removeItem("email");
    navigate("/signin");
  };


  return (
    <>
      <div className={show ? "sidebar show " : "sidebar"}>
        <button className="tag_btn" onClick={() => setShow(!show)}>
          <i className="fa fa-bars"></i>
          <i className="fa fa-times"></i>
        </button>
        <Link className="brand-logo" to={"/"}>
          <img src={require("../../../images/logo-with-text.png")} alt="" />
        </Link>
        <PageTitle />

        <div className="menu">
          <ul>
            <li>
              <NavLink
                to="/admin/customer"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span>
                  <i className="mdi mdi-account-multiple"></i>
                </span>
                <span className="nav-text">Customers</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/admin/trades"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span>
                  <i className="mdi mdi-swap-horizontal"></i>
                </span>
                <span className="nav-text">Trades</span>
              </NavLink>
            </li>
            
            <li>
              <NavLink
                to="/admin/customer-accounts"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span>
                  <i className="mdi mdi-format-list-bulleted"></i>
                </span>
                <span className="nav-text">Customer Accounts</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/admin/sales"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span>
                  <i className="mdi mdi-shopping"></i>
                </span>
                <span className="nav-text">Sales</span>
              </NavLink>
            </li>

            <li className="mobinav">
              <NavLink to="/admin/login" onClick={handleLogout}>
                <span>
                  <i className="la la-sign-out"></i>
                </span>
                <span className="nav-text">Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="sidebar-footer">
          <div className="social">
            <Link to="">
              <i className="fa-brands fa-youtube"></i>
            </Link>
            <Link to="">
              <i className="fa-brands fa-instagram"></i>
            </Link>
            <Link to="">
              <i className="fa-brands fa-x-twitter"></i>
            </Link>
            <Link to="">
              <i className="fa-brands fa-facebook-f"></i>
            </Link>
          </div>

          <div className="copy_right">
            © {new Date().getFullYear()}<br/> The Crypto Exchange
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminSidebar;
