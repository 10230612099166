import React from 'react';

function Svgverify() {

    return (
        <>
            <div className="spm-icon h-auto spm-cc">
                <svg className="checkmark animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle animateElement" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check animateElement" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
            </div>
        </>
    )
}

export default Svgverify;