import React from "react";
import Header3 from "../layout/header3";
import Footer1 from "../layout/footer1";


function NonCustodial() {
    return (
        <>
            <Header3 />
            <div className="explore sp-inners">
                <div className="banners">
                    <h2>Non Custodial​</h2>
                </div>

                <div className="main">
                    <div className="container">
                        <div className="owfsec" id="market">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <p>At The Crypto Exchange, we've embraced the non-custodial approach to empower you, our valued clients, with greater control and security over your cryptocurrency holdings. Here's a detailed breakdown of what being a non-custodial exchange means and how it benefits you:​​​</p>
                                </div>

                                <div className="col-md-4">
                                    <div className="card sshn">
                                        {/* <div className="sshnum">1</div> */}
                                        <div className="card-body ">
                                            <img src={require("./../../images/expo/expo3-1.png")} alt="" className="img-fluid" />
                                            <div className="section_subheading mb-0">

                                                <h4>Full Control Over Your Assets</h4>
                                                <p>
                                                As a non-custodial exchange, we never take custody of your funds. This means your cryptocurrencies remain in your personal wallets, not on our platform. You have complete ownership and control over your assets at all times.​
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card sshn">
                                        {/* <div className="sshnum">2</div> */}
                                        <div className="card-body ">
                                            <img src={require("./../../images/expo/expo3-2.png")} alt="" className="img-fluid" />
                                            <div className="section_subheading mb-0">
                                                <h4>Enhanced Security</h4>
                                                <p>
                                                With non-custodial services, your assets are significantly less susceptible to hacks or internal vulnerabilities often associated with custodial exchanges. Your funds are stored in your wallet, which you can secure with the level of protection you choose.​​
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card sshn">
                                        {/* <div className="sshnum">3</div> */}
                                        <div className="card-body ">
                                            <img src={require("./../../images/expo/expo3-3.png")} alt="" className="img-fluid" />
                                            <div className="section_subheading mb-0">
                                                <h4>Mitigated Counterparty Risk</h4>
                                                <p>
                                                Custodial exchanges expose you to counterparty risk; if the exchange becomes insolvent or faces legal issues, your funds may be at risk. With non-custodial services, this risk is largely eliminated, as your assets are off the exchange.​​
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className="col-md-4">
                                    <div className="card sshn">
                                        {/*      */}
                                        <div className="card-body ">
                                            <img src={require("./../../images/expo/expo3-4.png")} alt="" className="img-fluid" />
                                            <div className="section_subheading mb-0">
                                                <h4>Access to a Range of Cryptocurrencies</h4>
                                                <p>
                                                Our non-custodial model enables you to access a broader range of cryptocurrencies, including those not typically supported by custodial exchanges. This diversification potential enhances your investment strategy.​​
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="card sshn">
                                        {/*      */}
                                        <div className="card-body ">
                                            <img src={require("./../../images/expo/expo3-5.png")} alt="" className="img-fluid" />
                                            <div className="section_subheading mb-0">
                                                <h4>RTransparent and Trustworthy</h4>
                                                <p>
                                                Non-custodial exchanges prioritize transparency and trust. We provide you with the tools and information needed to validate transactions and monitor your holdings independently.​​
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                               
                                <div className="col-md-4">
                                    <div className="card sshn">
                                        {/*      */}
                                        <div className="card-body ">
                                            <img src={require("./../../images/expo/expo3-6.png")} alt="" className="img-fluid" />
                                            <div className="section_subheading mb-0">
                                                <h4>Educational Resources</h4>
                                                <p>
                                                We are committed to educating our clients on best practices for securing their non-custodial wallets and managing their cryptocurrency portfolios effectively. We empower you with knowledge to make informed decisions.​​​
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 mb-5">
                                    <p>In essence, being a non-custodial exchange is all about giving you the keys to your financial future. We're dedicated to providing you with a secure, private, and self-directed cryptocurrency trading and investment experience. Your empowerment and financial well-being are at the core of our mission at The Crypto Exchange.​​</p>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>

              
            </div>
            <Footer1 />
        </>
    );
}

export default NonCustodial;
