import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../actions/authAction';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateIs = useSelector((state) => state);

  useEffect(() => {
    // Perform logout and clear local storage here

      dispatch(logoutUser());
      sessionStorage.removeItem("at");
      sessionStorage.removeItem("ut");
      sessionStorage.removeItem("user_email");
      sessionStorage.removeItem("guid");
      localStorage.removeItem("f_at");
      localStorage.removeItem("displayName");
      // localStorage.removeItem("f_refreshToken");
  
      // After performing logout and clearing storage, navigate to "/signin"
      
      navigate("/signin");
  }, [dispatch, navigate]);

  // You can render some loading indicator or message if needed
  return <div>
    <></>
  </div>;
};

export default Logout;
