import React, { useState, useEffect, useRef } from "react";
import { 
  postWorkflowsAction, 
  resetPostWorkflowsAction, 
  getWorkFlow, 
  resetGetWorkFlow, 
  identityVerificationAction,
  pbResetGetSingleExternalBankAccountFailed,
  pbGetSingleExternalBankAccountAction,
  pbResetGetSingleExternalBankAccountSuccess,
  commonError,
  pbPostExternalBankAccountAction,
  pbResetPostExternalBankAccountActionSuccess,
  pbResetPostExternalBankAccountActionFailed,
} from '../../actions/customerActions';
import { useDispatch, useSelector } from "react-redux";
import { decryptPassword } from "../../utils/Encryption";
import {
    usePlaidLink
} from 'react-plaid-link';

const Plaidbank = ({recheckbanks, setBankUpdate, bankUpdate}) => {

    const dispatch = useDispatch();
    let tempToken = sessionStorage.getItem("guid");
    let deToken = decryptPassword(tempToken);

    const plaidDivRef = useRef(null);
    const postWorkflow = useSelector((state) => state.customerReducer.postWorkflowData);
    const getWorkflowData = useSelector((state) => state.customerReducer.getWorkflowData);
    const postExternalBankAccount = useSelector((state) => state.customerReducer.pbPostExternalBankAccountData);
    const postExternalBankAccountFailed = useSelector((state) => state.customerReducer.pbPostExternalBankAccountFailed);
    const pbGetSingleExternalBankAccount = useSelector((state) => state.customerReducer.pbGetSingleExternalBankAccountData)
    const pbGetSingleExternalBankAccountFailed = useSelector((state) => state.customerReducer.pbGetSingleExternalBankAccountFailed)
 
    const [plaidToken, setPlaidToken] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [pbNewExternalBankAccGuid, setPbNewExternalBankAccGuid] = useState('')
    const pbSingleExternalBankAccInterval = useRef()
  
    const startConnectBank = () => {
      console.log("startConnectBank triggered");
      const postwork = {
        "type": "plaid",
        "kind": "link_token_create",
        "language": "en",
        "customer_guid": deToken,
        "link_customization_name": "default"
      };
      setBankUpdate("loading");
      setIsActive(true);
      dispatch(postWorkflowsAction(sessionStorage.at, postwork));
    }
  
  useEffect(() => {
      if (Object.keys(postWorkflow).length !== 0) {
          dispatch(resetPostWorkflowsAction());
          dispatch(getWorkFlow(sessionStorage.at, postWorkflow.guid));
      }
  }, [postWorkflow]);
  
  useEffect(() => {
      if (Object.keys(getWorkflowData).length !== 0) {
        if(getWorkflowData.state == "storing"){
          setTimeout(() => {
            dispatch(getWorkFlow(sessionStorage.at, getWorkflowData.guid));
          }, 3000);
        }else {
          setPlaidToken(getWorkflowData.plaid_link_token);
        }
        dispatch(resetGetWorkFlow());
      }
  }, [getWorkflowData]);
  
  useEffect(() => {
      if (plaidToken) {
          setTimeout(() => {
              setBankUpdate("");
              setIsActive(false);
              plaidDivRef.current.click();
          }, 500);
      }
  }, [plaidToken]);
  
  const onLinkSuccess = (public_token, metadata) => {
      console.log("onLinkSuccess triggered");
      const bankaccount = {
          "name": "Plaid Checking",
          "account_kind": "plaid",
          "customer_guid": deToken,
          "asset": "USD",
          "plaid_public_token": public_token,
          "plaid_account_id": metadata.account.id,
          "plaid_institution_id": metadata.institution.institution_id,
          "plaid_account_mask": metadata.account.mask,
          "plaid_account_name": metadata.account.name
      };
      setBankUpdate("loading");
      dispatch(pbPostExternalBankAccountAction(sessionStorage.at, bankaccount));
  };
  
  useEffect(() => {
    if (Object.keys(postExternalBankAccount).length !== 0) {
      setPbNewExternalBankAccGuid(postExternalBankAccount.guid) 
      // Call getExternalBankList function
      recheckbanks('verifykyc');
      dispatch(pbResetPostExternalBankAccountActionSuccess());
    }
  }, [postExternalBankAccount]);

  useEffect(()=> {
    if(postExternalBankAccountFailed && Object.keys(postExternalBankAccountFailed)?.length !== 0) {
      let errObj = postExternalBankAccountFailed;
      if (errObj && errObj.data && errObj.data.error_message) {
        dispatch(commonError(errObj.data.error_message));
        dispatch(pbResetPostExternalBankAccountActionFailed());
      }
    }
  },[postExternalBankAccountFailed])

  useEffect(() => {
    pbSingleExternalBankAccInterval.current = setInterval(() => {
      if(pbNewExternalBankAccGuid !== '') {
          dispatch(pbGetSingleExternalBankAccountAction(sessionStorage.at, pbNewExternalBankAccGuid))
      }
    }, 5000)
    return () => clearInterval(pbSingleExternalBankAccInterval.current)
  }, [pbNewExternalBankAccGuid])
  
  useEffect(() => {
    if (pbGetSingleExternalBankAccount && Object.keys(pbGetSingleExternalBankAccount).length !== 0 && pbGetSingleExternalBankAccount.state === 'unverified') {
        clearInterval(pbSingleExternalBankAccInterval.current)
        const bankIdVerification = {
            type: 'bank_account',
            method: 'account_ownership',
            customer_guid: pbGetSingleExternalBankAccount.customer_guid,
            external_bank_account_guid: pbGetSingleExternalBankAccount.guid,
        };
        // Creating a bank account identity verification
        dispatch(identityVerificationAction(sessionStorage.at, bankIdVerification));
        dispatch(pbResetGetSingleExternalBankAccountSuccess());
    }
  }, [pbGetSingleExternalBankAccount]);

  useEffect(() => {
    if (pbGetSingleExternalBankAccountFailed && Object.keys(pbGetSingleExternalBankAccountFailed).length !== 0) {
      let errObj = pbGetSingleExternalBankAccountFailed
      if (errObj && errObj.data && errObj.data.error_message) {
        dispatch(commonError(errObj.data.error_message))
        dispatch(pbResetGetSingleExternalBankAccountFailed())
      }
    }
  }, [pbGetSingleExternalBankAccountFailed]);
  
  const config = {
      onSuccess: onLinkSuccess,
      onExit: (err, metadata) => {
          console.log("onExit triggered");
          console.log("Error: ", err);
          setBankUpdate('');
      },
      onEvent: (eventName, metadata) => {
          console.log("onEvent triggered");
          console.log("Event Name: ", eventName);
          console.log("Metadata: ", metadata);
      },
      token: plaidToken
  };
  
  const { open, exit, ready } = usePlaidLink(config);
  
  const openplaid = () => {
      console.log("openplaid triggered");
      open();
  }
  

  return (
    <>
      <button type="button" name="submit" className="btn btn-outline-primary btn-cussm" disabled={bankUpdate === "completed" || bankUpdate === "failed" || bankUpdate === "loading" || isActive} onClick={() => startConnectBank()} >Connect</button>
      <div ref={plaidDivRef} name="submit" className="d-none" onClick={openplaid} >plaid</div>
    </>
  );
}

export default Plaidbank;