import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { commonError, commonSuccess } from '../../actions/customerActions';
import { Link } from 'react-router-dom';
import ButtonLoader from '../layout/buttonLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { auth } from '../../firebase-config';
import { confirmPasswordReset } from 'firebase/auth';
import apiSignUp from "../../services/apiSignUp";

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const oobCode = query.get('oobCode');

  const passwordChange = (value) => {
    setPassword(value);
  };

  const confirmPasswordChange = (value) => {
    setConfirmPassword(value);
  };

  const submitHandler = async (password, confirmPassword) => {
    if (isBtnLoading) {
      return;
    }

    if (password.trim()?.length === 0 && confirmPassword.trim()?.length === 0) {
      dispatch(commonError('Password and Confirm new password are required.'));
    } else if (password.trim()?.length === 0) {
      dispatch(commonError('Password is required.'));
    } else if (confirmPassword.trim()?.length === 0) {
      dispatch(commonError('Confirm new password is required.'));
    } else if (
      password.trim()?.length < 8 ||
      confirmPassword.trim()?.length < 8
    ) {
      dispatch(commonError('Password should be at least 8 characters.'));
    } else if (!(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(password))) {
      dispatch(commonError('Password must include at least one capital letter, one number, and one special character'));
      return;
    } else if (password !== confirmPassword) {
      dispatch(commonError('The password and confirm password fields should be identical.'));
    } else {
      setIsBtnLoading(true);
      setPassword('');
      setConfirmPassword('');
      try {
        const res = await apiSignUp.post("/customers/check-reset-password-tokens-validity", { oobCode: oobCode,update: false});
        if(res.data.data.validity){
          await confirmPasswordReset(auth(), oobCode, password);
          await apiSignUp.post("/customers/check-reset-password-tokens-validity", { oobCode: oobCode, update: true});
          dispatch(commonSuccess('Password changed successfully'));
          navigate('/signin');
          setIsBtnLoading(false);
        } else{
          setIsBtnLoading(false);
          return dispatch(commonError('Link has been expired'));
        }
      } catch (error) {
        setIsBtnLoading(false);
        if (error.code === 'auth/invalid-action-code') {
          return dispatch(commonError('Link has been expired'));
        }
        dispatch(commonError('Try again later'));
      }
    }
  };

  return (
    <>
      <div className="authincation section-padding1 fslogin">
        <div className="art-wrapper">
          <img src={require('./../../images/auth-bg.png')} alt="" />
        </div>
        <div className="fscon-wrapper">
          <div className="container-fluid h-100">
            <div className="mini-logo text-center mb-4">
              <Link to={'/'}>
                <img
                  src={require('./../../images/logo-with-text.png')}
                  alt=""
                />
              </Link>
            </div>
            <div className="auth-form card fslogincard mb-0">
              <div className="card-header justify-content-center">
                <h4 className="card-title">Reset password</h4>
              </div>
              <div className="card-body">
                <form action="">
                  <div className="mb-3">
                    <label>Password</label>
                    <div className="input-group">
                      <input
                        type={viewPassword ? 'text' : 'password'}
                        className="form-control"
                        placeholder="********"
                        value={password}
                        onChange={(e) => passwordChange(e.target.value)}
                      />
                      <div
                        className="input-group-append"
                        onClick={() => setViewPassword(!viewPassword)}
                      >
                        <span className="input-group-text bg-transparent cursor-pointer">
                          {viewPassword ? (
                            <FontAwesomeIcon icon="eye" />
                          ) : (
                            <FontAwesomeIcon icon="eye-slash" />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label>Confirm Password</label>
                    <div className="input-group">
                      <input
                        type={viewConfirmPassword ? 'text' : 'password'}
                        className="form-control"
                        placeholder="********"
                        value={confirmPassword}
                        onChange={(e) => confirmPasswordChange(e.target.value)}
                      />
                      <div
                        className="input-group-append"
                        onClick={() =>
                          setViewConfirmPassword(!viewConfirmPassword)
                        }
                      >
                        <span className="input-group-text bg-transparent cursor-pointer">
                          {viewConfirmPassword ? (
                            <FontAwesomeIcon icon="eye" />
                          ) : (
                            <FontAwesomeIcon icon="eye-slash" />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="text-center  mt-5">
                    <button
                      type="button"
                      className={`btn btn-primary w-30 btn-loader ${
                        isBtnLoading ? 'active' : ''
                      }`}
                      onClick={() => {
                        submitHandler(password, confirmPassword);
                      }}
                    >
                      <span>Submit</span>
                      {isBtnLoading && <ButtonLoader />}
                    </button>
                  </div>
                </form>
                <div className="new-account mt-5 text-center">
                  <p>
                    Already have an account?{' '}
                    <Link className="text-primary" to={'/signin'}>
                      Sign in
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
