import React, { useState, useEffect } from "react";
import { Tab, Table, Tabs, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  getAdminAccounts,
  getAdminExternalBankAccounts,
  getAdminExternalWallets,
  getAdminQuotes,
  getAdminSingleCustomer,
  getAdminTrades,
  getAdminTransfers,
  resetGetAccountsFailed,
  resetGetAccountsSuccess,
  resetGetExternalBankAccountFailure,
  resetGetExternalBankAccountSuccess,
  resetGetExternalWalletsFailure,
  resetGetExternalWalletsSuccess,
  resetGetSingleCustomerDataFailure,
  resetGetSingleCustomerDataSuccess,
  resetGetTradesFailed,
  resetGetTradesSuccess,
  resetGetTransfersFailed,
  resetGetTransfersSuccess,
  resetGetSingleCustomerDBDataSuccess,
  resetGetSingleCustomerDBDataFailure,
  getAdminSingleCustomerFromDB,
  getAdminIdentityVerfications,
  resetGetIdentityVerificationsSuccess,
  resetGetIdentityVerificationsFailed
} from "../../../actions/adminActions";
import {
  findCadDecimal,
  findNameForCrypto,
  formatDate,
  formatDateWithSeconds,
  renderAddress
} from "../../../utils/Encryption";
import { commonSuccess } from "../../../actions/customerActions";
import { commonError } from "../../../actions/customerActions";
import Gifloader from "../../layout/gifloader";
import AdminSidebar from "./AdminSidebar";
import AdminHeader from "./AdminHeader";

const CustomerDetails = () => {
  const { guid } = useParams();
  const dispatch = useDispatch();
  let jwtToken = sessionStorage.getItem("a_at");


  // const [customersList, setCustomersList] = useState([]);
  const [totalQuotesPages, setTotalQuotesPages] = useState(0);
  const [quotePageNum, setQuotePageNum] = useState(0);
  const [tradePageNum, setTradePageNum] = useState(0);


  const getSC = useSelector((state) => state.adminReducer.getCustomerData);
  const getSCF = useSelector((state) => state.adminReducer.getCustomerDataFailed);
  const getEBA = useSelector((state) => state.adminReducer.getExternalBankAccounts);
  const getEBAF = useSelector((state) => state.adminReducer.getExternalBankAccountsFailed);
  const getEBW = useSelector((state) => state.adminReducer.getExternalWallets);
  const getEBWF = useSelector((state) => state.adminReducer.getExternalWalletsFailed);
  const getA = useSelector((state) => state.adminReducer.getAccounts);
  const getAF = useSelector((state) => state.adminReducer.getAccountsFailed);
  const getQ = useSelector((state) => state.adminReducer.getQuotes);
  const getQF = useSelector((state) => state.adminReducer.getQuotesFailed);
  const getTDS = useSelector((state) => state.adminReducer.getTrades);
  const getTDF = useSelector((state) => state.adminReducer.getTradesFailed);
  const getTF = useSelector((state) => state.adminReducer.getTransfers);
  const getTFF = useSelector((state) => state.adminReducer.getTransfersFailed);
  const getSCAS = useSelector((state) => state.adminReducer.getSingleCustomerData);
  const getSCAF = useSelector((state) => state.adminReducer.getSingleCustomerDataFailed);
  const getCIV = useSelector((state) => state.adminReducer.getIdentityVerifications);
  const getCIVF = useSelector((state) => state.adminReducer.getIdentityVerificationsFailed);


  const [singleCustomerList, setSingleCustomer] = useState([]);
  const [externalBankAccountsList, setExternalBankAccountsList] = useState([]);
  const [externalWalletsList, setExternalWalletsList] = useState([]);
  const [accountsList, setAccountsList] = useState([]);
  const [quotesList, setQuotesList] = useState([]);
  const [tradesList, setTradesList] = useState([]);
  const [transfersList, setTransfersList] = useState([]);
  const [activeKeyis, setActiveKeyis] = useState("Account");
  const [isAccountTabLoading, setIsAccountTabLoading] = useState(true);
  const [isExternalBanksTabLoading, setIsExternalBanksTabLoading] = useState(false);
  const [isExternalWalletsTabLoading, setIsExternalWalletsTabLoading] = useState(false);
  const [isQuotesTabLoading, setIsQuotesTabLoading] = useState(false);
  const [isTradesTabLoading, setIsTradesTabLoading] = useState(false);
  const [isTransfersTabLoading, setIsTransfersTabLoading] = useState(false);
  const [singleCusDataFromDB, setSingleCusDataFromDB] = useState({});
  const [customerIdentityVerificationList, setCustomerIdentityVerificationList] = useState([]);
  const [isIdentityVerificationTabLoading, setIsIdentityVerificationTabLoading] = useState(false);


  // Pagination Calculations
  const tradesItemsPerPage = 20;
  const tradePageCount = Math.ceil(tradesList.length / tradesItemsPerPage);
  const endOffset = tradePageNum + tradesItemsPerPage;
  const currentTradeItems = tradesList.slice(tradePageNum, endOffset);
  const quotesItemsPerPage = 20;
  const navigate = useNavigate();


  // Logout function
  const handleLogout = () => {
    localStorage.removeItem("a_at");
    localStorage.removeItem("email");
    navigate("/admin/signin")
  }

  // Define a function called 'copyaddress' that takes a 'guid' parameter.
  const copyaddress = (guid) => {
    const textArea = document.createElement('textarea'); // Create a new <textarea> element in the DOM.
    textArea.value = guid; // Set the value of the <textarea> to the provided 'guid'.
    document.body.appendChild(textArea); // Append the <textarea> element to the document's body.
    textArea.select(); // Select the content within the <textarea>.
    document.execCommand('copy'); // Execute the 'copy' command to copy the selected text to the clipboard.
    document.body.removeChild(textArea); // Remove the temporary <textarea> element from the document's body.
    dispatch(commonSuccess('Text copied to clipboard'));
  }


  useEffect(() => {
    dispatch(getAdminQuotes(jwtToken, quotePageNum, quotesItemsPerPage, guid));
  }, [quotePageNum]);

  useEffect(() => {
    dispatch(getAdminTrades(jwtToken, guid));
  }, []);

  useEffect(() => {
    dispatch(getAdminSingleCustomer(jwtToken, guid));
    dispatch(getAdminAccounts(jwtToken, guid));
    dispatch(getAdminSingleCustomerFromDB(jwtToken, guid));
    dispatch(getAdminIdentityVerfications(jwtToken, guid));
  }, []);

  // Fetch single customer data from DB
  useEffect(() => {
    if (getSCAS && Object.keys(getSCAS).length !== 0) {
      setSingleCusDataFromDB(getSCAS?.data);
      dispatch(resetGetSingleCustomerDBDataSuccess());
    }
  }, [getSCAS]);

  // If single customer data failed from DB
  useEffect(() => {
    if (getSCAF && Object.keys(getSCAF).length !== 0) {
      setSingleCusDataFromDB({});
      dispatch(resetGetSingleCustomerDBDataFailure());
    }
  }, [getSCAF]);

  // Fetch customer data
  useEffect(() => {
    if (Object.keys(getSC).length !== 0) {
      setSingleCustomer(getSC.data);
      dispatch(resetGetSingleCustomerDataSuccess());
    }
  }, [getSC]);

  // If customer data failed
  useEffect(() => {
    if (Object.keys(getSCF).length !== 0) {
      let errObj = getSCF;
      if (errObj) {
        if (errObj?.statusCode === 401) {
          handleLogout();
        }
        if (errObj && errObj.data && errObj?.data?.message) {
          dispatch(commonError(errObj?.data?.message))
        }
        dispatch(commonError(errObj?.data?.message));
        dispatch(resetGetSingleCustomerDataFailure());
      }
    }
  }, [getSCF]);

  // Fetch external bank accounts data
  useEffect(() => {
    if (Object.keys(getEBA).length !== 0) {
      dispatch(resetGetExternalBankAccountSuccess());
      setExternalBankAccountsList(getEBA.data.objects);
      setIsExternalBanksTabLoading(false);
    }
  }, [getEBA]);

  // If external bank accounts data failed
  useEffect(() => {
    if (Object.keys(getEBAF).length !== 0) {
      let errObj = getEBAF;
      if (errObj) {
        if (errObj?.statusCode === 401) {
          handleLogout();
        }
        if (errObj && errObj.data && errObj?.data?.message) {
          dispatch(commonError(errObj?.data?.message))
        }
        dispatch(commonError(errObj?.data?.message));
        dispatch(resetGetExternalBankAccountFailure());
        setIsExternalBanksTabLoading(false);
      }
    }
  }, [getEBAF]);

  // Fetch external wallets data
  useEffect(() => {
    if (Object.keys(getEBW).length !== 0) {
      dispatch(resetGetExternalWalletsSuccess());
      setExternalWalletsList(getEBW.data);
      setIsExternalWalletsTabLoading(false);
    }
  }, [getEBW]);

  // If external wallets data failed
  useEffect(() => {
    if (Object.keys(getEBWF).length !== 0) {
      let errObj = getEBWF;
      if (errObj) {
        if (errObj?.statusCode === 401) {
          handleLogout();
        }
        if (errObj && errObj.data && errObj?.data?.message) {
          dispatch(commonError(errObj?.data?.message))
        }
        dispatch(commonError(errObj?.data?.message));
        dispatch(resetGetExternalWalletsFailure());
        setIsExternalWalletsTabLoading(false);
      }
    }
  }, [getEBWF]);

  // Fetch customer accounts
  useEffect(() => {
    if (Object.keys(getA).length !== 0) {
      dispatch(resetGetAccountsSuccess());
      getA && setAccountsList(getA.data);
      setIsAccountTabLoading(false);
    }
  }, [getA]);

  // If customer accounts failed
  useEffect(() => {
    if (Object.keys(getAF).length !== 0) {
      let errObj = getAF;
      if (errObj) {
        if (errObj?.statusCode === 401) {
          handleLogout();
        }
        if (errObj && errObj.data && errObj?.data?.message) {
          dispatch(commonError(errObj?.data?.message))
        }
        dispatch(commonError(errObj?.data?.message));
        dispatch(resetGetAccountsFailed());
        setIsAccountTabLoading(false);
      }
    }
  }, [getAF]);

  // Fetch quotes list
  useEffect(() => {
    if (Object.keys(getQ).length !== 0) {
      const pageCount = Math.ceil(getQ.data.total / quotesItemsPerPage);
      setTotalQuotesPages(pageCount)
      dispatch(resetGetSingleCustomerDataSuccess());
      const quotesData = getQ.data.objects?.map((quote)=> {
        return {
          ...quote,
          product_type:quote?.product_type === "crypto_transfer" ? "Crypto" : quote?.product_type,
        }
      });
      setQuotesList(quotesData)
      setIsQuotesTabLoading(false);
    }
  }, [getQ]);

  // If quotes list failed
  useEffect(() => {
    if (Object.keys(getQF).length !== 0) {
      let errObj = getQF;
      if (errObj) {
        if (errObj?.statusCode === 401) {
          handleLogout();
        }
        if (errObj && errObj.data && errObj?.data?.message) {
          dispatch(commonError(errObj?.data?.message))
        }
        dispatch(commonError(errObj?.data?.message));
        dispatch(resetGetSingleCustomerDataFailure());
        setIsQuotesTabLoading(false);
      }
    }
  }, [getQF]);

  // Fetch trades list data
  useEffect(() => {
    if (Object.keys(getTDS).length !== 0) {
      dispatch(resetGetTradesSuccess());
      setTradesList(getTDS.data.data);
      setIsTradesTabLoading(false);
    }
  }, [getTDS]);

  // If trades list data failed
  useEffect(() => {
    if (Object.keys(getTDF).length !== 0) {
      let errObj = getTDF;
      if (errObj) {
        if (errObj?.statusCode === 401) {
          handleLogout();
        }
        if (errObj && errObj.data && errObj?.data?.message) {
          dispatch(commonError(errObj?.data?.message))
        }
        dispatch(commonError(errObj?.data?.message));
        dispatch(resetGetTradesFailed());
        setIsTradesTabLoading(false);
      }
    }
  }, [getTDF]);

  // Fetch transfers data
  useEffect(() => {
    if (Object.keys(getTF).length !== 0) {
      dispatch(resetGetTransfersSuccess());
      setTransfersList(getTF.data.objects);
      setIsTransfersTabLoading(false);
    }
  }, [getTF]);

  // If transfers data failed
  useEffect(() => {
    if (Object.keys(getTFF).length !== 0) {
      let errObj = getTFF;
      if (errObj) {
        if (errObj?.statusCode === 401) {
          handleLogout();
        }
        if (errObj && errObj.data && errObj?.data?.message) {
          dispatch(commonError(errObj?.data?.message))
        }
        dispatch(commonError(errObj?.data?.message));
        dispatch(resetGetTransfersFailed());
        setIsTransfersTabLoading(false);
      }
    }
  }, [getTFF]);

  // Fetch Customer Identity Verification Information
  useEffect(() => {
    if (getCIV && Object.keys(getCIV).length !== 0) {
      dispatch(resetGetIdentityVerificationsSuccess());
      setCustomerIdentityVerificationList(getCIV.data.objects);
      setIsIdentityVerificationTabLoading(false);
    }
  }, [getCIV]);

    // If identity verifications list failed
    useEffect(() => {
      if (getCIVF && Object.keys(getCIVF).length !== 0) {
        let errObj = getCIVF;
        if (errObj) {
          if (errObj?.statusCode === 401) {
            handleLogout();
          }
          if (errObj && errObj.data && errObj?.data?.message) {
            dispatch(commonError(errObj?.data?.message))
          }
          dispatch(commonError(errObj?.data?.message));
          dispatch(resetGetIdentityVerificationsFailed());
          setIsIdentityVerificationTabLoading(false);
        }
      }
    }, [getCIVF]);
  
  // Pagination for quote page
  const handleQuotePageClick = (event) => {
    setQuotesList([]);
    setIsQuotesTabLoading(true)
    setQuotePageNum(event.selected);
  };

  // Pagination for trade page
  const handleTradePageClick = (event) => {
    const newOffset = (event.selected * tradesItemsPerPage) % tradesList.length;
    setTradePageNum(newOffset);
  };

  const handleTabChange = (newKey) => {
    setActiveKeyis(newKey);
    // Dispatch actions based on the selected tab
    switch (newKey) {
      case "Account":
        dispatch(getAdminAccounts(jwtToken, guid));
        setIsAccountTabLoading(true);
        break;
      case "ExternalBanks":
        dispatch(getAdminExternalBankAccounts(jwtToken, guid));
        setIsExternalBanksTabLoading(true);
        break;
      case "ExternalWallets":
        dispatch(getAdminExternalWallets(jwtToken, guid));
        setIsExternalWalletsTabLoading(true);
        break;
      case "Quotes":
        dispatch(getAdminQuotes(jwtToken, quotePageNum, quotesItemsPerPage, guid));
        setIsQuotesTabLoading(true);
        break;
      case "Trades":
        dispatch(getAdminTrades(jwtToken, guid));
        setIsTradesTabLoading(true);
        break;
      case "Transfers":
        dispatch(getAdminTransfers(jwtToken, guid));
        setIsTransfersTabLoading(true);
        break;
      case "IdentityVerifications":
        dispatch(getAdminIdentityVerfications(jwtToken, guid));
        setIsTransfersTabLoading(true);
        break; 
      default:
    }
  };

  const setExternalBanksClassName = (state) => {
    if (state === "completed") {
      return "mw-badge badge badge-success text-capitalize"
    } else if (state === "deleted" || state === "failed" || state === "unverified") {
      return "mw-badge badge badge-danger1 text-capitalize"
    } else {
      return "mw-badge badge badge-warning text-capitalize w-100"
    }
  }

  const setExternalWalletClassName = (state) => {
    if (state === "completed") {
      return "mw-badge badge badge-success text-capitalize"
    } else if (state === "deleted" || state === "failed") {
      return "mw-badge badge badge-danger1 text-capitalize"
    } else {
      return "mw-badge badge badge-warning text-capitalize"
    }
  }

  const setTradesClassName = (state) => {
    if (state === "completed") {
      return "mw-badge badge badge-success"
    } else if (state === "cancelled" || state === "failed") {
      return "mw-badge badge badge-danger1"
    } else {
      return "mw-badge badge badge-warning"
    }
  }

  const setTransfersClassName = (state) => {
    if (state === "completed") {
      return "mw-badge badge badge-success"
    } else if (state === "failed") {
      return "mw-badge badge badge-danger1"
    } else {
      return "mw-badge badge badge-warning"
    }
  }


  return (
    <div className="content-body dash-main ">
      <div className="container-fluid">
        <AdminHeader />
        <AdminSidebar />


        <div className="card mt-5">
          {
            singleCustomerList && Object.keys(singleCustomerList).length !== 0 ? (
              <div className="card-body">
                <div className="custicon">
                  {singleCustomerList?.state === "verified" ?
                    <span className="tick">
                      <i className="mdi mdi-check"></i>
                    </span> : <span className="cross">
                      <i className="mdi mdi-close"></i>
                    </span>}

                  <span className="cusdnamefl">
                    {singleCusDataFromDB?.firstName && `${singleCusDataFromDB?.firstName.charAt(0)}`}
                  </span>
                </div>
                <div className="row text-center" style={{ margin: "0px" }}>
                  <div className="col-12 mb-3 mb-sm-5">
                    <h3 className="fw-bold text-capitalize">{singleCusDataFromDB?.firstName && `${singleCusDataFromDB?.firstName} ${singleCusDataFromDB?.lastName}`}</h3>
                  </div>
                </div>
                <div className="cutdetalist">
                  <div className="row text-center">
                    <div className="col-12 col-sm-6 pb-3 pb-md-0  col-md-3">
                      <p>GUID</p>
                      <h5 className="fw-bold">{singleCustomerList?.guid}</h5>
                    </div>

                    <div className="col-12 col-sm-6 pb-3 pb-md-0  col-md-3">
                      <p>Phone Number</p>
                      <h5 className="fw-bold">{singleCusDataFromDB?.mobileNumber}</h5>
                    </div>

                    <div className="col-12 col-sm-6 pb-3 pb-md-0  col-md-3">
                      <p>Email</p>
                      <h5 className="fw-bold">{singleCustomerList?.email_address}</h5>
                    </div>

                    <div className="col-12 col-sm-6 pb-3 pb-md-0  col-md-3">
                      <p>State</p>
                      <h5 className="fw-bold flcap text-primary">{singleCustomerList?.state}</h5>
                    </div>

                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="text-center mt-5">
                  <Gifloader />
                </div>
              </div>
            )
          }
        </div>



        <div className="card">

          <div className="card-body">
            <div className="transaction-widget mt-3">
              <>
                <Tabs
                  defaultActiveKey="Account"
                  id="uncontrolled-tab-example"
                  className="m-0"
                  onSelect={handleTabChange}
                >
                  <Tab eventKey="Account" title="Account">
                    {activeKeyis === "Account" ? (
                      isAccountTabLoading ? (
                        <div className="text-center my-5 py-5">
                          <Gifloader />
                        </div>
                      ) : (
                        <div className="card-body py-0">
                          <div className="transaction-table">
                            <div className="table-responsive">
                              <Table hover responsive>
                                <thead>
                                  <tr>
                                    <th className="">Asset</th>
                                    <th className="">Account GUID</th>
                                    <th className="text-center">Type</th>
                                    <th className="text-end">Platform Balance</th>
                                    <th className="text-end">Platform Available</th>
                                    <th className="text-center">State</th>
                                    <th className="text-end">Created</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {accountsList && accountsList.length !== 0 ? (
                                    accountsList.map((item) => (
                                      <tr key={item.guid}>
                                        <td style={{ whiteSpace: 'nowrap' }}>{findNameForCrypto(item.asset)}</td>
                                        <td style={{ whiteSpace: 'nowrap' }}>{(item.guid)}</td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-center text-capitalize">
                                          <span className={`${item.type === 'trading' ? 'mw-badge badge badge-outline-primary' : 'mw-badge badge badge-outline-dark'}`}>{item.type}</span>
                                        </td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-end"><span className="text-primary fw-bold">{findCadDecimal(item.platform_balance, item.asset)}</span> {item.asset}</td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-end"><span className="text-primary fw-bold">{findCadDecimal(item.platform_available, item.asset)}</span> {item.asset}</td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-center"><span className={`${item.state === 'created' ? 'mw-badge badge badge-success' : 'mw-badge badge badge-warning'}`}>
                                          {item.state}
                                        </span></td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-end">{formatDate(item.created_at)}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={10} className="text-center">
                                        <p className="fw-bold text-capitalize">No accounts found.</p>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      )
                    ) : null}
                  </Tab>

                  <Tab eventKey="ExternalBanks" title="External Banks">
                    {activeKeyis === "ExternalBanks" ? (
                      isExternalBanksTabLoading ? (
                        <div className="text-center my-5 py-5">
                          <Gifloader />
                        </div>
                      ) : (
                        <div className="card-body py-0">
                          <div className="transaction-table">
                            <div className="table-responsive">
                              <Table hover responsive className="">
                                <thead>
                                  <tr>
                                    <th className="">Asset</th>
                                    <th className="">GUID</th>
                                    <th className="">Name</th>
                                    <th className="text-end">Number</th>
                                    <th className="text-center">Type</th>
                                    <th className="text-center">State</th>
                                    <th style={{ whiteSpace: 'nowrap' }} className="text-center">Failure Code</th>
                                    <th className="text-end">Created</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {externalBankAccountsList && externalBankAccountsList.length !== 0 ? (
                                    externalBankAccountsList.map((item) => (
                                      <tr key={item.guid}>
                                        <td style={{ whiteSpace: 'nowrap' }}>{findNameForCrypto(item.asset)}</td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="d-flex flex-row gap-2">
                                          {renderAddress(item.guid)}
                                          <div className="text-end"> <OverlayTrigger overlay={
                                            <Tooltip>Copy</Tooltip>
                                          }>
                                            <span className="text-primary" type="button" id="button-addon2" onClick={() => copyaddress(item.guid)}><i className="fa fa-copy"></i></span>
                                          </OverlayTrigger>
                                          </div>
                                        </td>
                                        <td style={{ whiteSpace: 'nowrap' }}>{item.name}</td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-end text-capitalize">{item.plaid_account_mask}</td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-center text-capitalize">
                                          <span className={`${item.account_kind === 'plaid' ? 'mw-badge badge badge-outline-primary' : 'mw-badge badge badge-outline-dark'}`}>{item.account_kind}</span>
                                        </td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-center"><span className={setExternalBanksClassName(item.state)}>
                                          {item.state}
                                        </span></td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-center">
                                          {item.failure_code === null ? '-' : <span className="mw-badge badge badge-danger1 w-100">{item.failure_code}</span>}
                                        </td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-end">{formatDate(item.created_at)}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={13} className="text-center">
                                        <p className="fw-bold">No external bank accounts found.</p>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      )
                    ) : null}
                  </Tab>

                  <Tab eventKey="ExternalWallets" title="External Wallets">
                    {activeKeyis === "ExternalWallets" ? (
                      isExternalWalletsTabLoading ? (
                        <div className="text-center my-5 py-5">
                          <Gifloader />
                        </div>
                      ) : (
                        <div className="card-body py-0">
                          <div className="transaction-table">
                            <div className="table-responsive">
                              <Table hover responsive className="">
                                <thead>
                                  <tr>
                                    <th className="">Asset</th>
                                    <th className="">GUID</th>
                                    <th className="">Name</th>
                                    <th className="">Wallet Address</th>
                                    <th className="text-center">State</th>
                                    <th className="text-end">Created</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {externalWalletsList && externalWalletsList.length !== 0 ? (
                                    externalWalletsList.map((item) => (
                                      <tr key={item.guid}>
                                        <td style={{ whiteSpace: 'nowrap' }}>{findNameForCrypto(item.asset)}</td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="d-flex flex-row gap-2">
                                          {renderAddress(item.guid)}
                                          <div className="text-end"> <OverlayTrigger overlay={
                                            <Tooltip>
                                              Copy
                                            </Tooltip>
                                          }>
                                            <span className="text-primary" type="button" id="button-addon2" onClick={() => copyaddress(item.guid)}><i className="fa fa-copy"></i></span>
                                          </OverlayTrigger>
                                          </div>
                                        </td>
                                        <td style={{ whiteSpace: 'nowrap' }}>{item.name}</td>
                                        <td style={{ whiteSpace: 'nowrap' }}>{item.address}</td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-center">
                                          <span className={setExternalWalletClassName(item.state)}>
                                            {item.state}
                                          </span></td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-end">{formatDate(item.created_at)}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={9} className="text-center">
                                        <p className="fw-bold text-capitalize">No external wallets found.</p>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      )
                    ) : null}
                  </Tab>

                  <Tab eventKey="Quotes" title="Quotes">
                    {activeKeyis === "Quotes" ? (
                      <div className="card-body py-0">
                        <div className="transaction-table">
                          <div className="table-responsive">
                            {!isQuotesTabLoading ?
                              <Table hover responsive className="">
                                <thead>
                                  <tr>
                                    <th className="">Symbol</th>
                                    <th className="">GUID</th>
                                    <th className="text-center">Buy/Sell</th>
                                    <th className="text-center">Type</th>
                                    <th className="text-end">Deliver Amount</th>
                                    <th className="text-end">Receive Amount</th>
                                    <th className="text-end">Fee</th>
                                    <th className="text-end">Issued</th>
                                    <th className="text-end">Expires</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {quotesList && quotesList.length !== 0 ? (
                                    quotesList.map((item) => {
                                      let tempSymbol = item.asset ? item.asset : item.symbol
                                      let recAmt;
                                      let delAmt;
                                      let crypAmtFee ; 
                                      if (item.product_type == "trading") {
                                        let divider;
                                        let sign;
                                        if (item.symbol === "BTC-USD") {
                                          sign = "BTC";
                                          divider = 100000000; // 8 decimal places
                                        } else if (item.symbol === "ETH-USD") {
                                          sign = "ETH";
                                          divider = 1000000000000000000; // 18 decimal places
                                        } else if (item.symbol === "USDC-USD") {
                                          sign = "USDC";
                                          divider = 1000000; // 8 decimal places
                                        }
                                        // else if (item.symbol === "BCH-USD") {
                                        //   sign = "BCH";
                                        //   divider = 100000000; // 8 decimal places
                                        // } else if (item.symbol === "LTC-USD") {
                                        //   sign = "LTC";
                                        //   divider = 100000000; // 8 decimal places
                                        // }
                                        recAmt =
                                          item.side === "buy"
                                            ? (<><span className="text-primary fw-bold">{(item.receive_amount / divider)?.toFixed(8)} </span> {sign}</>)
                                            : (<><span className="text-primary fw-bold">{(item.deliver_amount / divider)?.toFixed(8)} </span> {sign}</>);
                                        delAmt =
                                          item.side === "buy"
                                            ? (<>$<span className="text-primary fw-bold">{(item.deliver_amount / 100).toFixed(2)}</span> USD</>)
                                            : (<>$<span className="text-primary fw-bold">{(item.receive_amount / 100).toFixed(2)}</span> USD</>);

                                        crypAmtFee = <>$<span className="text-primary fw-bold">{(item.fee / 100).toFixed(2)} </span>USD</>
                                      } else if (item.product_type == "Crypto") {
                                        let divider;
                                        if (item.asset === "BTC") {
                                          divider = 100000000; // 8 decimal places
                                        } else if (item.asset === "ETH") {
                                          divider = 1000000000000000000; // 18 decimal places
                                        } else if (item.asset === "USDC") {
                                          divider = 1000000; // 18 decimal places
                                        } 
                                        // else if (item.asset === "BCH") {
                                        //   divider = 100000000; // 8 decimal places
                                        // } else if (item.asset === "LTC") {
                                        //   divider = 100000000; // 8 decimal places
                                        // }
                                        recAmt = (<><span className="text-primary fw-bold">{(item.receive_amount / divider)?.toFixed(8)}</span> {item.asset}</>)
                                        delAmt = (<><span className="text-primary fw-bold">{(item.receive_amount / divider)?.toFixed(8)}</span> {item.asset}</>)
                                        crypAmtFee = (<><span className="text-primary fw-bold">{item.fee / divider}</span> {item.asset}</>)
                                      } else if (item.product_type == "funding") {
                                        recAmt = (<>$<span className="text-primary fw-bold">{(item.deliver_amount / 100).toFixed(2)}</span> USD</>)
                                        delAmt = (<>$<span className="text-primary fw-bold">{(item.receive_amount / 100).toFixed(2)} </span> USD</>)
                                        crypAmtFee = (<>$<span className="text-primary fw-bold">{(item.fee / 100).toFixed(2)}</span> USD</>)
                                      }

                                      return (
                                        <tr key={item.guid}>
                                          <td style={{ whiteSpace: 'nowrap' }}>{findNameForCrypto(tempSymbol)}</td>
                                          <td style={{ whiteSpace: 'nowrap' }} className="d-flex flex-row gap-2">
                                            {renderAddress(item.guid)}
                                            <div className="text-end"> <OverlayTrigger overlay={
                                              <Tooltip>
                                                Copy
                                              </Tooltip>
                                            }>
                                              <span className="text-primary" type="button" id="button-addon2" onClick={() => copyaddress(item.guid)}><i className="fa fa-copy"></i></span>
                                            </OverlayTrigger>
                                            </div>
                                          </td>
                                          <td style={{ whiteSpace: 'nowrap' }} className="text-center">
                                            <span className={`${item.side === 'buy' ? 'mw-badge badge badge-primary' : 'mw-badge badge badge-danger'}`}>{item.side}</span>
                                          </td>
                                          <td style={{ whiteSpace: 'nowrap' }} className="text-center text-capitalize">
                                            <span className={`${item.product_type === 'funding' ? 'mw-badge badge badge-outline-primary' : 'mw-badge badge badge-outline-dark'}`}>{item.product_type}</span>
                                          </td>
                                          <td style={{ whiteSpace: 'nowrap' }} className="text-end ">{item.side == "buy" ? delAmt : recAmt}</td>
                                          <td style={{ whiteSpace: 'nowrap' }} className="text-end">{item.side == "buy" ? recAmt : delAmt}</td>
                                          <td style={{ whiteSpace: 'nowrap' }} className="text-end">{crypAmtFee}</td>
                                          <td style={{ whiteSpace: 'nowrap' }} className="text-end">{formatDateWithSeconds(item.issued_at)}</td>
                                          <td style={{ whiteSpace: 'nowrap' }} className="text-end">{formatDateWithSeconds(item.expires_at)}</td>
                                        </tr>
                                      )
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan={13} className="text-center">
                                        <p className="fw-bold">No quotes found.</p>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                              :
                              <div className="text-center my-5 py-5">
                                <Gifloader />
                              </div>
                            }
                            <div className={(isQuotesTabLoading || quotesList.length === 0) ? "d-none" : ""}>
                              <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next"
                                onPageChange={handleQuotePageClick}
                                pageCount={totalQuotesPages}
                                previousLabel="Previous"
                                containerClassName={'pagination justify-content-center my-3'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                activeClassName={'active'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </Tab>

                  <Tab eventKey="Trades" title="Trades">
                    {activeKeyis === "Trades" ? (
                      <div className="card-body py-0">
                        <div className="transaction-table">
                          <div className="table-responsive">
                            {!isTradesTabLoading ? (
                              <>
                                <Table hover responsive className="">
                                  <thead>
                                    <tr>
                                      <th className="">Symbol</th>
                                      <th className="">Quote GUID</th>
                                      <th className="text-center">Buy/Sell</th>
                                      <th className="text-end">Deliver Amount</th>
                                      <th className="text-end">Receive Amount</th>
                                      <th className="text-end">Fee</th>
                                      <th className="text-center">State</th>
                                      <th className="text-end">Created At</th>
                                      <th className="text-end">Updated At</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    {currentTradeItems && currentTradeItems.length !== 0 ? (
                                      currentTradeItems.map((item) => {
                                        let recAmt;
                                        let delAmt;
                                        let divider;
                                        let sign;
                                        let cryAmtFee;
                                        if (item.symbol === "BTC-USD") {
                                          sign = "BTC";
                                          divider = 100000000; // 8 decimal places
                                        } else if (item.symbol === "ETH-USD") {
                                          sign = "ETH";
                                          divider = 1000000000000000000; // 18 decimal places
                                        } else if (item.symbol === "USDC-USD") {
                                          sign = "USDC";
                                          divider = 1000000; // 8 decimal places
                                        }
                                        // else if (item.symbol === "BCH-USD") {
                                        //   sign = "BCH";
                                        //   divider = 100000000; // 8 decimal places
                                        // } else if (item.symbol === "LTC-USD") {
                                        //   sign = "LTC";
                                        //   divider = 100000000; // 8 decimal places
                                        // }
                                        recAmt =
                                          item.side === "buy"
                                            ? (<><span className="text-primary fw-bold">{(item.receive_amount / divider)?.toFixed(8)} </span> {sign}</>)
                                            : (<><span className="text-primary fw-bold">{(item.deliver_amount / divider)?.toFixed(8)} </span> {sign}</>);
                                        delAmt =
                                          item.side === "buy"
                                            ? (<>$<span className="text-primary fw-bold">{(item.deliver_amount / 100).toFixed(2)}</span> USD</>)
                                            : (<>$<span className="text-primary fw-bold">{(item.receive_amount / 100).toFixed(2)} </span> USD</>);
                                        cryAmtFee =<>$<span className="text-primary fw-bold">{(item.fee / 100).toFixed(2)} </span> USD</>

                                        return (
                                          <tr key={item.guid}>
                                            <td style={{ whiteSpace: 'nowrap' }}>{findNameForCrypto(item.symbol)}</td>
                                            <td style={{ whiteSpace: 'nowrap' }} className="d-flex flex-row gap-2">
                                              {renderAddress(item.quote_guid)}
                                              <div className="text-end"> <OverlayTrigger overlay={
                                                <Tooltip>
                                                  Copy
                                                </Tooltip>
                                              }>
                                                <span className="text-primary" type="button" id="button-addon2" onClick={() => copyaddress(item.quote_guid)}><i className="fa fa-copy"></i></span>
                                              </OverlayTrigger>
                                              </div>
                                            </td>
                                            <td style={{ whiteSpace: 'nowrap' }} className="text-center">
                                              <span className={`${item.side === 'buy' ? 'mw-badge badge badge-primary' : 'mw-badge badge badge-danger'}`}>{item.side}</span>
                                            </td>
                                            <td style={{ whiteSpace: 'nowrap' }} className="text-end">{item.side == "buy" ? delAmt : recAmt}</td>
                                            <td style={{ whiteSpace: 'nowrap' }} className="text-end">{item.side == "buy" ? recAmt : delAmt}</td>
                                            <td style={{ whiteSpace: 'nowrap' }} className="text-end">{cryAmtFee}</td>
                                            <td style={{ whiteSpace: 'nowrap' }} className="text-center"><span className={setTradesClassName(item.state)}>
                                              {item.state}
                                            </span></td>
                                            <td style={{ whiteSpace: 'nowrap' }} className="text-end">{formatDateWithSeconds(item.created_at)}</td>
                                            <td style={{ whiteSpace: 'nowrap' }} className="text-end">{formatDateWithSeconds(item.updated_at)}</td>

                                          </tr>
                                        )
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan={12} className="text-center">
                                          <p className="fw-bold">No trades found.</p>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                                <div className={(isTradesTabLoading || currentTradeItems.length === 0) ? "d-none" : ""}>
                                  <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Next"
                                    onPageChange={handleTradePageClick}
                                    pageCount={tradePageCount}
                                    previousLabel="Previous"
                                    containerClassName={'pagination justify-content-center my-3'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                  />
                                </div>
                              </>
                            )
                              :
                              <div className="text-center my-5 py-5">
                                <Gifloader />
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </Tab>

                  <Tab eventKey="Transfers" title="Transfers">
                    {activeKeyis === "Transfers" ? (
                      isTransfersTabLoading ? (
                        <div className="text-center my-5 py-5">
                          <Gifloader />
                        </div>
                      ) : (
                        <div className="card-body py-0">
                          <div className="transaction-table">
                            <div className="table-responsive">
                              <Table hover responsive className="">
                                <thead>
                                  <tr>
                                    <th className="">Asset</th>
                                    <th className="">Quote GUID</th>
                                    <th className="text-center">Type</th>
                                    <th className="text-center">Side</th>
                                    <th className="text-end">Amount</th>
                                    <th className="text-end">Fee</th>
                                    <th className="text-center">State</th>
                                    <th className="text-end">Created At</th>
                                    <th className="text-end">Updated At</th>


                                  </tr>
                                </thead>
                                <tbody>
                                  {transfersList && transfersList.length !== 0 ? (
                                    transfersList.map((item) => {
                                      let cryAmtfee;
                                      let amount
                                      if (item.transfer_type == "crypto") {
                                        let divider;
                                        if (item.asset === "BTC") {
                                          divider = 100000000; // 8 decimal places
                                        } else if (item.asset === "ETH") {
                                          divider = 1000000000000000000; // 18 decimal places
                                        } else if (item.asset === "USDC") {
                                          divider = 1000000; // 18 decimal places
                                        } 
                                        // else if (item.asset === "BCH") {
                                        //   divider = 100000000; // 8 decimal places
                                        // } else if (item.asset === "LTC") {
                                        //   divider = 100000000; // 8 decimal places
                                        // }
                                        amount = (<><span className="text-primary fw-bold">{(item.amount / divider)?.toFixed(8)} </span> {item.asset}</>)
                                        cryAmtfee = (<><span className="text-primary fw-bold">{item.fee / divider} </span> {item.asset}</>)
                                      } else if (item.transfer_type == "funding") {
                                        amount = (<>$<span className="text-primary fw-bold">{(item.amount / 100)?.toFixed(2)} </span> USD</>)
                                        cryAmtfee =(<>$<span className="text-primary fw-bold">{(item.fee / 100)?.toFixed(2)} </span> USD</>)
                                      }
                                      return (
                                        <tr key={item.guid}>
                                          <td style={{ whiteSpace: 'nowrap' }}>{findNameForCrypto(item.asset)}</td>
                                          <td style={{ whiteSpace: 'nowrap' }} className="d-flex flex-row gap-2">
                                            {renderAddress(item.quote_guid)}
                                            <div className="text-end"> <OverlayTrigger overlay={
                                              <Tooltip>
                                                Copy
                                              </Tooltip>
                                            }>
                                              <span className="text-primary" type="button" id="button-addon2" onClick={() => copyaddress(item.quote_guid)}><i className="fa fa-copy"></i></span>
                                            </OverlayTrigger>
                                            </div>
                                          </td>
                                          <td style={{ whiteSpace: 'nowrap' }} className="text-capitalize text-center">
                                            <span className={`${item.transfer_type === 'funding' ? 'mw-badge badge badge-outline-primary' : 'mw-badge badge badge-outline-dark'}`}>{item.transfer_type}</span>
                                          </td>
                                          <td style={{ whiteSpace: 'nowrap' }} className="text-capitalize text-center">
                                            <span className={`${item.side === 'deposit' ? 'mw-badge badge badge-primary' : 'mw-badge badge badge-danger'}`}>{item.side}</span>
                                          </td>
                                          <td style={{ whiteSpace: 'nowrap' }} className="text-end">{amount}</td>
                                          <td style={{ whiteSpace: 'nowrap' }} className="text-end">{cryAmtfee}</td>
                                          <td style={{ whiteSpace: 'nowrap' }} className="text-center"><span className={setTransfersClassName(item.state)}>
                                            {item.state}
                                          </span></td>
                                          <td style={{ whiteSpace: 'nowrap' }} className="text-end">{formatDateWithSeconds(item.created_at)}</td>
                                          <td style={{ whiteSpace: 'nowrap' }} className="text-end">{formatDateWithSeconds(item.updated_at)}</td>

                                        </tr>
                                      )
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan={23} className="text-center">
                                        <p className="fw-bold">No transfers found.</p>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      )
                    ) : null}
                  </Tab>

                  <Tab eventKey="IdentityVerifications" title="Identity Verification">
                    {activeKeyis === "IdentityVerifications" ? (
                      isIdentityVerificationTabLoading ? (
                        <div className="text-center my-5 py-5">
                          <Gifloader />
                        </div>
                      ) : (
                        <div className="card-body py-0">
                          <div className="transaction-table">
                            <div className="table-responsive">
                              <Table hover responsive className="">
                                <thead>
                                  <tr>
                                    <th>Type</th>
                                    <th>GUID</th>
                                    <th>Customer GUID</th>
                                    <th>Method</th>
                                    <th className="text-center">State</th>
                                    <th className="text-center">Outcome</th>
                                    <th style={{ whiteSpace: 'nowrap' }} className="text-center">Failure Code</th>
                                    <th className="text-end">Created At</th>
                                    <th className="text-end">Updated At</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {customerIdentityVerificationList && customerIdentityVerificationList.length !== 0 ? (
                                    customerIdentityVerificationList.map((item) => (
                                      <tr key={item.guid}>
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-uppercase">{item.type}</td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="d-flex flex-row gap-2">
                                          {renderAddress(item.guid)}
                                          <div className="text-end"> <OverlayTrigger overlay={
                                            <Tooltip>
                                              Copy
                                            </Tooltip>
                                          }>
                                            <span className="text-primary" type="button" id="button-addon2" onClick={() => copyaddress(item.guid)}><i className="fa fa-copy"></i></span>
                                          </OverlayTrigger>
                                          </div>
                                        </td>
                                        <td style={{ whiteSpace: 'nowrap' }}>{item.customer_guid}</td>
                                        <td style={{ whiteSpace: 'nowrap' }}>{item.method}</td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-center"><span className={`${item.state === 'completed' ? 'mw-badge badge badge-success' : (item.state === 'storing' || item.state === 'waiting') ? 'mw-badge badge badge-warning' : item.state === 'expired' ? 'mw-badge badge badge-danger1' : ""}`}>{item.state}</span></td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-center"><span className={`${item.outcome === "passed" ? 'mw-badge badge badge-success' : item.outcome === "failed" ? 'mw-badge badge badge-danger1' : ""}`}>{item?.outcome === null ? "-" : item?.outcome}</span></td>
                                        {item.failure_codes && item.failure_codes.length > 0 ? (
                                          item.failure_codes.map(code => (
                                            <td key={code} style={{ whiteSpace: 'nowrap' }} className="text-center">
                                              <span className="mw-badge badge badge-danger1 w-100">{code}</span>
                                            </td>
                                          ))
                                        ) : (
                                          <td style={{ whiteSpace: 'nowrap' }} className="text-center">-</td>
                                        )}
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-end">{formatDateWithSeconds(item.created_at)}</td>
                                        <td style={{ whiteSpace: 'nowrap' }} className="text-end">{formatDateWithSeconds(item.updated_at)}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={9} className="text-center">
                                        <p className="fw-bold text-capitalize">No details found.</p>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      )
                    ) : null}
                  </Tab>


                </Tabs>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
